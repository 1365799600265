import { takeEvery, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { apiWrapper } from 'utils/reduxUtils';
import {
  loginApi,
  // deleteInstallationApi,
  logoutApi,
  updateCurrentUserApi,
  getCurrentUserApi,
  resetPasswordApi,
  forgotPasswordApi,
  registerApi,
  registerWithTokenApi,
  getPermissionsApi,
} from 'api/user';
import {
  loginSuccess,
  loginFail,
  getCurentUserSuccess,
  getCurentUserFailure,
  updateUserSuccess,
  updateUserFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  updateUser,
  forgotPassword,
  getCurentUser,
  login,
  logout,
  resetPassword,
  register,
  registerWithToken,
  registerFail,
  registerSuccess,
  getPermissions,
  // getPermissionsSuccess,
  getPermissionsFail,
  getPermissionsSuccess,
} from './slice';
// import { clearSummariesData } from '../config/slice';

function* loginSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: true,
        isShowSuccessNoti: false,
      },
      loginApi,
      payload,
    );
    if (response) {
      localStorage.setItem('sessionToken', response.data.token.access_token);
      localStorage.setItem('name', response.data.user.name);
      localStorage.setItem('id', response.data.user.id);
      yield put(loginSuccess(response.data.user));
      if (response.data.user?.isFirstLogin) {
        yield put(push('/profile'));
      }
      // yield put(getSummaries());
    } else {
      yield put(loginFail(response));
    }
  } catch (error) {
    yield put(loginFail(error));
  }
}

function* logoutSaga() {
  try {
    yield call(
      apiWrapper,
      {
        isShowLoading: false,
        isShowSuccessNoti: false,
      },
      logoutApi,
      // payload,
    );

    localStorage.removeItem('sessionToken');
    localStorage.removeItem('name');
    localStorage.removeItem('id');
    localStorage.removeItem('urlExpiredToken');
    // yield put(clearSummariesData());
    // const installationId = localStorage.getItem('installationId');
  } catch (error) {
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('name');
    localStorage.removeItem('id');
    // /logic here
  }
}

function* getCurrentUserSaga() {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: false,
        isShowSuccessNoti: false,
      },
      getCurrentUserApi,
      // {
      //   includes: ['role'],
      // },
      localStorage.getItem('id'),
    );
    if (response) {
      localStorage.setItem('name', response.name);
      localStorage.setItem('id', response.id);
      yield put(getCurentUserSuccess(response));
    } else {
      yield put(getCurentUserFailure(response));
    }
  } catch (error) {
    yield put(getCurentUserFailure(error));
  }
}

function* updateUserSaga({
  payload: { id, successAction, failureAction, ...data },
}) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: true,
        isShowSuccessNoti: true,
      },
      updateCurrentUserApi,
      id,
      data,
    );
    if (response) {
      if (successAction)
        yield put(successAction({ data: { ...data, ...response.data } }));
      else yield put(updateUserSuccess({ ...data, ...response.data }));
    } else {
      yield put(updateUserFailure(response));
    }
  } catch (error) {
    if (failureAction) yield put(failureAction({ error, data: { id, data } }));
    else yield put(updateUserFailure(error));
  }
}

function* forgotPasswordSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: true,
        isShowSuccessNoti: true,
      },
      forgotPasswordApi,
      payload,
    );
    yield put(forgotPasswordSuccess(response));
  } catch (error) {
    yield put(forgotPasswordFailure(error));
  }
}

function* resetPasswordSaga({ password, resetPasswordToken }) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: true,
        isShowSuccessNoti: true,
      },
      resetPasswordApi,
      {
        password,
        resetPasswordToken,
      },
    );
    yield put(resetPasswordSuccess(response));
    yield put(push('/login'));
  } catch (error) {
    yield put(resetPasswordFailure(error));
  }
}

function* registerSaga({ params }) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: true,
        isShowSuccessNoti: false,
      },
      registerApi,
      params,
    );
    if (response.token) {
      localStorage.setItem('sessionToken', response.token);
      yield put(registerSuccess(response));
      yield put(getCurentUser());
    } else {
      yield put(registerFail(response));
    }
  } catch (error) {
    yield put(registerFail(error));
  }
}

function* registerWithTokenSaga({ params }) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: true,
        isShowSuccessNoti: false,
      },
      registerWithTokenApi,
      params,
    );
    if (response.token) {
      localStorage.setItem('sessionToken', response.token);
      yield put(registerSuccess(response));
      yield put(getCurentUser());
    } else {
      yield put(registerFail(response));
    }
  } catch (error) {
    yield put(registerFail(error));
  }
}

function* getPermissionsSaga() {
  try {
    const response = yield call(
      apiWrapper,
      { isShowLoading: true, isShowSuccessNoti: false },
      getPermissionsApi,
    );
    yield put(getPermissionsSuccess(response.data));
  } catch (error) {
    yield put(getPermissionsFail(error));
  }
}

export default [
  takeEvery([login.type], loginSaga),
  takeEvery([logout.type], logoutSaga),
  takeEvery([getCurentUser.type], getCurrentUserSaga),
  takeEvery([updateUser.type], updateUserSaga),
  takeEvery([forgotPassword.type], forgotPasswordSaga),
  takeEvery([resetPassword.type], resetPasswordSaga),
  takeEvery([register.type], registerSaga),
  takeEvery([registerWithToken.type], registerWithTokenSaga),
  takeEvery([getPermissions.type], getPermissionsSaga),
];
