import styled from 'styled-components';

const CreateStyles = styled.div`
  .content .ant-form > div {
    display: flex;
    flex-direction: column;
  }
  .form-section {
    padding: 0;
    .ant-col {
      width: 100%;
    }
  }
  .avatar-section {
    padding: 0 24px 24px;
  }
`;

export default CreateStyles;
