import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
// import { updateUser } from 'redux/auth/slice';
import ProfileForm from '../Form';
import ProfileDetail from '../ProfileDetail';
import ProfileInfoStyles from './styles';
import ButtonGroup from '../ButtonGroup';

const ProfileInfo = props => {
  const [isEdit, setIsEdit] = useState(false);
  const handleClick = () => {
    setIsEdit(!isEdit);
  };
  const isFirstLogin = useSelector(state => state.auth.isFirstLogin);

  useEffect(() => {
    props.isProfile && isFirstLogin && setIsEdit(true);
    // eslint-disable-next-line
  }, [isFirstLogin]);

  return (
    <ProfileInfoStyles>
      {isEdit ? (
        <div className="edit-section">
          <ProfileForm isProfile={props.isProfile} />
          <div className="action-section">
            <ButtonGroup
              isProfile={props.isProfile}
              setIsEdit={value => setIsEdit(value)}
            />
          </div>
        </div>
      ) : (
        <ProfileDetail {...props} handleClick={handleClick} />
      )}
    </ProfileInfoStyles>
  );
};

ProfileInfo.propTypes = {
  isProfile: PropTypes.bool,
};

export default Form.create()(ProfileInfo);
