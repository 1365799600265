import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import styled from 'styled-components';
import { Button } from 'antd';

const StyledButton = styled(Button)`
  && {
    height: 32px;
    ${'' /* border-radius: 2px; */}
  }
`;

const CreateButton = ({ header, gotoCreatePage, source, style }) => (
  <StyledButton source={source} type="primary" onClick={gotoCreatePage} style={style}>
    {/* <span className="t-500-14px-1.57">{I18n.t(header)}</span> */}
    <span>{I18n.t(header)}</span>
  </StyledButton>
);
CreateButton.propTypes = {
  gotoCreatePage: PropTypes.func,
  header: PropTypes.string,
  source: PropTypes.string,
  style: PropTypes.object,
};

CreateButton.defaultProps = {
  source: 'create',
  header: 'button.create',
};

export default CreateButton;
