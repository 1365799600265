import styled from 'styled-components';
import { Modal } from 'antd';

export const FormMultiUploadWrapper = styled.div`
  .control-form-item input {
    display: none;
  }
  .control-form-item {
    margin-bottom: 10px;
  }
  & > span {
    ${'' /* min-height: 200px; */}
    display: flex;
    ${'' /* margin-bottom: 20px; */}
  }
  .ant-upload-list {
    margin-left: 8px;
  }
  .uploadArea {
    margin: auto;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .anticon {
      color: ${({ theme }) => theme.border.default};
      font-size: 50px !important;
      margin-bottom: 10px;
    }
    color: ${({ theme }) => theme.text.secondary};
    .ant-upload-text {
      display: flex;
      flex-direction: column;
      .select-media-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #1890FF;
    }
    }
    
  }
  .ant-upload.ant-upload-drag .ant-upload-btn {
    height: auto;
    padding: 15px;
  }
  .ant-upload.ant-upload-drag {
    width: 100%;
    height: 100%;
    ${'' /* position: absolute; */}
    background: transparent;
    border: 2px dashed ${({ theme }) => theme.border.default};
    color: ${({ theme }) => theme.text.secondary};
    top: 0;
    .ant-upload-drag-container {
      ${'' /* text-align: left;
      vertical-align: top; */}
    }
    .overlayImage {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${({ theme }) => theme.background.loadingBackgroundColor};
      opacity: 0;
      transition: 300ms ease all;
      img {
        transition: 300ms ease all;
        width: 200px;
        height: 200px;
        object-fit: contain;
        transform: scale(0.5);
      }
    }
    &:hover {
      .uploadArea {
        .anticon, .select-media-text {
          color: ${({ theme }) => theme.palette.primary};
        }
        color: ${({ theme }) => theme.palette.primary};
      }
    }
  }
  .ant-upload.ant-upload-drag-hover {
    .overlayImage {
      opacity: 1;
      img {
        transform: scale(1);
      }
    }
  }
  .file-item {
    display: flex;
    ${'' /* flex-direction: column; */}
    align-items: center;
    height: 100%;
    padding: 10px;

    & > .name-file {
      ${'' /* margin-top: 10px; */}
      padding-left: 10px;
      ${'' /* max-width: 100px; */}
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
    .div-item-image {
      position: relative;
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      & > i.icon-file {
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ${'' /* border: 1px solid ${({ theme }) => theme.border.default}; */}
    }
    .loading {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
    }
    .error {
      color: ${({theme}) => theme.color.red}
    }
  }

  .uploadImage {
    ${'' /* display: inline-flex;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: center; */}
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${({ theme }) => theme.background.cancelButton};
    border-radius: 4px;
    overflow: hidden;
    margin: 5px;
    position: relative;
    z-index: 2;
    ${'' /* background: ${({ theme }) => theme.background.content}; */}
    .lbSetDefault {
      background: ${({ theme }) => theme.background.disabled};
      color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      cursor: pointer;
      visibility: hidden;
      &.active {
        visibility: visible;
        background: ${({ theme }) => theme.palette.primary};
      }
      &:hover {
        visibility: visible;
        background: ${({ theme }) => theme.palette.primary};
      }
    }
    .icon-file {
      font-size: 30px;
    }
    .image {
      object-fit: contain;
      width: 45px;
      height: 45px;
      ${'' /* border-radius: 4px;
      background: ${({ theme }) => theme.background.loadingBackgroundColor}; */}
    }
    .overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 5px;
      bottom: 0px;
      width: 100px;
      height: 100px;
      display: flex;
      z-index: 2;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.3);
      visibility: hidden;
      &:hover {
        visibility: visible;
        & ~ .lbSetDefault {
          visibility: visible;
        }
      }
      .anticon {
        color: #fff;
        font-size: 24px;
        margin: 5px;
      }
    }

    &:hover {
      .overlay {
        visibility: visible;
      }
    }
    .image:hover ~ .overlay {
      visibility: visible;
    }
    .loading {
      position: absolute;
    }
    .remove-button {
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      height: 100%;
      color: ${({ theme }) => theme.text.primary};
      font-size: 20px;
      &:hover, &:active{
        color: ${({ theme }) => theme.palette.primary};
      }
    }
  }
  .selectedImage {
    max-height: 100%;
    overflow-y: auto;
    height: 100%;
    .list-file-upload {
      display: flex;
      ${'' /* align-items: stretch; */}
      ${'' /* flex-wrap: wrap; */}
      justify-content: center;
      flex-direction: column;
    }
  }
`;

export const ModalViewStyles = styled(Modal)`
  .ant-modal-close-x {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
`;
