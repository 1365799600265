import React from 'react';
import {Button} from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { ModalWrapper } from './styles';

const ModalCustom = (props) => {
  return(
    <ModalWrapper
      width={800}
      footer={[
        <Button key="back" onClick={props.onCancel} className="cancel-button">
          {i18next.t('button.cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={props.loading}
          onClick={props.onOk}
          disabled={props.isDisabled}
          className="submit-button"
        >
          {i18next.t('button.save')}
        </Button>,
    ]}
      {...props}
      destroyOnClose
      maskClosable={false}
    />
  )
  
};

ModalCustom.propTypes = {
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  onOk: PropTypes.func,
  isDisabled: PropTypes.bool,
};

ModalCustom.defaultProps = {
  onCancel: () => {},
  loading: false,
  onOk: () => {},
  isDisabled: false,
};

export default ModalCustom;
