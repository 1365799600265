import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { Icon } from 'antd';
import { goBack as goBackAction, replace } from 'connected-react-router';
import { closeModal as closeModalAction } from 'redux/modal/slice';
import CRUDActions from '../../../redux/crudActions';
import RestShowComponent from '../../../components/RestLayout/Show';
import crudSelectors from '../../../redux/crudSelectors';
import { PRIMARY_KEY } from '../../../redux/crudCreator/slice';
import { getIdByUrl, getIdByLocation } from '../../../utils/tools';
import Text from '../../../components/common/Text';

class RestShow extends Component {
  // componentWillReceiveProps(nextProps) {
  //   console.log(nextProps, 'nextProps')
  //   // check current props and nextPros of dealLoaded flag. If dealLoaded was false, and is true, which means the data is fetched, then we should reset isFlushed
  //   if (!this.props.dealLoaded && nextProps.dealLoaded) {
  //     this.setState({
  //       isFlushed: false
  //     });
  //   }
  //   // // since we assigned the location.state in <Link>, if we see this prop, and the data is not flushed yet, then flush data, in this case we call loadDeals(), which is a redux action
  //   if (!this.state.isFlushed && nextProps.location.state === 'flushDeal')) {
  //     this.setState({
  //       isFlushed: true,
  //     }, () => this.props.retrieveOneRecord(getIdByUrl(this.props)));
  //   }
  // }

  componentDidMount() {
    this.props.retrieveOneRecord(getIdByUrl(this.props)); 

    this.unregisterHistoryListener = this.props.history?.listen((location) => {
      // console.log(location, 'this.props');
      if(location?.state === 'flushDeal') {
        const id = getIdByLocation(location, this.props.customResource || this.props.resource)
        if(id) {
          this.props.retrieveOneRecord(id);
        }
      }
    });
  }

  componentWillUnmount() {
    this.props.clearCurrent();
    if(this.unregisterHistoryListener) this.unregisterHistoryListener();
  }

  onBack = () => {
    const { visibleModal, goBack, closeModal } = this.props;
    // console.log(this.props, 'nhihi')
    if (!visibleModal) {
      goBack();
    } else {
      closeModal();
    }
  };

  render() {
    const {showModal, header, resource } = this.props;
    return !showModal ? (
      <RestShowComponent {...this.props} />
    ) : (
      <>
        {header !== null && (
          <Text type="h4" className="modalTitleContent">
            <div className="modalTitle">
              {!header || typeof header === 'string'
                ? I18n.t(header || `${resource}.showPage`)
                : header}
            </div>
            <Icon onClick={this.onBack} className="modalBtnBack" type="ic-close" />
          </Text>
        )}
        <RestShowComponent {...this.props} />
      </>
    );
  }
}
RestShow.propTypes = {
  retrieveOneRecord: PropTypes.func,
  goBack: PropTypes.func,
  showModal: PropTypes.bool,
  header: PropTypes.any,
  resource: PropTypes.string,
  isDeleted: PropTypes.bool,
  clearCurrent: PropTypes.func,
  history: PropTypes.object,
  visibleModal: PropTypes.bool,
  closeModal: PropTypes.func,
  customResource: PropTypes.string,
};

RestShow.defaultProps = {
  isDeleted: true,
};

const mapStateToProps = (state, props) => ({
  record: crudSelectors[props.resource].getCurrentData(state),
  location: state.router.location,
});

const mapDispatchToProps = (dispatch, props) => ({
  retrieveOneRecord: (id) =>
    dispatch(
      CRUDActions[props.resource].getById(
        {
          [PRIMARY_KEY]: id,
        },
        { ...props.defaultOptions },
      ),
    ),
  clearCurrent: () => dispatch(CRUDActions[props.resource].clearCurrent()),
  goBack: () => dispatch(goBackAction()),
  // gotoEditPage: id =>
  //   props.history.push(
  //     `${props.match.path.replace('/:id/show', '')}/${id}/edit`,
  //   ),
  gotoEditPage: (id) =>
    dispatch(
      replace(`${props.location.pathname}#${[props.resource]}/${id}/edit`),
    ),
  deleteItem: (id) => {
    dispatch(
      CRUDActions[props.resource].del(
        {
          [PRIMARY_KEY]: id,
        },
        { ...props.defaultOptions, isBack: true, isDeleted: props.isDeleted },
      ),
    );
    // dispatch(replace(props.match.path.replace('/:id/show', ''));
  },
  closeModal: () => dispatch(closeModalAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RestShow);
