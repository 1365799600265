import React from 'react';
// import isEmpty from 'lodash';
import { useSelector } from 'react-redux';
import Create from '../../rest/Create';
import Form from '../components/Form';

const InternalMessagesCreate = props => {
  const filter = useSelector(state => state.internalMessages.filter);
  return (
    <Create
      {...props}
      resource="internalMessages"
      customTextBtnCreate="button.send"
      defaultOptions={{
        customApiResource: 'internaltasks',
        isGetAll: filter.isSent,
        notReloadAfterCreate: !filter.isSent,
      }}
      formatOnSubmit={({ activities, medias, title, content, ...data }) => {
        return {
          ...data,
          title: title && title.trim(),
          content: content && content.trim(),
          ...(activities && {
            activity: {
              items: activities[0].items.map(item => ({ userId: item })),
            },
          }),
          ...(medias && { mediaIds: medias.map(item => item.id || item) }),
          taskType: 0,
        };
      }}
    >
      <Form />
    </Create>
  );
};

InternalMessagesCreate.propTypes = {};

export default InternalMessagesCreate;
