import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import NotificationList from 'containers/Notifications/List';

import { ContentPopoverStyles } from './styles';

const Notifications = (props) => {

  return (
    <ContentPopoverStyles>
      <div className="title-popover">{i18next.t('announcements.header')}</div>
      <NotificationList {...props} />
      {/* <Link
        to="/announcements"
        className="view-all"
        onClick={() => props.handleHidden(false)}
      >
        {i18next.t('announcements.viewAll')}
      </Link> */}
    </ContentPopoverStyles>
  );
};

Notifications.propTypes = {
  handleHidden: PropTypes.func,
};

export default Notifications;
