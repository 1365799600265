import PropTypes from 'prop-types';
import React from 'react';
import ContentTextStyles from './styles';

const ContentText = ({content}) => {
  return(
    <ContentTextStyles className="content-text">
      {content}
    </ContentTextStyles>
  )
}

ContentText.propTypes = {
  content: PropTypes.string,
}

export default ContentText;