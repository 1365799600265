import styled from 'styled-components';

const ListStyles = styled.div`
  .time-file {
    margin-top: 5px;
    color: #8d8a8a;
  }
  .name-file {
    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.palette.primary} !important;
      .anticon {
        color: ${({ theme }) => theme.palette.primary} !important;
      }
    }
  }
  .file-archive {
    font-size: 15px;
    font-weight: bold;
  }
`;

export default ListStyles;