import { call, put, cancel, fork, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import _ from 'lodash';
import { apiWrapper } from 'utils/reduxUtils';
import { getAllApi } from 'api/crud';
import { convertResponseData } from '../crudCreator/dataProvider';
import {
  retrieveReferenceFailed,
  retrieveReferenceSuccess,
  retrieveReference,
} from './slice';

const debouncedIds = {};
const mappeds = {};
const tasks = {};

const addIds = (resource, ids) => {
  if (!debouncedIds[resource]) {
    debouncedIds[resource] = [];
  }
  debouncedIds[resource] = _.flatten(_.union(debouncedIds[resource], ids));
};

const addMappedBy = (resource, mappedBy) => {
  if (!mappeds[resource]) {
    mappeds[resource] = [];
  }
  mappeds[resource] = mappedBy;
};

function* finalize(resource, options) {
  // combined with cancel(), this debounces the calls
  yield call(delay, 50);
  yield fork(retrieveReferenceList, resource, options);
  delete tasks[resource];
  delete debouncedIds[resource];
  delete mappeds[resource];
}

export function* retrieveReferenceSaga({
  payload: { resource, ids, mappedBy },
}) {
  if (tasks[resource]) {
    yield cancel(tasks[resource]);
  }
  addIds(resource, ids);
  addMappedBy(resource, mappedBy);
  tasks[resource] = yield fork(finalize, resource, { primaryKey: mappedBy });
}

export function* retrieveReferenceList(resource, options) {
  try {
    const params = {
      pageSize: 100,
      pageNumber: 1,
      filter: JSON.stringify({
        [mappeds[resource] || 'id']: {
          $in: debouncedIds[resource],
        },
      }),
    };
    const response = yield call(
      apiWrapper,
      { isShowProgress: false },
      getAllApi,
      resource,
      params,
    );
    const result = convertResponseData('GET_ALL', response, options);
    yield put(retrieveReferenceSuccess({ resource, data: result }));
  } catch (error) {
    yield put(retrieveReferenceFailed({ resource, error }));
  }
}

export default [takeEvery([retrieveReference.type], retrieveReferenceSaga)];
