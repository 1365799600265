import styled from 'styled-components';

const LogoutStyles = styled.div`
  .ant-btn {
    width: 100%;
    ${'' /* display: flex;
    align-items: center; */}
    ${'' /* i{
      color: #fff;
    } */}
  }  
`;

export default LogoutStyles;