import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import RestMultiPhotos from 'components/RestInput/RestMultiPhotos';
import { PRIORITY } from 'configs/localData';
import i18next from 'i18next';
import TextArea from 'antd/lib/input/TextArea';

const AnnouncementsForm = props => {
  return (
    <div {...props}>
      <RestSelect
        required
        source="priority"
        header="announcements.priority"
        resourceData={PRIORITY}
        valueProp="value"
        titleProp="text"
        formatText={data => i18next.t(data)}
        allowClear={false}
        defaultValue={PRIORITY[1]?.value}
        ruleType="number"
        isShowSearch={false}
      />
      <RestInputItem
        required
        source="title"
        header="announcements.title"
        // ContentComponent={TextArea}
        // rows={2}
        placeholder="announcements.title"
        rules={[
          {
            max: 500,
            message: i18next.t('input.title.validateMsg.maxLength', {
              max: 500,
            }),
          },
        ]}
      />
      <RestInputItem
        required
        source="content"
        header="announcements.content"
        ContentComponent={TextArea}
        rows={6}
        placeholder="announcements.content"
        rules={[
          {
            max: 500,
            message: i18next.t('input.content.validateMsg.maxLength', {
              max: 500,
            }),
          },
        ]}
      />
      <RestMultiPhotos
        resource="announcements"
        defaultSourceKey="medias"
        source="medias"
        placeholder="announcements.medias"
        typeFile={1}
      />
    </div>
  );
};

AnnouncementsForm.propTypes = {};

export default AnnouncementsForm;
