import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { List, Col, Row, Card } from 'antd';
import { checkRole } from 'utils/tools';
import { connect } from 'react-redux';
import { getAction } from '../TableLayout';

class RestListLayout extends Component {
  onChangePagination = ({ current, pageSize }) => {
    const { resourceFilter } = this.props;
    this.props.retrieveList({
      pageNumber: current,
      pageSize,
      filter: resourceFilter.filter,
    });
  };

  onChangeRecord(record, item) {
    switch (item.props.type) {
      case 'switch':
        return this.props.updateRecord(
          record.id,
          {
            [item.props.source]: !record[item.props.source],
          },
          true,
        );

      default:
        return null;
    }
  }

  onChangePage = page => {
    const { resourceFilter } = this.props;
    this.props.retrieveList({
      pageNumber: page,
      pageSize: resourceFilter.pageSize || 15,
      filter: resourceFilter.filter,
    });
  };

  checkRoleAction = (element, record) => {
    const acceptRole = checkRole(element.props.roles, this.props.currentPermission);
    
    if(element.props.checkShowAction && acceptRole) {
      return element.props.checkShowAction(record);
    }
    return acceptRole;
  }

  renderListItem = record => {
    const { children } = this.props;
    const actionGroup =
      Array.isArray(children) &&
      children.find(
        element => element && element.props.source === 'actionGroup',
      );
    const actions =
      Array.isArray(children) && actionGroup
        ? React.Children.map(actionGroup.props.children, (item, index) =>
          this.checkRoleAction(item, record) ? (
            React.cloneElement(item, {
              key: String(index),
              record,
              table: true,
              list: true,
              onChange: () => this.onChangeRecord(record, item),
              ...getAction(this.props, item),
            })) : null,
        )
        : [];
    return Array.isArray(children) ? (
      <Card className="item" actions={actions}>
        <Row>
          {React.Children.map(children, (item, index) => {
            if (!item || item.props.source === 'actionGroup') return null;
            return (
              <Col span={24} key={`${item.props.header}_${String(index)}`}>
                <div className="title">{I18n.t(item.props.header)}</div>
                {React.cloneElement(item, {
                  record,
                  table: true,
                  list: true,
                  onChange: () => this.onChangeRecord(record, item),
                  ...getAction(this.props, item),
                })}
              </Col>
            );
          })}
        </Row>
      </Card>
    ) : (
      React.cloneElement(children, {
        record,
        table: true,
        list: true,
        onChange: () => this.onChangeRecord(record),
        ...getAction(this.props, { props: {} }),
      })
    );
  };

  render() {
    const {
      resourceData,
      gotoEditPage,
      deleteItem,
      gotoShowPage,
      responseRender,
      isList,
      resourceFilter,
      grid,
      rowClassName,
    } = this.props;

    // console.log(rowClassName, 'rowClassName')

    return (
      <List
        grid={grid || { gutter: 16 }}
        pagination={{
          position: 'none',
          onChange: this.onChangePage,
          pageSize: resourceFilter.pageSize || 15,
        }}
        style={{ marginTop: 20 }}
        dataSource={resourceData || []}
        renderItem={(record, index) => (
          <List.Item
            key={String(index)}
            className={rowClassName && rowClassName(record)}
          >
            {responseRender && !isList
              ? responseRender(record, {
                  gotoShowPage,
                  deleteItem,
                  gotoEditPage,
                })
              : this.renderListItem(record)}
          </List.Item>
        )}
      />
    );
  }
}

RestListLayout.propTypes = {
  retrieveList: PropTypes.func,
  resourceData: PropTypes.array,
  resourceFilter: PropTypes.object,
  updateRecord: PropTypes.func,
  responseRender: PropTypes.func,
  gotoEditPage: PropTypes.func,
  gotoShowPage: PropTypes.func,
  deleteItem: PropTypes.func,
  children: PropTypes.any,
  isList: PropTypes.bool,
  grid: PropTypes.object,
  rowClassName: PropTypes.func,
  currentPermission: PropTypes.array,
};

export default connect(
  (state) => ({
    currentPermission: state.auth.permissionData,
  }),
)(RestListLayout);
