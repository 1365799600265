import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Input, Switch } from 'antd';
import i18n from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getDepartments, getPositions } from 'redux/config/selectors';
import RestAvatarInput from 'components/RestInput/RestAvatarInput';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { validateRegex } from 'utils/validateUtils';
import { actions } from 'redux/users/slice';
import CRUDActions from 'redux/crudActions';
import { checkRole } from 'utils/tools';
import FormStyles from './styles';

const Form = ({ isCreate, isProfile }) => {
  const dataDepartments = useSelector(state => getDepartments(state));
  const dataPositions = useSelector(state => getPositions(state));
  const permissionData = useSelector(state => state.auth.permissionData);
  const dispatch = useDispatch();
  return (
    <FormStyles>
      <RestAvatarInput
        style={{
          // marginBottom: 10,
          width: 150,
          height: 150,
          borderRadius: '50%',
        }}
        className="avatar-section"
        defaultIcon="picture"
        defaultText="picture"
        cropDimension={{ width: 300, height: 300 }}
        hasCrop
        source="avatarUrl"
        typeFile={0}
        // header={i18n.t('profile.uploadAvatar')}
        {...(!isCreate && {
          onUploadSuccess: (record, url) => {
            dispatch(
              actions.uploadAvatar({
                id: record?.id,
                avatarUrl: url,
                ...(!isProfile && {
                  successAction: (response) =>
                    CRUDActions.users.editSuccess(response),
                }),
                ...(!isProfile && {
                  failureAction: (response) =>
                    CRUDActions.users.editFailure(response),
                }),
              }),
            );
          },
        })}
      />
      <Row gutter={24} className="form-section">
        {/* <Col span={24}>
            <RestInputItem
              defaultValue={record?.username}
              source="username"
              header="profile.username"
            />
          </Col> */}
        {isCreate && (
          <Col md={12} xs={24}>
            <RestInputItem
              required
              source="userName"
              header="profile.username"
              placeholder="input.username.placeholder"
              rules={[
                {
                  pattern: validateRegex.username,
                  message: i18n.t('input.username.validateMsg.pattern'),
                },
              ]}
            />
          </Col>
        )}
        <Col md={12} xs={24}>
          <RestInputItem
            required
            source="name"
            header="profile.fullName"
            placeholder="input.fullName.placeholder"
            rules={[
              {
                max: 255,
                message: i18n.t('input.fullName.validateMsg.maxLength', {
                  max: 255,
                }),
              },
            ]}
          />
        </Col>
        <Col md={12} xs={24}>
          <RestInputItem
            source="email"
            header="profile.email"
            placeholder="input.email.placeholder"
            rules={[
              {
                type: 'email',
                message: i18n.t('input.email.validateMsg.invalid'),
              },
              {
                max: 255,
                message: i18n.t('input.email.validateMsg.maxLength', {
                  max: 255,
                }),
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <RestInputItem
            source="phoneNumber"
            header="profile.mobilePhone"
            rules={[
              {
                pattern: validateRegex.phone,
                message: i18n.t('error.phone'),
              },
            ]}
            placeholder="profile.mobilePhone"
          />
        </Col>
        <Col span={12}>
          <RestInputItem
            source="telephoneNumber"
            header="profile.deskPhone"
            rules={[
              {
                pattern: validateRegex.phone,
                message: i18n.t('error.phone'),
              },
            ]}
            placeholder="profile.deskPhone"
          />
        </Col>
        {/* <Col span={12}>
            <RestSelect
              resourceData={GENDERS}
              defaultValue={record?.gender}
              header="profile.gender"
              source="gender"
              titleProp="text"
              valueProp="value"
              formatText={text => i18n.t(text)}
            />
          </Col> */}
        {checkRole(['AllowAll', 'PositionEdit'], permissionData) && (
          <Col md={12} xs={24}>
            <RestSelect
              required
              resourceData={dataDepartments}
              header="profile.department"
              source="department.id"
              titleProp="name"
              valueProp="id"
              placeholder="input.department.placeholder"
            />
          </Col>
        )}
        {checkRole(['AllowAll', 'DepartmentEdit'], permissionData) && (
          <Col md={12} xs={24}>
            <RestSelect
              required
              resourceData={dataPositions}
              header="profile.position"
              source="positions"
              titleProp="name"
              valueProp="id"
              placeholder="input.position.placeholder"
              formatValue={(data) => data?.[0].id}
              // mode="multiple"
              // ruleType="array"
            />
          </Col>
        )}
        <Col md={12} xs={24}>
          <RestInputItem
            required={isCreate}
            header="profile.password"
            source="password"
            type="password"
            autoComplete="new-password"
            isValidatorEmpty={false}
            rules={[
              {
                min: 3,
                message: i18n.t('input.password.validateMsg.minLength', {
                  min: 3,
                }),
              },
              {
                max: 255,
                message: i18n.t('input.password.validateMsg.maxLength', {
                  max: 255,
                }),
              },
            ]}
            placeholder="input.password.placeholder"
            ContentComponent={Input.Password}
          />
        </Col>
        {!isProfile && checkRole(['AllowAll', 'UserEdit'], permissionData) && (
          <Col md={12} xs={24}>
            <RestInputItem
              header="profile.isCanAddAnnouncement"
              source="isCanAddAnnouncement"
              ruleType="boolean"
              isValidatorEmpty={false}
              ContentComponent={Switch}
              valuePropName="checked"
            />
          </Col>
        )}
      </Row>
    </FormStyles>
  );
};

Form.propTypes = {
  isCreate: PropTypes.bool,
  isProfile: PropTypes.bool,
};

Form.defaultProps = {
  isCreate: false,
};

export default Form;
