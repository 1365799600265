import React, { useCallback } from 'react';
import i18next from 'i18next';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/documentaries/slice';

import ModalCustom from 'components/common/ModalCustom';
import { useModalEditNote } from './context';

const ModalEditNote = ({ form }) => {
  const dispatch = useDispatch();
  const taskId = useSelector(state => state.documentaries.currentId);
  const { getFieldDecorator, resetFields, validateFields } = form;
  const { visible, noteObj = {}, toggleModal } = useModalEditNote();

  const handleCancel = useCallback(() => {
    toggleModal();
    resetFields();
  }, [toggleModal, resetFields])

  const handleSubmit = useCallback(
    () => {
      validateFields((err, values) => {
        if(!err) {
          dispatch(actions.editNoteItemActivity({id: taskId, itemId: noteObj.id, note: values.note?.trim()}));
          handleCancel()
        }
      })
    },
    [dispatch, taskId, noteObj.id, handleCancel, validateFields],
  );

  return (
    <ModalCustom
      title={i18next.t('documentaries.editNoteTitle')}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      width={500}
    >
      <Form.Item>
        {getFieldDecorator('note', {
          initialValue: noteObj.notes,
          rules: [
            {
              max: 500,
              message: i18next.t('input.content.validateMsg.maxLength', {
                max: 500,
              }),
            },
          ],
        })(<Input placeholder={i18next.t('documentaries.note')} />)}
      </Form.Item>
    </ModalCustom>
  );
};

ModalEditNote.propTypes = {
  form: PropTypes.object,
};

export default Form.create()(ModalEditNote);
