import styled from 'styled-components';

const CommentItemStyles = styled.div`
  margin-bottom: 15px;
  display: flex;
  .ant-avatar {
    min-width: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > i {
      font-size: 18px;
    }
  }
  .content-comment-item {
    display: flex;
    flex-direction: column;
    .content-text,
    strong {
      margin-bottom: 5px;
    }
    .time-noti > i {
      margin-right: 10px;
    }
  }
  .edit-comment,
  .view-comment {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 15px;
  }

  .edit-btn {
    border: none !important;
    color: ${({ theme }) => theme.text.primary} !important;
    background: transparent !important;
    box-shadow: none !important;
    .anticon {
      font-size: 20px;
    }
  }
`;

export default CommentItemStyles;
