import styled from 'styled-components';
import { Drawer } from 'antd';

const DrawerStyles = styled(Drawer)`
.ant-drawer-body {
  padding: 0;
}
.loading-file {
  position: absolute;
  top: 50%;
  left: 50%;
}
  .div-image {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    img {
      max-width: 100%;
    }
  }
  .ant-drawer-body,
  .holds-the-iframe,
  .pdfobject-container,
  #iframeID {
    height: 100%;
  }
  #iframeID {
    width: 100%;
  }
  .ant-drawer-header {
    border: none;
    height: 54px;
    font-size: 18px;
    .ant-drawer-title,
    .ant-drawer-close {
      font-size: 18px;
    }
  }
  .ant-drawer-body {
    max-height: calc(100% - 54px);
  }
  .ant-drawer-title {
    overflow: hidden;
    max-width: calc(100% - 24px);
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;

export default DrawerStyles;
