import styled from 'styled-components';

const AddCommentStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-form-item {
    width: 100%;
    margin-bottom: 0 !important;
    margin-right: 15px;
    textarea {
      border: 1px solid ${({ theme }) => theme.border.default};
    }
  }
`;

export default AddCommentStyles;
