import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Tooltip } from 'antd';
import I18n from 'i18next';
import { ButtonWrapper } from './styles';

const { confirm } = Modal;

// eslint-disable-next-line no-unused-vars
const DeleteButton = ({ isShowTextButton, customOnClick, deleteItem, record, source, customResource, resource, customMessageItemName, disabled }) => {
  const handleDelete = (e) => {
    e.stopPropagation();
    customOnClick();
    confirm({
      title: `${I18n.t('popup.alertDelete')} ${I18n.t(`${customResource || resource}.header`)}`,
      content: I18n.t('popup.alertDeleteDes', {
        customMessage: I18n.t(customMessageItemName) || `${record.id}`,
      }),
      okText: I18n.t('button.ok'),
      cancelText: I18n.t('button.cancel'),
      onOk: () => deleteItem(record.id, record),
      onCancel: () => {},
    });
  };

  return (
    <Tooltip title={I18n.t('button.delete')}>
      <ButtonWrapper icon="delete" onClick={handleDelete} disabled={disabled}>
        {isShowTextButton ? I18n.t('button.delete') : null}
      </ButtonWrapper>
    </Tooltip>
  );
};

DeleteButton.propTypes = {
  deleteItem: PropTypes.func,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  customMessageItemName: PropTypes.string,
  disabled: PropTypes.bool,
  customResource: PropTypes.string,
  customOnClick: PropTypes.func,
  isShowTextButton: PropTypes.bool,
};

DeleteButton.defaultProps = {
  source: 'delete',
  customOnClick: () => null,
  isShowTextButton: false,
};

export default DeleteButton;
