import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
// import PageTitle from 'components/common/PageTitle';
// import CustomBreadcrumb from 'components/common/Breadcrumb';
import { useSelector } from 'react-redux';
import Edit from '../../rest/Edit';
// import Form from '../components/Form';
import ProfileInfo from '../../Profile/components/ProfileInfo';

const UsersEdit = props => {
  const currentData = useSelector(state => state.users.currentData);
  const BREADCRUMB = [
    {
      title: i18n.t('users.pageTitle'),
      path: '/users',
    },
    {
      title: `#${currentData?.userName}`,
      path: props.match.url,
    },
  ];
  return (
    <div>
      {/* <PageTitle>
        <CustomBreadcrumb data={BREADCRUMB} />
      </PageTitle> */}
      <Edit
        {...props}
        resource="users"
        isShowButtonGroup={false}
        breadCrumb={BREADCRUMB}
        header={i18n.t('users.editTitle')}
      >
        <ProfileInfo isProfile={false} />
      </Edit>
    </div>
  );
};

UsersEdit.propTypes = {
  match: PropTypes.object,
};

export default UsersEdit;
