import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Pagination } from 'antd';
import I18n from 'i18next';
import ExportExcelButton from 'components/RestActions/ExportExcelButton';
// import ActionView from 'components/RestLayout/ActionLayout';
import CustomBreadcrumb from '../../common/Breadcrumb';
import CreateButton from '../../RestActions/CreateButton';
import RestTableLayout, { getAction, showTotal } from '../TableLayout';
import Box from '../../common/Box';
import RestFilterForm from '../FilterLayout';
import RestListLayout from '../ListLayout';
import { ListWrapper } from './styles';
import SearchInput from '../../RestActions/SearchInput';
import PageTitle from '../../common/PageTitle';

class RestListComponent extends Component {
  onTextSearch = text => {
    const { retrieveList, customInitialFilterSearch } = this.props;
    retrieveList({
      query: text,
      ...customInitialFilterSearch,
    });
  };

  onChangePagination = (page, pageSize) => {
    const { resourceFilter, retrieveList } = this.props;
    retrieveList({
      ...resourceFilter,
      sortBy: resourceFilter.sortBy,
      isSortByDesc: resourceFilter.isSortByDesc,
      pageNumber: page,
      pageSize,
      filter: resourceFilter.filter,
    });
  };

  renderListItem = record => {
    const { children } = this.props;
    return React.Children.map(children, (item, index) =>
      React.cloneElement(item, {
        key: `${item.source}_${String(index)}`,
        record,
        onChange: () => this.onChangeRecord(record, item),
        ...getAction(this.props, item),
      }),
    );
  };

  render() {
    const {
      retrieveList,
      noCardWrapper,
      resourceData,
      resource,
      hasCreate,
      layoutButtonCreate,
      gotoCreatePage,
      filter,
      header,
      isList,
      location,
      hasSearch,
      hasExport,
      createHeader,
      resourceFilter,
      customActions,
      placeholderSearch,
      customLayout,
      summaryRow,
      noSummaries,
      isShowPagination,
      extraAction,
      hideOnSinglePage,
      showSizeChanger,
    } = this.props;

    const BREADCRUMB_LIST = [];
    location &&
      location.pathname.split('/').forEach((data, index) => {
        if (data === '') return;
        BREADCRUMB_LIST.push({
          key: data,
          title: data,
          path: `${
            BREADCRUMB_LIST[index - 1] ? BREADCRUMB_LIST[index - 1].path : ''
          }/${data}`,
        });
      });
    if (BREADCRUMB_LIST.length > 0) {
      BREADCRUMB_LIST[BREADCRUMB_LIST.length - 1].title =
        typeof header === 'string'
          ? I18n.t(header)
          : header || BREADCRUMB_LIST[BREADCRUMB_LIST.length - 1].title;
    }
    const actions = (
      <div className="vActions">
        {hasSearch && (
          <SearchInput
            defaultValue={resourceFilter?.filter?.query}
            onTextSearch={this.onTextSearch}
            placeholder={placeholderSearch}
          />
        )}
        {customActions}
        {hasExport && (
          <div className="mx-12">
            <ExportExcelButton
              resource={resource}
              resourceFilter={resourceFilter}
            />
          </div>
        )}
        {hasCreate && layoutButtonCreate !== 'inline' && (
          <CreateButton
            header={createHeader}
            resource={resource}
            gotoCreatePage={gotoCreatePage}
          />
        )}
      </div>
    );

    if (!resourceData) return null;
    const filterForm = filter ? (
      <RestFilterForm
        format={filter.props.format}
        resourceFilter={resourceFilter}
        retrieveList={retrieveList}
        {...this.props}
      >
        {filter}
      </RestFilterForm>
    ) : null;
    const paginationView = (
      <Pagination
        hideOnSinglePage={hideOnSinglePage}
        showSizeChanger={showSizeChanger}
        showQuickJumper
        total={resourceFilter.count}
        defaultCurrent={resourceFilter.pageNumber || 1}
        current={resourceFilter.pageNumber || 1}
        showTotal={showTotal}
        pageSize={resourceFilter.pageSize || 15}
        onChange={this.onChangePagination}
        onShowSizeChange={this.onChangePagination}
        pageSizeOptions={['5', '15', '25', '50']}
      />
    );
    // const paginationTopView = (
    //   <Row
    //     className="paginationRow pagination-top-row"
    //     align="middle"
    //     type="flex"
    //   >
    //     {isShowPagination && (
    //       <Col md={actions ? 20 : 24} xs={24}>
    //         {paginationView}
    //       </Col>
    //     )}
    //     {actions && (
    //       <Col md={4} xs={24}>
    //         <ActionView>{actions}</ActionView>
    //       </Col>
    //     )}
    //   </Row>
    // );

    const paginationBottomView = (
      <Row
        key="paginationBottom"
        className="paginationRow pagination-bottom-row"
        // justify="end"
        align="middle"
        type="flex"
      >
        {isShowPagination && <Col>{paginationView}</Col>}
      </Row>
    );

    const tableContent = [
      <Box key="table" className="box">
        <RestTableLayout {...this.props} />
        {paginationBottomView}
      </Box>,
      // paginationBottomView('table'),
    ];
    const listCotent = customLayout ? (
      React.cloneElement(customLayout, {
        retrieveList,
        resource,
        resourceData,
        resourceFilter,
      })
    ) : (
      <RestListLayout {...this.props} key="listLayout" />
    );
    const content =
      isList || customLayout ? (
        <div>
          {listCotent}
          {paginationBottomView}
        </div>
      ) : (
        <Row className="viewContent">
          <Col md={0} xs={24}>
            {listCotent}
            {paginationBottomView}
          </Col>
          <Col md={24} xs={0}>
            {tableContent}
          </Col>
        </Row>
      );

    return (
      <ListWrapper className="list-wrapper">
        <div className="viewContent">
          {!noCardWrapper && (
            <PageTitle {...extraAction && {extraAction}}>
              <CustomBreadcrumb data={BREADCRUMB_LIST} />
            </PageTitle>
          )}
          {!noCardWrapper && !noSummaries && <div>{summaryRow}</div>}
          {filterForm}
          {actions}
          {/* {paginationTopView} */}
          {content}
          {/* {paginationBottomView} */}
        </div>
      </ListWrapper>
    );
  }
}

RestListComponent.propTypes = {
  resource: PropTypes.string,
  noCardWrapper: PropTypes.bool,
  retrieveList: PropTypes.func,
  resourceData: PropTypes.array,
  hasCreate: PropTypes.bool,
  gotoCreatePage: PropTypes.func,
  filter: PropTypes.object,
  header: PropTypes.any,
  children: PropTypes.any,
  isList: PropTypes.bool,
  hasSearch: PropTypes.bool,
  hasExport: PropTypes.bool,
  location: PropTypes.object,
  createHeader: PropTypes.string,
  resourceFilter: PropTypes.object,
  layoutButtonCreate: PropTypes.string,
  placeholderSearch: PropTypes.string,
  exportExcel: PropTypes.func,
  customActions: PropTypes.any,
  customLayout: PropTypes.any,
  summaryRow: PropTypes.node,
  noSummaries: PropTypes.bool,
  isShowPagination: PropTypes.bool,
  extraAction: PropTypes.any,
  sourceSearch: PropTypes.string,
  customInitialFilterSearch: PropTypes.object,
  hideOnSinglePage: PropTypes.bool,
  showSizeChanger: PropTypes.bool,
};

RestListComponent.defaultProps = {
  noCardWrapper: false,
  isList: false,
  hasExport: true,
  hasSearch: true,
  hasCreate: true,
  layoutButtonCreate: 'non-inline',
  isShowPagination: true,
  customInitialFilterSearch: {},
  hideOnSinglePage: false,
  showSizeChanger: true,
};
export default RestListComponent;
