import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Tag, Button } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { checkRole } from 'utils/tools';

import {
  PRIORITY,
  DOCUMENTARIES_STATUS,
  DOCUMENTARY_STATUS_VALUE,
  ACTIVITY_ROLE_VALUE,
} from 'configs/localData';

import UserInfo from 'components/RestField/UserInfo';
// import FilesList from 'components/RestField/FilesList';
import ContentText from 'components/common/ContentText';
import ModalDueDate from '../ModalDueDate';

import InfoStyles from './styles';

const DocumentaryInfo = ({ record }) => {
  const permissionData = useSelector(state => state.auth.permissionData);
  const [visible, setVisible] = useState(false);
  const checkShowEditDueDate = () => {
    return (
      !record?.isDeleted &&
      record?.status < DOCUMENTARY_STATUS_VALUE.process &&
      record?.role === ACTIVITY_ROLE_VALUE.execute
    );
  };

  // const mediasList = (type = 2) => record?.medias?.filter((item) => item.type === type);

  return (
    <InfoStyles>
      <div className="detail-info">
        <div className="header-item-documentary">
          {record.priority > 0 && (
            <Tag
              className="label-priority"
              color={
                PRIORITY.find(item => item.value === record.priority)?.color
              }
            >
              {i18next.t(
                PRIORITY.find(item => item.value === record.priority)?.text,
              )}
            </Tag>
          )}
          <div className="sender-span">
            <UserInfo
              record={record?.createdUser}
              nameProp="name"
              note={moment(record?.createdAt).format('DD-MM-YYYY HH:mm')}
              isLink={checkRole(
                ['AllowAll', 'UserRead', 'UserEdit'],
                permissionData,
              )}
              isShowPopover
            />
          </div>
          <Tag
            className="label-status"
            color={
              DOCUMENTARIES_STATUS.find(item => item.value === record.status)
                ?.color
            }
          >
            {i18next.t(
              DOCUMENTARIES_STATUS.find(item => item.value === record.status)
                ?.text,
            )}
          </Tag>
        </div>
        <div className="title-documentary">{record.title}</div>
        <div className="info-task">
          <div className="item-value-info">
            <span className="label-title">
              {`${i18next.t('documentaries.numberCV')}: `}
            </span>
            {record.fromNumber}
          </div>
          <div className="item-value-info">
            <span className="label-title">
              {`${i18next.t('documentaries.numberVB')}: `}
            </span>
            {record.sourceNumber}
          </div>
          {record.toNumber && (
            <div className="item-value-info">
              <span className="label-title">
                {`${i18next.t('documentaries.toNumber')}: `}
              </span>
              {record.toNumber}
            </div>
          )}
          <div className="item-value-info">
            <span className="label-title">
              {`${i18next.t('documentaries.publishPlace')}: `}
            </span>
            {record.publisher}
          </div>
          <div className="item-value-info">
            <span className="label-title">
              {`${i18next.t('documentaries.dueDate')}: `}
            </span>
            {record?.dueDateUtc ? (
              <Tag className="due-date" color="red">
                {moment(record.dueDateUtc).format('DD-MM-YYYY HH:mm')}
              </Tag>
            ) : (
              <span className="due-date">
                {i18next.t('dueDate.emptyDueDate')}
              </span>
            )}
            {checkShowEditDueDate() && (
              <Button
                icon="edit"
                className="edit-button"
                onClick={() => setVisible(true)}
              />
            )}
          </div>
          {record.tags?.length > 0 && (
            <div className="item-value-info">
              <span className="label-title">
                {`${i18next.t('documentaries.tags')}: `}
              </span>
              {record.tags.map((item, index) => (
                <Tag key={String(index)}>{item}</Tag>
              ))}
            </div>
          )}
        </div>
        <div className="content-documentary">
          <span className="label-title">
            {`${i18next.t('documentaries.content')}: `}
          </span>
          <ContentText content={record?.content} />
        </div>
      </div>
      <ModalDueDate
        width={300}
        title={i18next.t('documentaries.changeDueDate')}
        visible={visible}
        data={{ id: record.id, dueDateUtc: record.dueDateUtc }}
        setVisible={value => setVisible(value)}
      />
    </InfoStyles>
  );
};

DocumentaryInfo.propTypes = {
  record: PropTypes.object,
};

export default DocumentaryInfo;
