import styled from 'styled-components';

const MessageInfoStyles = styled.div`
  .detail-info {
    margin-bottom: 30px;
  }
  .empty-data {
    margin-bottom: 15px;
  }
  .detail-info > div:not(:last-child) {
    margin-bottom: 15px;
  }

  .title-documentary {
    font-weight: 600;
  }
  ${'' /* .view-total-item-announcement {
    display: flex;
    align-items: center;
    .totalView-span {
      margin-left: 5px;
    }
  } */}
  .header-item-documentary {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 80%;
    .label-priority,
    .sender-span {
      margin-bottom: 15px;
    }
    .ant-tag {
      margin-right: 20px;
    }
    .sender-span {
      padding: 0;
    }
  }
  .title-label {
    margin-bottom: 10px;
    font-weight: 600;
  }
  .received-users {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 630px) {
    .header-item-documentary {
      .sender-span {
        padding: 0;
        border: none;
      }
    }
  }
`;

export default MessageInfoStyles;
