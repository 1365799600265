import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import CRUDActions from 'redux/crudActions';
import crudSelectors from 'redux/crudSelectors';
import RestListComponent from 'components/RestLayout/List';
import {
  getFilterFromUrl,
  getSearch,
  getValidData,
  convertObjToSearchStr,
} from 'utils/tools';
import { PRIMARY_KEY } from 'redux/crudCreator/slice';
import useRouter from 'hooks/useRouter';

const RestList = ({ isDynamicApiResource, filterByTab, ...props }) => {
  const { location, history } = useRouter();
  const dispatch = useDispatch();

  const [isMounted, setIsMounted] = useState(false);

  const { initialFilter, defaultOptions, keyTab } = props;

  const retrieveList = useCallback(
    (filter = { filter: {} }, isRefresh) =>
      dispatch(
        CRUDActions[props.resource].getAll(
          {
            ...props.initialFilter,
            ...filter,
            filter:
              props.initialFilter?.filter && filter.filter
                ? {
                    ...props.initialFilter.filter,
                    ...filter.filter,
                    ...filterByTab?.filter,
                  }
                : {
                    ...(props.initialFilter?.filter || filter.filter),
                    ...filterByTab?.filter,
                  },
          },
          { ...defaultOptions, isRefresh },
        ),
      ),
    [
      dispatch,
      props.resource,
      defaultOptions,
      props.initialFilter,
      filterByTab,
    ],
  );

  const getAllFilter = () => {
    const { getFromUrl } = props;
    const filter =
      (getFromUrl && getFilterFromUrl(location.search)) || initialFilter;
    // (!filterByTab) &&
    retrieveList(filter || { pageSize: 15, pageNumber: 1, filter: {} }, true);
  };

  useEffect(() => {
    !isDynamicApiResource && getAllFilter();
    setIsMounted(true);
    // eslint-disable-next-line
  }, []);

  /** start handle go back   */

  useEffect(() => {
    if (!isMounted) return;
    // handle call api khi 2 tab cùng dùng chung 1 table (chung state reducer)
    const isCallWhenPush =
      filterByTab &&
      history.action === 'PUSH' &&
      !location.hash &&
      !location.search &&
      location.pathname?.includes(keyTab);
    // handle call api khi go back cho page có nhiều tab (chứa table) hoặc normal page
    const isCallWhenBack =
      history.action === 'POP' &&
      (keyTab ? location.pathname?.includes(keyTab) : true);

    if (isCallWhenPush || isCallWhenBack) {
      getAllFilter();
    }
    // eslint-disable-next-line
  }, [location]);

  /** end handle go back   */
  useEffect(() => {
    if (isMounted && initialFilter?.parentId && history.action !== 'POP') {
      getAllFilter();
    }
    // eslint-disable-next-line
  }, [initialFilter?.parentId]); // handle re-call api in document boxes

  // useEffect(() => {
  //   if(isMounted) {
  //     getAllFilter();
  //   }
  //   // eslint-disable-next-line
  // }, [filterByTab]);

  useEffect(() => {
    isMounted &&
      isDynamicApiResource &&
      defaultOptions?.customApiResource &&
      !defaultOptions?.customApiResource?.includes('undefined') &&
      getAllFilter();
    // eslint-disable-next-line
  }, [defaultOptions?.customApiResource]);

  const pushQuery = (searchStr) => {
    dispatch(
      push(
        props.customPath
          ? `${props.customPath}?${searchStr}`
          : `${location.pathname}?${searchStr}`,
      ),
    );
  };

  const pushRoute = (data) => dispatch(push(data));

  const retrievedList = (filter, isRefresh = true) => {
    const { isUpdateRoute } = props;
    isUpdateRoute && pushQuery(getSearch(filter));
    retrieveList(filter, isRefresh);
  };

  const gotoEditPage = (id) => {
    const { redirects, resource, location } = props;
    const route = `/${resource}/${id}/edit`;
    if (redirects.edit === 'modal') {
      pushRoute(
        `${location.pathname}${location.search}#${resource}/${id}/edit`,
      );
    } else {
      pushRoute(route);
    }
  };

  const gotoShowPage = (id) => {
    const { redirects, resource } = props;
    const route = `/${resource}/${id}/show`;
    if (redirects.edit === 'modal') {
      pushRoute(`#${resource}/${id}/show`);
    } else {
      pushRoute(route);
    }
  };

  const gotoCreatePage = () => {
    const { redirects, resource, rootPath, initCreateData, location } = props;
    const route = `${rootPath}/${resource}/create`;
    if (redirects.create === 'modal') {
      pushRoute(
        `${location.pathname}${
          location.search
        }#${resource}/create?${convertObjToSearchStr(initCreateData)}`,
      );
    } else {
      pushRoute(route);
    }
  };

  const getFilterData = () => {
    const { resourceFilter, isUpdateRoute } = props;
    const filter =
      (location && getFilterFromUrl(location.search)) || props.initialFilter;

    return isUpdateRoute
      ? {
          ...filter,
          ...pick(resourceFilter, [
            'pageSize',
            'pageNumber',
            'count',
            'sortBy',
            'isSortByDesc',
          ]),
        }
      : getValidData(resourceFilter);
  };

  return (
    <RestListComponent
      header={`${props.resource}.header`}
      {...props}
      resourceFilter={getFilterData()}
      gotoEditPage={gotoEditPage}
      gotoCreatePage={gotoCreatePage}
      gotoShowPage={gotoShowPage}
      retrieveList={retrievedList}
    />
  );
};;

const mapStateToProps = (state, props) => ({
  loading: crudSelectors[props.resource].getLoading(state, props),
  resourceData: crudSelectors[props.resource].getDataArr(state, props),
  resourceFilter: crudSelectors[props.resource].getFilters(state, props),
});

const mapDispatchToProps = (dispatch, props) => ({
  customQuery: (id, queryUrl, data, isChangeToEdit) =>
    dispatch(
      CRUDActions[props.resource].edit(
        {
          ...data,
          [PRIMARY_KEY]: id,
        },
        {
          ...props.defaultOptions,
          isChangeToEdit,
          customApiResource: queryUrl,
          isBack: false,
        },
      ),
    ),
  updateRecord: (id, data, isChangeToEdit) =>
    dispatch(
      CRUDActions[props.resource].edit(
        {
          ...data,
          [PRIMARY_KEY]: id,
        },
        { ...props.defaultOptions, isChangeToEdit, isBack: false },
      ),
    ),
  deleteItem: id =>
    dispatch(
      CRUDActions[props.resource].del(
        {
          [PRIMARY_KEY]: id,
        },
        { ...props.defaultOptions, isBack: false },
      ),
    ),
  exportExcel: () => dispatch(CRUDActions[props.resource].exportExcel()),
});

const ConnectRestList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestList);

RestList.propTypes = {
  retrieveList: PropTypes.func,
  initialFilter: PropTypes.object,
  resource: PropTypes.string,
  redirects: PropTypes.object,
  rootPath: PropTypes.string,
  isUpdateRoute: PropTypes.bool,
  initCreateData: PropTypes.object,
  resourceFilter: PropTypes.object,
  customPath: PropTypes.string,
  defaultOptions: PropTypes.object,
  getFromUrl: PropTypes.bool,
  filterByTab: PropTypes.object,
  isDynamicApiResource: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
  keyTab: PropTypes.string,
};

ConnectRestList.propTypes = {
  retrieveList: PropTypes.func,
  initialFilter: PropTypes.object,
  resource: PropTypes.string,
  redirects: PropTypes.object,
  rootPath: PropTypes.string,
  isUpdateRoute: PropTypes.bool,
  initCreateData: PropTypes.object,
  defaultOptions: PropTypes.object,
  filterByTab: PropTypes.object,
  isDynamicApiResource: PropTypes.bool,
};

ConnectRestList.defaultProps = {
  isUpdateRoute: true,
  rootPath: '',
  getFromUrl: true,
  redirects: {
    edit: 'modal',
    create: 'modal',
  },
  defaultOptions: {},
  initCreateData: {},
  isDynamicApiResource: false,
};

export default ConnectRestList;
