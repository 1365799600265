import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import RestDateTimePicker from 'components/RestInput/RestDateTimePicker';
import { SEEN_DATA } from 'configs/localData';
import i18next from 'i18next';
// import { RestInputContext } from 'components/RestInput/RestInputContext';

const Filter = ({ isReceived, format, keyTab, ...props }) => {
  const { form } = props;
  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [keyTab]);

  const getResponsive = useCallback(() => {
    if (isReceived)
      return {
        isSeen: {
          xl: 4,
          lg: 4,
          md: 12,
          xs: 24,
        },
        title: {
          xl: 6,
          lg: 6,
          md: 12,
          xs: 24,
        },
        createdUser: {
          xl: 6,
          lg: 6,
          md: 12,
          xs: 24,
        },
        startDate: {
          xl: 4,
          lg: 4,
          md: 12,
          xs: 24,
        },
        endDate: {
          xl: 4,
          lg: 4,
          md: 12,
          xs: 24,
        },
      };

    return {
      title: {
        xl: 6,
        lg: 6,
        md: 12,
        xs: 24,
      },
      receivedUser: {
        xl: 6,
        lg: 6,
        md: 12,
        xs: 24,
      },
      // isDeleted: {
      //   xl: 6,
      //   lg: 6,
      //   md: 12,
      // },
      startDate: {
        xl: 6,
        lg: 6,
        md: 12,
        xs: 24,
      },
      endDate: {
        xl: 6,
        lg: 6,
        md: 12,
        xs: 24,
      },
    };
  }, [isReceived]);

  const responsive = useMemo(() => getResponsive() || {}, [getResponsive]);

  return (
    <Row
      gutter={24}
      type="flex"
    >
      {/* <Col xl={4} lg={8} md={8} xs={24}>
        <RestSelect
          source="priority"
          placeholder="internalMessages.priority"
          resourceData={PRIORITY}
          valueProp="value"
          titleProp="text"
          formatText={data => i18next.t(data)}
          ruleType="number"
          isShowSearch={false}
        />
      </Col> */}
      {isReceived && (
        <Col {...responsive.isSeen}>
          <RestSelect
            source="isSeen"
            placeholder="internalMessages.isSeen"
            resourceData={SEEN_DATA}
            valueProp="value"
            titleProp="text"
            formatText={(data) => i18next.t(data)}
            isShowSearch={false}
          />
        </Col>
      )}

      <Col {...responsive.title}>
        <RestInputItem
          source="title"
          placeholder="internalMessages.nameDocumentary"
        />
      </Col>

      {isReceived ? (
        <Col {...responsive.createdUser}>
          <RestInputItem
            source="createdUser"
            placeholder="internalMessages.sender"
          />
        </Col>
      ) : (
        <Col {...responsive.receivedUser}>
          <RestInputItem
            source="receivedUser"
            placeholder="internalMessages.receivedUser"
          />
        </Col>
      )}
      {/* {!isReceived && (
        <Col {...responsive.isDeleted}>
          <RestSelect
            source="isDeleted"
            placeholder="internalMessages.isDeleted"
            resourceData={DELETE_STATUS}
            valueProp="value"
            titleProp="text"
            formatText={(data) => i18next.t(data)}
            isShowSearch={false}
          />
        </Col>
      )} */}

      <Col {...responsive.startDate}>
        <RestDateTimePicker
          source="startDate"
          placeholder="internalMessages.startDate"
          isShowTime={false}
        />
      </Col>
      <Col {...responsive.endDate}>
        <RestDateTimePicker
          source="endDate"
          placeholder="internalMessages.endDate"
          isShowTime={false}
        />
      </Col>
    </Row>
  );
};

Filter.propTypes = {
  isReceived: PropTypes.bool,
  format: PropTypes.func,
  form: PropTypes.object,
  keyTab: PropTypes.string,
};

export default Filter;
