import { takeLatest, call, put } from 'redux-saga/effects';
import { disableUserApi, uploadAvatarApi } from 'api/user';
import { apiWrapper } from 'utils/reduxUtils';
import { updateUserSuccess } from 'redux/auth/slice';
import { makeCRUDSaga } from '../crudCreator/saga';
import { RESOURCE, actions } from './slice';

function* disableUserSaga({ payload: { id, isActive } }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowLoading: true, isShowSuccessNoti: true },
      disableUserApi,
      id,
      { isActive },
    );
    if (response) yield put(actions.disableUserSuccess(response));
  } catch (error) {
    yield put(actions.disableUserFailure(error, id));
  }
}

function* uploadAvatarSaga({
  payload: { id, successAction, failureAction, avatarUrl },
}) {
  try {
    // console.log()
    const response = yield call(
      apiWrapper,
      { isShowLoading: true, isShowSuccessNoti: true },
      uploadAvatarApi,
      id,
      { avatarUrl },
    );
    if (response) {
      if (successAction) {
        yield put(successAction(response));
      } else {
        yield put(updateUserSuccess(response.data));
      }
    }
  } catch (error) {
    if (failureAction)
      yield put(failureAction({ error, data: { id, avatarUrl } }));
    else yield put(actions.uploadAvatarFailure(error, id));
  }
}

export default [
  ...makeCRUDSaga(RESOURCE, [], actions),
  takeLatest([actions.disableUser.type], disableUserSaga),
  takeLatest([actions.uploadAvatar.type], uploadAvatarSaga),
];
