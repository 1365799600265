import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { Link } from 'react-router-dom';
import { Menu, Icon, Dropdown, Avatar, Badge, Button, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/auth/slice';
// import { ROLE } from 'configs/localData';
import { checkRole } from 'utils/tools';
// import { actions } from 'redux/notifications/slice';
import { isEmpty } from 'lodash';
import Notifications from './Notifications';
import HeaderWrapper from './styles';

const Header = ({ onToggle, collapsed }) => {
  const dispatch = useDispatch();
  // const currentRole = useSelector(state => state.auth.role);
  const currentPermissions = useSelector(state => state.auth.permissionData);
  const currentUser = useSelector(state => state.auth.data);
  const notSeen = useSelector(
    state => state.notifications.totalNewNotification,
  );
  const [visible, setVisible] = useState(false);
  // const togglePopover = () => {
  //   setVisible(!visible);
  // };
  const handleVisibleChange = visible => {
    setVisible(visible);
  };
  // const [locale, setLocale] = useState(
  //   localStorage.getItem('i18nextLng') || I18n.language,
  // );
  const profileMenu = [
    {
      key: 'profile',
      text: 'header.profile',
      url: '/profile',
    },
  ];

  // const changeLocale = e => () => {
  //   setLocale(e);
  //   I18n.changeLanguage(e);
  //   localStorage.setItem('i18nextLng', e);
  // };

  useEffect(() => {
    I18n.changeLanguage('vi');
  }, []);

  const onClose = () => {
    setVisible(false);
  }

  return (
    <HeaderWrapper className="header">
      <div className="leftHeader">
        <Icon
          className={`trigger ${collapsed ? '' : 'reverse-trigger'}`}
          type="ic-menu"
          onClick={onToggle}
        />
        <div className="title">{I18n.t('appInfo.name')}</div>
      </div>
      <div className="rightHeader">
        {/* <div
          className={`localeSelect${locale === 'vi' ? ' active' : ''}`}
          role="presentation"
          onClick={changeLocale('vi')}
        >
          VI
        </div>
        <div
          className={`localeSelect${locale === 'en' ? ' active' : ''}`}
          role="presentation"
          onClick={changeLocale('en')}
        >
          EN
        </div> */}
        {/* {checkRole(['AllowAll', 'MenuAnnouncement'], currentPermissions) && ( */}
        <div className="notification-section">
          {/* <div> */}
          <Popover
            visible={visible}
            content={
              <Notifications onClose={onClose} />
            }
            placement="bottomRight"
            trigger="click"
            onVisibleChange={handleVisibleChange}
          >
            <Button>
              <Badge count={notSeen}>
                <Icon type="bell" />
              </Badge>
            </Button>
          </Popover>
          {/* </div> */}
        </div>
        {/* )} */}
        <div className="user-role">
          <div className="name">{`${currentUser?.name || ''}`}</div>
          <div className="role">
            {!isEmpty(currentUser.positions) &&
              currentUser.positions?.map((item, index) =>
                index === currentUser.positions.length - 1 ? (
                  <span key={String(index)}>{item.name}</span>
                ) : (
                  <span key={String(index)}>{`${item.name}, `}</span>
                ),
              )}
            {/* {I18n.t(ROLE.find(role => role.value === currentRole)?.text)} */}
          </div>
        </div>
        <Dropdown
          overlay={() => (
            <Menu style={{ minWidth: '120px' }}>
              {profileMenu.map(menu =>
                !checkRole(menu.roles, currentPermissions) ? null : (
                  <Menu.Item key={menu.key}>
                    <Link to={menu.url}>{I18n.t(menu.text)}</Link>
                  </Menu.Item>
                ),
              )}
              <Menu.Divider />
              <Menu.Item
                onClick={() => {
                  currentUser?.id && dispatch(logout(currentUser.id));
                }}
                key="logout"
              >
                {I18n.t('header.logout')}
              </Menu.Item>
            </Menu>
          )}
          trigger={['click']}
        >
          <Avatar src={currentUser?.avatarUrl} size="large" icon="user" />
        </Dropdown>
      </div>
    </HeaderWrapper>
  );
};
Header.propTypes = {
  collapsed: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default Header;
