import { createSelector } from 'reselect';
import React from 'react';
import { uniq ,uniqBy, findIndex, sortBy} from 'lodash';
import { ACTIVITY_ROLES } from 'configs/localData';

import TitleTree from 'containers/Documentaries/components/DocumentFlow/TitleTree';

import CRUDSelectors from '../crudCreator/selectors';
import { RESOURCE } from './slice';

const documentariesRest = state => state.documentaries.currentData;

const orderItemsOfActivityItemByRole = ({items, ...activityItem}) => {
  const restItems = uniqBy(sortBy(items, obj => findIndex(ACTIVITY_ROLES, role => role.value === obj.role)), 'user.id');
  return {...activityItem, items: restItems};
}

const formatData = (data) => {
  // List assigner id unique
  const uniqAssignerId = uniq(data?.map(i => i.assigner.id));

  return uniqAssignerId.map(assignerId => {
    let activityItem = {};

    const listAssigner = data?.filter(i => i.assigner.id === assignerId);

    activityItem = listAssigner.reduce((accumulator, currentValue) => {
      if(accumulator) {
        const acc = {...accumulator};
        acc.items = accumulator?.items.concat(currentValue?.items);

        return acc;
      }

      return currentValue;

    }, null);

    // order by role - documentaries
    activityItem = orderItemsOfActivityItemByRole(activityItem || {});

    return activityItem;
  })
}

// const formatTest = () => {

// }

// const formatChildrenTree = (data, index = 1) => {
//   if(index < 1 || index >= data?.length - 1 || !data?.[index]?.items) return null;
//   return (data?.[index]?.items?.map(item => ({
//     title: (
//       <TitleTree
//         user={item.user}
//         data={item}
//       />
//     ),
//     itemActivity: item,
//     expanded: true,
//     children: data
//       .slice(index + 1)
//       .find(elem => item?.user?.id === elem.assigner.id)
//       ?.items?.map(child => ({
//         title: (
//           <TitleTree
//             user={child.user}
//             data={child}
//           />
//         ),
//         expanded: true,
//         itemActivity: child,
//         children: formatChildrenTree(data, index + 1),
//       })),
//   })))
// }

const formatToTreeObject = ({dataItem, isUseAssigner, isOnlyFormatChildren, createdAt}) => {

  // Chỉ cần format children
  if(isOnlyFormatChildren) {
    return dataItem?.items?.map(item => ({
      ...item,
      title: (
        <TitleTree user={item.user} data={{...item, parentId: dataItem?.assigner?.id}} />
      ),
      // title: item.user?.name || 'node',
      expanded: true,
      itemActivity: {item},
    }));
  }

  return {
    ...dataItem,
    // title: isUseAssigner ? dataItem.assigner?.name : dataItem.user?.name,
    title: isUseAssigner ? (
      <TitleTree user={dataItem.assigner} data={{...dataItem, createdDateUtc: createdAt, role: 0}} />
    ) : (
      <TitleTree user={dataItem.user} data={{...dataItem, parentId: dataItem?.assigner?.id}} />
    ),
    expanded: true,
    itemActivity: isUseAssigner 
      ? {...dataItem, role: 0, user: dataItem.assigner} 
      : {dataItem},
    children: dataItem?.items?.map(item => ({
      ...item,
      title: <TitleTree user={item.user} data={{...item, parentId: dataItem?.assigner?.id}} />,
      expanded: true,
      itemActivity: {item},
    })),
  }
}

const formatTree = (data, createdAt) => {

  // console.log(data, 'data')

  let result = [...data];

  if(result.length) result[0] = formatToTreeObject({dataItem: result[0], isUseAssigner: true, createdAt});
  if(result.length > 1) result[1] = formatToTreeObject({dataItem: result[1]})

  // Lặp ngược từ phần tử cuối đến  phần tử số 2
  for (let index = data.length - 1; index > 1; index -= 1) {
    const restItem = result[index];
    // const restItem = formatToTreeObject({dataItem: data[index], isOnlyFormatChildren: true}); // item có chứa assigner.id

    // Lặp từ phần tử trước phần tử được chọn đến phần tử số 1
    for (let jIndex = index - 1; jIndex >= 1; jIndex -= 1) {

      // Tìm restItem.assigner.id có trong items của phần tử jIndex hiện tại không
      const matchedItemIndex = findIndex(data[jIndex].items, (item) => item?.user?.id === restItem?.assigner?.id)
      
      // Nếu có
      if(matchedItemIndex !== -1) {
        // console.log(`data[${jIndex}] chứa items[${matchedItemIndex}] là bố của thằng data[${index}]`)
        // Bỏ đi phần tử index
        
        result.splice(index, 1);
        // format lại restItem theo format tree
        // if(jIndex === 1 && index === 2) console.log(restItem, 'restItem')
        const restItemFormat = formatToTreeObject({dataItem: restItem, isOnlyFormatChildren: true});
        // console.log(restItemFormat, 'restItemFormat');

        // Đem phần tử index bỏ vào trong items của phần tử jIndex
        result = result.map((raItem, raIndex) => {
          if (raIndex === jIndex) {
            return formatToTreeObject({
              dataItem: {
                ...raItem,
                items: raItem.items.map((hItem, hIndex) => {

                  if (hIndex === matchedItemIndex) {
                    return {
                      ...hItem,
                      items: hItem.items ? [...hItem.items, ...restItemFormat] : [...restItemFormat],
                      children: hItem.children ? [...hItem.children, ...restItemFormat] : [...restItemFormat],
                    }
                  }

                  return hItem;
                }),
              },
            })
          }
          return raItem;
        })
        // console.log(result, index, result[index-1], 'resultItemFormat');
      }
    }
  }

  if(result.length) {
    // const [result0, ...restResult1] = result;    

    result[0] = {
      ...result[0],
      children: result[0]?.children?.map((childItem, childIndex) => {
        if(childIndex === 0) {
          return {
            ...childItem,
            children: result?.[1]?.children,
          }
        }
        return childItem
      }),
      // children: [...result[0].children, ...restResult1],
    }

    // console.log(result[0], 'result[0]')

    return [result[0]];
  }

  return result;
}

export const getTreeActivities = createSelector(
  [documentariesRest],
  restData => {

    const data = formatData(restData?.activities);

    // const dataActivities = [
    //   {
    //     // key: data?.[0]?.id,
    //     // title: data?.[0]?.assigner?.name,
    //     title: (
    //       <TitleTree user={data?.[0]?.assigner} data={{...data?.[0]?.items[0], createdDateUtc: restData?.createdAt, role: 0}} />
    //     ),
    //     expanded: true,
    //     itemActivity: {...data?.[0]?.items[0], role: 0, user: data?.[0]?.assigner},
    //     // icon: data?.[0]?.assigner?.avatarUrl,
    //     children: [
    //       {
    //         // key: data?.[0]?.items[0]?.user?.id,
    //         // title: data?.[0]?.items[0]?.user?.name,
    //         title: (
    //           <TitleTree
    //             user={data?.[0]?.items[0]?.user}
    //             data={data?.[0]?.items[0]}
    //           />
    //         ),
    //         // icon: data?.[0]?.items[0]?.user?.avatarUrl,
    //         expanded: true,
    //         itemActivity: data?.[0]?.items[0],
    //         children: data?.[1]?.items?.map(item => ({
    //           // key: item.user.id,
    //           // title: item.user.name,
    //           title: (
    //             <TitleTree
    //               user={item.user}
    //               data={item}
    //             />
    //           ),
    //           itemActivity: item,
    //           // icon: item.user.avatarUrl,
    //           expanded: true,
    //           children: data
    //             .slice(2)
    //             .find(elem => item?.user?.id === elem.assigner.id)
    //             ?.items?.map(child => ({
    //               // key: child?.user?.id,
    //               // title: child?.user?.name,
    //               title: (
    //                 <TitleTree
    //                   user={child.user}
    //                   data={child}
    //                 />
    //               ),
    //               expanded: true,
    //               itemActivity: child,
    //               // icon: child?.user?.avatarUrl,
    //             })),
    //         })),
    //       },
    //     ],
    //   },
    // ];
    // console.log(dataActivities, 'dataActivities')
    // return dataActivities || [];
    return formatTree(data, restData?.createdAt) || [];
  },
);

export const documentariesSelectors = new CRUDSelectors(RESOURCE);
