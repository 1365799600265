import { all } from 'redux-saga/effects';
import authSaga from './auth/sagas';
import referenceSagas from './referenceData/sagas';
import configSagas from './config/sagas';
import modalSagas from './modal/sagas';
import notificationsSagas from './notifications/sagas';
import usersSagas from './users/sagas';
import announcementsSagas from './announcements/sagas';
import internalMessagesSagas from './internalMessages/sagas';
import documentariesSagas from './documentaries/sagas';
import documentBoxesSagas from './documentBoxes/sagas';
import documentaryFilesSagas from './documentaryFiles/sagas';

export default function* root() {
  yield all([
    ...documentaryFilesSagas,
    ...documentBoxesSagas,
    ...documentariesSagas,
    ...internalMessagesSagas,
    ...announcementsSagas,
    ...usersSagas,
    ...notificationsSagas,
    ...modalSagas,
    ...configSagas,
    ...authSaga,
    ...referenceSagas,
  ]);
}
