import React from 'react';
import { Form, Button, Input } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import EditCommentStyles from './styles';

const EditComment = ({
  submitActionEdit,
  idTask,
  form,
  // resource,
  onToggle,
  comment,
}) => {
  // console.log(comment, 'dadadad');
  const dispatch = useDispatch();
  const { validateFields, resetFields, getFieldDecorator } = form;
  // const currentInternal = useSelector(
  //   state => state.internalMessages.currentData,
  // );
  // const loading = useSelector(state => state[resource].createComment);
  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        if (
          values?.comment !== null &&
          values?.comment !== undefined &&
          values?.comment.trim().length !== 0
        ) {
          dispatch(
            submitActionEdit({
              id: idTask,
              idComment: comment.id,
              note: values.comment.trim(),
            }),
          );
          handleCancel();
        }
      }
    });
  };

  const handleCancel = () => {
    onToggle();
    resetFields();
  };

  return (
    <EditCommentStyles>
      <Form.Item>
        {getFieldDecorator('comment', {
          rules: [
            {
              max: 1000,
              message: i18next.t('input.comment.validateMsg.maxLength', {
                max: 1000,
              }),
            },
          ],
          initialValue: comment.note,
        })(
          <Input.TextArea
            placeholder={i18next.t('input.comment.placeholder')}
          />,
        )}
      </Form.Item>
      <div className="group-action">
        <Button onClick={handleCancel} className="btn-cancel">
          {i18next.t('button.cancel')}
        </Button>
        <Button onClick={handleSubmit} type="primary">
          {i18next.t('button.update')}
        </Button>
      </div>
    </EditCommentStyles>
  );
};

EditComment.propTypes = {
  submitActionEdit: PropTypes.func,
  idTask: PropTypes.string,
  form: PropTypes.object,
  // resource: PropTypes.string,
  onToggle: PropTypes.func,
  comment: PropTypes.object,
};

EditComment.defaultProps = {
  // resource: 'internalMessages',
  onToggle: () => null,
  submitActionEdit: () => null,
};

export default Form.create()(EditComment);
