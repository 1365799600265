import React from 'react';
import { Tabs } from 'antd';
// import { useDispatch } from 'react-redux';
// import { replace } from 'connected-react-router';
import PropTypes from 'prop-types';

import i18next from 'i18next';
import PageTitle from 'components/common/PageTitle';
import CreateButton from 'components/RestActions/CreateButton';
import CustomBreadcrumb from 'components/common/Breadcrumb';
import MessageList from '../components/MessageList';
import ListStyles from './styles';

const { TabPane } = Tabs;

const InternalMessagesList = (props) => {
  const {match, location, history} = props;

  const BREADCRUMB = [
    {title: i18next.t("internalMessages.header"), path: location?.pathname || "/internalMessages"},
  ];

  const handleChangeTab = key => {
    history && history.push(`/internalMessages/${key}`)
  };

  return (
    <ListStyles>
      <PageTitle
        extraAction={(
          <div className="extraAction-div">
            <CreateButton
              header="button.createInternalMessages"
              resource="internalMessages"
              gotoCreatePage={() =>
                history.replace(
                  `${location.pathname}${location.search}#internalMessages/create?`,
                )}
            />
          </div>
        )}
      >
        <CustomBreadcrumb data={BREADCRUMB} />
      </PageTitle>
      <Tabs
        activeKey={match?.params?.model || 'received'}
        onChange={handleChangeTab}
      >
        <TabPane tab={i18next.t('internalMessages.received')} key="received">
          <MessageList
            isReceived
            initialFilter={{ filter: { isSent: false } }}
            // isUpdateRoute={false}
            keyTab="received"
            filterByTab={{ filter: { isSent: false } }}
            history={history}
          />
        </TabPane>
        <TabPane tab={i18next.t('internalMessages.sent')} key="sent">
          <MessageList
            filterByTab={{ filter: { isSent: true, isDeleted: false } }}
            initialFilter={{ filter: { isSent: true, isDeleted: false } }}
            // isUpdateRoute={false}
            // keyTab={keyTab}
            keyTab="sent"
            history={history}
          />
        </TabPane>
      </Tabs>
    </ListStyles>
  );
};

InternalMessagesList.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default InternalMessagesList;
