import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { Layout, Icon, Badge } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkRole } from 'utils/tools';
import PrivateLayoutWrapper from './styles';
import Header from '../../containers/Header';
import SideBar from '../../containers/SideBar';
// import HeaderWithoutSideBar from '../../containers/Header/HeaderWithoutSideBar';

const { Content, Footer } = Layout;

class PrivateLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // collapsed: window.innerWidth <= 767,
      collapsed: true,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  getUrlHome = (path) => {

    if(['/notifications', '/profile'].filter(i => path?.includes(i)).length === 0) {
      return path;
    }
    return '/';
  };

  render() {
    const {
      children,
      notSeen,
      permissionData,
      totalNewExternalTask,
      totalNewInternalTask,
      totalNewAnnouncement,
    } = this.props;
    const mobileTabs = [
      {
        key: 'documentaries',
        text: 'Documentaries',
        url: this.getUrlHome(children?.props?.location?.pathname),
        icon: 'home',
        // count: totalNewExternalTask,
        count:
          totalNewExternalTask + totalNewInternalTask + totalNewAnnouncement,
        roles: ['AllowAll', 'MenuDocumentTask', 'MenuInternalTask', 'MenuAnnouncement', 'MenuDocumentBox', 'MenuUser'],
      },
      {
        key: 'notifications',
        text: 'notifications',
        url: '/notifications',
        icon: 'bell',
        count: notSeen,
        // roles: ['AllowAll', 'MenuAnnouncement'],
      },
      {
        key: 'user',
        text: 'Profile',
        url: '/profile',
        icon: 'user',
      },
    ];
    // if (!hasPrivateLayoutWrapper) {
    //   return (
    //     <PrivateLayoutWrapper>
    //       <Layout className="mainView mainWithoutSidebar">
    //         <HeaderWithoutSideBar title={title} />
    //         <Content className="container">
    //           <div className="content">{children}</div>
    //         </Content>
    //       </Layout>
    //     </PrivateLayoutWrapper>
    //   );
    // }
    return (
      <PrivateLayoutWrapper>
        <Layout className="windowView">
          <input
            onChange={() => {}}
            id="collapsedTracker"
            type="checkbox"
            checked={!this.state.collapsed}
          />
          <label
            role="presentation"
            htmlFor="collapsedTracker"
            className="overlay"
            onClick={this.toggle}
          />
          <SideBar collapsed={this.state.collapsed} />
          <Layout className="mainView">
            <Header onToggle={this.toggle} collapsed={this.state.collapsed} />
            <Content className="container">
              <div className="content">{children}</div>
              <Footer
                className="footer"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>{I18n.t('appInfo.name')}</div>
                <div>
                  <span>{I18n.t('appInfo.footer')}</span>
                  <Icon
                    type="heart"
                    theme="filled"
                    style={{ marginLeft: '5px', color: 'red' }}
                  />
                </div>
              </Footer>

              <Footer className="footerMobile">
                {mobileTabs.map((tab, index) => {
                  if (!checkRole(tab.roles, permissionData)) return null;
                  return (
                    <Link
                      to={tab.url}
                      key={tab.key}
                      className={
                        tab.url === children.props.location.pathname ||
                        (index === 0 && children.props.location.pathname === '/documentaries')
                          ? 'active'
                          : 'not-active'
                      }
                    >
                      {tab.count ? (
                        <Badge count={tab.count}>
                          <Icon type={tab.icon} className="tabIcon" />
                        </Badge>
                      ) : (
                        <Icon type={tab.icon} className="tabIcon" />
                      )}
                    </Link>
                  );
                })}
              </Footer>
            </Content>
          </Layout>
        </Layout>
      </PrivateLayoutWrapper>
    );
  }
}

PrivateLayout.propTypes = {
  children: PropTypes.any,
  // hasPrivateLayoutWrapper: PropTypes.bool,
  // title: PropTypes.string,
  notSeen: PropTypes.number,
  permissionData: PropTypes.array,
  totalNewExternalTask: PropTypes.number,
  totalNewInternalTask: PropTypes.number,
  totalNewAnnouncement: PropTypes.number,
};

export default connect(state => ({
  notSeen: state.notifications.totalNewNotification,
  totalNewExternalTask: state.notifications.totalNewExternalTask,
  totalNewInternalTask: state.notifications.totalNewInternalTask,
  totalNewAnnouncement: state.notifications.totalNewAnnouncement,
  permissionData: state.auth.permissionData,
}))(PrivateLayout);
