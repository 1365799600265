import styled from 'styled-components';

export const ExtraButtonsStyles = styled.div`
  text-align: right;
  & > button:not(:first-child) {
    margin-left: 15px;
  }

  @media only screen and (max-width: 630px) {
    button {
      margin-bottom: 10px;
    }
  }
`;

const ShowStyles = styled.div`

.content-detail-show > .action-buttons-show {
  z-index: 3;
  top: 20px;
}

  @media only screen and (min-width: 1200px) {
    .content-detail-show > .action-buttons-show {
      right: 52%;
    }
  }
`;

export default ShowStyles;
