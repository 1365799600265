import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { downloadFileByURL, handleViewFile, checkRole } from 'utils/tools';

import List from 'containers/rest/List';
import ModalViewFile from 'components/RestField/ModalViewFile';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
// import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import CustomButton from 'components/RestActions/CustomButton';
import UserInfo from 'components/RestField/UserInfo';
import ListStyles from './styles';

const DocumentaryFilesList = (props) => {
  const externalTaskId = useSelector((state) => state.documentaries.currentId);
  const [visibleModalViewFile, setVisibleModalViewFile] = useState(false);

  const [urlViewObj, setUrlViewObj] = useState({ name: '', url: '' });

  const onCancelViewFile = () => {
    setVisibleModalViewFile(false);
  };

  const handleClickViewFile = (record) => {
    handleViewFile(record, (data = {}) => {
      data.data && setUrlViewObj(data.data);
      data.visible && setVisibleModalViewFile(data.visible);
    });
  };

  const handleDownloadFile = (record) => {
    record && downloadFileByURL(record);
  };

  const checkDeleteFile = (record) =>
    record?.createdUser?.id === localStorage.getItem('id');

  const getRoleCreateUser = useCallback(
    (roles = []) => roles.map((item) => item.slug),
    [],
  );

  return (
    <ListStyles>
      <List
        {...props}
        resource="documentaryFiles"
        noCardWrapper
        hasSearch={false}
        hasExport={false}
        hasCreate={false}
        widthTable="500px"
        defaultOptions={{
          customApiResource: `externaltasks/${externalTaskId}/attachments`,
          isDeleted: true,
        }}
        createHeader="button.uploadFile"
        hideOnSinglePage
        showSizeChanger={false}
        isDynamicApiResource
        isUpdateRoute={false}
      >
        <RestFieldItem
          source="fileName"
          header="documentaryFiles.name"
          format={(data, record = {}) => {
            const roles = getRoleCreateUser(record.createdUser?.roles);
            return (
              <div>
                <div
                  className={`${
                    checkRole(['Van_Thu'], roles) ? 'file-archive' : ''
                  } name-file`}
                  onClick={() => handleClickViewFile(record)}
                  role="presentation"
                >
                  {data}
                </div>
                <div className="time-file">
                  {record.createdAt
                    ? moment(record.createdAt).format('DD-MM-YYYY HH:mm')
                    : null}
                </div>
              </div>
            );
          }}
        />
        <RestFieldItem
          source="createdUser"
          header="documentaryFiles.createdUser"
          format={(data, record) =>
            data ? (
              <UserInfo
                record={record.createdUser}
                nameProp="name"
                isShowPopover
              />
            ) : null
          }
          width={160}
        />
        <ActionGroup widthAction={80}>
          {/* <CustomButton handleSubmit={handleClickViewFile} /> */}
          <CustomButton
            icon="download"
            title="button.downloadFile"
            handleSubmit={handleDownloadFile}
          />
          <DeleteButton
            customMessageItemName="documentaryFiles.customMessageItemName"
            checkShowAction={checkDeleteFile}
          />
        </ActionGroup>
      </List>
      {visibleModalViewFile && (
        <ModalViewFile
          visible
          onCancel={onCancelViewFile}
          urlViewObj={urlViewObj}
        />
      )}
    </ListStyles>
  );
};

DocumentaryFilesList.propTypes = {};

export default withRouter(DocumentaryFilesList);
