import React from 'react';
import Create from '../../rest/Create';
import Form from '../components/Form';

const DocumentariesCreate = props => (
  <Create
    {...props}
    resource="documentaries"
    defaultOptions={{ customApiResource: 'externaltasks' }}
    formatOnSubmit={({ activities, medias, fromNumber, sourceNumber, publisher, title, content, ...data }) => {
      return {
        ...data,
        fromNumber: fromNumber && fromNumber.trim(),
        sourceNumber: sourceNumber && sourceNumber.trim(),
        publisher: publisher && publisher.trim(),
        title: title && title.trim(),
        content: content && content.trim(),
        ...(activities[0]?.items[0]?.user?.id && {
          activity: {
            items: [{ userId: activities[0]?.items[0]?.user?.id, role: 1 }],
          },
        }),
        ...(medias && { mediaIds: medias.map(item => item.id || item) }),
        taskType: 1,
      };
    }}
  >
    <Form />
  </Create>
);

DocumentariesCreate.propTypes = {};

export default DocumentariesCreate;
