import React, { useMemo, useCallback } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { indexOf } from 'lodash';
// import moment from 'moment';
import {
  DOCUMENTARY_STATUS_VALUE,
  ACTIVITY_ROLE_VALUE,
} from 'configs/localData';
import { getTreeActivities } from 'redux/documentaries/selectors';
import { checkRole } from 'utils/tools';

import RestShow from '../../rest/Show';
import DocumentaryDetail from '../components/DocumentaryDetail';
import ShowStyles from './styles';
import ModalForward from '../components/ModalForward';
import ModalArchive from '../components/ModalArchive';
import ExtraActions from '../components/ExtraActions';
import { ModalProvider } from '../components/ModalContext';

const DocumentariesShow = (props) => {
  const customBreadcrumb = [
    {
      title: i18next.t('documentaries.header'),
      path: '/documentaries',
    },
    {
      title: i18next.t('documentaries.detailDocumentary'),
      path: props.location.pathname || '/',
    },
  ];

  const idTask = props.match?.params?.id;

  const currentData =
    useSelector((state) => state.documentaries.currentData) || {};
  const roleUser = useSelector((state) => state.auth.role);
  const authId = localStorage.getItem('id');
  const permissionData = useSelector((state) => state.auth.permissionData);
  const activitiesData = useSelector((state) => getTreeActivities(state));
  // const [isClickComplete, setIsClickShowComplete] = useState(false);

  const checkShowAction = useCallback((permission) => {
    return (
      !currentData.isDeleted &&
      currentData.status <= DOCUMENTARY_STATUS_VALUE.waitingProcessing &&
      (authId === currentData.createdUser?.id ||
        checkRole(permission, permissionData))
    );
  }, [currentData, authId, permissionData]);

  const isForward = useMemo(
    () =>
      indexOf(
        [
          DOCUMENTARY_STATUS_VALUE.waitingProcessing,
          DOCUMENTARY_STATUS_VALUE.processing,
          DOCUMENTARY_STATUS_VALUE.needKnow,
        ],
        currentData?.status,
      ) !== -1 &&
      currentData?.role !== ACTIVITY_ROLE_VALUE.execute &&
      checkRole(['Truong_Phong', 'Pho_Phong'], roleUser),
    [currentData.role, currentData.status, roleUser],
  );

  // const checkDisabledAction = () => {
  //   return (moment().diff(moment(currentData?.createdAt), 'hours') > 12)
  // }

  return (
    <ShowStyles>
      <ModalProvider>
        <RestShow
          {...props}
          hasEdit={checkShowAction(['DocumentTaskEdit'])}
          hasDel={checkShowAction(['DocumentTaskRemove'])}
          // disabledEdit={checkDisabledAction()}
          // disabledDel={checkDisabledAction()}
          resource="documentaries"
          defaultOptions={{ customApiResource: 'externaltasks' }}
          customBreadcrumb={customBreadcrumb}
          customMessageItemName="documentaries.customMessageItemName"
          extraAction={<ExtraActions idTask={idTask} isForward={isForward} />}
        >
          <DocumentaryDetail activitiesData={activitiesData} idTask={idTask} />
        </RestShow>

        <ModalForward
          idTask={idTask}
          isForward={isForward}
          activitiesData={activitiesData}
        />

        <ModalArchive idTask={idTask} toNumber={currentData.toNumber} />
      </ModalProvider>
    </ShowStyles>
  );
};

DocumentariesShow.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default DocumentariesShow;
