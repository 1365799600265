import React, { useState } from 'react';
import { Menu, Icon, Button, Dropdown } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import DeleteButton from 'components/RestActions/DeleteButton';
import DropdownStyles, { MenuStyles } from './styles';

const DropdownMenu = ({ onClickEditNote, comment, submitActionDel, idTask }) => {

  const dispatch = useDispatch();

  const [visibleDropDown, setVisibleDropDown] = useState(false);

  const onClickEdit = (e) => {
    e.preventDefault();
    onClickEditNote();
    setVisibleDropDown(false);
  };

  const handleDeleteNote = () => {
    dispatch(submitActionDel({ id: idTask, idComment: comment?.id }));
  };

  const onClickIcon = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleVisibleChange = (visible) => {
    setVisibleDropDown(visible);
  }

  const menu = (
    <MenuStyles>
      <Menu.Item key="0">
        <Button
          className="btn-edit-note"
          onClick={onClickEdit}
          icon="edit"
        >
          {i18next.t('button.edit')}
        </Button>
      </Menu.Item>
      <Menu.Item key="1">
        <DeleteButton
          resource="notes"
          customMessageItemName="notes.customMessageItemName"
          deleteItem={handleDeleteNote}
          record={{ id: comment?.id }}
          isShowTextButton
          customOnClick={() => setVisibleDropDown(false)}
        />
      </Menu.Item>
    </MenuStyles>
  );

  return (
    <DropdownStyles>
      <Dropdown
        overlay={menu}
        trigger={['click']}
        visible={visibleDropDown}
        onVisibleChange={handleVisibleChange}
      >
        <Icon type="dash" onClick={onClickIcon} className="icon-dropdown" />
      </Dropdown>
    </DropdownStyles>
  )
}

DropdownMenu.propTypes = {
  onClickEditNote: PropTypes.func,
  comment: PropTypes.object,
  submitActionDel: PropTypes.func,
  idTask: PropTypes.string,
}

export default DropdownMenu;