import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
// import { Tooltip } from 'antd';
import { Tooltip } from 'antd';
import { ButtonWrapper } from './styles';

const CustomButton = ({ record, icon, title, handleSubmit }) => {

  const handleOnClick = () => {
    handleSubmit(record);
  }
  return (
    <Tooltip title={I18n.t(title)}>
      <ButtonWrapper
        icon={icon}
        onClick={handleOnClick} 
      />      
    </Tooltip>
  );
};

CustomButton.propTypes = {
  record: PropTypes.object,
  icon: PropTypes.string,
  title: PropTypes.string,
  handleSubmit: PropTypes.func,
};

CustomButton.defaultProps = {
  handleSubmit: () => null,
  icon: 'eye',
  title: 'button.viewFile',
};

export default CustomButton;
