import { get } from './utils';

export async function getConfig() {
  return get(`/configs`);
}

export async function getSummariesApi() {
  return get('/dashboards');
}

export async function getDepartmentsApi(data) {
  return get('/departments', data);
}

export async function getPositionsApi(data) {
  return get('/positions', data);
}

export async function getAllUsersApi() {
  return get(`/users/all`);
}

export async function getAllTagsApi() {
  return get(`/configs/tags`);
}
