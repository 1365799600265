import styled from 'styled-components';

export const ListWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${'' /* background: ${({ theme }) => theme.background.content}; */}
  padding: 0;
  .viewContent {
    width: 100%;
    ${'' /* height: 100%; */}
  }
  .ant-pagination > li {
    margin-top: 10px; /*fix style pagination */
  }
  .paginationRow .vActions {
    margin-top: 10px; /*fix style pagination */
  }
  .ant-pagination-options > div {
    height: 32px;
  }
  ${
    '' /* .ant-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  } */
  }
  .ant-pagination {
    .ant-pagination-prev, 
    .ant-pagination-item,  
    .ant-pagination-next, 
    .ant-pagination-options > .ant-pagination-options-size-changer, 
    .ant-pagination-options > .ant-pagination-options-quick-jumper {
      z-index: 1000;
    }
  }
  .paginationRow {
    ${'' /* margin-bottom: 20px; */}
    .ant-pagination-next {
      margin-right: 15px;
    }
    .ant-pagination-options {
      margin-left: 0px;
    }
  }
  .ant-pagination {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    ${'' /* .ant-select-selection {
      border: none;
    } */}
  }
  .pagination-top-row .ant-pagination {
    justify-content: start;
  }
  .pagination-bottom-row {
    margin-top: 20px;
    ${'' /* .ant-pagination {
      justify-content: flex-end;
    } */}
  }
  .ant-pagination-total-text {
    display: flex;
    align-items: center;
  }
  .ant-pagination-options {
    height: inherit;
  }
  .ant-select-arrow {
    right: 6px;
  }
  .ant-pagination-options-quick-jumper {
    font-weight: 500;
    font-size: 14px;
    line-height: inherit;
    height: inherit;
  }
  .ant-pagination-options-quick-jumper input {
    height: auto;
    border: 1px solid #d9d9d9;

  }
  .ant-select-selection-selected-value {
    font-weight: 500;
    font-size: 14px;
  }
  .ant-pagination-item {
    font-weight: 500;
    font-size: 14px;
  }
  .box {
    ${'' /* padding: 0px; */}
    border: 0px;
    border-radius: 5.5px;
    margin-bottom: 0;
  }
  .txtTotal {
    font-weight: normal;
  }
  .item {
    background: ${({ theme }) => theme.background.content};
    margin-bottom: 3px;
    border-radius: 4px;
    .ant-list-item-content {
      padding: 15px;
    }
  }

  .active-row-item .item, .active-row-item .ant-card-actions {
    background: ${({theme}) => theme.background.notSeenTask}
  }
  .active-row-item-execute .item, .active-row-item-execute .ant-card-actions {
    background: ${({theme}) => theme.background.notSeenTaskExecute}
  }

  .ant-table-body {
    overflow-x: auto !important;
  }
  .vActions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
    & > button {
      margin-left: 15px;
    }
  }
  .title {
    font-size: 14px;
    font-weight: bold;
  }
  ${'' /* .ant-input {
    border: 1px solid;
    border-color: ${({ theme }) => theme.border.default}
  } */}

  .ant-table-thead {
    & > tr {
      & > th {
        ${'' /* text-transform: uppercase; */}
        ${'' /* line-height: 17px; */}
        background: ${({ theme }) => theme.background.headerTable};
        color: ${({ theme }) => theme.text.headerTable};
        font-size: 14px;
        font-weight: ${({ theme }) => theme.fontWeight.medium};
        & > div {
          white-space: nowrap;
        }
      }
    }
  }
  .ant-select-selection--single {
    height: 32px;
  }
  .ant-select-selection__rendered,.ant-select-selection-selected-value {
    line-height: 32px;
  }
  @media only screen and (max-width: 767px) {
    ${'' /* margin-bottom: 24px; */}
    padding: 0px;
    display: flex;
    align-items: center;
    .ant-card-body {
      padding: 15px;
      .title {
        margin-right: 10px;
        width: 30%;
      }
      .ant-col {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        & > span {
          width: 70%;
          word-break: break-word;
        }
      }
    }
    .ant-list {
      margin-top: 0!important;
    }
  }
  @media only screen and (max-width: 576px) {
    /* .ant-pagination > .ant-pagination-next {
      margin-right: 0;
    } */
    .ant-pagination-options {
      display: block !important;
    }
  }
`;
