import { createSelector } from 'reselect';
import { getFilterFromUrl } from '../../utils/tools';

export default class CRUDSelectors {
  constructor(resource) {
    this.resource = resource;
  }

  getRestByResourceData = state => state[this.resource];

  getRestData = state => state[this.resource];

  getDefaultValue = (state, props) =>
    props.location && (!props.location.hash || props.location.hash === '#')
      ? decodeURI(props.location.search.substring(1)).trim()
      : props.location.hash.match(`#${this.resource}/create?(.*)`)?.[1];

  getDefaultFromProps = (state, props) => {
    // console.log(props.defaultValue, 'nananana');
    return props.defaultValue;
  };

  getDataArr = createSelector(
    [this.getRestData],
    resources => {
      const { data, ids } = resources;
      return ids.map(id => data[id]);
    },
  );

  getTotal = createSelector(
    [this.getRestData],
    resources => {
      const { total } = resources;
      return total;
    },
  );

  getDefaultCreateData = createSelector(
    [this.getDefaultValue, this.getDefaultFromProps],
    (defaultValue, defaultValueFromProps) => {
      // console.log(defaultValue, getFilterFromUrl(defaultValue)?.filter, 'ahahahah');
      return defaultValue !== ''
        ? getFilterFromUrl(defaultValue).filter
        : defaultValueFromProps || {};
    },
  );

  getCurrentData = createSelector(
    [this.getRestData],
    (resources = {}) => {
      const { currentData } = resources;
      return currentData || {};
    },
  );

  getLoadingCurrentRecord = createSelector(
    [this.getRestData],
    (resources = {}) => {
      const { currentId, itemLoadings } = resources;
      return itemLoadings[currentId];
    },
  );

  enabledLoadMore = createSelector(
    [this.getRestData],
    resources => {
      const { pageNumber, loading, numberOfPages } = resources;
      return !loading && pageNumber < numberOfPages;
    },
  );

  getLoading = createSelector(
    [this.getRestData],
    (resources = { loading: false }) => {
      const { loading } = resources;
      return loading;
    },
  );

  getCreateLoading = createSelector(
    [this.getRestData],
    (resources = { createLoading: false }) => {
      const { createLoading } = resources;
      return createLoading;
    },
  );

  getError = createSelector(
    [this.getRestData],
    resources => {
      const { error } = resources;
      return error;
    },
  );

  getFilters = createSelector(
    [this.getRestData],
    resources => {
      const {
        pageSize,
        pageNumber,
        filter,
        total,
        sortBy,
        isSortByDesc,
        q,
      } = resources;
      return {
        pageSize,
        pageNumber,
        filter,
        count: total,
        sortBy,
        isSortByDesc,
        q,
      };
    },
  );
}

export const crudSelectors = new CRUDSelectors();
