import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import i18next from 'i18next';
import FormItem from '../../form/FormItem';
import { getRecordData } from '../../../utils/tools';
import { RestInputContext } from '../RestInputContext';

const RestInputItem = ({
  ContentComponent,
  isReference,
  children,
  format,
  placeholder,
  defaultValue,
  ruleType,
  valuePropName,
  autoComplete,
  rules,
  isValidatorEmpty,
  required,
  // allowPressEnter,
  ...props
}) => {
  const getDefaultValue = record => {
    return format(defaultValue) || format(getRecordData(record, props.source));
  }; 
  const validatorEmpty = (rule, value, callback) => {
    if (value && (typeof value === 'string') && (value.trim().length === 0)) {
      callback(i18next.t('error.empty'));
    } else {
      callback();
    }
  }

  const customRules =
    isValidatorEmpty && required && (ruleType === undefined || ruleType === 'string' )
      ? [
          {
            validator: validatorEmpty,
          },
          ...rules,
        ]
      : [...rules];
  return (
    <RestInputContext.Consumer>
      {
        ({ record, form, handleSubmit, allowPressEnter }) =>
          isReference ? (
            React.cloneElement(children, {
              record,
            })
          ) : (
            <FormItem
              {...props}
              required={required}
              form={form}
              ruleType={ruleType}
              valuePropName={valuePropName}
              defaultValue={getDefaultValue(record, handleSubmit)}
              rules={customRules}
            >
              <ContentComponent
                {...props}
                record={record}
                {...(autoComplete && { autoComplete })}
                {...(allowPressEnter && handleSubmit && { onPressEnter: handleSubmit })}
                placeholder={i18next.t(placeholder)}
              />
            </FormItem>
          )
        // eslint-disable-next-line react/jsx-curly-newline
      }
    </RestInputContext.Consumer>
  );
};

RestInputItem.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  defaultValue: PropTypes.any,
  ContentComponent: PropTypes.any,
  isReference: PropTypes.bool,
  children: PropTypes.any,
  format: PropTypes.func,
  placeholder: PropTypes.string,
  ruleType: PropTypes.string,
  valuePropName: PropTypes.string,
  autoComplete: PropTypes.string,
  isValidatorEmpty: PropTypes.bool,
  required: PropTypes.bool,
  rules: PropTypes.array,
  // allowPressEnter: PropTypes.bool,
};

RestInputItem.defaultProps = {
  ContentComponent: Input,
  format: data => data,
  isValidatorEmpty: true,
  required: false,
  rules: [],
  // allowPressEnter: false,
};

export default RestInputItem;
