import styled from 'styled-components';

export const NotifyItemStyles = styled.div`
  cursor: pointer;
  padding: 20px;
  background: ${({ isSeen, theme }) =>
    isSeen ? '#fff' : theme.background.notSeenNoti};
`;

const CustomListStyles = styled.div`
  ${'' /* .ant-list-items {
    max-height: 500px;
    overflow-y: auto;
  } */}
  .ant-list-item {
    padding: 0;
    ${'' /* margin-bottom: 20px; */}
    border-bottom: 1px solid ${({theme}) => theme.border.default};
  }
  .col-title-content {
    display: flex;
    flex-direction: column;
    justify-center: center;
    .title-header {
      display: flex;
      margin-bottom: 10px;
      @media only screen and (max-width: 576px) {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      & > div:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    }
    .list-tags {
      margin-right: 10px;
      display: flex;
    }
  }
  

  .col-tag-medias {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    .ant-tag {
      margin-right: 15px;
    }
    @media only screen and (max-width: 1200px) {
      margin-top: 10px;
    }

    @media only screen and (max-width: 350px) {
      flex-direction: column;
      align-items: start;
      justify-content: center;
      .ant-tag {
        margin: 0 0 10px 0;
      }
    }
  }

  .ant-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
  }

  .header-item-announcement {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .top-item {
      display: flex;
      align-items: center;
      ${'' /* margin-bottom: 15px; */}
      flex-wrap: wrap;
      .label-priority, .sender-span {
        margin-bottom: 15px;
      }
    }
    .view-button {
      margin-bottom: 15px;
    }
    .ant-tag {
      margin-right: 10px;
    }
    .time-span {
      padding-right: 10px;
    }
    .sender-span {
      padding: 0px 10px;
    }
    ${
      '' /* .label-priority {
      border-right: 1px solid ${({ theme }) => theme.border.default};
      margin-right: 10px;
    } */
    }

    .time-span {
      ${'' /* border-left: 1px solid ${({ theme }) => theme.border.default}; */}
      border-right: 1px solid ${({ theme }) => theme.border.default};
    }
    .sender-span {
      & > i {
        color: ${({ theme }) => theme.palette.primary};
      }
      & > span {
        margin-left: 10px;
      }
    }
  }
  .item-content {
    width: 100%;
    & > div:not(:first-child):not(:last-child) {
    margin-bottom: 15px;
  }
  }
  ${
    '' /* .name-item-announcement, .description-item-announcement {
    margin-bottom: 15px;
  } */
  }
  
  .name-item-announcement {
    font-weight: 600;
    font-size: 15px;
    margin-right: 30px;
  }
  .name-item-announcement,
  .description-item-announcement {
    ${'' /* margin-top: 5px; */}
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
    -webkit-line-clamp: 1;
  }

  .name-item-announcement {
    ${'' /* -webkit-line-clamp: 1; */}
    color: ${({ theme }) => theme.text.primary};
  }
  .description-item-announcement {
    ${'' /* -webkit-line-clamp: 2; */}
    color: ${({theme}) => theme.color.secondary};
    font-size: 14px;
  }

  .bottom-item-announcement {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .totalView-span {
      margin-left: 5px;
    }
  }
  .total-medias {
    i {
      margin-right: 5px;
      color: #1890ff;
    }
  }

  .row-announcement {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  

  @media only screen and (max-width: 992px) {
    .row-announcement > .ant-col:not(:last-child) {
      margin-bottom: 15px;
    }
    .col-tag-medias {
      margin-top: 0;
      justify-content: start;
    }
  }
  @media only screen and (max-width: 630px) {
    .header-item-announcement {
      ${
        '' /* display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start; */
      }
      .time-span {
        padding: 10px 0;
        border: none;
      }
      .sender-span {
        padding: 0;
        border: none;
      }
    }
  }
`;

export default CustomListStyles;
