// import crud action
import { actions as documentaryFiles } from './documentaryFiles/slice';
import { actions as documentBoxes } from './documentBoxes/slice';
import { actions as documentaries } from './documentaries/slice';
import { actions as internalMessages } from './internalMessages/slice';
import { actions as announcements } from './announcements/slice';
import { actions as users } from './users/slice';
import { actions as notifications } from './notifications/slice';

export default {
  // actions here
  documentaryFiles,
  documentBoxes,
  documentaries,
  internalMessages,
  announcements,
  users,
  notifications,
};
