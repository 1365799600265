import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalStyles = styled(Modal)`
  .item-user-seen {
    margin-bottom: 15px;
  }
`;

const SeenUsersStyles = styled.div`
  .seen-users {
    ${'' /* cursor: pointer; */}
    width: 100%;
    border: none;
    display: flex;
    box-shadow: none;
    padding: 0;
    strong {
      margin-right: 5px;
    }
  }
`;

export default SeenUsersStyles;
