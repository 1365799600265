import styled from 'styled-components';

const ListStyles = styled.div`
  .list-wrapper {
    padding: 0;
    margin: 0;
    .paginationRow {
      display: none;
    }
    .vActions {
      display: none;
    }
  }

  @media only screen and (max-width: 576px){
    margin: -24px;
  }
`;

export default ListStyles;