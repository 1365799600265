import styled from 'styled-components';

const ListFolderFileStyles = styled.div`
  .item-name {
    display: flex;
    /* align-items: center; */
    .anticon {
      font-size: 16px;
      margin-right: 10px;
      line-height: 23px;
    }
    color: ${({ theme }) => theme.text.primary};
    .count-number {
      margin-left: 10px;
      white-space: nowrap;
    }
  }

  .folder-link, .name-file {
    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.palette.primary} !important;
      .anticon {
        color: ${({ theme }) => theme.palette.primary} !important;
      }
    }
  }
`;

export default ListFolderFileStyles;