import React from 'react';
import PropTypes from 'prop-types';
import RestMultiPhotos from 'components/RestInput/RestMultiPhotos';

const DocumentaryFilesForm = ({isEdit, ...props}) => (
  <div {...props}>
    <RestMultiPhotos
      required
      resource="documentBoxes"
      multiple={!isEdit}
      source={isEdit ? 'media' : 'mediaIds'}
      header="boxFiles.attachedFile"
      placeholder="documentaries.medias"
      requiredMessage="input.medias.validateMsg.required"
      typeFile={2}
    />
  </div>
);

DocumentaryFilesForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default DocumentaryFilesForm;
