import styled from 'styled-components';

const ExtraButtonsStyles = styled.div`
  text-align: right;
  & > button:not(:first-child) {
    margin-left: 15px;
  }

  @media only screen and (max-width: 630px) {
    button {
      margin-bottom: 10px;
    }
  }
`;

export default ExtraButtonsStyles;