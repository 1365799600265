import React, { useState , useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Icon } from 'antd';
import I18n from 'i18next';


const SearchInput = ({ onTextSearch, defaultValue, placeholder }) => {
  const [valueSearch, setValueSearch] = useState(defaultValue);

  useEffect(() => {
    setValueSearch(defaultValue);
  }, [defaultValue]);
  
  const handleOnChange = (e) => {
    setValueSearch(e.target.value)
  }

  return (
    <Input
      style={{ width: '180px' }}
      value={valueSearch}
      placeholder={I18n.t(placeholder)}
      prefix=<Icon style={{ color: '#41433f' }} type="search" size={14} />
      onPressEnter={(e) => onTextSearch(e.currentTarget.value)}
      onChange={handleOnChange}
    />
  );};
SearchInput.propTypes = {
  onTextSearch: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
};

export default SearchInput;
