import React, { useEffect } from 'react';
import { List, Tag, Icon, Spin, Row, Col } from 'antd';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PRIORITY } from 'configs/localData';
import i18next from 'i18next';
import moment from 'moment';
import UserInfo from 'components/RestField/UserInfo';
import ContentText from 'components/common/ContentText';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/notifications/slice';
// import { checkRole } from 'utils/tools';
import CustomListStyles, { NotifyItemStyles } from './styles';

const AnnouncementsListCustom = ({ history, resourceData }) => {
  const dispatch = useDispatch();
  const notSeen = useSelector(
    state => state.notifications.totalNewAnnouncement,
  );
  // const permissionData = useSelector(state => state.auth.permissionData);
  const profileData = useSelector(state => state.auth.data);
  const loading = useSelector(state => state.announcements.loading);
  useEffect(() => {
    notSeen && dispatch(actions.trackingResetAnnouncements());
    // eslint-disable-next-line
  }, [notSeen]);
  return (
    <CustomListStyles>
      {loading && <Spin />}
      <List
        itemLayout="horizontal"
        dataSource={resourceData || []}
        renderItem={(item, index) => (
          <List.Item key={String(index)}>
            <NotifyItemStyles
              className="item-content"
              isSeen={profileData?.id === item?.createdUser?.id || item.isSeen}
              // isDeleted={item.isDeleted}
              // to={`/announcements#announcements/${item.id}/show`}
              onClick={() =>
                history?.push(`/announcements#announcements/${item.id}/show`)}
              // style={{ ...(item.isDeleted && { background: '#f0f0f0' }) }}
            >
              <Row gutter={24} className="row-announcement">
                <Col xxl={3} xl={4} lg={4} md={24} xs={24} className="col-sender">
                  <UserInfo
                    record={item?.createdUser}
                    nameProp="name"
                    // note={moment(item.createdAt).format('DD-MM-YYYY HH:mm')}
                    // isLink={checkRole(
                    //   ['AllowAll', 'UserRead', 'UserEdit'],
                    //   permissionData,
                    // )}
                    isShowPopover
                  />
                </Col>
                <Col xxl={16} xl={13} lg={20} md={24} xs={24} className="col-title-content">
                  <div className="title-header">
                    {(item?.isDeleted || item.priority > 0) && (
                    <div className="list-tags">
                      {item?.isDeleted && (
                      <Tag className="view-button" color="purple">
                        {i18next.t('announcements.deleted')}
                      </Tag>
                      )}
                      {item.priority > 0 && (
                      <span className="label-priority">
                        <Tag
                          color={
                            PRIORITY.find(
                              priority => priority.value === item.priority,
                            )?.color
                          }
                        >
                          {i18next.t(
                            PRIORITY.find(
                              priority => priority.value === item.priority,
                            )?.text,
                          )}
                        </Tag>
                      </span>
                    )}
                    </div>
                  )}
                    <div className="name-item-announcement">{item.title}</div>
                  </div>
                  <div className="description-item-announcement">
                    <ContentText content={item?.content} />
                  </div>
                </Col>
                <Col xxl={5} xl={7} lg={24} md={24} xs={24} className="col-tag-medias">
                  {item.mediaFileCount ? (
                    <Tag className="total-medias" color="blue">
                      <Icon type="paper-clip" />
                      <span>
                        {`${item.mediaFileCount} ${i18next.t(
                          'medias.attached',
                        )}`}
                      </span>
                    </Tag>
                  ) : null}
                  <span className="time-notify">{moment(item.createdAt).format('DD-MM-YYYY HH:mm')}</span>
                </Col>
              </Row>
            </NotifyItemStyles>
          </List.Item>
        )}
      />
    </CustomListStyles>
  );
};

AnnouncementsListCustom.propTypes = {
  resourceData: PropTypes.array,
  // retrieveList: PropTypes.func,
  // resourceFilter: PropTypes.object,
  history: PropTypes.object,
};

export default AnnouncementsListCustom;
