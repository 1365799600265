import { notification } from 'antd';
import { call, put, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import I18n from 'i18next';
// eslint-disable-next-line
import { setLoading } from 'redux/loading/slice';
// eslint-disable-next-line
import { logout, loginFail } from 'redux/auth/slice';
// import { logout } from './login/actions';

const ERROR_CODE = [401];

export function* apiWrapper(
  config = { isShowProgress: true, isShowSuccessNoti: false },
  apiFunc,
  ...params
) {
  try {
    yield put(setLoading(config.isShowLoading));
    const response = yield call(apiFunc, ...params);
    yield put(setLoading(false));
    yield fork(checkError, response);

    config.isShowSuccessNoti &&
      notification.success({
        message: I18n.t('success.title'),
        description:
          response.message ||
          config.successDescription ||
          I18n.t('success.description'),
        duration: 2,
      });
    return response;
  } catch (err) {
    // console.log(err, 'err')
    yield put(setLoading(false));
    notification.destroy();
    if (err?.code === 500)
      notification.error({
        message: I18n.t('error.title'),
        description: I18n.t('error.description'),
        duration: 2,
      });
    else if (err?.code === 403)
      notification.error({
        message: I18n.t('error.title'),
        description: I18n.t('error.error403'),
        duration: 2,
      });
    else if (err?.code === 401)
      notification.error({
        message: I18n.t('error.title'),
        description: I18n.t('error.error401'),
        duration: 2,
      });
    else if (err?.code === 404)
      notification.error({
        message: I18n.t('error.title'),
        description: I18n.t('error.error404'),
        duration: 2,
      });
    else if (err?.code === 503)
      notification.error({
        message: I18n.t('error.title'),
        description: I18n.t('error.error503'),
        duration: 2,
      });
    else
      notification.error({
        message: I18n.t('error.title'),
        description: err.message || I18n.t('error.description'),
        duration: 2,
      });
    yield fork(checkError, err);
    throw err;
  }
}

export function* checkError(res) {
  // const auth = yield select(state => state.auth);
  if (res.code === 401) {
    // yield put(logout());
    // const path = window.location.pathname;
    // if(path !== '/login') {
    //   localStorage.setItem('urlExpiredToken', path);
    // }
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('name');
    localStorage.removeItem('id');
    yield put(loginFail(res.code));
    // yield put(push(`/login`));
  } else if (ERROR_CODE.indexOf(res.code) > -1) {
    yield put(push(`/error/${res.code}`));
  }
}
