import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import RestDateTimePicker from 'components/RestInput/RestDateTimePicker';
import { DELETE_STATUS } from 'configs/localData';
import i18next from 'i18next';

const Filter = ({ format, ...props }) => (
  <Row
    {...props}
    gutter={24}
    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
  >
    {/* <Col xl={4} lg={8} md={8} xs={24}>
      <RestSelect
        source="priority"
        placeholder="announcements.priority"
        resourceData={PRIORITY}
        valueProp="value"
        titleProp="text"
        formatText={data => i18next.t(data)}
        ruleType="number"
        isShowSearch={false}
      />
    </Col> */}
    <Col xl={10} lg={8} md={12} xs={24}>
      <RestInputItem source="query" placeholder="announcements.query" />
    </Col>
    <Col xl={4} lg={4} md={12} xs={24}>
      <RestSelect
        source="isDeleted"
        placeholder="announcements.isDeleted"
        resourceData={DELETE_STATUS}
        valueProp="value"
        titleProp="text"
        formatText={data => i18next.t(data)}
        isShowSearch={false}
      />
    </Col>
    {/* <Col lg={6} lg={12} xs={24}>
      <RestInputItem source="title" placeholder="announcements.title" />
    </Col> */}
    <Col xl={5} lg={6} md={12} xs={24}>
      <RestDateTimePicker
        source="startDate"
        placeholder="announcements.startDate"
        isShowTime={false}
        allowClear
      />
    </Col>
    <Col xl={5} lg={6} md={12} xs={24}>
      <RestDateTimePicker
        source="endDate"
        placeholder="announcements.endDate"
        isShowTime={false}
        allowClear
      />
    </Col>
  </Row>
);

Filter.propTypes = {
  format: PropTypes.func,
};

export default Filter;
