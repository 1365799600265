import React from 'react';
import PropTypes from 'prop-types';
// import RestTreeSelect from 'components/RestInput/RestTreeSelect';
// import { useSelector } from 'react-redux';
import i18next from 'i18next';
// import { getRootFolders } from 'redux/documentBoxes/selectors';
// import { checkRole } from 'utils/tools';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const DocumentBoxesForm = ({isEdit, ...props}) => {

  // const listFolders = useSelector(state => getRootFolders(state));
  // const selectedFolder = useSelector(state => state.documentBoxes.selectedFolder);
  // const roles = useSelector(state => state.auth.role);
  // const [defaultFolder, setDefaultFolder] = useState(selectedFolderId)

  // useEffect(() => {
  //   defaultFolder && 
  // }, [])

  // const checkSelectedFolder = () => {
  //   let selectedItem = null;
  //   if(listFolders?.find(item => item.id === selectedFolder?.id)) selectedItem = selectedFolder;
  //   if(selectedItem?.parentId) return selectedItem?.parentId;
  //   return selectedItem?.id;
  // }

  return (
    <div {...props}>
      {/* {!isEdit && (
      <RestTreeSelect
        required={!checkRole(['Quan_Tri_Vien'], roles)}
        source="parentFolderId"
        placeholder="documentBoxes.parentFolder"
        header="documentBoxes.parentFolder"
        treeData={listFolders}
        defaultValue={checkSelectedFolder()}
        treeCheckable={false}
      />
    )} */}
      
      <RestInputItem
        required
        source="name"
        header="documentBoxes.folderName"
        placeholder="documentBoxes.folderName"
        rules={[
          {
            max: 500,
            message: i18next.t('input.folderName.validateMsg.maxLength', {
              max: 500,
            }),
          },
        ]}
      />
    </div>
  );
};

DocumentBoxesForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default DocumentBoxesForm;
