import styled from 'styled-components';
import { Menu } from 'antd';

const DropdownStyles = styled.div`
  .icon-dropdown {
    font-size: 20px;
  }
`;

export default DropdownStyles;


export const MenuStyles = styled(Menu)`
  .btn-edit-note {
    border: none;
    color: ${({ theme }) => theme.text.primary} !important;
    height: 30px !important;
    background: transparent !important;
    box-shadow: none !important;
    &:hover {
      background: transparent;
      transform: scale(1.1, 1.1);
      color: ${({ theme }) => theme.palette.primary} !important;
    }
    &:focus {
      background: transparent;
      transform: scale(1.1, 1.1);
      color: ${({ theme }) => theme.palette.primary} !important;
    }
    .anticon {
      font-size: 20px;
    }
  }
`