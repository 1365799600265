import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { isArray } from 'lodash';
import { actions } from 'redux/documentaries/slice';
import { RestInputContext } from 'components/RestInput/RestInputContext';
// import ForwardModalStyles from './styles';
import ModalCustom from 'components/common/ModalCustom';
import FormForward from '../FormForward';
import { useModalContext } from '../ModalContext';

const ModalForward = ({ form, isForward, idTask, activitiesData }) => {
  const dispatch = useDispatch();
  const { visibleForward, toggleForward } = useModalContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        dispatch(
          actions.addActivity({
            id: idTask,
            // notes: values.notes && values.notes.trim(),
            items:
              isArray(values.users) &&
              values.users.map((item, index) => ({
                userId: values.items[index],
                ...item,
              })),
          }),
        );
        handleOnCancel();
      }
    });
  };

  const handleOnCancel = () => {
    toggleForward();
    form.resetFields();
  };

  return (
    <ModalCustom
      width={800}
      title={
        isForward
          ? i18next.t('documentaries.titleForwardModal')
          : i18next.t('documentaries.titleExecuteModal')
      }
      visible={visibleForward}
      onCancel={handleOnCancel}
      onOk={handleSubmit}
    >
      <RestInputContext.Provider value={{ form, record: {} }}>
        {/* {React.cloneElement(<FormForward />, {
          form,
        })} */}
        <FormForward isForward={isForward} activitiesData={activitiesData} />
      </RestInputContext.Provider>
    </ModalCustom>
  );
};

ModalForward.propTypes = {
  form: PropTypes.object,
  isForward: PropTypes.bool,
  idTask: PropTypes.string,
  activitiesData: PropTypes.array,
};

ModalForward.defaultProps = {
  isForward: true,
};

export default Form.create()(ModalForward);
