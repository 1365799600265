import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { flatMap, map } from 'lodash';
import { withNamespaces } from 'react-i18next';
import { getCurentUser } from 'redux/auth/slice';
import { getDepartments, getPositions, getTags, getUsers } from 'redux/config/slice';
import { actions as notificationActions } from 'redux/notifications/slice';
import { checkRole } from 'utils/tools';
import LaunchScreen from 'components/common/LaunchScreen';
import PrivateLayout from '../../layout/PrivateLayout';
import DocumentBoxes from '../../pages/DocumentBoxes';
import Documentaries from '../../pages/Documentaries';
import InternalMessages from '../../pages/InternalMessages';
import Announcements from '../../pages/Announcements';
import Users from '../../pages/Users';
import Home from '../../pages/Dashboard';
import Profile from '../../pages/Profile';
import Settings from '../../pages/Settings';
import Notifications from '../../pages/Notifications';
import DocumentaryFiles from '../../pages/DocumentaryFiles';

const routes = [

  {
    path: '/documentaryFiles',
    routes: [
      {
        path: '/',
        component: DocumentaryFiles.List,
      },
      {
        path: '/create',
        component: DocumentaryFiles.Create,
      },
      {
        path: '/:id/edit',
        component: DocumentaryFiles.Edit,
      },
    ],
  },

  {
    path: '/documentBoxes',
    routes: [
      {
        path: '/create',
        component: DocumentBoxes.Create,
        roles: ['AllowAll', 'FolderAddNew'],
      },
      {
        path: '/:id/edit',
        component: DocumentBoxes.Edit,
        roles: ['AllowAll', 'FolderEdit'],
      },
      {
        path: '/',
        component: DocumentBoxes.List,
        roles: ['AllowAll', 'MenuDocumentBox'],
      },
      {
        path: '/:id',
        component: DocumentBoxes.List,
        roles: ['AllowAll', 'MenuDocumentBox'],
      },
    ],
  },
  {
    path: '/documentaries',
    routes: [
      {
        path: '/',
        component: Documentaries.List,
        roles: ['AllowAll', 'MenuDocumentTask'],
      },
      {
        path: '/create',
        component: Documentaries.Create,
        roles: ['AllowAll', 'DocumentTaskCreate'],
      },
      {
        path: '/:id/edit',
        component: Documentaries.Edit,
        roles: ['AllowAll', 'DocumentTaskEdit'],
      },
      {
        path: '/:id/show',
        component: Documentaries.Show,
      },
    ],
  },
  {
    path: '/internalMessages',
    routes: [
      {
        path: '/create',
        component: InternalMessages.Create,
        roles: ['AllowAll'],
      },
      {
        path: '/:id/edit',
        component: InternalMessages.Edit,
        roles: ['AllowAll'],
      },
      {
        path: '/:id/show',
        component: InternalMessages.Show,
      },
      // {
      //   path: '/',
      //   exact: true,
      //   component: InternalMessages.List,
      //   roles: ['AllowAll', 'MenuInternalTask'],
      // },
    ],
  },
  
  {
    path: '/internalMessages/:model',
    exact: true,
    component: InternalMessages.List,
    routes: [
      {
        path: '/create',
        component: InternalMessages.Create,
        roles: ['AllowAll'],
      },
      {
        path: '/:id/edit',
        component: InternalMessages.Edit,
        roles: ['AllowAll'],
      },
      {
        path: '/:id/show',
        component: InternalMessages.Show,
      },
      {
        path: '/',
        exact: true,
        component: InternalMessages.List,
        roles: ['AllowAll', 'MenuInternalTask'],
      },
    ],
  },

  {
    path: '/announcements',
    routes: [
      {
        path: '/',
        component: Announcements.List,
        roles: ['AllowAll', 'MenuAnnouncement'],
      },
      {
        path: '/create',
        component: Announcements.Create,
        roles: ['AllowAll', 'AnoucementAddNew'],
      },
      {
        path: '/:id/edit',
        component: Announcements.Edit,
        roles: ['AllowAll', 'AnoucementEdit'],
      },
    ],
  },
  {
    path: '/users',
    routes: [
      {
        path: '/',
        component: Users.List,
        roles: ['AllowAll', 'MenuUser'],
      },
      {
        path: '/create',
        component: Users.Create,
        roles: ['AllowAll', 'UserAddNew'],
      },
      {
        path: '/:id/edit',
        component: Users.Edit,
        roles: ['AllowAll', 'UserRead', 'UserEdit'],
      },
    ],
  },
  {
    path: '/notifications',
    routes: [
      {
        path: '/',
        component: Notifications.List,
      },
      {
        path: '/create',
        component: Notifications.Create,
      },
      {
        path: '/:id/edit',
        component: Notifications.Edit,
      },
    ],
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
    title: 'profile.title',
  },
  {
    path: '/',
    component: Home,
    exact: true,
    title: 'dashboard.title',
    hasPrivateLayoutWrapper: true,
  },
  {
    path: '/settings/:model',
    component: Settings,
    exact: true,
    title: 'settings.title',
    hasPrivateLayoutWrapper: true,
    // roles: ['superadmin', 'admin'],
  },
];

const wrappedRoutes = map(
  flatMap(routes, route => {
    if (route.routes) {
      return map(route.routes, subRoute => ({
        ...subRoute,
        path: route.path + subRoute.path,
        exact: subRoute.path === '/',
        hasPrivateLayoutWrapper: route.hasPrivateLayoutWrapper,
        component: withNamespaces()(subRoute.component || route.component),
      }));
    }
    return route;
  }),
  route => <PrivateRoute {...route} key={route.path} />,
);

function PrivateRoute({
  component: Component,
  title,
  hasPrivateLayoutWrapper,
  roles,
  ...rest
}) {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const permissionData = useSelector(state => state.auth.permissionData);
  const currentRole = useSelector(state => state.auth.role);
  const path = window.location.pathname;

  if (!isAuthenticated) {
    if (path !== '/login') {
      localStorage.setItem('urlExpiredToken', path);
    }
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: '/login',
              // eslint-disable-next-line
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  if (!currentRole) return <Route component={LaunchScreen} />;

  return checkRole(roles, permissionData) ? (
    <Route
      {...rest}
      render={props => (
        <PrivateLayout
          title={title}
          hasPrivateLayoutWrapper={hasPrivateLayoutWrapper}
        >
          <Component {...props} />
        </PrivateLayout>
      )}
    />
  ) : (
    // eslint-disable-next-line react/jsx-curly-newline
    <Route render={null} />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  title: PropTypes.string,
  hasPrivateLayoutWrapper: PropTypes.bool,
  roles: PropTypes.array,
  location: PropTypes.object,
};

const PrivateRoutes = () => {
  const dispatch = useDispatch();
  // const isActive = useSelector(state => state.auth.isActive);
  const currentRole = useSelector(state => state.auth.role);
  // const seenNotification = data =>
  //   dispatch(notificationActions.seenNotification(data));
  useEffect(() => {
    if (currentRole) {
      localStorage.removeItem('urlExpiredToken');
      dispatch(notificationActions.getNotificationCount());
      // dispatch(
      //   notificationActions.watchFirebaseNotifications({ seenNotification }),
      // );
      // dispatch(getSummaries());
      dispatch(getDepartments());
      dispatch(getPositions());
      dispatch(getTags());
      dispatch(getUsers());
    }
    // eslint-disable-next-line
  }, [currentRole]);
  useEffect(() => {
    localStorage.getItem('sessionToken') && dispatch(getCurentUser());
    // dispatch(
    //   getDepartments({
    //     pageSize: 100,
    //     pageNumber: 1,
    //     sortBy: 'createdAt',
    //     isSortByDesc: true,
    //   }),
    // );
    // dispatch(
    //   getPositions({
    //     pageSize: 100,
    //     pageNumber: 1,
    //     sortBy: 'createdAt',
    //     isSortByDesc: true,
    //   }),
    // );
    // eslint-disable-next-line
  }, []);
  // useEffect(() => {
  //   if (!isActive) {
  //     dispatch(logout());
  //   }
  //   // eslint-disable-next-line
  // }, [isActive]);
  return wrappedRoutes;
};

export default PrivateRoutes;
