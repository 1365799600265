import { xor } from 'lodash';
import { makeCRUDSlice } from '../crudCreator/slice';

export const RESOURCE = 'documentBoxes';

const documentBoxesSlice = makeCRUDSlice(RESOURCE, {
  getAllFolders: (state) => {
    state.loadingFolder = true;
  },
  getAllFoldersSuccess: (state, {payload: { data }}) => {
    state.loadingFolder = false;
    state.dataFolders = data;
  },
  getAllFoldersFailure: (state, {payload}) => {
    state.loadingFolder = false;
    state.error = payload;
  },

  createFolder: state => {
    state.error = null;
    state.createLoading = true;
    state.createFolderLoading = true;
    state.loading = false;
  },
  
  createFolderSuccess: (state, { payload: { data } }) => {
    state.dataFolders = [...[data], ...state.dataFolders ];
    state.loading = false;
    state.createLoading = false;
    state.createFolderLoading = false;
    state.error = null;
  },
  
  createFolderFailure: (state, { payload: { data } }) => {
    state.error = data;
    state.createLoading = false;
    state.createFolderLoading = false;
    state.loading = false;
  },

  editFolder: (state, {payload: { id }}) => {
    state.error = null;
    state.loading = false;
    state.itemLoadings = { ...state.itemLoadings, [id]: true };
  },
  
  editFolderSuccess: (state, { payload: { data } }) => {
    state.dataFolders = state?.dataFolders?.map(item => item.id === data.id ? data : item);
    state.currentFolderData = {
      ...state.currentFolderData,
      ...data,
    };
    state.itemLoadings = { ...state.itemLoadings, [data.id]: false };
  },
  
  editFolderFailure: (state, { payload: { id, error } }) => {
    state.error = error;
    state.itemLoadings = { ...state.itemLoadings, [id]: false };
  },

  getByIdFolderSuccess: (state, { payload: { data } }) => {
    state.currentData = data;
  },

  selectFolder: (state, { payload }) => {
    state.selectedFolder = payload;
  },

  delFolder: (state, { payload: { id } }) => {
    state.isDelFolderSuccess = false;
    state.error = null;
    state.itemLoadings = id
      ? { ...state.itemLoadings, [id]: true }
      : null;
  },
  
  delFolderSuccess: (state, { payload: { id, isDeleted } }) => {
    if (!isDeleted) {
      state.dataFolders = state.dataFolders?.map(item => item.id === id ? {...item, isDeleted: true} : item)
      state.itemLoadings = { ...state.itemLoadings, [id]: false };
    } else {
      const dataNew = state.dataFolders?.filter(item => item.id !== id);
      state.selectedFolder.id = state.dataFolders?.find(item => (item.id === id && !item.parentId)) 
        ? dataNew?.filter(item => !item.parentId)?.[0]?.id
        : state.dataFolders?.find(item => (item.id === id))?.parentId;
      state.isDelFolderSuccess = true;
      state.dataFolders = dataNew;
      state.error = null;
      state.currentId = null;
      state.itemLoadings = id
        ? { ...state.itemLoadings, [id]: null }
        : null;
    }
  },
  
  delFolderFailure: (state, { payload: { error, id, data } }) => {
    state.isDelFolderSuccess = false;
    state.error = error || data;
    state.loading = false;
    state.itemLoadings = id
      ? { ...state.itemLoadings, [id]: null }
      : null;
  },

  resetIsDelFolderSuccess: (state) => {
    state.isDelFolderSuccess = false;
  },


  createFile: state => {
    state.error = null;
    state.createLoading = true;
    state.loading = false;
  },
  
  createFileSuccess: (state, {payload: {folderId}}) => {
    // state.data = { [data.id]: data, ...state.data };
    // state.ids = [...state.ids, ...data.ids];
    // state.data = { ...state.data, ...data.data };
    state.loading = false;
    state.createLoading = false;
    state.error = null;
    state.total += 1;
    state.dataFolders = state.dataFolders?.map(item => item.id === folderId ? {...item, items: item.items + 1} : item);
  },
  
  createFileFailure: (state, { payload: { data } }) => {
    state.error = data;
    state.createLoading = false;
    state.loading = false;
  },

  getByIdFile: (state, { payload: { id } }) => {
    state.currentId = id;
  },
  
  getByIdFileSuccess: (state, { payload: { data } }) => {
    state.data = { ...state.data, [data.id]: data };
    state.currentData = data;
    state.loading = false;
  },
  
  getByIdFileFailure: (state, { payload: { data } }) => {
    state.error = data;
    state.loading = false;
  },
  
  editFile: (state, {payload: { id }}) => {
    state.error = null;
    state.itemLoadings = { ...state.itemLoadings, [id]: true };
  },
  
  editFileSuccess: (state, { payload: { data } }) => {
    state.data = { ...state.data, [data.id]: data};
    state.currentData = {
      ...state.currentData,
      ...data,
    };
    state.itemLoadings = { ...state.itemLoadings, [data.id]: false };
  },
  
  editFileFailure: (state, { payload: { data } }) => {
    state.error = data;
    state.itemLoadings = { ...state.itemLoadings, [data.id]: false };
  },

  delFile: (state, { payload: { id } }) => {
    state.error = null;
    state.itemLoadings = id
      ? { ...state.itemLoadings, [id]: true }
      : null;
  },
  
  delFileSuccess: (state, { payload: { data, isDeleted, folderId } }) => {
    if (!isDeleted) {
      state.data = {
        ...state.data,
        [data.id]: {
          ...state.data[data.id],
          ...data,
          isDeleted: true,
        },
      }; 
      state.currentData = {
        ...state.currentData,
        ...data,
        isDeleted: true,
      };
      state.itemLoadings = { ...state.itemLoadings, [data.id]: false };
    } else {
      delete state.data[data.id];
      state.error = null;
      state.currentId = null;
      state.itemLoadings = data.id
        ? { ...state.itemLoadings, [data.id]: null }
        : null;
      state.ids = xor(state.ids, [data.id]);
      state.data = data.id ? state.data : null;
      state.dataFolders = state.dataFolders?.map(
        item => (item.id === folderId && item.items > 0) ? {...item, items: (item.items - 1)} : item);
    }
  },
  
  delFileFailure: (state, { payload: { error, id } }) => {
    state.error = error;
    state.loading = false;
    state.itemLoadings = id
      ? { ...state.itemLoadings, [id]: null }
      : null;
  },

  getByIdFileOrFolder: () => {},
  
  getByIdFileOrFolderSuccess: (state, { payload: { data } }) => {
    state.selectedFolder = data;
  },
  
  getByIdFileOrFolderFailure: (state, { payload: { data } }) => {
    state.error = data;
  },
});

export const { actions, reducer } = documentBoxesSlice;

export default reducer;
