import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import MaterialInputWrapper from './styles';

const MaterialInput = forwardRef(
  ({ placeholder, prefix, suffix, disabled, ...params }, ref) => (
    <MaterialInputWrapper isPrefix={!!prefix} isSuffix={!!suffix}>
      <Input
        ref={ref}
        {...params}
        placeholder={placeholder}
        // autocomplete="new-password"
        disabled={disabled}
      />

      {prefix}
      <span className="suffix">{suffix}</span>
      <label style={{ color: '#05060A', fontSize: '20px' }}>
        {placeholder}
      </label>
      <span className="bar" />
    </MaterialInputWrapper>
  ),
);

MaterialInput.propTypes = {
  placeholder: PropTypes.string,
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  disabled: PropTypes.bool,
};

export default MaterialInput;
