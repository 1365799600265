import React, { useCallback, useMemo } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Popconfirm } from 'antd';
import { checkRole } from 'utils/tools';
import { actions } from 'redux/documentaries/slice';
import { isArray, indexOf } from 'lodash';
import {
  DOCUMENTARY_STATUS_VALUE,
  ACTIVITY_ROLE_VALUE,
} from 'configs/localData';
import { useHistory } from 'react-router';

import CreateButton from 'components/RestActions/CreateButton';
import ExtraButtonsStyles from './styles';
import { useModalContext } from '../ModalContext';

const ExtraActions = ({ idTask, isForward }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { toggleForward, toggleArchive } = useModalContext();

  const roleUser = useSelector((state) => state.auth.role);
  const authId = localStorage.getItem('id');
  const currentData =
    useSelector((state) => state.documentaries.currentData) || {};

  const getActivityId = useCallback(() => {
    if (!isArray(currentData.activities)) return null;
    return currentData.activities.find(
      (activity) =>
        isArray(activity.items) &&
        activity.items.find((item) => item.user?.id === authId),
    )?.id;
  }, [currentData.activities, authId]);

  const isApproved = useCallback(() => {
    let check = false;
    currentData.activities &&
      currentData.activities.every((activity) => {
        if (isArray(activity.items)) {
          const itemOfActivityItems = activity.items.find(
            (item) => item.user?.id === authId,
          );
          if (itemOfActivityItems) {
            if (itemOfActivityItems.completedAt) check = true;
            return false;
          }
          return true;
        }
        return true;
      });
    return check;
  }, [currentData.activities, authId]);

  const isExecute = useMemo(
    () =>
      indexOf(
        [
          DOCUMENTARY_STATUS_VALUE.waitingProcessing,
          DOCUMENTARY_STATUS_VALUE.processing,
          DOCUMENTARY_STATUS_VALUE.needKnow,
        ],
        currentData.status,
      ) !== -1 &&
      currentData.role === ACTIVITY_ROLE_VALUE.execute &&
      checkRole(['Giam_Doc'], roleUser),
    [currentData.status, currentData.role, roleUser],
  );

  const isShowArchiveBtn = useMemo(
    () =>
      currentData.status !== DOCUMENTARY_STATUS_VALUE.archived &&
      currentData.status !== DOCUMENTARY_STATUS_VALUE.deleted &&
      checkRole(['Van_Thu'], roleUser),
    [currentData.status, roleUser],
  );

  const isShowToKnowBtn = useMemo(
    () =>
      indexOf(
        [
          DOCUMENTARY_STATUS_VALUE.archived,
          DOCUMENTARY_STATUS_VALUE.needKnow,
          DOCUMENTARY_STATUS_VALUE.deleted,
        ],
        currentData.status,
      ) === -1 && checkRole(['Van_Thu', 'Giam_Doc'], roleUser),
    [currentData.status, roleUser],
  );

  const checkShowCompleteBtn = useCallback(() => {
    if (
      currentData.role === ACTIVITY_ROLE_VALUE.created ||
      !isArray(currentData.activities)
    )
      return false;

    if (
      currentData.status === DOCUMENTARY_STATUS_VALUE.archived ||
      currentData.status === DOCUMENTARY_STATUS_VALUE.deleted
    )
      return false; // status là Lưu trữ, đã xoá thì ẩn nút hoàn thành
    return !isApproved();
  }, [currentData.role, currentData.activities, currentData.status, isApproved]);

  const isShowCompleteBtn = useMemo(
    () => checkShowCompleteBtn(),
    [checkShowCompleteBtn],
  );

  const handleToKnow = () => {
    dispatch(actions.knowExternal({ id: idTask }));
  };

  const clickCreateFile = () => {
    history.replace('#documentaryFiles/create');
  };

  const handleComplete = () => {
    dispatch(
      actions.completeExternal({
        data: {
          id: idTask,
          activityId: getActivityId(),
        },
      }),
    );
  };

  const onClickArchive = () => {
    toggleArchive();
  };

  const onClickForward = () => {
    toggleForward();
  };

  const isShowSendDocumentarySign = useMemo(
    () =>
      indexOf(
        [ACTIVITY_ROLE_VALUE.execute, ACTIVITY_ROLE_VALUE.directing],
        currentData.role,
      ) !== -1,
    [currentData.role],
  );

  return (
    <ExtraButtonsStyles className="button-actions-extra">
      {isShowCompleteBtn && (
        <Popconfirm
          placement="top"
          title={
            isShowSendDocumentarySign
              ? i18next.t('documentaries.confirmSendDocumentarySign')
              : i18next.t('documentaries.confirmComplete')
          }
          onConfirm={handleComplete}
          okText={i18next.t('button.ok')}
          cancelText={i18next.t('button.cancel')}
        >
          <Button type="primary" className="complete-button">
            {isShowSendDocumentarySign
              ? i18next.t('button.sendDocumentarySign')
              : i18next.t('button.complete')}
          </Button>
        </Popconfirm>
        /* <Button
                type="primary"
                className="complete-button"
                onClick={handleClickCompleteBtn}
              >
                {i18next.t('button.complete')}
              </Button> */
      )}
      {(isExecute || isForward) && (
        <Button
          type="primary"
          className="forward-button"
          onClick={onClickForward}
        >
          {isExecute
            ? i18next.t('button.execute')
            : i18next.t('button.forward')}
        </Button>
      )}
      {isShowArchiveBtn && (
        <Button
          type="primary"
          className="archived-button"
          onClick={onClickArchive}
        >
          {i18next.t('button.archived')}
        </Button>
      )}
      {isShowToKnowBtn && (
        <Popconfirm
          placement="top"
          title={i18next.t('documentaries.confirmToKnowTitle')}
          onConfirm={handleToKnow}
          okText={i18next.t('button.ok')}
          cancelText={i18next.t('button.cancel')}
        >
          <Button type="primary">{i18next.t('button.needKnow')}</Button>
        </Popconfirm>
      )}
      <CreateButton
        header="button.uploadFile"
        resource="documentaryFiles"
        gotoCreatePage={clickCreateFile}
      />
    </ExtraButtonsStyles>
  );
};

ExtraActions.propTypes = {
  idTask: PropTypes.string,
  isForward: PropTypes.bool,
};

export default ExtraActions;
