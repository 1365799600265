import React, { useMemo } from 'react';
import i18next from 'i18next';
import moment from 'moment';
import { Tag, Avatar, Popover, Button } from 'antd';
import PropTypes from 'prop-types';
import { ACTIVITY_ROLES } from 'configs/localData';
import UserInfoPopoverContent from 'components/RestField/UserInfoPopover';
import TitleTreeStyles from './styles';
import { useModalEditNote } from '../ModalNote/context';

const ContentNote = ({ dataNote }) => {
  return <div style={{ maxWidth: 300 }}>{dataNote}</div>;
};

ContentNote.propTypes = {
  dataNote: PropTypes.string,
};

const TitleTree = ({ user = {}, data = {} }) => {
  const isShowEditBtn = useMemo(
    () =>
      data.parentId === localStorage.getItem('id') &&
      moment().diff(moment(data.updatedDateUtc), 'seconds') <=
        (process.env.REACT_APP_EXPIRED_TIME_EDIT_NOTE || 60 * 60),
    [data.parentId, data.updatedDateUtc],
  );
  const { toggleModal, setNoteObj } = useModalEditNote();

  const editNote = () => {
    toggleModal();
    setNoteObj(data);
  };

  return (
    <TitleTreeStyles className="tree-title">
      <div className="user-avatar">
        <Popover
          content={<UserInfoPopoverContent record={user} />}
          placement="right"
          trigger="hover"
        >
          <Avatar src={user.avatarUrl} icon="user" />
        </Popover>
      </div>
      <div className="info-activity">
        <div className="header-tree-title">
          <span className="name-user">{user.name}</span>
          <span className="role-user">
            {`(${i18next.t(
              ACTIVITY_ROLES?.find((role) => role.value === data.role)?.text,
            )})`}
          </span>
        </div>
        <div className="bottom-title-tree">
          {/* <span className="name-user">{data?.role}</span> */}
          {data.completedAt && (
            <div className="completed">
              <div className="time-noti">
                {/* <Icon type="clock-circle" /> */}
                {moment(data.completedAt).format('DD-MM-YYYY [lúc] HH:mm')}
              </div>
              <Tag color="green">{i18next.t('documentaries.completed')}</Tag>
            </div>
          )}
          {data.createdDateUtc && !data.completedAt && (
            <div className="time-noti">
              {/* <Icon type="clock-circle" /> */}
              {moment(data.createdDateUtc).format('DD-MM-YYYY [lúc] HH:mm')}
            </div>
          )}
          {data.isCurrentProcess && (
            <div className="completed">
              <Tag color="blue">
                {i18next.t('documentaries.currentProcess')}
              </Tag>
            </div>
          )}
        </div>
        {data.notes && (
          <Popover
            placement="leftBottom"
            content={<ContentNote dataNote={data.notes} />}
            trigger="hover"
          >
            <div className="note-item">{data.notes}</div>
          </Popover>
        )}
      </div>
      {isShowEditBtn && (
        <Button icon="edit" className="edit-btn" onClick={editNote} />
      )}
    </TitleTreeStyles>
  );
};

TitleTree.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

export default TitleTree;
