import styled from 'styled-components';
import { Layout } from 'antd';

export const ContentPopoverStyles = styled.div`
  ${'' /* height: 300px; */}
  width: 500px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
  margin: -12px -16px;
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  .title-popover {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 800;
    border-bottom: 1px solid ${({ theme }) => theme.border.default};
  }
  .view-all {
    padding: 10px;
    text-align: center;
  }
  .list-noti {
    width: 100%;
    height: auto;
    max-height: 300px;
    overflow-y: auto;
  }
`;

const HeaderWrapper = styled(Layout.Header)`
  border-bottom: 1.5px solid ${({ theme }) => theme.background.container};
  background: ${({ theme }) => theme.background.content};
  .btn {
    margin-right: 10px;
  }
  .notification-section {
    margin-right: 20px;
    .ant-btn {
      padding: 0;
      border: none;
      i {
        font-size: 20px;
      }
    }
  }
  .user-role {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10px;
    justify-content: center;
    & > div {
      line-height: normal;
    }
    .name {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .trigger {
    color: ${({ theme }) => theme.text.primary};
    font-size: 24px;
    transition: 300ms ease all;
  }
  .reverse-trigger {
    transform: rotate(180deg);
  }

  .leftHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 767px) {
      width: 100%;
      display: inherit;
      padding-right: 45px;
    }
    @media only screen and (max-width: 350px) {
      .title {
        font-size: 16px;
      }
    }
  }
  .rightHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  .localeSelect {
    padding: 5px;
    font-weight: bold;
    cursor: pointer;
    color: ${({ theme }) => theme.text.disabled};
    &.actife {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
  &.withoutSidebar {
    .leftHeader {
      .ant-page-header {
        padding: 0px;
      }
    }
  }
  .ant-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default HeaderWrapper;
