import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { actions } from 'redux/documentaries/slice';

// import FilesList from 'components/RestField/FilesList';
import CommentComponent from 'components/common/Comment';
import ModalDueDate from '../ModalDueDate';
import InfoComponent from '../InfoComponent';
import DocumentaryFiles from '../../../DocumentaryFiles/List';

import DocumentaryInfoStyles from './styles';

const DocumentaryInfo = ({ record, idTask }) => {
  const [visible, setVisible] = useState(false);

  return (
    <DocumentaryInfoStyles>
      <div className="container-info">
        <InfoComponent record={record} />
        <div className="title-files">{`${i18next.t('documentaries.files')}: `}</div>
        <DocumentaryFiles />
        {/* <FilesList medias={mediasList(2)} />
        {record?.status > 3 && (
          <FilesList
            medias={mediasList(3)}
            header="documentaries.completedCVFile"
          />
        )} */}
        <CommentComponent
          totalSeen={record?.totalSeen}
          totalReceiver={record?.totalReceiver}
          seenUsers={record?.seenUsers}
          listComments={record?.comments}
          isDeleted={record?.isDeleted}
          submitAction={actions.commentExternal}
          submitActionEdit={actions.editCommentExternal}
          submitActionDel={actions.delCommentExternal}
          idTask={idTask}
          resource="documentaries"
        />
      </div>
      <ModalDueDate
        width={300}
        title={i18next.t('documentaries.changeDueDate')}
        visible={visible}
        data={{ id: record.id, dueDateUtc: record.dueDateUtc }}
        setVisible={value => setVisible(value)}
      />
    </DocumentaryInfoStyles>
  );
};

DocumentaryInfo.propTypes = {
  record: PropTypes.object,
  idTask: PropTypes.string,
};

export default DocumentaryInfo;
