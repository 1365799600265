import React, { useContext, createContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialContextValue = {
  visible: false,
  noteObj: {},
  setVisible: (visible) => visible,
  setNoteObj: (noteObj) => noteObj,
};

const ModalEditNoteContext = createContext(initialContextValue);

export function ModalEditNoteProvider(props) {
  const [visible, setVisible] = useState(false);
  const [noteObj, setNoteObj] = useState({});

  const value = {
    visible,
    noteObj,
    setVisible,
    setNoteObj,
  };

  return (
    <ModalEditNoteContext.Provider value={value}>
      {props.children}
    </ModalEditNoteContext.Provider>
  );
}

export function useModalEditNote() {
  const { visible, noteObj, setVisible, setNoteObj } = useContext(
    ModalEditNoteContext,
  );

  const toggleModal = () => setVisible(!visible);

  return {
    visible,
    noteObj,
    setNoteObj,
    toggleModal,
  };
};

ModalEditNoteProvider.propTypes = {
  children: PropTypes.node,
}
