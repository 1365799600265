import React, { useEffect } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { PRIORITY } from 'configs/localData';
import UserInfo from 'components/RestField/UserInfo';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/internalMessages/slice';
import { checkRole } from 'utils/tools';
import FilesList from 'components/RestField/FilesList';
import ContentText from 'components/common/ContentText';
import CommentComponent from 'components/common/Comment';
import MessageInfoStyles from './styles';

const MessageInfo = ({ record, idTask }) => {
  const profileData = useSelector(state => state.auth.data);
  const rolesUser = useSelector(state => state.auth.role);
  const dispatch = useDispatch();

  useEffect(() => {
    idTask && dispatch(actions.getCommentsInternal(idTask));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      record?.id &&
      profileData.id !== record?.createdUser?.id &&
      !checkRole(['Quan_Tri_Vien'], rolesUser) &&
      !record?.isSeen
    ) {
      dispatch(actions.seenInternal(record.id));
    } else if (record?.id) {
      dispatch(actions.getTrackingInternal(record.id));
    }

    // eslint-disable-next-line
  }, [record?.id]);

  return (
    <MessageInfoStyles>
      <div className="detail-info">
        <div className="header-item-documentary">
          {record?.priority > 0 && (
            <Tag
              className="label-priority"
              color={
                PRIORITY.find(item => item.value === record.priority)?.color
              }
            >
              {i18next.t(
                PRIORITY.find(item => item.value === record.priority)?.text,
              )}
            </Tag>
          )}
          <div className="sender-span">
            <UserInfo
              record={record?.createdUser}
              nameProp="name"
              note={moment(record?.createdAt).format('DD-MM-YYYY HH:mm')}
              isShowPopover
            />
          </div>
        </div>
        <div className="title-documentary">{record?.title}</div>
        <div className="content-documentary">
          <ContentText content={record?.content} />
        </div>
        <FilesList medias={record?.medias} />
      </div>
      <div className="detail-timeline">
        <div className="received-users">
          <div className="title-label">
            {`${i18next.t('internalMessages.receivedUsers')}: `}
          </div>
          {!record?.activities?.length && (
            <div className="empty-data">
              {i18next.t('internalMessages.emptyReceivedUsers')}
            </div>
          )}
          <div className="list-received-user">
            {record?.activities?.length
              ? record.activities[0]?.items?.map((receivedUser, index) =>
                  index === record.activities[0].items.length - 1 ? (
                    <span key={String(index)}>{receivedUser.user?.name}</span>
                  ) : (
                    <span key={String(index)}>
                      {`${receivedUser.user?.name}, `}
                    </span>
                  ),
                )
              : null}
          </div>
        </div>
        <CommentComponent
          totalSeen={record?.totalSeen}
          totalReceiver={record?.totalReceiver}
          seenUsers={record?.seenUsers}
          listComments={record?.comments}
          isDeleted={record?.isDeleted}
          submitAction={actions.commentInternal}
          submitActionEdit={actions.editCommentInternal}
          submitActionDel={actions.delCommentInternal}
          idTask={idTask}
        />
      </div>
    </MessageInfoStyles>
  );
};

MessageInfo.propTypes = {
  // form: PropTypes.object,
  record: PropTypes.object,
  idTask: PropTypes.string,
};
export default MessageInfo;
