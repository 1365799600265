import React from 'react';
import moment from 'moment';
import { checkRole } from 'utils/tools';
import { useSelector } from 'react-redux';
import List from '../../rest/List';
// import RestFieldItem from '../../../components/RestField/RestFieldItem';
// import ActionGroup from '../../../components/RestActions/ActionGroup';
// import EditButton from '../../../components/RestActions/EditButton';
// import DeleteButton from '../../../components/RestActions/DeleteButton';
import AnnouncementsListCustom from '../components/AnnouncementsList';
import Filter from '../components/Filter';
import ListStyles from './styles';

const AnnouncementsList = (props) => {
  const permissionData = useSelector((state) => state.auth.permissionData);

  return (
    <ListStyles>
      <List
        {...props}
        resource="announcements"
        customLayout={<AnnouncementsListCustom {...props} />}
        // noCardWrapper
        // getFromUrl={false}
        // isUpdateRoute={false}
        initialFilter={{ sortBy: 'createdAt', sortDesc: true }}
        hasExport={false}
        hasSearch={false}
        hasCreate={checkRole(['AllowAll', 'AnoucementAddNew'], permissionData)}
        createHeader="button.createAnnouncement"
        filter={(
          <Filter
            format={({ startDate, endDate, ...data }) => ({
              ...data,
              startDate: startDate ? moment(startDate).startOf('days').utc().toISOString() : startDate,
              endDate: endDate ? moment(endDate).endOf('days').utc().toISOString() : endDate,
            })}
          />
        )}
      />
      {/* <RestFieldItem source="priority" header="announcements.priority" />
        <RestFieldItem source="message" header="announcements.title" />
        <RestFieldItem source="description" header="announcements.content" />
        <RestFieldItem source="createdAt" header="announcements.createdAt" />
        <RestFieldItem source="sender" header="announcements.sender" />
        <RestFieldItem source="totalView" header="announcements.totalView" />
        <ActionGroup>
          <EditButton />
          <DeleteButton />
        </ActionGroup> */}
      {/* </List> */}
    </ListStyles>
  );
};

AnnouncementsList.propTypes = {};

export default AnnouncementsList;
