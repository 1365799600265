import React from 'react';
import Create from '../../rest/Create';
import Form from '../components/Form';

const AnnouncementsCreate = (props) => (
  <Create
    {...props}
    resource="announcements"
    formatOnSubmit={({ medias, title, content, ...data }) => ({
      ...data,
      title: title && title.trim(),
      content: content && content.trim(),
      mediaIds: medias,
    })}
  >
    <Form />
  </Create>
);

AnnouncementsCreate.propTypes = {};

export default AnnouncementsCreate;
