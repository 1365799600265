import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { Form, Icon, Button } from 'antd';
import i18n from 'i18next';
import { login } from 'redux/auth/slice';
// import { validateRegex } from 'utils/validateUtils';
import { checkRole } from 'utils/tools';
import MaterialInput from '../../components/common/MaterialInput';
import logo from '../../assets/images/Logo_DNTrain.svg';
import LoginStyles from './styles';

const FormItem = Form.Item;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowPassword: false,
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.login({ ...values, grant_type: 'password' });
      }
    });
  };

  showPassword = key => () => {
    const currentData = this.state[key];
    this.setState({ [key]: !currentData });
  };

  render() {
    const { form, isAuthenticated, loading, roles } = this.props;
    if (isAuthenticated) {
      const url = localStorage.getItem('urlExpiredToken');
      if(url) return <Redirect to={url} />
      if(checkRole(['Quan_Tri_Vien'], roles)) return <Redirect to="/users" />;
      return <Redirect to="/documentaries" />;
    }
    const { getFieldDecorator } = form;
    return (
      <LoginStyles>
        <div style={{ textAlign: 'center' }}>
          <img alt="" src={logo} className="logo" />
          <div className="title">
            <div className="maintitle">{i18n.t('login.title')}</div>
            {/* <div className="mintitle">{i18n.t('login.botTitle')}</div> */}
          </div>
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            <FormItem>
              {getFieldDecorator('username', {
                rules: [
                  {
                    required: true,
                    message: i18n.t('input.usernameOrEmail.validateMsg.required'),
                  },
                  /* {
                    pattern: validateRegex.username,
                    message: i18n.t('input.username.validateMsg.pattern'),
                  }, */
                ],
              })(
                <MaterialInput
                  placeholder={i18n.t('input.usernameOrEmail.placeholder')}
                  prefix={<Icon type="user" />}
                />,
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: i18n.t('input.password.validateMsg.required'),
                  },
                  {
                    min: 3,
                    message: i18n.t('input.password.validateMsg.minLength', {
                      min: 3,
                    }),
                  },
                  {
                    max: 255,
                    message: i18n.t('input.password.validateMsg.maxLength', {
                      max: 255,
                    }),
                  },
                ],
              })(
                <MaterialInput
                  type={this.state.isShowPassword ? undefined : 'password'}
                  placeholder={i18n.t('input.password.placeholder')}
                  prefix={<Icon type="lock" />}
                  suffix=<Icon
                    type={this.state.isShowPassword ? 'eye-invisible' : 'eye'}
                    onClick={this.showPassword('isShowPassword')}
                  />
                />,
              )}
            </FormItem>
            <div className="action-div">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                {i18n.t('login.loginBtn')}
              </Button>
              <div
                style={{
                  marginTop: 20,
                  fontSize: '20px',
                  // width: '180px',
                  height: '23px',
                  // textAlign: 'left',
                }}
              >
                <Link to="/forgot-password" href="/forgot-password">
                  {i18n.t('forgotPassword.title')}
                </Link>
              </div>
            </div>
          </Form>
        </div>
      </LoginStyles>
    );
  }
}

Login.propTypes = {
  form: PropTypes.object,
  login: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
  roles: PropTypes.array,
};

export default withRouter(
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
      roles: state.auth.role,
      loading: state.auth.loading,
    }),
    dispatch => ({
      login: params => {
        dispatch(login(params));
      },
    }),
  )(Form.create()(Login)),
);
