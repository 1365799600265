import styled from 'styled-components';

const DocumentaryDetailStyles = styled.div`
  .row-info {
    background: ${({theme}) => theme.background.container};
    display: flex;
    align-items: stretch;
    margin: -20px!important;
    display: flex;
    flex-wrap: wrap;
    & > .ant-col {
      margin-bottom: 24px;
    }
    & > .ant-col > div {
      background: #fff;
      padding: 15px;
      height: 100%;
    }
    .col-detail {
      padding-left: 0!important;
    }
    .col-flow-document {
      padding-right: 0!important;
    }
  }
  .isoBoxWrapper {
    padding: 0;
  }

  @media only screen and (max-width: 1199px) {
    .col-detail, .col-flow-document {
      padding: 0!important;
    }
  }
`;

export default DocumentaryDetailStyles;
