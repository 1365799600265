import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import { handleViewFile, downloadFileByURL, checkRole } from 'utils/tools';
// import { actions } from 'redux/documentBoxes/slice';

import UserInfo from 'components/RestField/UserInfo';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import CustomButton from 'components/RestActions/CustomButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import ModalViewFile from 'components/RestField/ModalViewFile';
import List from '../../../rest/List';
import ListFolderFileStyles from './styles';

const ListFolderFile = (props) => {
  const { folderId, isResultSearchScreen, location } = props;

  // const isRootScreen = !folderId && !isResultSearchScreen;

  const [visibleModalViewFile, setVisibleModalViewFile] = useState(false);

  const [urlViewObj, setUrlViewObj] = useState({ name: '', url: '' });
  const currentUser = useSelector((state) => state.auth.data);
  const roles = useSelector((state) => state.auth.role);

  const getInitialFilter = () => {
    if (isResultSearchScreen) return {};
    if (folderId) return { parentId: folderId };
    return { parentId: 'root' };
  };

  const checkShowActionFile = (record) => {
    if (!record?.media) return false;
    return (
      record?.createdUser?.id === currentUser?.id ||
      checkRole(['Quan_Tri_Vien'], roles)
    );
  };

  const isViewDownloadFile = (record) => record?.media;

  const checkShowActionFolder = (record) => {
    if (record?.media) return false;
    return (
      record?.createdUser?.id === currentUser?.id ||
      checkRole(['Quan_Tri_Vien'], roles)
    );
  };

  const onCancelViewFile = () => {
    setVisibleModalViewFile(false);
  };

  const handleClickViewFile = (record) => {
    record.media &&
      handleViewFile(record.media, (data = {}) => {
        data.data && setUrlViewObj(data.data);
        data.visible && setVisibleModalViewFile(data.visible);
      });
  };

  const handleDownloadFile = (record) => {
    record?.media && downloadFileByURL(record.media);
  };

  return (
    <ListFolderFileStyles>
      <List
        {...props}
        resource="documentBoxes"
        defaultOptions={{
          customApiResource: 'files',
          isDeleted: true,
        }}
        // hasSearch={false}
        customInitialFilterSearch={{ parentId: undefined }} // khi đang ở page documentBoxes/folderId -> search -> k truyền parentId lên
        initialFilter={getInitialFilter()}
        hasExport={false}
        hasCreate={false}
        noCardWrapper
        // isDynamicApiResource
        widthTable="1000px"
      >
        <RestFieldItem
          source="name"
          header="boxFiles.documentName"
          format={(data, record) => {
            if (record?.media)
              return (
                <div
                  className="item-name name-file"
                  onClick={() => handleClickViewFile(record)}
                  role="presentation"
                >
                  <Icon type="paper-clip" />
                  <span className="item-title">{data}</span>
                </div>
              );
            return (
              <Link
                to={`/documentBoxes/${record?.id}`}
                className="folder-link item-name"
              >
                <Icon type="folder" theme="filled" />
                <span className="folder-title item-title">{data}</span>
                {record.items ? (
                  <span className="count-number">{`(${record.items})`}</span>
                ) : null}
              </Link>
            );
          }}
        />
        {/* <RestFieldItem
          source="items"
          header="boxFiles.countItems"
          width={100}
        /> */}
        <RestFieldItem
          source="createdUser"
          header="boxFiles.createdUser"
          format={(data, record) =>
            data ? (
              <UserInfo
                record={record.createdUser}
                nameProp="name"
                isShowPopover
              />
            ) : null}
          width={250}
        />
        <RestFieldItem
          source="createdAt"
          header="boxFiles.createdAt"
          format={(data) => (
            <span>{data && moment(data).format('DD-MM-YYYY HH:mm')}</span>
          )}
          width={150}
        />
        <ActionGroup widthAction={140}>
          {/* <CustomButton
            handleSubmit={handleClickViewFile}
            checkShowAction={isViewDownloadFile}
          /> */}
          <CustomButton
            icon="download"
            title="button.downloadFile"
            checkShowAction={isViewDownloadFile}
            handleSubmit={handleDownloadFile}
          />
          <EditButton
            roles={['FileFolderEdit']}
            checkShowAction={checkShowActionFile}
            gotoEditPage={(idFile) =>
              props.history.replace(
                `${location.pathname}${location.search}#boxFiles/${idFile}/edit`,
              )}
          />
          <DeleteButton
            roles={['FileFolderRemove']}
            checkShowAction={checkShowActionFile}
            customResource="boxFiles"
            customMessageItemName="boxFiles.customMessageItemName"
          />
          <EditButton
            roles={['FolderEdit']}
            checkShowAction={checkShowActionFolder}
          />
          <DeleteButton
            roles={['FolderRemove']}
            checkShowAction={checkShowActionFolder}
            resource="boxFolders"
            customMessageItemName="documentBoxes.customMessageItemName"
          />
        </ActionGroup>
      </List>
      {visibleModalViewFile && (
        <ModalViewFile
          visible
          onCancel={onCancelViewFile}
          urlViewObj={urlViewObj}
        />
      )}
    </ListFolderFileStyles>
  );
};

ListFolderFile.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  folderId: PropTypes.string,
  isResultSearchScreen: PropTypes.bool,
  location: PropTypes.object,
};

export default ListFolderFile;
