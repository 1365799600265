import styled from 'styled-components';

export const PopoverStyles = styled.div`
  width: 320px;

  .avatar-user-popover {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    .ant-avatar {
      height: 80px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 30px;
      }
    }
  }
  .content-info-user {
    .ant-row .ant-col {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    .title-label {
      font-weight: 600;
    }
    .value-item-info {
      word-break: break-word;
      text-align: right;
      max-width: 70%;
    }
  }
  @media only screen and (max-width: 576px) {
    max-width: 70vw;
  }
`;
