import React from 'react';
import Edit from '../../rest/Edit';
import Form from '../components/Form';

const DocumentBoxesEdit = props => {
  return (
    <Edit
      {...props}
      resource="documentBoxes"
      // customGetById={customGetById}
      defaultOptions={{ customApiResource: 'files'}}
      formatOnSubmit={({name, ...data}) => ({
        ...data,
        title: name && name.trim(),
      })}
    >
      <Form isEdit />
    </Edit>
  );
};

DocumentBoxesEdit.propTypes = {};

export default DocumentBoxesEdit;
