import { get, post, put, patch, del } from './utils';

export async function getAllFoldersApi(data) {
  return get('/documentboxs', data);
}

export async function createFolderApi(data) {
  return post('/documentboxs', data);
}

export async function editFolderApi(id, data) {
  return put(`/documentboxs/${id}`, data);
}

export async function delFolderApi(id, data) {
  return del(`/documentboxs/${id}`, data);
}

export async function createFileApi(id, data) {
  return patch(`/documentboxs/${id}/file`, data);
}

export async function editFileApi(id, idFile, data) {
  return put(`/documentboxs/${id}/file/${idFile}`, data);
}

export async function delFileApi(id, idFile, data) {
  return del(`/documentboxs/${id}/file/${idFile}`, data);
}

export async function getByIdFileApi(id, idFile) {
  return get(`/documentboxs/${id}/file/${idFile}`);
}

export async function getByIdFileOrFolderApi(id) {
  return get(`/files/${id}`);
}