import styled from 'styled-components';

const DocumentaryInfoStyles = styled.div`

  ${'' /* @media only screen and (min-width: 1500px) {
    .list-file-item > .ant-col {
      width: 33.33%;
    }
  }

  @media only screen and (max-width: 1500px) and (min-width: 1200px) {
    .list-file-item > .ant-col {
      width: 50%;
    }
  } */}
  .title-files {
    font-weight: 700;
  }

  .pagination-bottom-row .ant-pagination{
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 1200px) {
    .detail-comment {
    max-height: 300px;
    overflow-y: auto;
  }
  }
`;

export default DocumentaryInfoStyles;