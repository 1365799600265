import styled from 'styled-components';

const ListStyles = styled.div`
  ${'' /* @media only screen and (min-width: 1200px) {
    .row-filter-internal > .ant-col {
      width: 20%;
    }
  } */}
  .active-row-item {
    background: ${({theme}) => theme.background.notSeenTask}
  }
  .title-task {
    display: flex;
    align-items: center;
    .list-tags {
      display: inline-flex;
    }
    @media only screen and (max-width: 576px) {
      flex-direction: column;
      align-items: start;
    }
  }
  .title-name {
    font-weight: 600;
    ${'' /* font-size: 15px; */}
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    color: ${({theme}) => theme.text.primary};
    &:hover, &:active {
    color: ${({theme}) => theme.palette.primary} !important;
    }
    @media only screen and (max-width: 576px) {
      ${'' /* margin-top: 5px; */}
      -webkit-line-clamp: 2;
      word-break: break-word;
    }
  }
  @media only screen and (max-width: 1199px) and (min-width: 993px) {
    .row-filter-container {
      display: flex;
      flex-direction: column;
      .col-filter-content {
        width: 100%;
      }
      .col-actions {
        width: 33.33%;
      }
    }
  }
`;

export default ListStyles;