import React from 'react';
import i18next from 'i18next';
import UserInfo from 'components/RestField/UserInfo';
import { Tag } from 'antd';
import { isEmpty } from 'lodash';
import { ACTIVE_TYPES } from 'configs/localData';
import { useSelector } from 'react-redux';
import { checkRole } from 'utils/tools';
import List from '../../rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
// import DeleteButton from '../../../components/RestActions/DeleteButton';
import Filter from '../components/Filter';
import ListStyles from './styles';

const UsersList = props => {
  const permissionData = useSelector(state => state.auth.permissionData);

  return (
    <ListStyles>
      <List
        {...props}
        resource="users"
        initialFilter={{ sortBy: 'createdAt', isSortByDesc: true }}
        hasExport={false}
        // placeholderSearch={i18next.t('users.placeholderSearch')}
        hasSearch={false}
        filter={<Filter />}
        redirects={{ edit: 'screen', create: 'modal' }}
        hasCreate={checkRole(['AllowAll', 'UserAddNew'], permissionData)}
      >
        <RestFieldItem
          // sorter
          source="name"
          header="users.name"
          format={(data, record) => (
            <UserInfo record={record} nameProp="name" />
          )}
        />
        <RestFieldItem source="userName" header="users.userName" />
        <RestFieldItem source="email" header="users.email" />
        <RestFieldItem source="department.name" header="users.department" />
        <RestFieldItem
          source="positions"
          header="users.positions"
          format={data =>
            data &&
            !isEmpty(data) &&
            data.map(item => <Tag key={item.id}>{item.name}</Tag>)}
        />
        <RestFieldItem
          source="isActive"
          header="users.isActive"
          format={data => (
            <Tag
              color={
                ACTIVE_TYPES.find(status => status.isActive === data)?.color
              }
            >
              {i18next.t(
                ACTIVE_TYPES.find(status => status.isActive === data)?.text,
              )}
            </Tag>
          )}
        />
        <ActionGroup widthAction={80}>
          <EditButton />
          {/* <DeleteButton /> */}
        </ActionGroup>
      </List>
    </ListStyles>
  );
};

UsersList.propTypes = {};

export default UsersList;
