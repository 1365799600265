/* eslint-disable */
import { omitBy, reduce, isEmpty, keyBy, get, intersection, uniq, isArray } from 'lodash';
import { notification } from 'antd';
import i18next from 'i18next';
import { uploadMediaImgur } from 'api/uploadMedia';
import { fetch } from 'whatwg-fetch';
import { IMAGE_TYPES } from 'configs/localData';
import jwtDecode from 'jwt-decode';
import RestAutoComplete from 'components/RestInput/RestAutoComplete';
import { getPrivateUrlApi } from 'api/uploadMedia';
import { async } from 'q';

export function decryptingToken(token) {
  const decodeToken = jwtDecode(token);
  const permissions = decodeToken.Permissions.replace(/(\[|\]|")/g, '').split(',');
    // .replace(/\]/g, '')
    // .replace(/\"/g, '')
    // .split(',');
  return { permissions: uniq(permissions), role: !isArray(decodeToken.role) ? [decodeToken.role] :  decodeToken.role};
}

export const handleViewFile = async (record, callback = () => null) => {
  // e.preventDefault();
  try {
    const response = await getPrivateUrlApi({
      id: record.id,
      fileName: record.fileName,
      type: record.type,
      extentionFile: record.extentionFile,
    });

    if (response) {
      // console.log(response, 'response');
      if (
        record.fileName &&
        /\.(xlsx|xls|doc|docx|ppt|pptx|pdf)$/i.test(record.fileName)
      ) {
        const data = {
          name: record.fileName,
          url: `//view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            response,
          )}`,
        };
        if (record.fileName && /\.(pdf)$/i.test(record.fileName)) {
          // data.url = response;
          // data.url = `//mozilla.github.io/pdf.js/es5/web/viewer.html?file=https://cors-anywhere.herokuapp.com/${encodeURIComponent(response)}`;
          data.url = `${
            process.env.REACT_APP_PDF_VIEWER_HOST
          }?file=${encodeURIComponent(response)}`;
          data.isPDF = true;
        }

        callback({ data, visible: true });
      } else if (isImageFile(record.fileName)) {
        callback({
          data: { name: record.fileName, url: response, isImage: true },
          visible: true,
        });
      } else {
        // window.location.replace(response);
        window.location.assign(response);
        // dùng assign để khi mở 1 page mới => bấm back => về đúng trang trc đó
        // còn dùng replace: khi mở 1 page mới => bấm back => về trang trc trc đó nữa
      }
    } else {
      notification.error({
        message: i18next.t('error.title'),
        description: i18next.t('error.description'),
        position: 'tr',
        duration: 2,
        // autoDismiss: 15,
      });
    }
  } catch (error) {
    notification.error({
      message: i18next.t('error.title'),
      description: i18next.t('error.description'),
      position: 'tr',
      duration: 2,
      // autoDismiss: 15,
    });
  }
};

export const downloadFileByURL = async (file) => {
  try {
    // console.log(file, 'file');
    const response = await getPrivateUrlApi({
      id: file.id,
      fileName: file?.fileName,
      type: file?.type,
      extentionFile: file?.extentionFile,
    });

    if (response) {
      fetch(response, {
        headers: {
          // 'Access-Control-Allow-Origin':'*',
          // Origin: '*',
        },
      })
        .then((res) => {
          if (res.status === 200) return res.blob();
          notification.error({
            message: i18next.t('error.title'),
            description: i18next.t('error.errorFile403'),
            duration: 2,
            position: 'tr',
          });
          return false;
        }) // Gets the response and returns it as a blob
        .then((blob) => {
          if (!blob) return false;
          // Here's where you get access to the blob
          // And you can use it for whatever you want
          // Like calling ref().put(blob)

          // Here, I use it to make an file appear on the page
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = file.fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        });
    }
    else {
      notification.error({
        message: i18next.t('error.title'),
        description: i18next.t('error.description'),
        position: 'tr',
        duration: 2,
        // autoDismiss: 15,
      });
    }
  } catch (error) {
    notification.error({
      message: i18next.t('error.title'),
      description: i18next.t('error.description'),
      position: 'tr',
      duration: 2,
      // autoDismiss: 15,
    });
  }
};

export const isImageFile = fileName => {
  const extension = getExtensionFile(fileName);
  // console.log(extension, 'extension');
  if (!extension) return false;
  return IMAGE_TYPES.includes(extension.toLocaleLowerCase());
};

export const getExtensionFile = fileName => {
  if (typeof fileName !== 'string' || fileName.indexOf('.') === -1) return '';
  return fileName.split('.').pop();
};

export const getFileName = fileName => {
  if (typeof fileName !== 'string') return '';
  const index = fileName.lastIndexOf('.');
  if(index === -1) return '';
  return fileName.slice(0, index < 500 ? index: 499);
};

export const checkRole = (elementRoles, userRole) => {
  // userRole?.forEach(
  //   item => {

  //   }
  // )
  // return !elementRoles || (userRole && elementRoles.indexOf(userRole) > -1);
  return !elementRoles || intersection(elementRoles, userRole).length > 0;
};

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const sortByProps = (list, props) => {
  if (!list) return [];
  const newList = list.sort((a, b) => {
    if (!a || !b) return -1;
    if (this.change_alias(a[props]) < this.change_alias(b[props])) {
      return -1;
    }
    if (this.change_alias(a[props]) > this.change_alias(b[props])) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  return newList;
};

export const upperCaseFirstChart = str =>
  str[0].toUpperCase() + str.substring(1);

export const changeAlias = alias => {
  let str = alias;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ {2}|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ {2}|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'| |\"|\&|\#|\[|\]|~|$|_/g,
    '-',
  );
  str = str.replace(/-+-/g, '-');
  str = str.replace(/^\-+|\-+$/g, '');
  return str;
};

export const validateName = name => {
  const re = /^[^0-9 *&^$#@!(){}\[\]\\//]+[^0-9*&^$#@!(){}\[\]\\//]+$/;
  return re.test(name);
};

export const getResourceTitle = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const formatFormData = (originalData, data) => {
  const newData = {};
  Object.keys(data).forEach(key => {
    newData[key] = formatData(data[key], typeof originalData[key]);
  });
  return newData;
};

export const formatData = (data, type) => {
  switch (type) {
    case 'number':
      return Number(data);
    default:
      return data;
  }
};

export const getMatchFromPath = string => {
  const re = '(\\/)((?:[a-z][a-z0-9_]*))(\\/)((?:[a-z][a-z0-9_]*))';
  const p = new RegExp(re, ['i']);
  const m = p.exec(string);
  return m && m.length > 0 ? m[0] : string;
};

export const getSearch = ({ sortBy, isSortByDesc, ...filter }) => {
  const params = {
    pageSize: filter.pageSize,
    pageNumber: filter.pageNumber,
    query: filter.query,
    ...(isSortByDesc !== undefined &&
      isSortByDesc !== null && { sortBy, isSortByDesc }),
    ...getValidData(filter.filter),
  };

  return convertObjToSearchStr(params);
};

export const convertObjToSearchStr = params => {
  return Object.keys(params)
    .map(key => {
      return params[key] !== undefined && params[key] !== null
      ? `${encodeURIComponent(key)}=${encodeURIComponent(
          JSON.stringify(params[key]),
        )}`
      : ''
    }
      
    )
    .filter(data => data !== '')
    .join('&');
};

const getValidDataOfObj = (obj, isFilter) => {
  const validData = reduce(
    obj,
    (result, value, key) => {
      if (Array.isArray(value)) {
        return value.length > 0 ? { ...result, [key]: value } : result;
      }
      if (typeof value === 'object' && !isEmpty(value)) {
        const formatChildValue = getValidDataOfObj(value);
        return !isEmpty(formatChildValue)
          ? { ...result, [key]: formatChildValue }
          : result;
      }

      if (value || value === false || value === 0) {
        // eslint-disable-next-line
        if (typeof value === 'string') value = value.trim();
        if (typeof value === 'string' && value.length === 0) {
          return result;
        }
        result[key] = value;
        return { ...result, [key]: value };
      }
      if (value === '' && !isFilter) {
        // eslint-disable-next-line
        result[key] = ' ';
      }
      return result;
    },
    {},
  );
  return validData;
};

export const getValidData = (filter, isFilter) =>
  getValidDataOfObj(filter, isFilter);

export const getFilterFromUrl = searchStr => {
  
  const parsed = {};
  if (!searchStr || searchStr.trim() === '') return {};

  const urlParams = new URLSearchParams(searchStr);

  const entries = urlParams.entries();

  for(const entry of entries) {
    try {
      parsed[entry[0]] = JSON.parse(entry[1])
    } catch (error) {
      parsed[entry[0]] = entry[1];
    }
  }
  // console.log("%.2f hghjghjghj ---0-0gmbmn %s", 3.72782676, 2)

  // decodeURIComponent(searchStr)
  //   .trim()
  //   .substring(1)
  //   .split('&')
  //   .forEach(text => {
  //     const keyValue = text.split('=');
  //     parsed[keyValue[0]] = keyValue[1];
  //     try {
  //       parsed[keyValue[0]] = JSON.parse(parsed[keyValue[0]]);
  //     } catch (error) {
  //       parsed[keyValue[0]] = parsed[keyValue[0]];
  //     }
  //   });
  // console.log(parsed.medias, 'parsed');
  const filter = {
    // q: parsed.q,
    sortBy: parsed.sortBy,
    isSortByDesc: parsed.isSortByDesc,
    ...(parsed.pageSize && { pageSize: parsed.pageSize }),
    ...(parsed.pageNumber && { pageNumber: parsed.pageNumber }),
  };
  delete parsed.pageSize;
  delete parsed.pageNumber;
  delete parsed.sortBy;
  delete parsed.isSortByDesc;
  // delete parsed.q;
  filter.filter = parsed;
  return filter;
};

export const getRecordData = (record, source) => {
  // const arrKeys = source ? replaceAll(replaceAll(source, '\\[', '.'), '\\]', '').split('.') : [];
  // let data = record;
  // arrKeys.forEach(key => {
  //   data = data ? data[key] : data;
  // });
  return get(record, source);
};

export const convertDataToObj = (formatOnSubmit, record) => {
  const newRecord = {};
  Object.keys(record).forEach(key => {
    newRecord[key] = formatOnSubmit[key]
      ? { ...record[key], ...formatOnSubmit[key](record[key]) }
      : record[key];
  });
  // const arrKeys = source.split('.');
  // let data = record;
  // arrKeys.forEach((key, index) => {
  //   if (index === arrKeys.index - 1) {
  //     data[key] = value;
  //   } else {
  //     data = data[key];
  //   }
  // });
  return newRecord;
};

export const replaceAll = function(str, search, replacement) {
  return str.replace(new RegExp(search, 'g'), replacement);
};

export const formattedRESTData = data => ({
  data: keyBy(data, 'id'),
  ids: data.map(item => item.id),
});

export const getFolderIdByUrl = (props, resource) => {
  const idFromPath =
  props.location.pathname.match(`${resource}/(.*)/edit`) ||
  props.location.pathname.match(`${resource}/(.*)/show`);
  const idFromHash =
  props.location.hash.match(`#${resource}/(.*)/edit`) ||
  props.location.hash.match(`#${resource}/(.*)/show`);

  return (idFromPath && idFromPath?.[1]?.split("/")) || (idFromHash && idFromHash?.[1]?.split("/"));
};

export const getIdByLocation = (location, resource) => {
  const idFromPath =
    location.pathname.match(`${resource}/(.*)/edit`) ||
    location.pathname.match(`${resource}/(.*)/show`) ||
    location.pathname.match(`${resource}/(.*)`);
  const idFromHash =
    location.hash.match(`#${resource}/(.*)/edit`) ||
    location.hash.match(`#${resource}/(.*)/show`);
  return (idFromPath && idFromPath[1]) || (idFromHash && idFromHash[1]);
};

export const getIdByUrl = (props, isGetHash) => {
  
  const idFromPath =
    props.location.pathname.match(`${props.customResource || props.resource}/(.*)/edit`) ||
    props.location.pathname.match(`${props.customResource || props.resource}/(.*)/show`);

  const idFromHash =
    props.location.hash.match(`#${props.customResource || props.resource}/(.*)/edit`) ||
    props.location.hash.match(`#${props.customResource || props.resource}/(.*)/show`);

  if(props.isGetHash) return idFromHash && idFromHash[1];

  return (idFromPath && idFromPath[1]) || (idFromHash && idFromHash[1]);
};

export const getPrefixPath = (props, action) =>
  `${
    props.redirects[action] === 'modal'
      ? `${props.location.pathname}${props.location.search}#`
      : props.rootPath
  }/${props.resource}`;

export const onSearch = (data, keySearch) => {
  return (
    data &&
    changeAlias(data.toLowerCase()).search(
      changeAlias(keySearch.toLowerCase()).toLowerCase(),
    ) !== -1
  );
};

export const formattedData = list => ({
  data: keyBy(list, 'id'),
  ids: list.map(data => data.id),
});

export const makeBreadCrumbFromPath = location => {
  const BREADCRUMB_LIST = [];
  const paths = location.pathname.split('/');
  paths.forEach(data => {
    if (data === '') return;
    BREADCRUMB_LIST.push({
      title: data,
      path: `${
        BREADCRUMB_LIST.length
          ? BREADCRUMB_LIST[BREADCRUMB_LIST.length - 1].path
          : ''
      }/${data}`,
    });
  });
  return BREADCRUMB_LIST;
};

export const reorderOffset = (
  boards,
  prevOrder,
  { sourceId, destinationId, sourceIndex, destinationIndex },
) => {
  const newBoards = { ...boards };
  if (sourceId === destinationId) {
    newBoards[sourceId] = reorder(
      boards[sourceId],
      sourceIndex,
      destinationIndex,
    );
  } else {
    const moveResults = move(
      boards,
      sourceId,
      destinationId,
      sourceIndex,
      destinationIndex,
    );
    newBoards[sourceId] = moveResults[sourceId];
    newBoards[destinationId] = moveResults[destinationId];
  }
  return { boards: newBoards, prevOrder: {} };
};

const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (
  boards,
  sourceId,
  destinationId,
  sourceIndex,
  destinationIndex,
) => {
  const sourceClone = Array.from(boards[sourceId]);
  const destClone = Array.from(boards[destinationId]);
  const [removed] = sourceClone.splice(sourceIndex, 1);

  destClone.splice(destinationIndex, 0, removed);

  const result = {};
  result[sourceId] = sourceClone;
  result[destinationId] = destClone;

  return result;
};

export const getAllIconName = async () => {
  const iconsFile = await fetch('/styles.css');
  const fileData = await iconsFile.text();
  const icons = fileData
    .match(/.icon-ic-(.*):before/gm)
    .map(e => e.match('.(.*):before')[1]);
  return icons;
};

export const imageUploadHandler = async (blobInfo, success, failure) => {
  var xhr, formData;

  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  // xhr.open('POST', 'postAcceptor.php');

  formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());

  const response = await uploadMediaImgur(blobInfo.blob());
  if (!response.data || response.data.error) {
    notification.error({
      message: I18n.t('error.title'),
      description: I18n.t('error.uploadSize'),
    });
    failure();
  } else {
    success(response.data.link);
  }
};
