import { apiWrapper } from 'utils/reduxUtils';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  changeDueDateExternalApi,
  trackingExternalApi,
  getTrackingExternalApi,
  addActivityExternalApi,
  completeExternalApi,
  archiveExternalApi,
  commentExternalApi,
  editCommentExternalApi,
  delCommentExternalApi,
  getCommentsExternalApi,
  knowExternalApi,
  editNoteItemActivityApi,
} from 'api/tasks';
import { actions as actionsNotify } from 'redux/notifications/slice';
import { makeCRUDSaga } from '../crudCreator/saga';
import { RESOURCE, actions } from './slice';

function* changeDueDateExternalSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      changeDueDateExternalApi,
      payload.id,
      { dueDateUtc: payload.dueDateUtc },
    );
    if (response) {
      yield put(actions.changeDueDateSuccess(payload));
    }
  } catch (error) {
    yield put(actions.changeDueDateFailure(error));
  }
}

function* seenExternalSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      trackingExternalApi,
      payload,
    );
    if (response) {
      yield put(actions.seenExternalSuccess(payload));
      yield put(actions.getTrackingExternal(payload));
      yield put(actionsNotify.recountNotification({ type: 'external' }));
    }
  } catch (error) {
    yield put(actions.seenExternalFailure(error));
  }
}

function* getTrackingExternalSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      getTrackingExternalApi,
      payload,
    );
    if (response) {
      yield put(
        actions.getTrackingSuccess({ id: payload, tracking: response }),
      );
    }
  } catch (error) {
    yield put(actions.getTrackingFailure(error));
  }
}

function* addActivityExternalSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: true },
      addActivityExternalApi,
      payload.id,
      payload,
    );
    if (response) {
      yield put(actions.addActivitySuccess(response));
    }
  } catch (error) {
    yield put(actions.addActivityFailure(error));
  }
}

function* completeExternalSaga({ payload: { data, options = {} } }) {
  try {
    const { id, activityId } = data;
    // console.log(data, 'data');
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: true },
      completeExternalApi,
      id,
      activityId,
      // restData,
    );
    if (response) {
      yield put(actions.completeExternalSuccess(response));
      if (options.successAction) {
        yield put(options.successAction);
      }
    }
  } catch (error) {
    yield put(actions.completeExternalFailure(error));
  }
}

function* archiveExternalSaga({ payload: { data, options = {} } }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: true },
      archiveExternalApi,
      data.id,
      data,
    );
    if (response) {
      yield put(actions.archiveExternalSuccess(response));
      if (options.successAction) {
        yield put(options.successAction);
      }
    }
  } catch (error) {
    yield put(actions.archiveExternalFailure(error));
  }
}

function* commentExternalSaga({ payload }) {
  try {
    const { id, ...data } = payload;
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      commentExternalApi,
      id,
      data,
    );
    if (response) {
      yield put(actions.commentSuccess(response));
    }
  } catch (error) {
    yield put(actions.commentFailure(error));
  }
}

function* editCommentExternalSaga({ payload }) {
  try {
    const { id, idComment, ...data } = payload;
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      editCommentExternalApi,
      id,
      idComment,
      data,
    );
    if (response) {
      yield put(actions.editCommentSuccess(response));
    }
  } catch (error) {
    yield put(actions.editCommentFailure(error));
  }
}

function* delCommentExternalSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      delCommentExternalApi,
      payload.id,
      payload.idComment,
    );
    if (response) {
      yield put(actions.delCommentSuccess(payload));
    }
  } catch (error) {
    yield put(actions.delCommentFailure(error));
  }
}

function* getCommentsExternalSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      getCommentsExternalApi,
      payload,
    );
    if (response) {
      yield put(actions.getCommentsSuccess(response));
    }
  } catch (error) {
    yield put(actions.getCommentsFailure(error));
  }
}

function* knowExternalSaga({ payload }) {
  try {
    // console.log(data, 'data');
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: true },
      knowExternalApi,
      payload.id,
    );
    if (response) {
      yield put(actions.knowExternalSuccess(response));
    }
  } catch (error) {
    yield put(actions.knowExternalFailure(error));
  }
}

function* editNoteItemActivitySaga({ payload }) {
  try {
    // console.log(data, 'data');
    const { id, itemId, ...data } = payload;
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: true },
      editNoteItemActivityApi,
      id,
      itemId,
      data,
    );

    if (response) {
      yield put(actions.editNoteItemActivitySuccess(response));
    }
  } catch (error) {
    yield put(actions.editNoteItemActivityFailure(error));
  }
}

export default [
  ...makeCRUDSaga(RESOURCE, [], actions),
  takeLatest([actions.changeDueDate.type], changeDueDateExternalSaga),
  takeLatest([actions.seenExternal.type], seenExternalSaga),
  takeLatest([actions.getTrackingExternal.type], getTrackingExternalSaga),
  takeLatest([actions.addActivity.type], addActivityExternalSaga),
  takeLatest([actions.completeExternal.type], completeExternalSaga),
  takeLatest([actions.archiveExternal.type], archiveExternalSaga),
  takeLatest([actions.commentExternal.type], commentExternalSaga),
  takeLatest([actions.editCommentExternal.type], editCommentExternalSaga),
  takeLatest([actions.delCommentExternal.type], delCommentExternalSaga),
  takeLatest([actions.getCommentsExternal.type], getCommentsExternalSaga),
  takeLatest([actions.knowExternal.type], knowExternalSaga),
  takeLatest([actions.editNoteItemActivity.type], editNoteItemActivitySaga),
];
