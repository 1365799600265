import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import I18n from 'i18next';
import { Layout, Menu, Icon, Badge } from 'antd';
import { checkRole } from 'utils/tools';
import { history } from '../../redux/store';
// import Logo from '../../assets/images/Logo.svg';
import FullLogo from '../../assets/images/Logo_DNTrain.svg';

const getCurrentTab = str => {
  const paths = str && str.split('/');
  return paths && paths[1];
};

const SideBar = ({ collapsed }) => {
  const permissionData = useSelector(state => state.auth.permissionData);
  const location = useSelector(state => state.router.location);
  const url = getCurrentTab(location.pathname);
  const sidebarMenu = [
    // {
    //   key: 'dashboard',
    //   text: 'sideBar.documentary',
    //   icon: 'folder-open',
    //   url: '/',
    // },
    {
      key: 'documentaries',
      text: 'sideBar.documentaries',
      icon: 'folder-open',
      url: '/documentaries',
      roles: ['AllowAll', 'MenuDocumentTask'],
      count: useSelector(state => state.notifications.totalNewExternalTask),
    },
    {
      key: 'internalMessages',
      text: 'sideBar.internalMessages',
      icon: 'message',
      url: '/internalMessages/received',
      roles: ['AllowAll', 'MenuInternalTask'],
      count: useSelector(state => state.notifications.totalNewInternalTask),
    },
    {
      key: 'announcements',
      text: 'sideBar.announcements',
      icon: 'ic-notification',
      url: '/announcements',
      roles: ['AllowAll', 'MenuAnnouncement'],
      count: useSelector(state => state.notifications.totalNewAnnouncement),
    },
    {
      key: 'users',
      text: 'sideBar.users',
      icon: 'team',
      url: '/users',
      roles: ['AllowAll', 'MenuUser'],
    },
    {
      key: 'documentBoxes',
      text: 'sideBar.documentBoxes',
      icon: 'hdd',
      url: '/documentBoxes',
      roles: ['AllowAll', 'MenuDocumentBox'],
    },
    // {
    //   key: 'settings',
    //   text: 'sideBar.settings',
    //   icon: 'ic-config',
    //   url: '/settings/default',
    //   // roles: ['superadmin', 'admin'],
    // },
  ];

  const handleClickMenuItem = (e, url) => {
    history.push(url);
  }
  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className="sidebar"
      collapsedWidth={64}
    >
      <div className="logo">
        <img
          alt=""
          src={FullLogo}
          className={collapsed ? 'logo-collapsed' : 'fullLogo'}
        />
      </div>
      <Menu
        mode="inline"
        selectedKeys={[url || 'dashboard']}
        defaultSelectedKeys={[url || 'dashboard']}
        // inlineCollapsed={collapsed}
      >
        {sidebarMenu.map(menu => {
          if (!checkRole(menu.roles, permissionData)) {
            return null;
          }
          return (
            <Menu.Item
              key={menu.key}
              title={I18n.t(menu.text)}
              onClick={(e) => handleClickMenuItem(e, menu.url)}
            >
              {menu.count ? (
                <Badge count={menu.count}>
                  <Icon type={menu.icon} />
                </Badge>
              ) : (
                <Icon type={menu.icon} />
              )}
              {!collapsed && (
                <span className="text-menu">{I18n.t(menu.text)}</span>
              )}
            </Menu.Item>
          );
        })}
      </Menu>
    </Layout.Sider>
  );
};

SideBar.propTypes = {
  collapsed: PropTypes.bool,
};

export default SideBar;
