import React from 'react';
import RestShow from '../../rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const DocumentBoxesShow = props => (
  <RestShow 
    {...props} 
    hasEdit 
    resource="documentBoxes"
    defaultOptions={{ customApiResource: 'documentboxs' }} 
  >
    <RestFieldItem source="name" header="documentBoxes.name" />
    <RestFieldItem source="createdAt" header="documentBoxes.createdAt" />
  </RestShow>
);

export default DocumentBoxesShow;
