import styled from 'styled-components';

const ListStyles = styled.div`
  .ant-tag {
    margin: 5px;
  }
  @media only screen and (max-width: 1200px) and (min-width: 993px) {
    .row-filter-container {
      display: flex;
      flex-direction: column;
      .col-filter-content {
        width: 100%;
      }
      .col-actions {
        width: 33.33%;
      }
    }
  }
`;

export default ListStyles;
