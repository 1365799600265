import React from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import FormSelect from '../../form/FormSelect';
import { getRecordData } from '../../../utils/tools';
import { RestInputContext } from '../RestInputContext';

const RestSelect = ({isFormatObjectValue, ...props}) => {
  const getDefaultValue = (record) => {
    if (props.mode === 'multiple' && isFormatObjectValue) {
      return getRecordData(record, props.source)?.map((item) => {
        return (
          getRecordData(item, props.valuePropEdit) || item[props.valueProp]
        );
      });
    }
    if (
      getRecordData(record, props.source) !== undefined &&
      getRecordData(record, props.source) !== null
    ) {
      return getRecordData(record, props.source);
    }
    return props.defaultValue;
  };
  const getDefaultResource = (record) => {
    if (props.mode === 'multiple') {
      const value = getRecordData(record, props.source);
      if (isArray(value))
        return value?.map((item) =>
          props.resourceProp ? item[props.resourceProp] : item,
        );
    } else {
      const valueSingle = props.resourceProp
        ? getRecordData(record, props.resourceProp)
        : getRecordData(record, props.source);
      if (typeof valueSingle === 'object' && !isArray(valueSingle))
        return [valueSingle];
    }
    return [];
  };

  return (
    <RestInputContext.Consumer>
      {({ record, form }) => (
        <FormSelect
          {...props}
          form={form}
          defaultValue={
            props.formatValue
              ? props.formatValue(getDefaultValue(record))
              : getDefaultValue(record)
          }
          defaultResource={getDefaultResource(record)}
        />
      )}
    </RestInputContext.Consumer>
  );
};

RestSelect.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  defaultValue: PropTypes.any,
  mode: PropTypes.string,
  valueProp: PropTypes.string,
  formatValue: PropTypes.func,
  valuePropEdit: PropTypes.string,
  resourceProp: PropTypes.string,
  isFormatObjectValue: PropTypes.bool,
};

export default RestSelect;
