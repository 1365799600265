import styled from 'styled-components';

const TitleTreeStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .user-avatar {
    margin-right: 10px;
    .ant-avatar {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
  }
  .header-tree-title {
    ${'' /* margin-bottom: 5px; */}
    display: flex;
    .role-user {
      margin-left: 10px;
      color: #13c2c2;
      font-weight: normal;
      font-size: 12px;
      display: inline-block;
      white-space: nowrap;
      line-height: 20px;
    }
  }
  .info-activity {
    max-width: 300px;

    @media only screen and (min-width: 1440px) {
      max-width: 350px;
    }

    @media only screen and (max-width: 1295px) and (min-width: 1270px) {
      max-width: 250px;
    }

    @media only screen and (max-width: 1270px) and (min-width: 1260px) {
      max-width: 220px;
    }

    @media only screen and (max-width: 1260px) and (min-width: 1230px) {
      max-width: 210px;
    }

    @media only screen and (max-width: 1230px) and (min-width: 1200px) {
      max-width: 190px;
    }
  }

  .bottom-title-tree {
    font-weight: normal;
    .completed {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .ant-tag{
        margin: 5px 0 0 0;
      }
    }
    .time-noti {
      color: #9fa9c0;
      margin-top: 5px;
      margin-right: 10px;
      & > i {
        margin-right: 5px;
        color: #9fa9c0;
      }
    }
  }
  .note-item {
    margin-top: 5px;
    font-weight: 500;
    -webkit-line-clamp: 2;
    word-break: break-word;
  }

  .note-item,
  .name-user {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .name-user {
    -webkit-line-clamp: 1;
    word-break: break-all;
  }
  
  .edit-btn {
    border: none;
    margin-left: 5px;
  }
`;

export default TitleTreeStyles;