import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Spin, Avatar, Icon } from 'antd';
import { Waypoint } from 'react-waypoint';
import { isEmpty } from 'lodash';
import {withRouter} from 'react-router-dom';
import { formatDateFromNow } from 'utils/textUtils';
import i18next from 'i18next';
import ListStyles, {NotifyItemStyles} from './styles';


const CustomList = ({
  // onClose,
  retrieveList,
  resourceData,
  resourceFilter,
  onClose,
  history,
}) => {
  // const dispatch = useDispatch();
  const handleEnterWaypoint = () => {
    if (resourceData?.length < resourceFilter?.count && resourceData?.length > 0 && !loading) {
      retrieveList({}, false);
    }
  };

  const loading = useSelector(state => state.notifications.loading);

  const handleClickNotiItem = (link) => {
    onClose();
    if(link) {
      const index = link?.indexOf("#");
      if(index !== -1) {
        const rest = link.slice(index, link.length);
        history.replace(`${history?.location?.pathname}${rest}`);
      } else {
        history.replace({pathname: link, state: 'flushDeal'});
      }
    }
  };

  // const handleClickNotification = record => {
  //   onClose();
  //   dispatch(actions.seenNotification({ id: record?.id, isSeen: true }));
  //   if (record?.prescriptionId) {
  //     dispatch(
  //       push(`/prescriptions#prescriptions/${record?.prescriptionId}/edit`),
  //     );
  //   }
  // };
  return (
    <ListStyles className="list-noti">
      {!isEmpty(resourceData) ? (
        resourceData?.map(item => (
          <NotifyItemStyles key={item?.id}>
            <div
              className="noti-item"
              key={item?.id}
              role="presentation"
              // to={item?.link}
              onClick={() => handleClickNotiItem(item?.link)}
            >
              {item.fromUser?.avatarUrl ? (
                <Avatar src={item?.fromUser?.avatarUrl} />
              ) : (
                <Avatar icon="user" />
              )}

              <div className="content-noti-item">
                <div className="top-noti-item">
                  <strong>{item?.fromUser?.name}</strong>
                  <span>{` ${item?.content ? item?.content.trim() : ''}`}</span>
                </div>
                <div className="bottom-noti-item">
                  <strong className="title-noti">{item?.title?.trim()}</strong>
                  <span className="time-noti">
                    <Icon type="clock-circle" />
                    {formatDateFromNow(item?.createdDateUtc)}
                  </span>
                </div>
              </div>
            </div>
          </NotifyItemStyles>
        ))
      ) : (
        <div className="no-data">{i18next.t('announcements.noData')}</div>
      )}
      {loading && (
      <div className="loading-noti">
        <Spin />
      </div>
      )}
      {!isEmpty(resourceData) && !loading && 
      <div className="waypoint-wrapper"><Waypoint onEnter={handleEnterWaypoint} /></div>}
    </ListStyles>
    // <ListStyles
    //   itemLayout="horizontal"
    //   dataSource={resourceData || []}
    //   renderItem={record => (
    //     <List.Item
    //       key={record?.id}
    //       onClick={() => handleClickNotification(record)}
    //       className={record?.isSeen ? '' : 'not-seen-noti'}
    //     >
    //       <List.Item.Meta
    //         avatar={(
    //           <div className="notification-icon">
    //             <SVGIcon
    //               size={35}
    //               type={
    //                 NOTIFICATIONS.find(
    //                   notification => notification.value === record.title,
    //                 )?.icon
    //               }
    //             />
    //           </div>
    //         )}
    //         title={i18next.t(
    //           NOTIFICATIONS.find(
    //             notification => notification.value === record.title,
    //           )?.text,
    //         )}
    //         description={formatDateTime(record.createdAt)}
    //       />
    //       {record.message}
    //     </List.Item>
    //   )}
    // >
    //   {loading && (
    //     <div style={{ display: 'flex', justifyContent: 'center' }}>
    //       <Spin />
    //     </div>
    //   )}
    //   <Waypoint onEnter={handleEnterWaypoint} />
    // </ListStyles>
  );
};

CustomList.propTypes = {
  resourceData: PropTypes.array,
  retrieveList: PropTypes.func,
  resourceFilter: PropTypes.object,
  onClose: PropTypes.func,
  history: PropTypes.object,
};

CustomList.defaultProps = {
  onClose: () => {},
};

export default withRouter(CustomList);
