import React from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import { Avatar, Row, Col } from 'antd';
import i18next from 'i18next';
import { getRecordData } from '../../../utils/tools';
import { PopoverStyles } from './styles';

const UserInfoPopoverContent = ({ record, avatarProp }) => {
  return (
    <PopoverStyles>
      <div className="avatar-user-popover">
        {(record && record.avatarUrl && (
          <Avatar src={getRecordData(record, avatarProp)} />
        )) || <Avatar icon="user" />}
      </div>

      <div className="content-info-user">
        <Row>
          {record?.name && (
            <Col span={24}>
              <span className="title-label">
                {`${i18next.t('profile.fullName')}: `}
              </span>
              <span className="value-item-info">{record?.name}</span>
            </Col>
          )}
          {record?.email && (
            <Col span={24}>
              <span className="title-label">
                {`${i18next.t('profile.email')}: `}
              </span>
              <span className="value-item-info">{record?.email}</span>
            </Col>
          )}
          {record?.phoneNumber && (
            <Col span={24}>
              <span className="title-label">
                {`${i18next.t('profile.mobilePhone')}: `}
              </span>
              <span className="value-item-info">{record?.phoneNumber}</span>
            </Col>
          )}
          {record?.telephoneNumber && (
            <Col span={24}>
              <span className="title-label">
                {`${i18next.t('profile.deskPhone')}: `}
              </span>
              <span className="value-item-info">{record?.telephoneNumber}</span>
            </Col>
          )}
          {record?.department?.name && (
            <Col span={24}>
              <span className="title-label">
                {`${i18next.t('profile.department')}: `}
              </span>
              <span className="value-item-info">
                {record?.department?.name}
              </span>
            </Col>
          )}

          {isArray(record?.positions) && (
            <Col span={24}>
              <span className="title-label">
                {`${i18next.t('profile.position')}: `}
              </span>
              <span className="value-item-info">
                {isArray(record?.positions) && record.positions[0]?.name}
              </span>
            </Col>
          )}
        </Row>
      </div>
    </PopoverStyles>
  );
};

UserInfoPopoverContent.propTypes = {
  record: PropTypes.object,
  avatarProp: PropTypes.string,
};

UserInfoPopoverContent.defaultProps = {
  avatarProp: 'avatarUrl',
};

export default UserInfoPopoverContent;
