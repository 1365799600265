import React from 'react';
import i18next from 'i18next';
// import moment from 'moment';
import PropTypes from 'prop-types';

import SeenUsers from 'components/RestField/SeenUsers';
// import { actions } from 'redux/internalMessages/slice';
import CommentItem from './CommentItem';
import CommentStyles from './styles';
import AddComment from './AddComment';

const CommentComponent = ({
  totalSeen,
  // totalReceiver,
  seenUsers,
  listComments,
  isDeleted,
  idTask,
  submitAction,
  submitActionEdit,
  submitActionDel,
  resource,
}) => {
  return (
    <CommentStyles className="comment-area">
      {!isDeleted && (
        <div>
          <div className="title-label">{`${i18next.t('comment.text')}: `}</div>
          <div className="input-comment">
            <AddComment
              idTask={idTask}
              submitAction={submitAction}
              resource={resource}
            />
          </div>
        </div>
      )}
      <div className="content-comment">
        <SeenUsers totalSeen={totalSeen} seenUsers={seenUsers} />
        <div className="detail-comment">
          {!listComments?.length && totalSeen ? (
            <div className="empty-data">{i18next.t('comment.emptyData')}</div>
          ) : null}
          {listComments?.map((comment, index) => (
            <CommentItem
              key={String(index)}
              comment={comment}
              submitActionEdit={submitActionEdit}
              resource={resource}
              idTask={idTask}
              submitActionDel={submitActionDel}
            />
          ))}
        </div>
      </div>
    </CommentStyles>
  );
};

CommentComponent.propTypes = {
  totalSeen: PropTypes.number,
  // totalReceiver: PropTypes.number,
  seenUsers: PropTypes.array,
  listComments: PropTypes.array,
  isDeleted: PropTypes.bool,
  submitAction: PropTypes.func,
  idTask: PropTypes.string,
  submitActionEdit: PropTypes.func,
  resource: PropTypes.string,
  submitActionDel: PropTypes.func,
};

CommentComponent.defaultProps = {
  submitAction: () => null,
  submitActionEdit: () => null,
  submitActionDel: () => null,
};

export default CommentComponent;
