import React from 'react';
// import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
// import CRUDActions from 'redux/crudActions';
// import crudSelectors from 'redux/crudSelectors';
import TextArea from 'antd/lib/input/TextArea';
import i18next from 'i18next';
import { getUsersTreeSelect } from 'redux/config/selectors';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import RestTreeSelect from 'components/RestInput/RestTreeSelect';
// import UserInfo from 'components/RestField/UserInfo';
import RestMultiPhotos from 'components/RestInput/RestMultiPhotos';
import { PRIORITY, FORWARD_DATA } from 'configs/localData';
// import { checkRole } from 'utils/tools';
import FormStyles from './styles';

const DocumentariesForm = ({isEdit, ...props}) => {
  // const usersData = useSelector(state => crudSelectors.users.getDataArr(state));
  // const profileData = useSelector(state => state.auth.data);
  const tagsList = useSelector(state => state.config.tags);

  const positionSlugs = FORWARD_DATA?.find(data => data.role === 'Van_Thu')?.positionsAccept;

  const treeData = useSelector(state => getUsersTreeSelect(state, positionSlugs));

  const getPopupContainer = (t) => t.parentNode;
  // const roleSlug = useSelector(state => state.auth.roleSlug);
  // const dispatch = useDispatch();
  // const loading = useSelector(state => state.users.loading);
  // const resourceFilter = useSelector(state =>
  //   crudSelectors.users.getFilters(state),
  // );
  // const [valueSearch, setValueSearch] = useState(null);

  // const getUsers = (valueSearch) => {
  //   dispatch(
  //     CRUDActions.users.getAll(
  //       {
  //         pageSize: 15,
  //         pageNumber: 1,
  //         sortBy: 'createdAt',
  //         name: valueSearch,
  //         isActive: true,
  //         isSortByDesc: true,
  //         positionSlugs,
  //       },
  //       {
  //         isRefresh: true,
  //       },
  //     ),
  //   );
  // }
  // const onHandleBlur = () => {
  //   if (valueSearch !== null) {
  //     getUsers(null);
  //     setValueSearch(null);
  //   }
  // };

  // useEffect(() => {
  //   getUsers(null);
  //   // eslint-disable-next-line
  // }, []);
  // const handleSearch = value => {
  //   setValueSearch(value);
  //   getUsers(value);
  // };

  // const onSearch = debounce(handleSearch, 600);

  // const handleScroll = () => {
  //   if (
  //     usersData.length < resourceFilter.count &&
  //     usersData.length > 0 &&
  //     !loading
  //   )
  //     dispatch(
  //       CRUDActions.users.getAll(
  //         {
  //           sortBy: 'createdAt',
  //           isSortByDesc: true,
  //           name: valueSearch,
  //           isActive: true,
  //           positionSlugs,
  //         },
  //         {
  //           isRefresh: false,
  //         },
  //       ),
  //     );
  // };

  // const formatData = () => {
  //   return usersData?.filter(item =>
  //     (item.id !== profileData.id))
  //   // && checkRole(positionSlugs, item?.positions?.map(a => a.slug)))
  // }

  return (
    <FormStyles>
      <Row {...props} gutter={24} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Col lg={12} md={24} xs={24}>
          <RestSelect
            required
            source="priority"
            header="documentaries.priority"
            placeholder="documentaries.priority"
            resourceData={PRIORITY}
            valueProp="value"
            titleProp="text"
            formatText={(data) => i18next.t(data)}
            defaultValue={PRIORITY[1]?.value}
            ruleType="number"
            isShowSearch={false}
          />
        </Col>
        <Col lg={12} md={24} xs={24}>
          <RestInputItem
            // required
            source="fromNumber"
            header="documentaries.numberCV"
            placeholder="documentaries.numberCV"
            rules={[
              {
                max: 100,
                message: i18next.t('input.numberCV.validateMsg.maxLength', {
                  max: 100,
                }),
              },
            ]}
          />
        </Col>

        <Col lg={12} md={24} xs={24}>
          <RestInputItem
            // required
            source="sourceNumber"
            header="documentaries.numberVB"
            placeholder="documentaries.numberVB"
            rules={[
              {
                max: 100,
                message: i18next.t('input.numberVB.validateMsg.maxLength', {
                  max: 100,
                }),
              },
            ]}
          />
        </Col>
        <Col lg={12} md={24} xs={24}>
          <RestInputItem
            // required
            source="publisher"
            header="documentaries.publishPlace"
            placeholder="documentaries.publishPlace"
            rules={[
              {
                max: 500,
                message: i18next.t('input.publishPlace.validateMsg.maxLength', {
                  max: 500,
                }),
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <RestInputItem
            required
            source="title"
            header="documentaries.title"
            placeholder="documentaries.title"
            rules={[
              {
                max: 500,
                message: i18next.t('input.title.validateMsg.maxLength', {
                  max: 500,
                }),
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <RestInputItem
            // required
            source="content"
            header="documentaries.content"
            ContentComponent={TextArea}
            rows={6}
            placeholder="documentaries.content"
            rules={[
              {
                max: 500,
                message: i18next.t('input.content.validateMsg.maxLength', {
                  max: 500,
                }),
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <RestSelect
            required
            source="tags"
            header="documentaries.tags"
            placeholder="documentaries.tags"
            ruleType="array"
            mode="multiple"
            resourceData={tagsList || []}
          />
        </Col>
        <Col span={24}>
          <RestTreeSelect
            required
            source="activities[0].items[0].user.id"
            header="documentaries.receivedUser"
            placeholder="documentaries.receivedUser"
            treeData={treeData}
            isShowChild
            treeDefaultExpandAll
            showSearch
            treeCheckable={false}
            selectProps={{getPopupContainer}}
          />
        </Col>
      
        {/* <Col span={24}>
          <RestSelect
            required
            source="activities[0].items[0].user.id"
            header="documentaries.executedUser"
            placeholder="documentaries.executedUser"
            resourceData={formatData()}
            valueProp="id"
            // valuePropEdit="user.id"
            titleProp="name"
            filterOption={false}
            onSearch={onSearch}
            loading={loading}
            onEnter={handleScroll}
            onBlur={onHandleBlur}
            resourceProp="activities[0].items[0].user"
          >
            <UserInfo
              isLink={false}
              format={(record) =>
                `${record?.department?.name}, ${record?.positions?.[0]?.name}`}
            />
          </RestSelect>
        </Col> */}
        {!isEdit && (
          <Col span={24}>
            <RestMultiPhotos
              resource="documentaries"
              source="medias"
              placeholder="documentaries.medias"
              typeFile={2}
            />
          </Col>
        )}
      </Row>
    </FormStyles>
  );
};

DocumentariesForm.propTypes = {
  isEdit: PropTypes.object,
};

export default DocumentariesForm;
