import React from 'react';
import PropTypes from 'prop-types';
import CustomBreadcrumb from '../../common/Breadcrumb';
import ButtonEdit from '../../RestActions/EditButton';
import DeleteButton from '../../RestActions/DeleteButton';
import Layout from '../../common/Layout';
import Box from '../../common/Box';
import ActionView from '../ActionLayout';
import PageTitle from '../../common/PageTitle';
import { makeBreadCrumbFromPath } from '../../../utils/tools';
import { ContentShowStyles } from './styles';

const RestShowComponent = ({
  noCardWrapper,
  noActions,
  deleteItem,
  gotoEditPage,
  record,
  resource,
  children,
  hasEdit,
  hasDel,
  header,
  location,
  isShowBreadcrumb,
  customMessageItemName,
  customBreadcrumb,
  extraAction,
  disabledEdit,
  disabledDel,
}) => {
  const BREADCRUMB_LIST = makeBreadCrumbFromPath(location);

  if (!record) return null;

  const actions = (
    <div className="actions-button">
      {hasEdit && (
        <ButtonEdit
          resource={resource}
          record={record}
          gotoEditPage={gotoEditPage}
          disabled={disabledEdit}
        />
      )}
      {hasDel && (
        <DeleteButton
          resource={resource}
          record={record}
          deleteItem={deleteItem}
          customMessageItemName={customMessageItemName}
          disabled={disabledDel}
        />
      )}
    </div>
  );

  const components = React.Children.map(children, element =>
    React.cloneElement(element, { key: element.props.source, record }),
  );

  const content = (
    <ContentShowStyles className="content-detail-show">
      {!noActions && <ActionView>{actions}</ActionView>}
      {components}
    </ContentShowStyles>
  );

  return noCardWrapper ? (
    content
  ) : (
    <Layout bordered={false} extra={actions}>
      {header ||
        (isShowBreadcrumb && (
          <PageTitle {...(extraAction && { extraAction })}>
            <CustomBreadcrumb data={customBreadcrumb || BREADCRUMB_LIST} />
          </PageTitle>
        ))}
      <Box>{content}</Box>
    </Layout>
  );
};

RestShowComponent.propTypes = {
  children: PropTypes.node,
  record: PropTypes.object,
  noCardWrapper: PropTypes.bool,
  deleteItem: PropTypes.func,
  gotoEditPage: PropTypes.func,
  resource: PropTypes.string,
  hasEdit: PropTypes.bool,
  hasDel: PropTypes.bool,
  header: PropTypes.any,
  noActions: PropTypes.bool,
  location: PropTypes.object,
  isShowBreadcrumb: PropTypes.bool,
  customMessageItemName: PropTypes.string,
  customBreadcrumb: PropTypes.array,
  extraAction: PropTypes.any,
  disabledEdit: PropTypes.bool,
  disabledDel: PropTypes.bool,
};

RestShowComponent.defaultProps = {
  noCardWrapper: false,
  isShowBreadcrumb: true,
  disabledEdit: false,
  disabledDel: false,
};
export default RestShowComponent;
