import styled from 'styled-components';

const UsersReceivedStyles = styled.div`
  .label-title {
    margin-bottom: 15px;
  }
  .header-list {
    padding: 10px;
    background: ${({ theme }) => theme.background.notSeenNoti};
    & > .ant-col {
      font-weight: 600;
    }
  }
  .row-item-user {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .ant-form-item {
    margin: 0;
    input {
      border: 1px solid ${({ theme }) => theme.border.default};
    }
  }

  ${'' /* @media only screen and (min-width: 630px) {
    .users-list-mobile {
      display: none;
    }
  } */}

  @media only screen and (max-width: 630px) {
    ${'' /* .content-list {
      display: none;
    } */}

    .label-mobile {
      font-weight: 600;
    }
    .item-mobile {
      background: ${({ theme }) => theme.background.contentItem};
      padding: 10px;
      margin-bottom: 15px;
    }
    .item-user {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .item-user:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

export default UsersReceivedStyles;
