import React from 'react';
import {
  PRIORITY,
  DOCUMENTARIES_STATUS,
  ACTIVITY_ROLES,
  ACTIVITY_ROLE_VALUE,
} from 'configs/localData';
import i18next from 'i18next';
import { Tag } from 'antd';
import moment from 'moment';
import { checkRole } from 'utils/tools';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import List from '../../rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
// import ActionGroup from '../../../components/RestActions/ActionGroup';
// import EditButton from '../../../components/RestActions/EditButton';
// import DeleteButton from '../../../components/RestActions/DeleteButton';
import Filter from '../components/Filter';
import ListStyles from './styles';

const DocumentariesList = (props) => {
  const permissionData = useSelector((state) => state.auth.permissionData);
  const profileData = useSelector((state) => state.auth.data);

  const setClassNameSeen = (record) => {
    if (profileData?.id !== record?.createdUser?.id && !record?.isSeen && !record?.isDeleted) {
      if (record.role === ACTIVITY_ROLE_VALUE.execute)
        return 'active-row-item-execute';
      return 'active-row-item';
    }
    return '';
  };

  const formatFilter = ({ startDate, endDate, ...data }) => ({
    ...data,
    startDate: startDate
      ? moment(startDate).startOf('days').utc().toISOString()
      : startDate,
    endDate: endDate
      ? moment(endDate).endOf('days').utc().toISOString()
      : endDate,
  });

  return (
    <ListStyles>
      <List
        {...props}
        resource="documentaries"
        hasSearch={false}
        hasExport={false}
        hasCreate={checkRole(
          ['AllowAll', 'DocumentTaskCreate'],
          permissionData,
        )}
        createHeader="button.createDocumentary"
        defaultOptions={{ customApiResource: 'externaltasks' }}
        initialFilter={{ filter: { type: 1 } }}
        rowClassName={(record) => setClassNameSeen(record)}
        // setClassNameSeen={(record) => setClassNameSeen(record)}
        redirects={{
          edit: 'screen',
          create: 'modal',
        }}
        widthTable="1100px"
        filter={<Filter format={formatFilter} />}
      >
        {/* <RestFieldItem
          source="priority"
          header="documentaries.priority"
          format={(data) =>
            data > 0 ? (
              <Tag color={PRIORITY.find((item) => item.value === data)?.color}>
                {i18next.t(PRIORITY.find((item) => item.value === data)?.text)}
              </Tag>
            ) : null}
          width={110}
        /> */}
        <RestFieldItem
          source="status"
          header="documentaries.status"
          format={(data) => {
            const item = DOCUMENTARIES_STATUS.find(
              (item) => item.value === data,
            );

            return item ? (
              <div>
                <Tag className="tag-single" color={item.color}>{i18next.t(item.text)}</Tag>
              </div>
            ) : null;
          }}
          width={130}
        />
        <RestFieldItem
          source="role"
          header="documentaries.role"
          format={(data) => {
            const item = ACTIVITY_ROLES.find((role) => role.value === data);
            return (
              <span>
                {data === 1 ? (
                  <Tag className="tag-single" color="#e64c38">{i18next.t(item?.text)}</Tag>
                ) : (
                  <Tag className="tag-single">{i18next.t(item?.text)}</Tag>
                )}
              </span>
            );
          }}
          width={160}
        />
        <RestFieldItem
          source="createdAt"
          header="documentaries.createdAt"
          format={(data) => (
            <span>{data && moment(data).format('DD-MM-YYYY HH:mm')}</span>
          )}
          width={100}
        />
        <RestFieldItem
          source="title"
          header="documentaries.title"
          format={(data, record) => {
            const item = PRIORITY.find(
              (item) => item.value === record.priority,
            );
            return (
              <div className="title-task">
                {record.priority > 0 ? (
                  <Tag color={item?.color}>{i18next.t(item?.textCode)}</Tag>
                ) : null}
                <Link
                  to={`/documentaries/${record?.id}/show`}
                  className="title-name"
                >
                  {data}
                </Link>
              </div>
            );
          }}
        />
        <RestFieldItem
          source="executedUser"
          header="documentaries.executedUser"
          format={(data) => data?.name || ''}
          width={150}
        />
        <RestFieldItem
          source="fromNumber"
          header="documentaries.numberCV"
          width={100}
        />
        <RestFieldItem
          source="toNumber"
          header="documentaries.toNumber"
          width={100}
        />
        {/* <RestFieldItem
          source="dueDateUtc"
          header="documentaries.dueDate"
          format={(data) => (
            <span>{data && moment(data).format('DD-MM-YYYY HH:mm')}</span>
          )}
          width={100}
        /> */}
        <RestFieldItem
          source="tags"
          header="documentaries.tags"
          format={(data) =>
            data
              ? (<div>{data.map((tag, index) => <Tag key={String(index)}>{tag}</Tag>)}</div>)
              : null}
          width={130}
        />
        {/* <ActionGroup widthAction={80}>
          <EditButton isView source="show" />
        </ActionGroup> */}
      </List>
    </ListStyles>
  );
};

DocumentariesList.propTypes = {};

export default DocumentariesList;
