import React from 'react';
import Edit from '../../rest/Edit';
import Form from '../components/Form';

const AnnouncementsEdit = props => (
  <Edit
    {...props}
    resource="announcements"
    formatOnSubmit={({ medias, title, content, ...data }) => ({
      ...data,
      title: title && title.trim(),
      content: content && content.trim(),
      mediaIds: medias?.map(media => media?.id || media),
    })}
  >
    <Form />
  </Edit>
);

AnnouncementsEdit.propTypes = {};

export default AnnouncementsEdit;
