import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
// import { Tooltip } from 'antd';
import { Tooltip } from 'antd';
import { ButtonWrapper } from './styles';

const EditButton = ({ isView, disabled, customOnClick, record, ...props }) => {
  // console.log(props.source, 'aaaa')
  // console.log(props.gotoEditPage, 'edit')
  const handleOnClick = () => {
    if(customOnClick) customOnClick(record);
    else if(isView) props.gotoShowPage(record ? record.id : '')
    else props.gotoEditPage(record ? record.id : '')
  }
  return (
    <Tooltip title={I18n.t(`button.${isView ? 'view' : 'edit'}`)}>
      <ButtonWrapper
        disabled={disabled}
        source={props.source}
        icon={isView ? 'eye' : 'edit'}
        onClick={handleOnClick}
      />
    </Tooltip>
  );
};

EditButton.propTypes = {
  gotoShowPage: PropTypes.func,
  gotoEditPage: PropTypes.func,
  record: PropTypes.object,
  source: PropTypes.string,
  isView: PropTypes.bool,
  disabled: PropTypes.bool,
  customOnClick: PropTypes.func,
};

EditButton.defaultProps = {
  source: 'edit',
};

export default EditButton;
