import React, {useEffect} from 'react';
import { formatDate } from 'utils/textUtils';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/notifications/slice';
import List from '../../rest/List';
import NotificationList from '../components/NotificationList';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';
import ListStyles from './styles';

const NotificationsList = props => {
  const dispatch = useDispatch();
  const notSeen = useSelector(
    state => state.notifications.totalNewNotification,
  );

  const currentUser = useSelector(state => state.auth.data);

  useEffect(() => {
    notSeen && currentUser.id && dispatch(actions.trackingResetNotifications(currentUser?.id));
    // eslint-disable-next-line
  }, [notSeen]);

  return (
    <ListStyles>
      <List
        {...props}
        customLayout={<NotificationList {...props} />}
        noCardWrapper
        getFromUrl={false}
        isUpdateRoute={false}
        resource="notifications"
        // initialFilter={{ pageNumber: 1 }}
        isShowPagination={false}
        hasExport={false}
        hasSearch={false}
        hasCreate={false}
      >
        <RestFieldItem source="message" header="notifications.message" />
        <RestFieldItem
          source="createdAt"
          header="notifications.createdAt"
          format={data => formatDate(data)}
        />
        <ActionGroup>
          <EditButton />
          <DeleteButton />
        </ActionGroup>
      </List>
    </ListStyles>
)};

NotificationsList.propTypes = {};

export default NotificationsList;
