import styled from 'styled-components';

const LoginStyles = styled.div`
  .logo {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
`;

export default LoginStyles;
