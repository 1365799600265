import styled from 'styled-components';

export default styled.div`
  background: ${({ theme }) => theme.background.content};
  height: 100%;
  padding: 24px;
  .action-section {
    display: flex;
    justify-content: flex-end;
    padding-right: 34px;
    .submit-button {
      margin-left: 15px;
    }
  }
  @media only screen and (max-width: 630px) {
    .action-section {
      padding: 0;
      margin-top: 10px;
    }
  }
`;
