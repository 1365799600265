import { createSelector } from 'reselect';
import { isEmpty, findIndex, isArray } from 'lodash';
import React from 'react';
import UserInfo from 'components/RestField/UserInfo';
// import { Avatar } from 'antd';
// import { sortBy } from 'lodash';

const getRawConfigName = (state, name) => state.config[name];
const getRawDepartments = (state) => state.config?.departments;
const getRawPositions = (state) => state.config?.positions;
const getRawUsers = (state) => state.config?.users;
const optionsFilter = (
  state,
  positionSlugsArr,
  departmentSlug,
  isCheckAssigned,
) => ({
  positionSlugsArr: positionSlugsArr || [],
  departmentSlug,
  isCheckAssigned,
});
const getRawActivitiesData = (state) =>
  state.documentaries.currentData?.activities;

export const getConfigByName = createSelector(
  [getRawConfigName],
  (config) => config || [],
);

export const getDepartments = createSelector(
  [getRawDepartments],
  // departments => sortBy(departments, o =>  o.name.toLowerCase()) || [],
  (departments) => departments || [],
);

export const getPositions = createSelector(
  [getRawPositions],
  // positions => sortBy(positions, o =>  o.name.toLowerCase()) || [],
  (positions) => positions || [],
);

const getAssignedUsers = createSelector(
  [getRawActivitiesData],
  (activitiesData) => {
    let arr = [];

    activitiesData && activitiesData.forEach((activity) => {
      if (isArray(activity.items)) {
        arr = arr.concat(activity.items);
      }
    });

    return arr;
  },
);

export const getUserFilter = createSelector(
  [getRawUsers, optionsFilter, getAssignedUsers],
  (users, object, assignedUsers) => {
    // console.log(assignedUsers, 'assignedUsers');
    let data = object.departmentSlug
      ? users?.filter((item) => item.department?.slug === object.departmentSlug)
      : users;

    if (object.isCheckAssigned) {
      data = data.filter(
        (item) => findIndex(assignedUsers, (o) => o.user?.id === item.id) === -1); // loai bo user da dc assign
    }

    return isEmpty(object.positionSlugsArr)
      ? data
      : data?.filter(
          (item) =>
            findIndex(item.positions, (o) =>
              object.positionSlugsArr.includes(o.slug),
            ) !== -1,
        );
  },
);

export const getUsersTreeSelect = createSelector(
  [getUserFilter, getRawDepartments, optionsFilter],
  // departments => sortBy(departments, o =>  o.name.toLowerCase()) || [],

  (users, departments, object) => {
    // console.log(users, 'users');
    const authId = localStorage.getItem('id');
    const departmentsData = object.departmentSlug
      ? departments?.filter((item) => item.slug === object.departmentSlug)
      : departments;

    const data =
      departmentsData?.map((item) => ({
        title: item.name,
        key: item.id,
        value: item.id,
        selectable: false,
        children: users
          ?.filter(
            (user) =>
              user.department?.id === item.id && // check phong ban
              user.id !== authId,
          ) // loai bo auth user
          ?.map((res) => ({
            key: res.id,
            value: res.id,
            title: (
              <UserInfo
                isLink={false}
                record={res}
                note={`${res?.department?.name}, ${res?.positions?.[0]?.name}`}
              />
            ),
            avatarUrl: res.avatarUrl,
            // icon: <Avatar src={res.avatarUrl} icon="user" />,
          })),
      })) || [];

    return data.filter((item) => !isEmpty(item.children));
  },
);
