import React from 'react';
// import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { isImageFile, getExtensionFile } from 'utils/tools';
import { FILE_TYPES } from 'configs/localData';

const UploadImageItem = ({
  // defaultSourceKey,
  // onSetDefault,
  // onMouseEnter,
  // onMouseLeave,
  item,
  // onPreviewUI,
  deleteImage,
  // isDefault,
}) => {
  const getPropertyFile = property => {
    return getExtensionFile(item.fileName || item.name)
      ? FILE_TYPES.find(
          file =>
            file.value.toLocaleLowerCase() ===
            getExtensionFile(item.fileName || item.name).toLocaleLowerCase(),
        )?.[property]
      : null;
  };

  const handleDeletedFile = (e, item) => {
    e.stopPropagation();
    deleteImage(item)
  }

  return (
    <div
      // onMouseEnter={onMouseEnter}
      // onMouseLeave={onMouseLeave}
      className="uploadImage"
    >
      <div className="file-item">
        <div className="div-item-image">
          {item.status === 'error' && (
            <Icon className="loading error" type="exclamation-circle" />
          )}
          {item.status !== 'error' && item.status !== 'done' && !item.urlFile && (
          <Icon className="loading" type="loading" />
          )}
          {(item.status === 'done' || item.urlFile) &&
            ((typeof (item?.type) === 'string' && item.type?.includes('image')) || isImageFile(item.fileName) ? (
              <img
                className="image"
                src={item.urlFile || item?.response?.urlFile}
                alt=""
              />
            ) : (
              <Icon
                type={getPropertyFile('icon') || 'file'}
                style={{
                  color: getPropertyFile('color'),
                }}
                className="icon-file"
              />
            ))}
        </div>
        <span className="name-file">{item.name || item.fileName}</span>
      </div>

      {/* <div className="overlay">
        {(typeof(item?.type)==='string' && item?.type?.includes('image')) || isImageFile(item.fileName) ? (
          <Icon
            type="eye"
            onClick={() =>
              onPreviewUI({
                ...item,
                url: item.url || item.response?.urlFile || item.urlFile,
              })}
          />
        ) : (
          <a 
            href={item.url || item?.response?.urlFile || item.urlFile} 
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon type="download" />
          </a>
        )}
        <Icon type="delete" onClick={() => deleteImage(item)} />
      </div> */}
      <Icon type="close" onClick={(e) => handleDeletedFile(e, item)} className="remove-button" />
      {/* {defaultSourceKey ? (
        <div
          role="presentation"
          onClick={() => onSetDefault(item)}
          className={`lbSetDefault  ${isDefault ? 'active' : ''}`}
        >
          {i18next.t('button.setDefault')}
        </div>
      ) : null} */}
    </div>
  );
};
UploadImageItem.propTypes = {
  item: PropTypes.object,
  // onPreviewUI: PropTypes.func,
  deleteImage: PropTypes.func,
  // onMouseLeave: PropTypes.func,
  // onMouseEnter: PropTypes.func,
  // onSetDefault: PropTypes.func,
  // defaultSourceKey: PropTypes.string,
  // isDefault: PropTypes.bool,
};

export default UploadImageItem;
