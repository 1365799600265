import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Icon, Popover } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { isEmpty, isArray } from 'lodash';
import { useSelector } from 'react-redux';
import { getRecordData, checkRole } from '../../../utils/tools';
import { MEMBER_ROLES } from '../../../configs/localData/index';
import { UserTagWrapper, UserPopoverWrapper } from './styles';
import UserInfoPopoverContent from '../UserInfoPopover';

const UserInfo = props => {
  const {
    record,
    isShowName,
    size,
    isLink,
    prefixLink,
    nameProp,
    roleProp,
    avatarProp,
    note,
    format,
    isShowPopover,
    contentPopoverCustom,
    idProp,
  } = props;
  const roles =
    getUserRole(getRecordData(record, roleProp)) ||
    note ||
    (format && format(record));
  
  const permissionData = useSelector(state => state.auth.permissionData);

  const content =
    record &&
    (isLink && checkRole(['AllowAll', 'UserRead', 'UserEdit'], permissionData) ? (
      <Link to={`${prefixLink}/${record[idProp]}/edit`} className="link-class">
        {getRecordData(record, nameProp) || record.email}
        {record.gender ? (
          <Icon
            style={{
              marginLeft: 5,
              fontSize: 17,
              color: record.gender === 'Male' ? '#00a7f7' : '#9b28b0',
            }}
            type={`${record.gender.toLowerCase()}`}
          />
        ) : (
          ''
        )}
        <div style={{ lineHeight: '12px' }}>
          <small style={{ color: '#8d8a8a' }}>
            {isArray(roles)
              ? !isEmpty(roles) &&
                roles?.map((item, index) =>
                  index === roles.length - 1 ? (
                    <span key={String(index)}>{item.roleName}</span>
                  ) : (
                    <span key={String(index)}>{`${item.roleName}, `}</span>
                  ),
                )
              : roles}
            {/* {getUserRole(getRecordData(record, roleProp))} */}
          </small>
        </div>
      </Link>
    ) : (
      // eslint-disable-next-line
      <a role="main" className="link-class">
        {getRecordData(record, nameProp) || record.email}
        <div style={{ lineHeight: '12px' }}>
          <small style={{ color: '#8d8a8a' }}>
            {isArray(roles)
              ? !isEmpty(roles) &&
                roles?.map((item, index) =>
                  index === roles.length - 1 ? (
                    <span key={String(index)}>{item.roleName}</span>
                  ) : (
                    <span key={String(index)}>{`${item.roleName}, `}</span>
                  ),
                )
              : roles}
          </small>
        </div>
      </a>
    ));
  const userTag = (
    <UserTagWrapper>
      {(record && record.avatarUrl && (
        <Avatar src={getRecordData(record, avatarProp)} size={size} />
      )) || <Avatar icon="user" size={size} />}
      {(record && isShowName && content) || (
        <span style={{ marginLeft: 10 }}>
          {i18next.t('error.waitingUpdate')}
        </span>
      )}
    </UserTagWrapper>
  );
  // const contentPopover = (
  //   <PopoverStyles>
  //     <div className="avatar-user-popover">
  //       {(record && record.avatarUrl && (
  //         <Avatar src={getRecordData(record, avatarProp)} size={size} />
  //       )) || <Avatar icon="user" size={size} />}
  //     </div>

  //     <div className="content-info-user">
  //       <Row>
  //         <Col span={24}>
  //           <span className="title-label">
  //             {`${i18next.t('profile.fullName')}: `}
  //           </span>
  //           <span className="value-item-info">{record?.name}</span>
  //         </Col>
  //         <Col span={24}>
  //           <span className="title-label">
  //             {`${i18next.t('profile.email')}: `}
  //           </span>
  //           <span className="value-item-info">{record?.email}</span>
  //         </Col>
  //         <Col span={24}>
  //           <span className="title-label">
  //             {`${i18next.t('profile.department')}: `}
  //           </span>
  //           <span className="value-item-info">{record?.department?.name}</span>
  //         </Col>
  //         <Col span={24}>
  //           <span className="title-label">
  //             {`${i18next.t('profile.position')}: `}
  //           </span>
  //           <span className="value-item-info">
  //             {isArray(record?.positions) && record.positions[0]?.name}
  //           </span>
  //         </Col>
  //       </Row>
  //     </div>
  //   </PopoverStyles>
  // );
  return (
    <UserPopoverWrapper className="avatar-item">
      {isShowPopover ? (
        <Popover
          content={
            contentPopoverCustom || (
              <UserInfoPopoverContent record={record} avatarProp={avatarProp} />
            )
          }
          trigger="hover"
          placement="right"
        >
          {userTag}
        </Popover>
      ) : (
        userTag
      )}
    </UserPopoverWrapper>
  );
};

const getUserRole = id => {
  const role = MEMBER_ROLES.find(data => data.id === id);
  return role ? role.text[i18next.language] : id;
};

UserInfo.propTypes = {
  record: PropTypes.object,
  isShowName: PropTypes.bool,
  size: PropTypes.number,
  isLink: PropTypes.bool,
  nameProp: PropTypes.string,
  roleProp: PropTypes.string,
  avatarProp: PropTypes.string,
  prefixLink: PropTypes.string,
  note: PropTypes.any,
  format: PropTypes.func,
  isShowPopover: PropTypes.bool,
  contentPopoverCustom: PropTypes.node,
  idProp: PropTypes.string,
};

UserInfo.defaultProps = {
  nameProp: 'name',
  roleProp: 'role',
  avatarProp: 'avatarUrl',
  isShowName: true,
  isLink: true,
  prefixLink: '/users',
  isShowPopover: false,
  idProp: 'id',
};

export default UserInfo;
