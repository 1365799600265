import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Button } from 'antd';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { push } from 'connected-react-router';
import { convertObjToSearchStr } from 'utils/tools';
import PageTitle from 'components/common/PageTitle';
import SettingStyle from './styles';

const { TabPane } = Tabs;

const Default = () => <div />

const TABS = [
  {
    key: 'default',
    text: 'tabs.default',
    url: '/default',
    component: Default,
  },
];

const Settings = ({ match, ...props }) => {
  const dispatch = useDispatch();
  const pushRoute = data => dispatch(push(data));
  const onChange = key => {
    pushRoute(`/settings/${key}`);
  };
  const gotoCreatePage = () => {
    const { redirects, rootPath, initCreateData } = props;
    const route = `${rootPath}/${match.params.model}/create`;
    if (redirects.create === 'modal') {
      pushRoute(
        `#${match.params.model}/create?${convertObjToSearchStr(
          initCreateData,
        )}`,
      );
    } else {
      pushRoute(route);
    }
  };
  return (
    <SettingStyle>
      <div className="page-header">
        <PageTitle>{i18next.t('settings.header')}</PageTitle>
        <Button type="primary" onClick={gotoCreatePage}>
          {i18next.t('button.create')}
        </Button>
      </div>
      <Tabs
        type="card"
        defaultActiveKey={match.params.model || 'rooms'}
        onChange={onChange}
      >
        {TABS.map(tab => (
          <TabPane tab={i18next.t(tab.text)} key={tab.key}>
            <tab.component
              rootPath="/settings"
              noCardWrapper
              layoutButtonCreate="non-inline"
              {...props}
            />
          </TabPane>
        ))}
      </Tabs>
    </SettingStyle>
  );
};

Settings.propTypes = {
  match: PropTypes.object,
  redirects: PropTypes.object,
  rootPath: PropTypes.string,
  initCreateData: PropTypes.object,
};

Settings.defaultProps = {
  rootPath: '/settings',
  redirects: {
    edit: 'modal',
    create: 'modal',
  },
  initCreateData: {},
};

export default Settings;
