import styled from 'styled-components';

const CommentStyles = styled.div`
  .input-comment,
  .seen-users {
    margin-bottom: 15px;
  }
  .title-label {
    font-weight: 700;
    margin-bottom: 15px;
  }
`;

export default CommentStyles;
