import { makeCRUDSlice } from '../crudCreator/slice';

export const RESOURCE = 'announcements';
const announcementsSlice = makeCRUDSlice(RESOURCE, {
  seenAnnouncement: () => {},
  seenAnnouncementSuccess: (state, { payload }) => {
    state.data = {
      ...state.data,
      [payload]: {
        ...state.data[payload],
        isSeen: true,
      },
    };
  },
  seenAnnouncementFailure: (state, { payload }) => {
    state.error = payload;
  },
});

export const { actions, reducer } = announcementsSlice;

export default reducer;
