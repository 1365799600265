import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalWrapper = styled(Modal)`
  ${'' /* max-height: 90%; */}
  min-height: 30%;
  max-width: calc(100vw - 16px);

  ${'' /* .multi-upload-wrapper .ant-form-item:first-child {
    display: none;
  }
  .multi-upload-wrapper .ant-form-item:not(:first-child) {
    margin-bottom: 10px;
  } */}

  .drawerContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 0!important;
    margin-right: 0!important;
    .footer {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }
  .content {
    flex: 1;
    overflow-y: auto;
  }
  .ant-drawer-content-wrapper {
    .ant-drawer-body {
      padding: 0px;
      padding-top: 70px;
      height: 100%;
      overflow: hidden;
      & > div {
        height: 100%;
        overflow: hidden;
      }
    }
    .ant-form {
      padding: 10px 24px;
    }
  }
  
  .ant-modal-header {
    ${'' /* background: ${({ theme }) => theme.background.content};
    border-bottom: 1px solid ${({ theme }) => theme.background.container}; */}
    background: ${({ theme }) => theme.palette.primary};
    border: none;
    color: ${({ theme }) => theme.palette.primary};
  }
  .ant-modal-content {
    ${'' /* background: ${({ theme }) => theme.background.container}; */}
    padding-top: 70px;
  }
  .ant-modal-title, .ant-modal-close,
  .ant-modal-close-icon {
    ${'' /* color: #fff; */}
    font-size: 18px;
    ${'' /* font-weight: 700; */}
  }

  .ant-modal-title {
    ${'' /* color: ${({ theme }) => theme.palette.primary}; */}
    font-size: 35px;
  }
  ${'' /* .ant-modal-close,
  .ant-modal-close-icon {
    display: none;
  } */}
  .ant-input,
  .ant-select-selection,
  .ant-input-number,
  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu,
  .ant-select-dropdown,
  .ant-select-clear-icon,
  .ant-select-dropdown-menu-vertical {
    border-radius: 4px;
    ${'' /* background: ${({ theme }) => theme.background.gray};
    border: 1px solid transparent;
    &:hover,
    &:focus,
    &:active {
      border: 1px solid ${({ theme }) => theme.border.default};
    } */}
  }
  ${'' /* textarea {
    background: ${({ theme }) => theme.background.content};
    border: none;
    &:hover,
    &:focus,
    &:active {
      border: 1px solid ${({ theme }) => theme.border.default};
    }
  } */}
  .ant-select-selection__clear {
    background-color: transparent;
    color: white;
    border-radius: 5px;
  }
  .ant-select-arrow-icon {
    background-color: transparent;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ant-modal-footer {
    ${'' /* border-top: 1px solid ${({ theme }) => theme.background.container}; */}
    border: none;
  }

  .ant-modal-body {
    padding: 0px 20px 20px;
    ${'' /* .isoBoxWrapper {
      
      padding: 10px 0 0 0;
    } */}
  }

  .ant-tabs-bar {
    font-weight: 500;
  }
  .ant-tabs-nav {
    .ant-tabs-tab {
      padding-bottom: 5px;
    }
  }
  .ant-tabs-tab {
    font-family: Roboto;
    color: ${({ theme }) => theme.text.tabTitle};
  }
  .ant-list {
    margin-top: 20px;
    overflow: auto;
    max-height: 460px;
  }
  div::-webkit-scrollbar-thumb {
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb} !important;
  }
  div::-webkit-scrollbar-track {
    position: absolute;
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.track} !important;
  }
  div::-webkit-scrollbar-thumb:hover {
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb} !important;
  }
  div::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb} !important;
  }
  .ant-list-split .ant-list-item {
    border-bottom: none;
    padding: 1px 0px;
  }
  .ant-list-empty-text {
    color: ${({ theme }) => theme.text.empty};
  }
  .modalTitleContent {
    ${'' /* background: ${({ theme }) => theme.background.gray}; */}
    ${'' /* background: ${({ theme }) => theme.palette.primary}; */}
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    ${'' /* border-bottom: 1px solid ${({ theme }) => theme.border.default}; */}
    .modalBtnBack {
      margin: 0px 24px;
      font-size: 18px;
      ${'' /* color: ${({ theme }) => theme.drawer.closeIcon}; */}
      ${'' /* color: #fff;
      font-weight: 700; */}
    }
    .modalTitle {
      flex: 1;
      padding-left: 24px;
      font-size: 18px;
      ${'' /* color: ${({ theme }) => theme.drawer.headerTitle}; */}
      ${'' /* color: #fff;
      font-weight: 700; */}
    }
  }

  .ant-form-item {
  }
  .ant-form-item-control {
    line-height: 2;
  }
  .txtTitle {
    font-size: 14px;
  }
  .ant-form-item-label {
    line-height: 1.5em;
    padding-bottom: 5px;
  }
  .ant-input-number {
    width: 100%;
  }
  .txtTitleForm {
    color: ${({ theme }) => theme.text.formLabel};
    font-size: 14px;
  }
  .ant-form-item .ant-form-explain {
    margin-top: 2px;
  }
  .showTotal {
    display: flex;
    justify-content: flex-end;
    button {
      height: 32px;
      margin-left: 15px;
      width: auto;
    }
  }
  @media only screen and (min-width: 577px) {
    .ant-drawer-content-wrapper {
      min-width: 450px;
    }
  }
  @media only screen and (max-width: 576px) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
    .isoBoxWrapper { 
      padding: 10px 0 0 0;
    }
  }
`;
