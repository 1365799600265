import React, { useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
// import { checkRole } from 'utils/tools';
import PropTypes from 'prop-types';
import { actions } from 'redux/announcements/slice';
import RestShow from '../../rest/Show';
// import RestFieldItem from '../../../components/RestField/RestFieldItem';
import AnnouncementInfo from '../components/AnnouncementInfo';

const AnnouncementsShow = (props) => {
  const userProfile = useSelector((state) => state.auth.data);
  const announcementCurrent = useSelector(
    (state) => state.announcements.currentData,
  );
  // const permissionData = useSelector((state) => state.auth.permissionData);
  const dispatch = useDispatch();
  useEffect(() => {
    announcementCurrent?.isSeen === false &&
      announcementCurrent?.isDeleted === false &&
      dispatch(actions.seenAnnouncement({ id: announcementCurrent?.id }));
    // eslint-disable-next-line
  }, [announcementCurrent]);

  const checkShowAction = () => {
    // console.log(process.env.REACT_APP_EXPIRED_TIME_ANNOUNCEMENT, 'expired time');

    return (
      !announcementCurrent?.isDeleted &&
      userProfile.id === announcementCurrent?.createdUser?.id &&
      moment().diff(moment(announcementCurrent?.createdAt), 'seconds') <=
        (process.env.REACT_APP_EXPIRED_TIME_ANNOUNCEMENT || 172800)
    );
  };

  return (
    <RestShow
      {...props}
      hasEdit={checkShowAction(['AllowAll', 'AnoucementEdit'])}
      hasDel={checkShowAction(['AllowAll', 'AnoucementRemove'])}
      resource="announcements"
      isShowBreadcrumb={false}
      customMessageItemName="announcements.customMessageItemName"
      isDeleted={false}
    >
      {/* <RestFieldItem source="priority" header="announcements.priority" />
    <RestFieldItem source="message" header="announcements.title" />
    <RestFieldItem source="description" header="announcements.content" />
    <RestFieldItem source="createdAt" header="announcements.createdAt" />
    <RestFieldItem source="sender" header="announcements.sender" />
    <RestFieldItem source="totalView" header="announcements.totalView" /> */}
      <AnnouncementInfo />
    </RestShow>
  );
};

AnnouncementsShow.propTypes = {
  record: PropTypes.object,
};

export default AnnouncementsShow;
