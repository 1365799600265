import React from 'react';
import { useSelector } from 'react-redux';

import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const DocumentaryFilesEdit = (props) => {
  const externalTaskId = useSelector((state) => state.documentaries.currentId);
  const handleObSubmit = ({media}) => {
    return {
      mediaId: (media && media.id) || media,
    };
  }
  return(
    <Edit
      {...props}
      resource="documentaryFiles"
      defaultOptions={{
        customApiResource: `externaltasks/${externalTaskId}/attachments`,
      }}
      formatOnSubmit={handleObSubmit}
    >
      <Form isEdit />
    </Edit>
  )};

DocumentaryFilesEdit.propTypes = {};

export default DocumentaryFilesEdit;
