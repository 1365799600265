import {
  takeLatest,
  takeEvery,
  call,
  put,
  // take,
  // select,
} from 'redux-saga/effects';
// import { eventChannel } from 'redux-saga';
// import firebase from 'firebase';
// import { notification } from 'antd';
import {
  getNotificationCountApi,
  getAllNotificationsApi,
  trackingResetNotificationsApi,
  trackingResetAnnouncementsApi,
} from 'api/notifications';


// import { handleFirebaseMessage } from 'api/firebase';
import { putApi } from 'api/crud';
import { apiWrapper } from 'utils/reduxUtils';
// import { history } from 'redux/store';
// import { fetch } from 'whatwg-fetch';
import { convertResponseData } from 'redux/crudCreator/dataProvider';
import { makeCRUDSaga } from '../crudCreator/saga';
import { RESOURCE, actions } from './slice';

// const notificationsChannel = null;

function* getNotificationCountSaga() {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      getNotificationCountApi,
    );
    if (response) {
      yield put(actions.getNotificationCountSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.getNotificationCountFailure(error));
  }
}

// function* watchFirebaseNotificationsSaga({ payload: { seenNotification } }) {
//   try {
//     const response = yield firebase.messaging().getToken();
//     yield put(actions.updateInstallations(response));
//     notificationsChannel = eventChannel(emit => {
//       const listener = handleFirebaseMessage(data => emit(data));
//       // const listener = firebase.messaging().onMessage(data => {
//       //   emit(data);
//       // });
//       return () => {
//         listener();
//       };
//     });
//     while (true) {
//       const response = yield take(notificationsChannel);
//       const receivedNotification = {
//         ...response.notification,
//         createdAt: response?.data?.createdAt,
//         body: JSON.parse(response.notification.body),
//       };
//       yield put(
//         actions.updateNotifications({
//           id: receivedNotification?.body?.notificationId,
//           message: receivedNotification?.body?.message,
//           title: receivedNotification?.title,
//           isSeen: false,
//           prescriptionId: receivedNotification?.body?.prescriptionId,
//           createdAt: receivedNotification?.createdAt,
//         }),
//       );
//       notification.open({
//         message: receivedNotification.title,
//         description: receivedNotification?.body?.message,
//         duration: 2.5,
//         onClick: () => {
//           seenNotification({
//             id: receivedNotification?.body?.notificationId,
//             isSeen: true,
//           });
//           history.push(
//             `/prescriptions#prescriptions/${receivedNotification?.body?.prescriptionId}/edit`,
//           );
//         },
//       });
//     }
//   } catch (error) {
//     yield put(actions.watchFirebaseNotificationsFailure(error));
//     notificationsChannel !== null && notificationsChannel.close();
//   }
// }
// function* getAllNotificationsSaga({ payload }) {
//   try {
//     const response = yield call(
//       apiWrapper,
//       { isShowProgress: false, isShowSuccessNoti: false },
//       getAllNotificationsApi,
//       payload,
//     );
//     if (response) {
//       yield put(actions.getAllNotificationsSuccess(response.data));
//     }
//   } catch (error) {
//     yield put(actions.getAllNotificationsFailure(error));
//   }
// }

function* getAllNotificationsSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      getAllNotificationsApi,
      payload,
    );

    const pageSize = payload.pageSize || 1;

    const result = convertResponseData('GET_ALL', response);
    if (result.ids) {
      yield put(
        actions.getAllNotificationsSuccess({
          data: {
            numberOfPages: Math.round(result.total / pageSize),
            ...result,
          },
        }),
      );
    }
  } catch (error) {
    yield put(actions.getAllNotificationsFailure(error));
  }
}

// function* watchFirebaseNotificationsSaga({ payload: { seenNotification } }) {
//   try {
//     const response = yield firebase.messaging().getToken();
//     yield put(actions.updateInstallations(response));
//     notificationsChannel = eventChannel(emit => {
//       const listener = handleFirebaseMessage(data => emit(data));
//       // const listener = firebase.messaging().onMessage(data => {
//       //   emit(data);
//       // });
//       return () => {
//         listener();
//       };
//     });
//     while (true) {
//       const response = yield take(notificationsChannel);
//       const receivedNotification = {
//         ...response.notification,
//         createdAt: response?.data?.createdAt,
//         body: JSON.parse(response.notification.body),
//       };
//       yield put(
//         actions.updateNotifications({
//           id: receivedNotification?.body?.notificationId,
//           message: receivedNotification?.body?.message,
//           title: receivedNotification?.title,
//           isSeen: false,
//           prescriptionId: receivedNotification?.body?.prescriptionId,
//           createdAt: receivedNotification?.createdAt,
//         }),
//       );
//       notification.open({
//         message: receivedNotification.title,
//         description: receivedNotification?.body?.message,
//         duration: 2.5,
//         onClick: () => {
//           seenNotification({
//             id: receivedNotification?.body?.notificationId,
//             isSeen: true,
//           });
//           history.push(
//             `/prescriptions#prescriptions/${receivedNotification?.body?.prescriptionId}/edit`,
//           );
//         },
//       });
//     }
//   } catch (error) {
//     yield put(actions.watchFirebaseNotificationsFailure(error));
//     notificationsChannel !== null && notificationsChannel.close();
//   }
// }

// function* updateInstallationsSaga({ payload }) {
//   try {
//     const locale = 'vi';
//     const userId = yield select(state => state.auth.data.id);
//     const requestParams = {
//       // ...(businessId && { businessId }),
//       firebaseToken: payload,
//       deviceType: 'web',
//       userId,
//       locale,
//     };
//     yield call(fetch, `${process.env.REACT_APP_SERVER_URL}/api/installations`, {
//       method: 'POST',
//       body: JSON.stringify(requestParams),
//     });
//   } catch (error) {
//     yield put(actions.updateInstallationsFailure(error));
//   }
// }

function* seenNotificationSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      putApi,
      'notifications',
      payload.id,
      { isSeen: payload.isSeen },
    );
    if (response) {
      yield put(actions.getNotificationCount());
      yield put(actions.seenNotificationSuccess(response.id));
    }
  } catch (error) {
    yield put(actions.seenNotificationFailure(error));
  }
}

function* trackingResetNotificationsSaga({payload}) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      trackingResetNotificationsApi,
      payload,
    );

    if (response) {
      // console.log(response, 'response');
      yield put(actions.trackingResetNotificationsSuccess({type: 'notify'}));
    }
  } catch (error) {
    yield put(actions.trackingResetNotificationsFailure(error));
  }
}

function* trackingResetAnnouncementsSaga() {
  try {
    // console
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      trackingResetAnnouncementsApi,
    );
    if (response) {
      yield put(actions.trackingResetNotificationsSuccess({type: 'announcements'}));
    }
  } catch (error) {
    yield put(actions.trackingResetNotificationsFailure({error, type: 'announcements'}));
  }
}

export default [
  ...makeCRUDSaga(RESOURCE, [], actions),
  takeLatest([actions.getNotificationCount.type], getNotificationCountSaga),
  // takeEvery(
  //   [actions.watchFirebaseNotifications.type],
  //   watchFirebaseNotificationsSaga,
  // ),
  // takeEvery([actions.updateInstallations], updateInstallationsSaga),
  takeEvery([actions.seenNotification.type], seenNotificationSaga),
  takeLatest([actions.getAllNotifications.type], getAllNotificationsSaga),
  takeLatest(
    [actions.trackingResetNotifications.type],
    trackingResetNotificationsSaga,
  ),
  takeLatest(
    [actions.trackingResetAnnouncements.type],
    trackingResetAnnouncementsSaga,
  ),
];
