import React from 'react';
// import { useSelector } from 'react-redux';
// import { actions } from 'redux/documentBoxes/slice';
// import { getFolderIdByUrl } from 'utils/tools';
import Edit from '../../rest/Edit';
import FileForm from '../components/FileForm';

const BoxFileEdit = props => {
  // const selectedFolderId = useSelector(state => state.documentBoxes.selectedFolder?.id);
  // const arrIds = getFolderIdByUrl(props, 'boxFiles');
  // const handleSubmitAction = (id, {name, ...data}) => {
  //   return actions.editFile({
  //     ...data,
  //     id: arrIds[1],
  //     folderId: arrIds[0],
  //     name: name && name.trim(),
  //     options: { isBack: true },
  //   })
  // }

  // const customGetById = () => actions.getByIdFile({ id: arrIds?.[1], folderId: arrIds?.[0] })

  return(
    <Edit
      {...props}
      resource="documentBoxes"
      customResource="boxFiles"
      header="boxFiles.editPage"
      // customGetById={customGetById}
      // customOnSubmit={handleSubmitAction}
      defaultOptions={{ customApiResource: 'files'}}
      formatOnSubmit={({name, ...data}) => ({
        ...data,
        title: name && name.trim(),
      })}
    >
      <FileForm isEdit />
    </Edit>
)};

BoxFileEdit.propTypes = {};

export default BoxFileEdit;
