import React from 'react';
import PropTypes from 'prop-types';

import Edit from '../../rest/Edit';
import Form from '../components/Form';

const InternalMessagesEdit = (props) => (
  <Edit
    {...props}
    isClearCurrent={false}
    resource="internalMessages"
    defaultOptions={{ customApiResource: 'internaltasks' }}
    isGetHash
    formatOnSubmit={({ activities, medias, title, content, ...data }) => {
      return {
        ...data,
        title: title && title.trim(),
        content: content && content.trim(),
        ...(activities && {
          activity: {
            items: activities[0].items.map((item) => ({
              userId: item?.user?.id || item,
            })),
          },
        }),
        ...(medias && { mediaIds: medias.map((item) => item.id || item) }),
        taskType: 0,
      };
    }}
  >
    <Form />
  </Edit>
);

InternalMessagesEdit.propTypes = {
  match: PropTypes.object,
};

InternalMessagesEdit.propTypes = {
  match: {},
};

export default InternalMessagesEdit;
