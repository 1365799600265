import styled from 'styled-components';

const EditCommentStyles = styled.div`
  width: 100%;
  .ant-form-item {
    width: 100%;
    margin-bottom: 0 !important;
    margin-right: 15px;
    textarea {
      border: 1px solid ${({ theme }) => theme.border.default};
    }
  }
  .group-action {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-cancel {
      margin-right: 10px;
    }
  }
`;

export default EditCommentStyles;
