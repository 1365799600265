import { apiWrapper } from 'utils/reduxUtils';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getTrackingInternalApi,
  trackingInternalApi,
  commentInternalApi,
  getCommentsInternalApi,
  editCommentInternalApi,
  delCommentInternalApi,
} from 'api/tasks';
import { actions as actionsNotify } from 'redux/notifications/slice';
import { makeCRUDSaga } from '../crudCreator/saga';
import { RESOURCE, actions } from './slice';

function* getTrackingInternalSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      getTrackingInternalApi,
      payload,
    );
    if (response) {
      yield put(
        actions.getTrackingSuccess({ id: payload, tracking: response }),
      );
    }
  } catch (error) {
    yield put(actions.getTrackingFailure(error));
  }
}

function* seenInternalMessageSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      trackingInternalApi,
      payload,
    );
    if (response) {
      yield put(actions.seenInternalSuccess(payload));
      yield put(actions.getTrackingInternal(payload));
      yield put(actionsNotify.recountNotification({ type: 'internal' }));
    }
  } catch (error) {
    yield put(actions.seenInternalFailure(error));
  }
}

function* commentInternalSaga({ payload }) {
  try {
    const { id, ...data } = payload;
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      commentInternalApi,
      id,
      data,
    );
    if (response) {
      yield put(actions.commentSuccess(response));
    }
  } catch (error) {
    yield put(actions.commentFailure(error));
  }
}

function* editCommentInternalSaga({ payload }) {
  try {
    const { id, idComment, ...data } = payload;
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      editCommentInternalApi,
      id,
      idComment,
      data,
    );
    if (response) {
      yield put(actions.editCommentSuccess(response));
    }
  } catch (error) {
    yield put(actions.editCommentFailure(error));
  }
}

function* delCommentInternalSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      delCommentInternalApi,
      payload.id,
      payload.idComment,
    );
    if (response) {
      yield put(actions.delCommentSuccess(payload));
    }
  } catch (error) {
    yield put(actions.delCommentFailure(error));
  }
}

function* getCommentsInternalSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      getCommentsInternalApi,
      payload,
    );
    if (response) {
      yield put(actions.getCommentsSuccess(response));
    }
  } catch (error) {
    yield put(actions.getCommentsFailure(error));
  }
}

export default [
  ...makeCRUDSaga(RESOURCE, [], actions),
  takeLatest([actions.getTrackingInternal.type], getTrackingInternalSaga),
  takeLatest([actions.seenInternal.type], seenInternalMessageSaga),
  takeLatest([actions.commentInternal.type], commentInternalSaga),
  takeLatest([actions.editCommentInternal.type], editCommentInternalSaga),
  takeLatest([actions.delCommentInternal.type], delCommentInternalSaga),
  takeLatest([actions.getCommentsInternal.type], getCommentsInternalSaga),
];
