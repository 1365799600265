import React from 'react';
import { useSelector } from 'react-redux';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const DocumentaryFilesCreate = props => {
  const externalTaskId = useSelector(state => state.documentaries.currentId);

  return(
    <Create
      {...props}
      resource="documentaryFiles"
      defaultOptions={{
        customApiResource: `externaltasks/${externalTaskId}/attachments`,
        isGetAll: false,
      }}
    >
      <Form />
    </Create>
  )};

DocumentaryFilesCreate.propTypes = {};

export default DocumentaryFilesCreate;
