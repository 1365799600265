import React, { Component } from 'react';
import SortableTree from 'react-sortable-tree';
import PropTypes from 'prop-types';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import TreeStyles from './styles';
import { ModalEditNoteProvider } from './ModalNote/context';
import ModalEditNoteObj from './ModalNote';

class DocumentFlow extends Component {

  calculateRowHeight = (data) => {
    const dataProp = data?.node?.title?.props?.data || {};

    if (dataProp.isCurrentProcess) return 140;

    if (dataProp.notes) return 110;

    return 80;
  };

  render() {
    return (
      <div>
        <ModalEditNoteProvider>
          <TreeStyles>
            <SortableTree
              treeData={this.props.activitiesData}
              onChange={() => {}}
              theme={FileExplorerTheme}
              canDrag={false}
              canDrop={() => false}
              generateNodeProps={() => ({
                listIndex: 0,
                lowerSiblingCounts: [],
              })}
              rowHeight={this.calculateRowHeight}
              style={{ minHeight: '200px' }}
            />
          </TreeStyles>
          <ModalEditNoteObj />
        </ModalEditNoteProvider>
      </div>
    );
  }
}

DocumentFlow.propTypes = {
  activitiesData: PropTypes.array,
};

export default DocumentFlow;
