import { createSlice, createAction } from '@reduxjs/toolkit';

export const initialState = {};

const { actions, reducer } = createSlice({
  name: 'Config',
  initialState,
  reducers: {
    getConfigSuccess: (state, { payload }) => {
      state.data = payload;
    },
    getConfigFailure: (state, { payload }) => {
      state.error = payload;
    },
    getSummariesSuccess: (state, { payload }) => {
      state.summaries = payload;
    },
    getSummariesFailure: (state, { payload }) => {
      state.error = payload;
    },
    clearSummariesData: (state) => {
      state.summaries = {};
    },
    getDepartmentsSuccess: (state, { payload }) => {
      state.departments = payload;
    },
    getDepartmentsFailure: (state, { payload }) => {
      state.error = payload;
    },
    getPositionsSuccess: (state, { payload }) => {
      state.positions = payload;
    },
    getPositionsFailure: (state, { payload }) => {
      state.error = payload;
    },
    getUsersSuccess: (state, { payload }) => {
      state.users = payload || [];
    },
    getUsersFailure: (state, { payload }) => {
      state.error = payload;
    },
    getTagsSuccess: (state, { payload }) => {
      state.tags = payload;
    },
    getTagsFailure: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export const getConfig = createAction('Config/getConfig');
export const getSummaries = createAction('Config/getSummaries');
export const getDepartments = createAction('Config/getDepartments');
export const getPositions = createAction('Config/getPositions');
export const getUsers = createAction('Config/getUsers');
export const getTags = createAction('Config/getTags');
export const {
  getConfigSuccess,
  getConfigFailure,
  getSummariesSuccess,
  getSummariesFailure,
  clearSummariesData,
  getDepartmentsSuccess,
  getDepartmentsFailure,
  getPositionsSuccess,
  getPositionsFailure,
  getUsersSuccess,
  getUsersFailure,
  getTagsSuccess,
  getTagsFailure,
} = actions;
export default reducer;
