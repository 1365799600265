import React from 'react';
import { PRIORITY } from 'configs/localData';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
// import CRUDActions from 'redux/crudActions';
// import crudSelectors from 'redux/crudSelectors';
// import { debounce } from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import { getUsersTreeSelect } from 'redux/config/selectors';

import RestSelect from 'components/RestInput/RestSelect';
import RestTreeSelect from 'components/RestInput/RestTreeSelect';
import RestMultiPhotos from 'components/RestInput/RestMultiPhotos';
// import UserInfo from 'components/RestField/UserInfo';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const InternalMessagesForm = props => {
  // const usersData = useSelector(state => crudSelectors.users.getDataArr(state));
  const treeData = useSelector(state => getUsersTreeSelect(state));
  const getPopupContainer = (t) => t.parentNode;

  // const dispatch = useDispatch();
  // const loading = useSelector(state => state.users.loading);
  // const profileData = useSelector(state => state.auth.data);
  // const resourceFilter = useSelector(state =>
  //   crudSelectors.users.getFilters(state)
  // );
  // const [valueSearch, setValueSearch] = useState(null);

  // const getUsers = (valueSearch) => {
  //   dispatch(
  //     CRUDActions.users.getAll(
  //       {
  //         pageSize: 15,
  //         pageNumber: 1,
  //         sortBy: 'createdAt',
  //         name: valueSearch,
  //         isActive: true,
  //         isSortByDesc: true
  //       },
  //       {
  //         isRefresh: true
  //       }
  //     )
  //   );
  // }
  // const onHandleBlur = () => {
  //   if (valueSearch !== null) {
  //     getUsers(null);
  //     setValueSearch(null);
  //   }
  // };

  // useEffect(() => {
  //   getUsers(null);
  //   // eslint-disable-next-line
  // }, []);
  // const handleSearch = value => {
  //   setValueSearch(value);
  //   getUsers(value);
  // };

  // const onSearch = debounce(handleSearch, 600);

  // const handleScroll = () => {
  //   if (
  //     usersData.length < resourceFilter.count &&
  //     usersData.length > 0 &&
  //     !loading
  //   )
  //     dispatch(
  //       CRUDActions.users.getAll(
  //         {
  //           sortBy: 'createdAt',
  //           isSortByDesc: true,
  //           name: valueSearch,
  //           isActive: true
  //         },
  //         {
  //           isRefresh: false
  //         }
  //       )
  //     );
  // };

  // const formatData = () => {
  //   return usersData?.filter(item =>
  //     /* eslint comma-dangle: ["error", "never"] */
  //     (item.id !== profileData.id)
  //   )
  // }

  return (
    <div {...props}>
      <RestSelect
        required
        source="priority"
        header="internalMessages.priority"
        placeholder="internalMessages.priority"
        resourceData={PRIORITY}
        valueProp="value"
        titleProp="text"
        formatText={(data) => i18next.t(data)}
        defaultValue={PRIORITY[1]?.value}
        ruleType="number"
        isShowSearch={false}
      />
      <RestInputItem
        required
        source="title"
        header="internalMessages.title"
        // ContentComponent={TextArea}
        // rows={2}
        placeholder="internalMessages.title"
        rules={[
          {
            max: 500,
            message: i18next.t('input.title.validateMsg.maxLength', {
              max: 500,
            }),
          },
        ]}
      />
      <RestInputItem
        // required
        source="content"
        header="internalMessages.content"
        ContentComponent={TextArea}
        rows={6}
        placeholder="internalMessages.content"
        rules={[
          {
            max: 500,
            message: i18next.t('input.content.validateMsg.maxLength', {
              max: 500,
            }),
          },
        ]}
      />
      {/* <RestTreeSelect
        required
        source="receivedUser"
        header="internalMessages.receivedUser"
        placeholder="internalMessages.receivedUser"
        treeData={treeData}
      /> */}
      {/* <RestSelect
        required
        source="activities[0].items"
        header="internalMessages.receivedUser"
        placeholder="internalMessages.receivedUser"
        resourceData={formatData()}
        valueProp="id"
        valuePropEdit="user.id"
        titleProp="name"
        resourceProp="user"
        filterOption={false}
        onSearch={onSearch}
        loading={loading}
        onEnter={handleScroll}
        onBlur={onHandleBlur}
        mode="multiple"
        ruleType="array"
        isBlurAfterSelected
        isFormatObjectValue
      >
        <UserInfo
          isLink={false}
          format={record =>
            `${record?.department?.name}, ${record?.positions?.[0]?.name}`}
        />
      </RestSelect> */}
      <RestTreeSelect
        required
        source="activities[0].items"
        header="documentaries.receivedUser"
        placeholder="documentaries.receivedUser"
        treeData={treeData}
        isShowChild
        showSearch
        ruleType="array"
        isFormatObjectValue
        valuePropEdit="user.id"
        treeDefaultExpandAll
        selectProps={{getPopupContainer}}
      />
      <RestMultiPhotos
        resource="internalMessages"
        source="medias"
        placeholder="internalMessages.medias"
        typeFile={2}
      />
    </div>
  );
};

InternalMessagesForm.propTypes = {};

export default InternalMessagesForm;
