import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { uniqBy } from 'lodash';
// import i18next from 'i18next';
// import { Input } from 'antd';
import PropTypes from 'prop-types';
// import RestInputItem from 'components/RestInput/RestInputItem';
// import RestSelect from 'components/RestInput/RestSelect';
import { getUsersTreeSelect, getUserFilter } from 'redux/config/selectors';
// import CRUDActions from 'redux/crudActions';
// import crudSelectors from 'redux/crudSelectors';
import { FORWARD_DATA } from 'configs/localData';
// import { checkRole } from 'utils/tools';
// import UserInfo from 'components/RestField/UserInfo';
import RestTreeSelect from 'components/RestInput/RestTreeSelect';
import UsersReceived from './UsersReceived';

const FormForward = ({ isForward }) => {
  // const usersData = useSelector(state => crudSelectors.users.getDataArr(state));
  // const activitiesData = useSelector(state => state.documentaries.currentData.activities);
  const profileData = useSelector(state => state.auth.data);

  const departmentSlug = isForward ? profileData?.department?.slug : null;

  const positionSlugs = isForward ? FORWARD_DATA?.find((data) => data.role === 'Truong_Phong')?.positionsAccept
    : FORWARD_DATA?.find((data) => data.role === 'Giam_Doc')?.positionsAccept;

  const treeData = useSelector((state) =>
    getUsersTreeSelect(state, positionSlugs, departmentSlug, true),
  );
  const usersData = useSelector((state) =>
    getUserFilter(state, positionSlugs, departmentSlug, true),
  );

  // const dispatch = useDispatch();
  // const loading = useSelector(state => state.users.loading);
  // const resourceFilter = useSelector(state =>
  //   crudSelectors.users.getFilters(state),
  // );
  // const [valueSearch, setValueSearch] = useState(null);
  const [usersSelected, setUsersSelected] = useState([]);
  // const [data, setData] = useState([])


  // const getUsers = ({valueSearch, departmentSlugs}) => {
  //   dispatch(
  //     CRUDActions.users.getAll(
  //       {
  //         pageSize: 15,
  //         pageNumber: 1,
  //         sortBy: 'createdAt',
  //         name: valueSearch,
  //         isActive: true,
  //         isSortByDesc: true,
  //         departmentSlugs,
  //         positionSlugs,
  //       },
  //       {
  //         isRefresh: true,
  //       },
  //     ),
  //   );
  //   // setData(usersData);
  // }
  // const onHandleBlur = () => {
  //   // console.log('blur')
  //   // setData([]);
  //   if (valueSearch !== null) {
  //     if(isForward) {
  //       getUsers({valueSearch: null, departmentSlugs: profileData?.department?.slug});
  //     } else {
  //       getUsers({valueSearch: null});
  //     }
  //     setValueSearch(null);
  //   }
  // };

  // useEffect(() => {
  //   if(isForward) {
  //     getUsers({valueSearch: null, departmentSlugs: profileData?.department?.slug});
  //   } else {
  //     getUsers({valueSearch: null});
  //   }
  //   // eslint-disable-next-line
  // }, []);
  // const handleSearch = value => {
  //   setValueSearch(value);
  //   if(isForward) {
  //     getUsers({valueSearch: value, departmentSlugs: profileData?.department?.slug});
  //   } else {
  //     getUsers({valueSearch: value});
  //   }
  // };

  // const onSearch = debounce(handleSearch, 600);

  // const handleScroll = () => {
  //   if (
  //     usersData.length < resourceFilter.count &&
  //     usersData.length > 0 &&
  //     !loading
  //   ) {
  //     dispatch(
  //       CRUDActions.users.getAll(
  //         {
  //           sortBy: 'createdAt',
  //           isSortByDesc: true,
  //           name: valueSearch,
  //           isActive: true,
  //           ...isForward && {departmentSlugs: profileData?.department?.slug},
  //           positionSlugs,
  //         },
  //         {
  //           isRefresh: false,
  //         },
  //       ),
  //     );
  //     // setData(formatData());
  //   }
      
  // };

  // const checkAssignedUser = (userItem) => {
  //   if (!isArray(activitiesData)) return true;
  //   let check = true;
  //   activitiesData.forEach(activity => {
  //     if (isArray(activity.items)) {
  //       if(activity.items.find(item => item.user?.id === userItem.id)) {
  //         check = false;
  //       }
  //     }
  //   });
  //   return check;
  //   // return true;
  //   // const data = activitiesData?.[0]?.children?.[0]?.children;
  //   // if (!isArray(data)) return true;
  //   // }), 'nhinhi')
  //   // if (
  //   //   type !== 'forward' &&
  //   //   data?.find((item) => item?.itemActivity?.user?.id === userItem?.id)
  //   // )
  //   //   return false;

  //   // if (
  //   //   data?.find((item) =>
  //   //     item?.children?.find(
  //   //       (child) => child?.itemActivity?.user?.id === userItem?.id,
  //   //     ),
  //   //   )
  //   // )
  //   //   return false;
  //   // return true;
  // }

  // const formatData = () => {
  //   return usersData?.filter(
  //     (item) =>
  //       item.id !== profileData.id &&
  //       // (isForward
  //       //   ? item?.department?.id === profileData?.department?.slug
  //       //   : true) &&
  //       // checkRole(positionSlugs, item?.positions?.map((a) => a.slug)) &&
  //       (isForward ? checkAssignedUser(item, 'forward') : checkAssignedUser(item, 'execute')),
  //   );
  // };

  const handleOnchange = values => {
    // refSelect.current.blur();
    // console.log(values, 'nhihihi');
    // setData([]);
    const users = values?.map(value =>
      usersData?.find(item => item?.id === value),
    );
    
    const selectedData =
    usersSelected &&
    usersSelected?.filter(item => values.indexOf(item?.id) !== -1);

    setUsersSelected(uniqBy(selectedData.concat(users), 'id'));
  };

  return (
    <div>
      <RestTreeSelect
        required
        source="items"
        header="documentaries.receivedUser"
        placeholder="documentaries.receivedUser"
        treeData={treeData}
        isShowChild
        showSearch
        onChange={handleOnchange}
        ruleType="array"
        treeDefaultExpandAll
      />
      {/* <RestSelect
        required
        source="items"
        header="documentaries.receivedUser"
        placeholder="documentaries.receivedUser"
        resourceData={formatData()}
        valueProp="id"
        isValueObject
        titleProp="name"
        filterOption={false}
        onSearch={onSearch}
        loading={loading}
        onEnter={handleScroll}
        mode="multiple"
        ruleType="array"
        onBlur={onHandleBlur}
        onChange={handleOnchange}
        isBlurAfterSelected
      >
        <UserInfo
          isLink={false}
          format={record =>
            `${record?.department?.name}, ${record?.positions?.[0]?.name}`}
        />
      </RestSelect> */}
      {/* <RestInputItem
        // required
        source="notes"
        header="documentaries.opinion"
        ContentComponent={Input.TextArea}
        rows={4}
        placeholder="documentaries.opinion"
        rules={[
          {
            max: 1000,
            message: i18next.t('input.content.validateMsg.maxLength', {
              max: 1000,
            }),
          },
        ]}
      /> */}
      <UsersReceived usersData={usersSelected} />

      {/* <div className="list-users-received">
        <div className="label-title">{i18next.t("documentaries.listUsersReceived")}</div>
        <div className="content-list">
        <Row className="header-list" gutter={10}>
          <Col span={6}>
              {i18next.t("")}
          </Col>
        </Row>
        <Row gutter={10}>
            <Col span={6}>
              Name
            </Col>
          </Row>
        </div>
      </div> */}
    </div>
  );
};

FormForward.propTypes = {
  isForward: PropTypes.bool,
  // activitiesData: PropTypes.array,
};

export default FormForward;
