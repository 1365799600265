import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Select, Spin } from 'antd';
import { Waypoint } from 'react-waypoint';
import { map, isObject, uniqBy } from 'lodash';
import FormItem from '../FormItem';
import {
  getRecordData,
  onSearch as onChangeSearch,
} from '../../../utils/tools';
import { SelectWrapper } from './style';

const { Option } = Select;
class FormSelect extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  onSelectOption = (inputValue, option) => {
    if (
      onChangeSearch(
        isObject(option.props.children)
          ? getRecordData(
              option.props.children.props && option.props.children.props.record,
              this.props.searchKey,
            )
          : option.props.children,
        inputValue,
      )
    ) {
      return option.props.value;
    }
    return null;
  };

  render() {
    const {
      allowClear,
      header,
      required,
      placeholder,
      defaultValue,
      disabled,
      resourceData,
      valueProp,
      titleProp,
      children,
      onSearch,
      // onChange,
      format,
      className,
      loading,
      selectProps,
      formatText,
      onEnter,
      isFilterOption,
      // onBlur,
      isShowSearch,
      defaultResource,
      isBlurAfterSelected,
      // isValueObject,
    } = this.props;

    const {onChange, onBlur, ...restProps} = this.props;

    const handleOnChange = (values) => {
      isBlurAfterSelected && this.myRef.current && this.myRef.current.blur()
      onChange(values);
    }
    return (
      <FormItem
        ruleType={Array.isArray(defaultValue) ? 'array' : 'string'}
        {...restProps}
        placeholder={i18n.t(placeholder)}
        header={i18n.t(header)}
        required={required}
        defaultValue={defaultValue}
      >
        <SelectWrapper
          disabled={disabled}
          placeholder={i18n.t(placeholder)}
          filterOption={isFilterOption ? this.onSelectOption : false}
          showSearch={isShowSearch}
          allowClear={allowClear}
          className={className}
          onSearch={value => onSearch(value)}
          onBlur={value => onBlur(value)}
          onChange={handleOnChange}
          ref={this.myRef}
          showArrow
          {...selectProps}
        >
          {map(format ? format(uniqBy([...resourceData, ...defaultResource], valueProp)) : uniqBy([...resourceData, ...defaultResource], valueProp), data => {
            return children ? (
              <Option
                key={valueProp ? getRecordData(data, valueProp) : data}
                // value={isValueObject ? data : getRecordData(data, valueProp)}
                value={valueProp ? getRecordData(data, valueProp) : data}
              >
                {React.cloneElement(children, {
                  key: valueProp ? getRecordData(data, valueProp) : data,
                  record: data,
                  valueProp,
                  titleProp,
                })}
              </Option>
            ) : (
              <Option
                key={valueProp ? getRecordData(data, valueProp) : data}
                // value={isValueObject ? data : getRecordData(data, valueProp)}
                value={valueProp ? getRecordData(data, valueProp) : data}
              >
                {formatText(
                  titleProp ? getRecordData(data, titleProp) : data,
                  data,
                )}
              </Option>
            );
          })}
          <Option key="loading" disabled value="loadingTracking">
            <Waypoint onEnter={onEnter} />
            {loading && (
            <div className="loading">
              <Spin />
            </div>
            )}
          </Option>
        </SelectWrapper>
      </FormItem>
    );
  }
}

FormSelect.propTypes = {
  allowClear: PropTypes.bool,
  source: PropTypes.string,
  header: PropTypes.any,
  required: PropTypes.bool,
  requiredMessage: PropTypes.node,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  form: PropTypes.object,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  resourceData: PropTypes.any,
  valueProp: PropTypes.string,
  titleProp: PropTypes.string,
  children: PropTypes.node,
  rules: PropTypes.array,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  format: PropTypes.func,
  searchKey: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  selectProps: PropTypes.object,
  formatText: PropTypes.func,
  record: PropTypes.object,
  onEnter: PropTypes.func,
  isFilterOption: PropTypes.bool,
  onBlur: PropTypes.func,
  isShowSearch: PropTypes.bool,
  isValueObject: PropTypes.bool,
  defaultResource: PropTypes.array,
  isBlurAfterSelected: PropTypes.bool,
};

FormSelect.defaultProps = {
  required: false,
  requiredMessage: 'error.required',
  rules: [],
  placeholder: 'placeholder.undefined',
  onChange: () => {},
  onSearch: () => {},
  onBlur: () => {},
  formatText: data => data,
  selectProps: {},
  allowClear: true,
  isFilterOption: true,
  isShowSearch: true,
  isBlurAfterSelected: false,
  // isValueObject: false,
};

export default FormSelect;
