import styled from 'styled-components';

const ButtonGroupWrapper = styled.div`
  .ant-btn {
      ${'' /* height: 30px !important; */}
      &:hover {
        transform: scale(1.1, 1.1);
      }
      &:focus {
        transform: scale(1.1, 1.1);
      }
      .anticon {
        font-size: 16px;
        color: currentColor;
      }
    }
    .save-button{
      margin-left: 15px;
    }
    .cancel-button {
      background: ${({theme}) => theme.background.cancelButton};
      border: none;
    }
`;

export default ButtonGroupWrapper;
