import { get, post, patch, del } from './utils';

export async function getTrackingInternalApi(id) {
  return get(`/internaltasks/${id}/tracking`);
}

export async function trackingInternalApi(id) {
  return post(`/internaltasks/${id}/tracking`);
}

export async function commentInternalApi(id, data) {
  return post(`/internaltasks/${id}/note`, data);
}

export async function editCommentInternalApi(id, idComment, data) {
  return patch(`/internaltasks/${id}/note/${idComment}`, data);
}

export async function delCommentInternalApi(id, idComment) {
  return del(`/internaltasks/${id}/note/${idComment}`);
}

export async function getCommentsInternalApi(id) {
  return get(`/internaltasks/${id}/note`);
}

export async function commentExternalApi(id, data) {
  return post(`/externaltasks/${id}/note`, data);
}

export async function editCommentExternalApi(id, idComment, data) {
  return patch(`/externaltasks/${id}/note/${idComment}`, data);
}

export async function delCommentExternalApi(id, idComment) {
  return del(`/externaltasks/${id}/note/${idComment}`);
}

export async function getCommentsExternalApi(id) {
  return get(`/externaltasks/${id}/note`);
}

export async function getTrackingExternalApi(id) {
  return get(`/externaltasks/${id}/tracking`);
}

export async function trackingExternalApi(id) {
  return post(`/externaltasks/${id}/tracking`);
}

export async function changeDueDateExternalApi(id, data) {
  return patch(`/externaltasks/${id}/dueday`, data);
}

export async function addActivityExternalApi(id, data) {
  return post(`/externaltasks/${id}/activities`, data);
}

export async function completeExternalApi(id, activityId) {
  return patch(`/externaltasks/${id}/activities/${activityId}/complete`);
}

export async function archiveExternalApi(id, data) {
  return patch(`/externaltasks/${id}/archive`, data);
}

export async function knowExternalApi(id) {
  return patch(`/externaltasks/${id}/know`);
}

export async function editNoteItemActivityApi(id, itemId, data) {
  return patch(`/externaltasks/${id}/noteitem/${itemId}`, data);
}
