import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Popconfirm, Tag, Avatar } from 'antd';
import RestAvatarInput from 'components/RestInput/RestAvatarInput';
import i18n from 'i18next';
import { ACTIVE_TYPES } from 'configs/localData';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { actions } from 'redux/users/slice';
import { useDispatch, useSelector } from 'react-redux';
import CRUDActions from 'redux/crudActions';
// import { isEmpty } from 'lodash';
import { checkRole } from 'utils/tools';
import ProfileDetailStyles from './styles';

const ProfileDetail = ({ isProfile, handleClick }) => {
  const dispatch = useDispatch();
  const handleSubmitStatus = record => {
    dispatch(
      actions.disableUser({ id: record?.id, isActive: !record?.isActive }),
    );
  };
  const dataProfile = useSelector(state => state.auth.data);
  const permissionData = useSelector(state => state.auth.permissionData);

  return (
    <ProfileDetailStyles>
      <RestInputContext.Consumer>
        {({ record }) => (
          <div className="profile-info">
            <div className="avatar-section">
              {isProfile ||
              checkRole(['AllowAll', 'UserEdit'], permissionData) ? (
                <RestAvatarInput
                  style={{
                    // marginBottom: 10,
                    width: 150,
                    height: 150,
                    borderRadius: '50%',
                  }}
                  className="avatar-section"
                  defaultIcon="picture"
                  defaultText="picture"
                  cropDimension={{ width: 300, height: 300 }}
                  hasCrop
                  source="avatarUrl"
                  typeFile={0}
                  onUploadSuccess={(source, url) =>
                    dispatch(
                      actions.uploadAvatar({
                        id: record?.id,
                        avatarUrl: url,
                        ...(!isProfile && {
                          successAction: (response) =>
                            CRUDActions.users.editSuccess(response),
                        }),
                        ...(!isProfile && {
                          failureAction: (response) =>
                            CRUDActions.users.editFailure(response),
                        }),
                      }),
                    )}
                />
              ) : (
                <Avatar src={record?.avatarUrl} icon="user" size={150} />
              )}

              <div className="account-info">
                {/* <div className="username">{record?.username || 'username'}</div> */}
                {/* <div className="role">
                  {!isEmpty(record?.roles) &&
                    record.roles?.map((item, index) =>
                      index === record.roles.length - 1 ? (
                        <span key={String(index)}>{item.roleName}</span>
                      ) : (
                        <span key={String(index)}>{`${item.roleName}, `}</span>
                      ),
                    )}
                  {/* {i18n.t(ROLE.find(role => record?.role === role.value)?.text)} */}
                {/* </div>  */}
                {!isProfile &&
                  dataProfile?.id !== record?.id &&
                  record?.isActive !== undefined &&
                  checkRole(['AllowAll', 'UserEdit'], permissionData) && (
                    <Popconfirm
                      placement="topRight"
                      title={
                        record?.isActive
                          ? i18n.t('profile.confirmLockUser')
                          : i18n.t('profile.confirmUnlockUser')
                      }
                      onConfirm={() => handleSubmitStatus(record)}
                      okText={i18n.t('button.ok')}
                      cancelText={i18n.t('button.cancel')}
                    >
                      <Button type="primary" className="lock-user-button">
                        {record?.isActive
                          ? i18n.t('button.lockUser')
                          : i18n.t('button.unlockUser')}
                      </Button>
                    </Popconfirm>
                  )}
              </div>
            </div>
            <div className="info-section">
              <div className="header-profile">
                <div className="profile-info-title">
                  {i18n.t('profile.profileInfo')}
                </div>
                {(isProfile ||
                  checkRole(['AllowAll', 'UserEdit'], permissionData)) && (
                  <Button
                    onClick={handleClick}
                    icon="edit"
                    className="button-edit"
                  />
                )}
              </div>
              <div className="name-section">
                <span>{record?.name || i18n.t('error.waitingUpdate')}</span>

                {record &&
                  record.isActive !== undefined &&
                  !record.isActive && (
                    <Tag
                      color={
                        ACTIVE_TYPES.find(
                          (item) => item.isActive === record?.isActive,
                        )?.color
                      }
                    >
                      {i18n.t(
                        ACTIVE_TYPES.find(
                          (item) => item.isActive === record?.isActive,
                        )?.text,
                      )}
                    </Tag>
                  )}
                {/* {`${record?.firstName || ''} ${record?.lastName || ''}`} */}
                {/* {record?.gender && (
              <Icon
                type={
                  GENDERS.find(gender => gender.value === record?.gender)?.icon
                }
                style={{
                  marginLeft: '10px',
                  color: GENDERS.find(gender => gender.value === record?.gender)
                    ?.color,
                }}
              />
            )} */}
              </div>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <div className="info-item-section">
                    <span className="label-item">
                      {`${i18n.t('profile.username')}:`}
                    </span>
                    <span>
                      {record?.userName || i18n.t('error.waitingUpdate')}
                    </span>
                  </div>
                </Col>
                <Col md={12} xs={24}>
                  <div className="info-item-section">
                    <span className="label-item">
                      {`${i18n.t('profile.email')}:`}
                    </span>
                    <span>
                      {record?.email || i18n.t('error.waitingUpdate')}
                    </span>
                  </div>
                </Col>
                <Col md={12} xs={24}>
                  <div className="info-item-section">
                    <span className="label-item">
                      {`${i18n.t('profile.mobilePhone')}:`}
                    </span>
                    <span>
                      {record?.phoneNumber || i18n.t('error.waitingUpdate')}
                    </span>
                  </div>
                </Col>
                <Col md={12} xs={24}>
                  <div className="info-item-section">
                    <span className="label-item">
                      {`${i18n.t('profile.deskPhone')}:`}
                    </span>
                    <span>
                      {record?.telephoneNumber || i18n.t('error.waitingUpdate')}
                    </span>
                  </div>
                </Col>
                <Col md={12} xs={24}>
                  <div className="info-item-section">
                    <span className="label-item">
                      {`${i18n.t('profile.department')}:`}
                    </span>
                    <span>
                      {record?.department?.name ||
                        i18n.t('error.waitingUpdate')}
                    </span>
                  </div>
                </Col>
                <Col md={12} xs={24}>
                  <div className="info-item-section">
                    <span className="label-item">
                      {`${i18n.t('profile.position')}:`}
                    </span>
                    <span>
                      {record?.positions
                        ? record.positions.map((item, index) =>
                            index === record.positions.length - 1 ? (
                              <span key={String(index)}>{item.name}</span>
                            ) : (
                              <span key={String(index)}>
                                {`${item.name}, `}
                              </span>
                            ),
                          )
                        : i18n.t('error.waitingUpdate')}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </RestInputContext.Consumer>
    </ProfileDetailStyles>
  );
};

ProfileDetail.propTypes = {
  isProfile: PropTypes.bool,
  handleClick: PropTypes.func,
};

ProfileDetail.defaultProps = {
  isProfile: false,
  handleClick: () => {},
};

export default ProfileDetail;
