import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker } from 'antd';
import i18next from 'i18next';
// import FormItemUI from '../FormItem';
import { DateTimePickerWrapper } from './styles';

const FormDatePicker = ({ isShowTime, formatDate, allowClear, ...props }) => {
  const {
    required,
    defaultValue,
    initialValue,
    formOptions,
    source,
    form,
    disabled,
    placeholder,
  } = props;
  // const values =
  //   (defaultValue || initialValue) && !form.getFieldValue(source)
  //     ? moment(defaultValue || initialValue)
  //     : form.getFieldValue(source);
  // const getValueFromEvent = value => {
  //   const e = moment.isMoment(value) ? value?.toISOString() : null;
  //   props.formOptions &&
  //     props.formOptions.getValueFromEvent &&
  //     props.formOptions.getValueFromEvent(e);
  //   return e;
  // };
  const config = {
    rules: [{ type: 'object' }, { required }],
    initialValue:
      defaultValue || initialValue
        ? moment(defaultValue || initialValue)
        : undefined,
    // normalize: value => value && moment(value),
    // getValueFromEvent,
    ...formOptions,
  };
  return (
    <DateTimePickerWrapper className="date-picker-wrapper">
      {/* <FormItemUI
        {...props}
        formOptions={{
          // getValueFromEvent,
          normalize: value => value && moment(value),
        }}
        ruleType="object"
        defaultValue={
          defaultValue || initialValue
            ? moment(defaultValue || initialValue)
            : undefined
        }
        className="title"
        required={required}
      > */}
      {/* <div> */}
      {/* {isShowTime && (
            <TimePicker
              disabled={disabled}
              onChange={newDate => {
                form.setFieldsValue({
                  [source]: newDate,
                });
                formOptions.getValueFromEvent &&
                  formOptions.getValueFromEvent(newDate?.toISOString());
              }}
              style={{ marginBottom: 10 }}
              value={values}
              format="HH:mm"
              allowClear={false}
              className="viewTimePicker"
            />
          )} */}
      {form.getFieldDecorator(source, config)(
        <DatePicker
          allowClear={allowClear}
          disabled={disabled}
          format={formatDate}
          className="viewDatePicker"
          {...(placeholder && { placeholder: i18next.t(placeholder) })}
        />,
      )}
      {/* </div> */}
      {/* </FormItemUI> */}
    </DateTimePickerWrapper>
  );
};

FormDatePicker.propTypes = {
  source: PropTypes.string,
  header: PropTypes.any,
  required: PropTypes.bool,
  requiredMessage: PropTypes.node,
  icon: PropTypes.string,
  form: PropTypes.object,
  defaultValue: PropTypes.any,
  initialValue: PropTypes.object,
  formOptions: PropTypes.object,
  disabled: PropTypes.bool,
  isShowTime: PropTypes.bool,
  formatDate: PropTypes.string,
  placeholder: PropTypes.string,
  allowClear: PropTypes.bool,
};

FormDatePicker.defaultProps = {
  isShowTime: true,
  formOptions: {},
  formatDate: 'DD-MM-YYYY',
  allowClear: true,
};

export default FormDatePicker;
