import styled from 'styled-components';

const ListStyles = styled.div`
  ${'' /* @media only screen and (min-width: 1150px) {
    .col-filter-content > .ant-row > .ant-col {
      width: 20% !important;
    }
  } */}
  @media only screen and (max-width: 1199px) and (min-width: 993px) {
    .row-filter-container {
      display: flex;
      flex-direction: column;
      .col-filter-content {
        width: 100%;
      }
      .col-actions {
        width: 33.33%;
      }
    }
  }
`;

export default ListStyles;
