import React from 'react';
import Edit from '../../rest/Edit';
import Form from '../components/Form';

const DocumentariesEdit = props => (
  <Edit
    {...props}
    resource="documentaries"
    defaultOptions={{ customApiResource: 'externaltasks' }}
    isClearCurrent={false}
    formatOnSubmit={({ activities, medias, fromNumber, sourceNumber, publisher, title, content, ...data }) => {
      return {
        ...data,
        fromNumber: fromNumber && fromNumber.trim(),
        sourceNumber: sourceNumber && sourceNumber.trim(),
        publisher: publisher && publisher.trim(),
        title: title && title.trim(),
        content: content && content.trim(),
        ...(activities[0]?.items[0]?.user?.id && {
          activity: {
            items: [{ userId: activities[0]?.items[0]?.user?.id, role: 0 }],
          },
        }),
        ...(medias && { mediaIds: medias.map(item => item.id || item) }),
        taskType: 1,
      };
    }}
  >
    <Form isEdit />
  </Edit>
);

DocumentariesEdit.propTypes = {};

export default DocumentariesEdit;
