import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth/slice';
import modal from './modal/slice';
// import here
import documentaryFiles from './documentaryFiles/slice';
import documentBoxes from './documentBoxes/slice';
import documentaries from './documentaries/slice';
import internalMessages from './internalMessages/slice';
import announcements from './announcements/slice';
import users from './users/slice';
import notifications from './notifications/slice';
import reference from './referenceData/slice';
import config from './config/slice';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    modal,
    config,
    reference,
    // add reducer here
    documentaryFiles,
    documentBoxes,
    documentaries,
    internalMessages,
    announcements,
    users,
    notifications,
  });
