import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, Icon, notification } from 'antd';
import { xor, takeRight, isEmpty, isPlainObject } from 'lodash';
import i18next from 'i18next';
// import { fetch } from 'whatwg-fetch';
import { loginFail } from 'redux/auth/slice';
import { useDispatch } from 'react-redux';
import CRUDActions from 'redux/crudActions';
import FormItem from '../FormItem';
import { getRecordData } from '../../../utils/tools';
import { getUrl, uploadMedia } from '../../../api/uploadMedia';
import { FormMultiUploadWrapper, ModalViewStyles } from './styles';
import UploadImage from '../../../assets/images/upload.png';
import UploadImageItem from './UploadImageItem';

const { Dragger } = Upload;

export const RestUpload = ({
  defaultSourceKey,
  typeFile,
  handleSubmit,
  required,
  setFileName,
  ...props
}) => {
  const [disabled, setDisabled] = useState(false);
  // const [defaultImage, setDefaultImage] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log(props.record, 'props.record');
    if (!isEmpty(props.record) && isEmpty(fileList)) {
      setFileList(makeFileList(props.record[props.source]));
    }
    // eslint-disable-next-line
  }, [props.record?.[props.source]]);
  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = file => {
    setPreviewVisible(true);
    setPreviewImage(file.url || file.thumbUrl);
  };

  const deleteImage = item => {
    const results = xor(fileList, [item]);
    setFileList(results);
    // console.log(item, 'item');
    // fetch(`${process.env.REACT_APP_SERVER_URL}/api/medias/${item?.response?.id || item?.id}`, {
    //   method: 'DELETE',
    //   // body: JSON.stringify({ key: item.response.id || item.id }),
    // });
    results.length === 0 && setDisabled(false);
    isDisableButtonSubmit(results);
    props.form &&
      props.form.setFieldsValue({
        [props.source]: results.map(e => e.id || e.response.id),
      });
  };

  // const onMouseEnter = () => {
  //   setDisabled(true);
  // };

  // const onMouseLeave = () => {
  //   setDisabled(false);
  // };

  // const onSetDefault = useCallback(
  //   item => {
  //     // defaultSourceKey && setDefaultImage(item.url || item.response);
  //     defaultSourceKey &&
  //       props.form &&
  //       props.form.setFieldsValue({
  //         [defaultSourceKey]: item.url || item.response,
  //       });
  //   },
  //   [defaultSourceKey, props.form],
  // );

  // const handleChange = e => {setFileList(e.fileList)};

  const uploadButton = (
    // fileList && fileList.length === 0 ? (
    <div className="uploadArea">
      <Icon type="upload" />
      <div className="ant-upload-text">
        <span>{i18next.t('upload.text')}</span>
        <span className="select-media-text">{i18next.t('upload.selectMediaText')}</span>
      </div>
    </div>
  );
  // ) : null;

  useEffect(
    () => {
      setFileList(
        props.defaultValue || getRecordData(props.record, props.source)
          ? makeFileList(
              props.defaultValue || getRecordData(props.record, props.source),
            )
          : [],
      );
      // defaultSourceKey &&
      //   onSetDefault({ url: getRecordData(props.record, defaultSourceKey) });
      setPreviewImage(
        makeFileList(
          props.defaultValue || getRecordData(props.record, props.source),
        ),
      );
    },
    // eslint-disable-next-line
    [
      // defaultSourceKey,
      // onSetDefault,
      // props.defaultValue,
      // props.record,
      // props.record.id,
      // props.source,
    ],
  );

  const customRequest = async ({ onSuccess, onError, file }) => {
    try {
      // console.log('customRequest')
      const responseS3 = await getUrl({
        fileName: file.name,
        typeFile,
        contentType: file.type,
      });

      const response = await uploadMedia(responseS3.data?.presignUrl, file);
      if (response?.status === 200) {
        // console.log(response, 'nhihi')
        onSuccess(responseS3.data, file);
        // onError(null, {status: 'done'})
      } else {
        onError(null, {status: 'done'})
      }
    } catch (error) {
      onError(null, {status: 'done'})
      if (error.code === 401) {
        // yield put(logout());
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('name');
        localStorage.removeItem('id');
        dispatch(loginFail(error));
        notification.error({
          message: i18next.t('error.title'),
          description: i18next.t('error.error401'),
          duration: 2,
          position: 'tr',
        });
      } else
        notification.error({
          message: i18next.t('error.title'),
          description: i18next.t('error.description'),
          position: 'tr',
          duration: 2,
          // autoDismiss: 15,
        });
    }
  };

  const setFieldAfterUploadChange = (results) => {
    const formattedData = results?.map(
      data => (data && data.response?.id) || data.id,
    );
    setPreviewImage(formattedData);
    props.onChange && props.onChange(formattedData);
    props.form &&
      props.form.setFieldsValue({
        [props.source]: props.multiple ? formattedData : formattedData[0],
      });
  }

  const onChangeUpload = e => {
    // console.log(e, 'e')
    const restFileList = e.fileList.filter(file => !!file.status);
    // if (fileList.length === 0) {
    //   onSetDefault(restFileList[0]);
    // }

    isDisableButtonSubmit(restFileList);
    if(!props.multiple) {
      setFileName(restFileList[restFileList.length - 1]?.name);
    }
    if(!props.multiple && restFileList?.length > 1) {
      const results = takeRight(restFileList);
      setFileList(results);
      setFieldAfterUploadChange(results);
    }
    else {
      // console.log(restFileList, 'restFileList')
      setFileList(restFileList);
      setFieldAfterUploadChange(restFileList);
    }
    
    // if (defaultSourceKey && formattedData.indexOf(defaultImage) === -1) {
    //   onSetDefault(restFileList[0]);
    // }
  };

  const isDisableButtonSubmit = (_fileList) => {
    let isDisabled =
      _fileList &&
      _fileList.length >= 0 &&
      _fileList?.filter((i) => {
        if (i.id !== undefined) return false;
        if(props.multiple && i.response) return i.status === 'error';
        return i.response === undefined;
      }).length > 0;
    if(!props.multiple && !isDisabled) isDisabled = _fileList[_fileList.length - 1]?.status === 'error';
    props.resource &&
      dispatch(
        CRUDActions[props.resource].setIsDisabledButtonSubmit(isDisabled),
      );
  };

  const handleBeforeUpload = (file) => {
    if (!file.type?.trim()) {
      notification.error({
        message: i18next.t('error.title'),
        description: i18next.t('error.fileInvalid'),
        position: 'tr',
        duration: 2,
        // autoDismiss: 15,
      });
      return false;
    }
    return true;
  }

  return (
    <FormMultiUploadWrapper className="multi-upload-wrapper">
      {props.form && defaultSourceKey && (
        <FormItem
          className="control-form-item"
          {...props}
          required={required}
          source={defaultSourceKey}
          defaultValue={
            props.defaultValue || getRecordData(props.record, defaultSourceKey)
          }
        />
        /* <input style={{ display: 'none' }} /> */
        /* </FormItem> */
      )}

      {props.form && (
        <FormItem
          className="control-form-item"
          {...props}
          required={required}
          // ruleType="array"
          defaultValue={
            props.defaultValue || getRecordData(props.record, props.source)
          }
        />
        /* <input style={{ display: 'none' }} />
          </FormItem> */
      )}
      <Dragger
        customRequest={customRequest}
        // action={`${process.env.REACT_APP_UPLOAD_PHOTO_URL}`}
        // headers={{
        //   'x-requested-with': undefined,
        //   Authorization: `Client-ID ${process.env.REACT_APP_UPLOAD_PHOTO_KEY}`,
        // }}
        // accept="image/*, application/*, video/*, text/*, audio/*, .xls, .doc, .ppt"
        multiple={props.multiple}
        disabled={props.disabled || disabled}
        listType="picture-card"
        fileList={fileList}
        showUploadList={false}
        onPreview={handlePreview}
        onChange={onChangeUpload}
        beforeUpload={handleBeforeUpload}
      >
        <div className="selectedImage">
          <div className="list-file-upload">
            {fileList?.map((img, index) => (
              <UploadImageItem
                key={String(index)}
                defaultSourceKey={defaultSourceKey}
                // onSetDefault={onSetDefault}
                // onMouseEnter={onMouseEnter}
                // onMouseLeave={onMouseLeave}
                deleteImage={deleteImage}
                onPreviewUI={handlePreview}
                item={img}
                alt="upload.png"
              />
            ))}
          </div>

          {uploadButton}
          <div className="overlayImage">
            <img src={UploadImage} alt="upload.png" />
          </div>
        </div>
      </Dragger>
      <ModalViewStyles
        visible={previewVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </ModalViewStyles>
    </FormMultiUploadWrapper>
  );
};

// const makeFileList = values =>
//   Array.isArray(values)
//     ? values.map(value =>
//         value && value.urlFile
//           ? value
//           : {
//               uid: value,
//               fileName: value,
//               status: 'done',
//               urlFile: value,
//             },
//       )
//     : [];
const makeObjFile = (value) => {
  if (isPlainObject(value)) {
    return {
      uid: value.id,
      fileName: value.fileName,
      status: 'done',
      urlFile: value.urlFile || value.url,
      id: value.id,
      ...value,
    };
  }
  return {
    uid: value,
    fileName: value,
    status: 'done',
    urlFile: value,
    id: value,
  };
};

const makeFileList = (values) => {
  if (isEmpty(values)) return [];

  if (Array.isArray(values))
    return values.map((value) =>
      makeObjFile(value),
    );

  return [makeObjFile(values)];
};

RestUpload.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  defaultValue: PropTypes.any,
  multiple: PropTypes.bool,
  form: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultSourceKey: PropTypes.string,
  typeFile: PropTypes.number,
  handleSubmit: PropTypes.func,
  required: PropTypes.bool,
  resource: PropTypes.string,
  setFileName: PropTypes.func,
};

RestUpload.defaultProps = {
  multiple: true,
  typeFile: 1,
  required: false,
  setFileName: () => null,
};

export default RestUpload;
