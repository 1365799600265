import React from 'react';
import RestShow from '../../rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const UsersShow = props => (
  <RestShow {...props} hasEdit resource="users">
    <RestFieldItem source="name" header="users.name" />
    <RestFieldItem source="userType" header="users.userType" />
    <RestFieldItem source="isActive" header="users.isActive" />
    <RestFieldItem source="positions" header="users.positions" />
    <RestFieldItem source="department" header="users.department" />
    <RestFieldItem source="email" header="users.email" />
    <RestFieldItem source="userName" header="users.userName" />
    <RestFieldItem source="createdAt" header="users.createdAt" />
    <RestFieldItem source="avatarUrl" header="users.avatarUrl" />
  </RestShow>
);

export default UsersShow;
