import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { checkRole } from 'utils/tools';
import Drawer from '../../components/common/Drawer';
import ModalCustom from '../../components/common/Modal';
import Announcements from '../../pages/Announcements';
import InternalMessages from '../../pages/InternalMessages';
import Documentaries from '../../pages/Documentaries';
import DocumentBoxes from '../../pages/DocumentBoxes';
import DocumentaryFiles from '../../pages/DocumentaryFiles';
import Users from '../../pages/Users';

const modalRoutes = [

  {
    path: '/documentaryFiles',
    routes: [
      {
        path: '/create',
        component: DocumentaryFiles.Create,
      },
      {
        path: '/edit',
        component: DocumentaryFiles.Edit,
      },
    ],
  },

  {
    path: '/documentBoxes',
    routes: [
      {
        path: '/create',
        component: DocumentBoxes.Create,
        roles: ['AllowAll', 'FolderAddNew'],
      },
      {
        path: '/edit',
        component: DocumentBoxes.Edit,
        isShowModal: true,
        modalOptions: { width: 500 },
        roles: ['AllowAll', 'FolderEdit'],
      },
    ],
  },

  {
    path: '/boxFiles',
    routes: [
      {
        path: '/create',
        component: DocumentBoxes.FileCreate,
        roles: ['AllowAll', 'FileInFolderAddNew'],
      },
      {
        path: '/edit',
        component: DocumentBoxes.FileEdit,
        isShowModal: true,
        modalOptions: {width: 500},
        roles: ['AllowAll', 'FileFolderEdit'],
      },
    ],
  },
  
  {
    path: '/documentaries',
    routes: [
      {
        path: '/create',
        component: Documentaries.Create,
      },
      {
        path: '/edit',
        component: Documentaries.Edit,
      },
      {
        path: '/show',
        component: Documentaries.Show,
        isShowModal: true,
      },
    ],
  },
  {
    path: '/internalMessages',
    routes: [
      {
        path: '/create',
        component: InternalMessages.Create,
      },
      {
        path: '/edit',
        component: InternalMessages.Edit,
      },
      {
        path: '/show',
        component: InternalMessages.Show,
      },
    ],
  },
  {
    path: '/announcements',
    routes: [
      {
        path: '/create',
        component: Announcements.Create,
        roles: ['AllowAll', 'AnoucementAddNew'],
      },
      {
        path: '/edit',
        component: Announcements.Edit,
        // roles: ['AllowAll', 'AnoucementEdit'],
      },
      {
        path: '/show',
        component: Announcements.Show,
        isShowModal: true,
        // roles: [
        //   'AllowAll',
        //   'AnoucementRead',
        //   'AnoucementEdit',
        //   'AnoucementRemove',
        // ],
      },
    ],
  },

  {
    path: '/users',
    routes: [
      {
        path: '/create',
        component: Users.Create,
        roles: ['AllowAll', 'UserAddNew'],
      },
      {
        path: '/edit',
        component: Users.Edit,
        roles: ['AllowAll', 'UserRead', 'UserEdit'],
      },
    ],
  },
  // {
  //   path: '/pharmacies',
  //   routes: [
  //     {
  //       path: '/create',
  //       component: Pharmacies.Create,
  //     },
  //     {
  //       path: '/edit',
  //       component: Pharmacies.Edit,
  //     },
  //   ],
  // },
];

const getModalRoute = currentModal => {
  const modalRoute =
    currentModal &&
    modalRoutes.find(route => currentModal.search(route.path) > -1);
  if (modalRoute) {
    return modalRoute.routes.find(
      route => currentModal.indexOf(route.path) > -1,
    );
  }
  return modalRoute;
};

class ModalRoute extends Component {
  componentDidMount() {
    const { location } = this.props;
    if (location.hash && location.hash !== '#') {
      const modelRoute = location.hash.replace('#', '/');
      this.modal = getModalRoute(modelRoute);
    }
  }

  closeModal = () => {
    const { replaceRoute, location } = this.props;
    replaceRoute(`${location.pathname}${location.search}`);
  };

  render() {
    const { location, permissionData } = this.props;
    const modelRoute = location.hash.replace('#', '/');
    this.modal = getModalRoute(modelRoute) || this.modal;

    const modalOptions =
      this.modal && this.modal.modalOptions ? this.modal.modalOptions : {};
    if (!checkRole(this.modal?.roles, permissionData)) return null;
    return this.modal?.isShowModal ? (
      <ModalCustom
        {...modalOptions}
        visible={!!(location.hash && location.hash !== '#')}
        footer={null}
        onCancel={this.closeModal}
        onClose={this.closeModal}
      >
        {this.modal && this.modal.component && (
          <this.modal.component
            showModal
            visibleModal={!!(location.hash && location.hash !== '#')}
            location={location}
          />
        )}
      </ModalCustom>
    ) : (
      <Drawer
        {...modalOptions}
        visible={!!(location.hash && location.hash !== '#')}
        footer={null}
        onCancel={this.closeModal}
        onClose={this.closeModal}
      >
        {this.modal && this.modal.component && (
          <this.modal.component
            showModal
            visibleModal={!!(location.hash && location.hash !== '#')}
            location={location}
          />
        )}
      </Drawer>
    );
  }
}

ModalRoute.propTypes = {
  location: PropTypes.object,
  currentModal: PropTypes.string,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  replaceRoute: PropTypes.func,
  permissionData: PropTypes.array,
};

const mapStateToProps = state => ({
  location: state.router.location,
  permissionData: state.auth.permissionData,
});

const mapDispatchToProps = dispatch => ({
  replaceRoute: data => dispatch(replace(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalRoute);
