import { apiWrapper } from 'utils/reduxUtils';
import { call, put, takeLatest } from 'redux-saga/effects';
import { trackingAnnouncementApi } from 'api/notifications';
import { actions as actionsNotify } from 'redux/notifications/slice';
import { makeCRUDSaga } from '../crudCreator/saga';
import { RESOURCE, actions } from './slice';

function* seenAnnouncementSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      trackingAnnouncementApi,
      payload.id,
    );
    if (response) {
      yield put(actions.seenAnnouncementSuccess(payload.id));
      yield put(actionsNotify.seenNotificationSuccess(payload.id));
    }
  } catch (error) {
    yield put(actions.seenAnnouncementFailure(error));
  }
}

export default [
  ...makeCRUDSaga(RESOURCE, [], actions),
  takeLatest([actions.seenAnnouncement.type], seenAnnouncementSaga),
];
