import { post, get, put, del, patch } from './utils';

export async function loginApi(params) {
  return post('/token', params);
}

export async function logoutApi() {
  return del(`/token`);
}

export async function getPermissionsApi() {
  return get('/auth/permission');
}

export async function getPermissionByIdApi(id) {
  return get(`/auth/permission/${id}`);
}

export async function getCurrentUserApi(id) {
  return get(`/users/${id}`);
}

export async function updateCurrentUserApi(id, data) {
  return put(`/users/${id}`, data);
}

export async function createInstallationApi(params) {
  return post('/installations', params);
}

export async function updateInstallationApi(id, params) {
  return put(`/installations/${id}`, params);
}

export async function deleteInstallationApi(id) {
  return del(`/installations/${id}`);
}

export async function forgotPasswordApi(data) {
  return post('/users/forgotpassword', data);
}

export async function resetPasswordApi(data) {
  return post('/auth/resetpassword', data);
}

export async function registerApi(data) {
  return post('/auth/register', data);
}

export async function registerWithTokenApi(data) {
  return post('/auth/registerByToken', data);
}

export async function disableUserApi(id, data) {
  return patch(`/users/${id}/status`, data );
}

export async function uploadAvatarApi(id, data) {
  return patch(`/users/${id}/avatar`, data );
}
