import styled from 'styled-components';

export default styled.div`
  .ant-row {
    ${'' /* display: flex;
    align-items: stretch; */}
  }
  .row-logout{
    margin-top: 15px;
    @media only screen and (min-width: 767px) {
      display: none;
    }
  }
`;
