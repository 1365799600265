import { fetch } from 'whatwg-fetch';
import { post, get } from './utils';

const CLIENT_ID = '50b73e2dc3f6fb5';

export async function getUrl(data) {
  return post('/medias', data);
  // return { uploadUrl: `${process.env.REACT_APP_SERVER_URL}/api/medias` };
}

export async function uploadMedia(url, data) {
  // const formData = new FormData();
  // formData.append('file', data);
  return fetch(url, {
    method: 'PUT',
    body: data,
    headers: {
      Accept: 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': data.type,
      // Origin: '',
    },
  });
  // .then(res => res.json());
}

export async function uploadMediaImgur(data) {
  return fetch('https://api.imgur.com/3/image', {
    method: 'POST',
    headers: {
      Authorization: `Client-ID ${CLIENT_ID}`,
    },
    body: data,
  }).then(res => res.json());
}

export async function getPrivateUrlApi(data) {
  return get(`/medias/${data?.id}/privateurl`, data);
}
