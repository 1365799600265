import React, { useEffect } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/documentaries/slice';
import { checkRole } from 'utils/tools';
// import SeenUsers from 'components/RestField/SeenUsers';
import DocumentaryDetailStyles from './styles';
import DocumentFlow from '../DocumentFlow';
import DocumentaryInfo from '../DocumentaryInfo';

const DocumentaryDetail = ({ record, activitiesData, idTask }) => {
  const profileData = useSelector((state) => state.auth.data);
  const rolesUser = useSelector((state) => state.auth.role);
  const dispatch = useDispatch();

  useEffect(() => {
    idTask && dispatch(actions.getCommentsExternal(idTask));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      record?.id &&
      profileData.id !== record?.createdUser?.id &&
      !checkRole(['Quan_Tri_Vien'], rolesUser)
    ) {
      dispatch(actions.seenExternal(record.id));
    }
    if (record?.id) {
      dispatch(actions.getTrackingExternal(record.id));
    }

    // eslint-disable-next-line
  }, [record?.id, profileData.id]);

  return (
    <DocumentaryDetailStyles>
      <Row gutter={20} className="row-info">
        <Col xl={12} lg={24} md={24} sm={24} xs={24} className="col-detail">
          <DocumentaryInfo record={record} idTask={idTask} />
        </Col>
        <Col
          xl={12}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className="col-flow-document"
        >
          <div className="detail-view">
            <div className="title-label">
              {`${i18next.t('documentaries.assignedUsersFlow')}: `}
            </div>
            <DocumentFlow activitiesData={activitiesData} />
          </div>
        </Col>
      </Row>
    </DocumentaryDetailStyles>
  );
};

DocumentaryDetail.propTypes = {
  record: PropTypes.object,
  activitiesData: PropTypes.array,
  idTask: PropTypes.string,
};

export default DocumentaryDetail;
