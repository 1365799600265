import React from 'react';
import { Form, Button, Input } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import AddCommentStyles from './styles';

const AddComment = ({ submitAction, idTask, form }) => {
  const dispatch = useDispatch();
  const { validateFields, resetFields, getFieldDecorator } = form;
  // const currentInternal = useSelector(
  //   state => state.internalMessages.currentData,
  // );
  // const loading = useSelector(state => state[resource].createComment);
  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        if (
          values?.comment !== null &&
          values?.comment !== undefined &&
          values?.comment.trim().length !== 0
        ) {
          dispatch(
            submitAction({
              id: idTask,
              note: values.comment.trim(),
            }),
          );
          resetFields();
        }
      }
    });
  };
  return (
    <AddCommentStyles>
      <Form.Item>
        {getFieldDecorator('comment', {
          rules: [
            {
              max: 1000,
              message: i18next.t('input.comment.validateMsg.maxLength', {
                max: 1000,
              }),
            },
          ],
        })(
          <Input.TextArea
            placeholder={i18next.t('input.comment.placeholder')}
          />,
        )}
      </Form.Item>
      <Button
        type="primary"
        onClick={handleSubmit}
        // loading={loading}
      >
        {i18next.t('button.post')}
      </Button>
    </AddCommentStyles>
  );
};

AddComment.propTypes = {
  submitAction: PropTypes.func,
  idTask: PropTypes.string,
  form: PropTypes.object,
  // resource: PropTypes.string,
};

AddComment.defaultProps = {
  // resource: 'internalMessages',
  submitAction: () => null,
};

export default Form.create()(AddComment);
