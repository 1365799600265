import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  transition: padding-left 0.3s ease 0.1s, padding-right 0.3s ease 0.1s,
    position 0 ease 0.3s;
  .extraAction {
    margin-bottom: 0.5em;
    margin-left: 15px;
    .ant-breadcrumb {
      display: flex;
      flex-wrap: wrap;
      & > span {
        display: flex;
        align-items: center;
      }
      & > span:not(:first-child) > .ant-breadcrumb-link{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
      }
    }
  }
  h1 {
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.text.title};
    flex: 1;
    display: flex;
    align-items: center;
    white-space: nowrap;
    @media only screen and (max-width: 350px) {
      font-size: 16px;
    }

    ${'' /* @media only screen and (max-width: 767px) {
      margin: 0 10px;
    } */}

    &:before {
      ${'' /* content: '';
      width: 5px;
      height: 40px;
      background-color: ${({ theme }) => theme.palette.color[1]};
      display: flex;
      margin: 0 15px 0 0; */}
    }

    &:after {
      ${'' /* content: '';
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.palette.color[1]};
      display: flex;
      margin-left: 15px; */}
    }
  }
  @media only screen and (max-width: 630px){
    display: flex;
    flex-direction: column;
    align-items: start;
    h1 {
      margin-bottom: 10px;
    }
    .extraAction {
      margin: 0;
    }
  }
`;
