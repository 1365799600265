import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatDateFromNow } from 'utils/textUtils';
import { Avatar, Icon, Popover } from 'antd';
import moment from 'moment';

import UserInfoPopoverContent from 'components/RestField/UserInfoPopover';
import ContentText from 'components/common/ContentText';
import CommentItemStyles from './styles';
import EditComment from '../EditComment';
import DropdownMenu from '../DropdownMenu';

const CommentItem = props => {
  const { comment } = props;

  const [isEdit, setIsEdit] = useState(false);

  const onToggle = () => {
    setIsEdit(!isEdit);
  };

  const isShowEditBtn = () => {
    if (localStorage.getItem('id') !== comment?.user?.id) return false;
    return (
      moment().diff(moment(comment?.updatedDateUtc), 'seconds') <=
      (process.env.REACT_APP_EXPIRED_TIME_EDIT_NOTE || 60 * 60)
    );
  };

  return (
    <CommentItemStyles className="comment-item">
      <Popover
        content={<UserInfoPopoverContent record={comment.user} />}
        trigger="hover"
        placement="right"
      >
        <Avatar src={comment.avatarUrl} icon="user" />
      </Popover>
      {isEdit ? (
        <div className="edit-comment">
          <EditComment {...props} onToggle={onToggle} />
        </div>
      ) : (
        <div className="view-comment">
          <div className="content-comment-item">
            <strong className="user-comment-item">{comment.user?.name}</strong>
            <ContentText
              className="message-comment-item"
              content={comment.note}
            />
            <span className="time-noti">
              <Icon type="clock-circle" />
              {formatDateFromNow(comment.createdDateUtc)}
            </span>
          </div>
          {isShowEditBtn() && (
            <DropdownMenu onClickEditNote={onToggle} {...props} />
          )}
        </div>
      )}
    </CommentItemStyles>
  );
};

CommentItem.propTypes = {
  comment: PropTypes.object,
};

export default CommentItem;
