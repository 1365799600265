import React from 'react';
import { Col, Row, Form } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import i18n from 'i18next';
import ProfileInfo from './components/ProfileInfo';
// import Summary from './components/SummarySection';
import PageTitle from '../../components/common/PageTitle';
import CustomBreadcrumb from '../../components/common/Breadcrumb';
import ProfileStyles from './styles';
import Logout from './components/Logout';

const Profile = ({ form }) => {
  const BREADCRUMB = [
    {
      title: i18n.t('profile.header'),
      path: '/profile',
    },
  ];
  const currentUser = useSelector(state => state.auth.data);
  // const summaries = useSelector(state => state.config.summaries);
  return (
    <ProfileStyles>
      <PageTitle>
        <CustomBreadcrumb data={BREADCRUMB} />
      </PageTitle>
      <Row gutter={24}>
        <Col span={24}>
          <RestInputContext.Provider value={{ form, record: currentUser }}>
            <ProfileInfo isProfile />
          </RestInputContext.Provider>
        </Col>
        
      </Row>
      <Row className="row-logout">
        <Logout currentUser={currentUser} />
      </Row>
    </ProfileStyles>
  );
};

Profile.propTypes = {
  form: PropTypes.object,
};

export default Form.create()(Profile);
