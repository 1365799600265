import styled from 'styled-components';
import { Icon, Table } from 'antd';

export const HeaderTableWrapper = styled.input`
  background: transparent;
  border: 1px dashed transparent;
  transition: all 0.3s;
  padding-left: 5px;
  transform: translate(-5px, 0px);
  text-transform: uppercase;
  &:hover {
    border: 1px dashed ${({ theme }) => theme.border.default};
  }
  &:focus {
    border: 1px dashed ${({ theme }) => theme.palette.primary};
    outline: none;
    background: ${({ theme }) => theme.background.content};
    transform: translate(0px, 0px);
  }
  &:disabled {
    border: 1px dashed transparent;
  }
  .highlightFilter {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const IconWrapper = styled(Icon)`
  &.highlightFilter {
    color: ${({ theme }) => theme.palette.primary} !important;
  }
`;

export const DropdownStyles = styled.div`
  .search-button {
    i {
      color: white;
    }
  }
`;

export const TableStyles = styled(Table)`
  .ant-table-thead > tr > th {
    background: ${({ theme }) => theme.table.headerBackground} !important;
    color: ${({ theme }) => theme.table.headerColor} !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    border: none;
  }
  .ant-table-tbody > tr > td, .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
      padding: 16px 8px;
  }
  
  .ant-table-tbody > tr:last-child > td {
    border: none !important;
  }
  .ant-table-tbody > tr {
    font-size: 14px;
  }
  .ant-table-header {
    margin-bottom: 0px !important;
    overflow: hidden !important;
    ${'' /* height: 52px; */}
  }
  .ant-table-scroll > .ant-table-header {
    margin-bottom: 0px !important;
  }

  .ant-table-fixed-right {
    .ant-table-body-outer {
      margin-bottom: -6px !important;
    }
    .ant-table-fixed tr:last-child > td {
      border: none;
    }
    .ant-table-header {
      background: ${({ theme }) => theme.table.headerBackground} !important;
      height: 52px;
    }
  }
  .ant-table-fixed
    > .ant-table-tbody
    > .ant-table-row
    > .ant-table-row-cell-break-word
    > div {
    ${'' /* width: 100% !important; */}
  }
`;
