import styled from 'styled-components';
// import { List } from 'antd';

const ListStyles = styled.div`
  ${'' /* .notification-icon {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
    border-radius: 50%;
    svg {
      margin-right: 0;
    }
  }
  .ant-list-item {
    cursor: pointer;
    padding-left: 24px;
    padding-right: 24px;
  }
  .not-seen-noti {
    background: #d0d0d0;
  } */}

  width: 100%;
  height: auto;
  ${'' /* max-height: 300px;
  overflow-y: auto; */}
  display: flex;
  flex-direction: column;

  .no-data {
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.background.container};
  }
  .loading-noti {
    background: ${({ theme }) => theme.background.container};
    display: flex;
    justify-content: center;
  }
  
  .noti-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.border.default};
    color: ${({ theme }) => theme.text.primary};
    cursor: pointer;
    .ant-avatar,
    ${'' /* .top-noti-item > strong {
      margin-right: 5px;
    } */}
    .bottom-noti-item > .time-noti > i {
      margin-right: 10px;
    }
    .ant-avatar {
      min-width: 50px;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      & > i {
        font-size: 25px;
      }
    }
    .top-noti-item {
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
    .bottom-noti-item {
      .title-noti {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-word;
        margin-bottom: 5px;
      }
    }
  }
  .waypoint-wrapper {
    display: flex;
    height: 1px;
  }
`;

export default ListStyles;

export const NotifyItemStyles = styled.div`
  ${'' /* background: ${({ isSeen, theme }) =>
    isSeen ? '#fff' : theme.background.notSeenNoti}; */}
  background: '#fff';
`;
