import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import {useSelector, useDispatch} from 'react-redux';
import { Form, DatePicker } from 'antd';
import moment from 'moment';
import {actions} from 'redux/documentaries/slice';

// import {ModalStyles} from './styles';
import ModalCustom from 'components/common/ModalCustom';

const FormDueDate = ({form, visible, setVisible, data}) => {

  const loading = useSelector(state => state.documentaries.changeDueDateLoading);
  const dispatch = useDispatch();

  const {getFieldDecorator, validateFields, resetFields} = form;
  const config = {
    rules: [{ type: 'object', required: true, message: i18next.t("input.dueDate.validateMsg.required")}],
  };

  const dateFormat = "DD-MM-YYYY HH:mm";

  const handleChangeDueDate = (e) => {
    e.preventDefault();
    
    validateFields((err, values) => {
      
      if (!err) {
        if (values.dueDate && moment().isAfter(values.dueDate, 'minutes')) {
          form.setFields({
            dueDate: {
              value: values.dueDate,
              errors: [
                new Error(
                  i18next.t('input.dueDate.validateMsg.confirm'),
                ),
              ],
            },
          });
        } else {
          dispatch(
            actions.changeDueDate({
              id: data.id,
              dueDateUtc: values.dueDate
                ? moment(values.dueDate).startOf('minutes').utc().toISOString()
                : values.dueDate,
            }),
          );
          setVisible(false);
          resetFields();
        }
      }
    });
  }

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i+=1) {
      result.push(i);
    }
    return result;
  }

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf('day');
  }
  
  const disabledDateTime = (value) => {
    const now = moment();
    if (value.days() === now.days()
      && value.months() === now.months()
      && value.years() === now.years()) {
      if(value.hours() === now.hours()) {
        return {
          disabledHours: () => range(0, now.hours()),
          disabledMinutes: () => range(0, now.minutes()),
          // disabledSeconds: () => range(0, now.seconds()),
        };
      }
      return {
        disabledHours: () => range(0, now.hours()),
      };
    }
    return {};
  }

  const handleOnCancel = () => {
    setVisible(false);
    resetFields();
  }

  return (
    <ModalCustom
      width={400}
      title={i18next.t("documentaries.changeDueDate")}
      visible={visible}
      onCancel={handleOnCancel}
      onOk={handleChangeDueDate}
      loading={loading}
    >
      <Form.Item>
        {getFieldDecorator('dueDate', {
          ...config,
          initialValue: data.dueDateUtc ? moment(data.dueDateUtc) : null,
        })(
          <DatePicker
            showTime={{format: "HH:mm", minuteStep: 30}}
            format={dateFormat}
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
            placeholder={i18next.t("input.dueDate.placeholder")}
          // onChange={handleOnchange}
          // showTime={{ defaultValue: moment(valueDate, dateFormat) }}
          />,
        )}
      </Form.Item>
    </ModalCustom>


  )
}

FormDueDate.propTypes = {
  form: PropTypes.object,
  visible: PropTypes.bool,
  data: PropTypes.object,
  setVisible: PropTypes.func,
}

export default Form.create()(FormDueDate);