import { get, post, patch } from './utils';

export async function getNotificationCountApi() {
  return get('/status');
}

// export async function getAllNotificationsApi(data) {
//   return get('/announcements', data);
// }

export async function getAllNotificationsApi(data) {
  return get('/notifications', data);
}

export async function trackingResetNotificationsApi(id) {
  return patch(`/notifications/${id}/reset`);
}

export async function trackingResetAnnouncementsApi() {
  return post('/announcements/trackings/reset');
}

export async function trackingAnnouncementApi(id) {
  return post(`/announcements/${id}/tracking`);
}
