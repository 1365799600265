import styled from 'styled-components';

const ListStyles = styled.div`

  .row-document {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    .col-folder {
      ${'' /* display: flex;
      flex: 1; */}
      background: #fff;
      ${'' /* @media only screen and (min-width: 768px) {
        width: calc(25% - 1px);
      } */}
    }
    ${'' /* .col-list-document {
      display: flex;
      flex: 3;
    } */}
  }
  .col-list-document {
    .list-wrapper {
      padding: 0 0 0 20px;
      @media only screen and (max-width: 767px) {
        padding: 0;
      }
    }
  }

  .extraAction-div > button:not(:first-child) {
    margin-left: 15px;
  }

  .col-folder-mobile {
      display: none;
    }
  
  .col-folder {
    padding: 10px 15px 0px 15px;
    ${'' /* border-right: 1px solid #f0f0f0; */}
  }
  .col-folder-web{
    position: sticky;
    top: 0;
    }

    @media only screen and (max-width: 1500px) and (min-width: 1200px) {
    .row-filter-container {
      .col-filter-content {
        width: 80%;
      }
      .col-actions {
        width: 20%;
      }
    }
  }

  @media only screen and (max-width: 1199px) and (min-width: 993px) {
    .row-filter-container {
      display: flex;
      flex-direction: column;
      .col-filter-content {
        width: 100%;
      }
      .col-actions {
        width: 50%;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    
  }

  @media only screen and (max-width: 767px) {
    .col-list-document {
      ${'' /* flex: 1!important; */}
    }  
    .row-document {
      padding-top: 10px;
    }
    .col-folder-web{
      display: none;
    }
    .col-folder {
      ${'' /* flex: auto!important; */}
      border: none;
      padding: 0;
      z-index: 1001;
      position: sticky;
      top: 0;
      background: transparent!important;
    }
    .col-folder-mobile {
      padding: 0px 0px 20px 0px;
      display: block;
      .folder-selected {
        width: 100%;
        background: ${({theme}) => theme.palette.primary};
        color: ${({theme}) => theme.text.primary};
        display: flex;
        justify-content: center;
        align-items: center;
        .span-button-folder-selected {
          overflow: hidden;
          text-overflow: ellipsis;
          i {
            margin-right: 5px;
          }
        }
      }
    }
  }
`;

export default ListStyles;