import React from 'react';
import PropTypes from 'prop-types';
import { TreeSelect } from 'antd';
import i18n from 'i18next';
import { isObject } from 'lodash';
import FormItem from '../FormItem';
import {
  getRecordData,
  onSearch as onChangeSearch,
} from '../../../utils/tools';
import { TreeSelectWrapper } from './style';

const { SHOW_CHILD, SHOW_PARENT  } = TreeSelect;

const FormTreeSelect = ({selectProps, ...props}) => {
  const onSelectOption = (inputValue, option) => {
    if (
      onChangeSearch(
        isObject(option.props.title)
          ? getRecordData(
              option.props.title.props && option.props.title.props.record,
              props.searchKey,
            )
          : option.props.title,
        inputValue,
      )
    ) {
      return option.props.value;
    }
    return null;
  };

  const {
    // source,
    header,
    required,
    // requiredMessage,
    placeholder,
    // form,
    defaultValue,
    onChange,
    loadData,
    treeData,
    showSearch,
    treeCheckable,
    treeIcon,
    isShowChild,
    allowClear,
    treeDefaultExpandAll,
  } = props;

  return (
    <FormItem
      ruleType={Array.isArray(defaultValue) ? 'array' : 'string'}
      {...props}
      placeholder={i18n.t(placeholder)}
      header={i18n.t(header)}
      required={required}
      defaultValue={defaultValue}
    >
      <TreeSelectWrapper
        onChange={onChange}
        treeData={treeData}
        showSearch={showSearch}
        placeholder={placeholder}
        loadData={loadData}
        style={{ width: '100%'}}
        dropdownClassName="tree-select-custom"
        dropdownStyle={{maxHeight: 'calc(100vh - 150px)', overflowX: 'hidden'}}
        treeCheckable={treeCheckable}
        treeIcon={treeIcon}
        showCheckedStrategy={isShowChild ? SHOW_CHILD : SHOW_PARENT}
        filterTreeNode={onSelectOption}
        allowClear={allowClear}
        treeDefaultExpandAll={treeDefaultExpandAll}
        {...selectProps}
        // treeNodeFilterProp="title"
        // filterTreeNode
      />
    </FormItem>
  );
};

FormTreeSelect.propTypes = {
  source: PropTypes.string,
  header: PropTypes.any,
  required: PropTypes.bool,
  requiredMessage: PropTypes.node,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  form: PropTypes.object,
  defaultValue: PropTypes.any,
  rules: PropTypes.any,
  onChange: PropTypes.func,
  loadData: PropTypes.func,
  options: PropTypes.array,
  showSearch: PropTypes.bool,
  treeData: PropTypes.array,
  treeCheckable: PropTypes.bool,
  treeIcon: PropTypes.bool,
  searchKey: PropTypes.string,
  isShowChild: PropTypes.bool,
  allowClear: PropTypes.bool,
  treeDefaultExpandAll: PropTypes.bool,
  selectProps: PropTypes.object,
};
FormTreeSelect.defaultProps = {
  required: false,
  requiredMessage: 'error.required',
  rules: [],
  placeholder: 'placeholder.undefined',
  treeCheckable: true,
  treeIcon: false,
  isShowChild: false,
  allowClear: true,
  treeDefaultExpandAll: false,
  searchKey: 'name',
  selectProps: {},
};
export default FormTreeSelect;
