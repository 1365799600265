import React from 'react';
import { PRIORITY } from 'configs/localData';
import i18next from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Tag, Icon } from 'antd';
import UserInfo from 'components/RestField/UserInfo';
import ContentText from 'components/common/ContentText';
// import { useSelector } from 'react-redux';
// import { checkRole } from 'utils/tools';
import FilesList from 'components/RestField/FilesList';
import AnnouncementInfoStyles from './styles';

const AnnouncementInfo = ({ record }) => {
  // const permissionData = useSelector(state => state.auth.permissionData);

  return (
    <AnnouncementInfoStyles>
      <div className="header-item-announcement">
        <div className="top-item">
          {record.priority > 0 && (
            <span className="label-priority">
              <Tag
                color={
                  PRIORITY.find(
                    (priority) => priority.value === record.priority,
                  )?.color
                }
              >
                {i18next.t(
                  PRIORITY.find(
                    (priority) => priority.value === record.priority,
                  )?.text,
                )}
              </Tag>
            </span>
          )}

          {/* <span className="time-span">
          {moment(record?.createdAt).format('DD-MM-YYYY HH:mm')}
        </span> */}
          <div className="sender-span">
            <UserInfo
              record={record?.createdUser}
              nameProp="name"
              note={moment(record?.createdAt).format('DD-MM-YYYY HH:mm')}
              isShowPopover
            />
          </div>
        </div>
        {record?.isDeleted && (
          <Tag className="view-button" color="red">
            {i18next.t('announcements.deleted')}
          </Tag>
        )}
      </div>
      <div className="name-item-announcement">{record?.title}</div>
      
      <div className="description-item-announcement">
        <ContentText content={record?.content} />
      </div>
      <FilesList medias={record?.medias} />
      {record?.totalSeen && (
        <div className="view-total-item-announcement">
          <Icon type="eye" />
          <span className="totalView-span">
            {`${record?.totalSeen} ${i18next.t('announcements.seenUser')}`}
          </span>
        </div>
      )}
    </AnnouncementInfoStyles>
  );
};

AnnouncementInfo.propTypes = {
  record: PropTypes.object,
};
export default AnnouncementInfo;
