import styled from 'styled-components';

const InfoStyles = styled.div`
.detail-info > div {
    margin-bottom: 15px;
  }
  .title-documentary {
    font-weight: 600;
  }
  .header-item-documentary {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 80%;
    .label-priority,
    .sender-span,
    .label-status {
      margin-bottom: 15px;
    }
    .ant-tag,
    .sender-span {
      margin-right: 20px;
    }
    .sender-span {
      padding: 0;
    }
  }
  .item-value-info {
    margin-bottom: 0px;
    min-height: 32px;
    line-height: 32px;
    word-break: break-word;
  }
  .label-title {
    font-weight: 600;
    margin-right: 10px;
  }
  .edit-button {
    margin-left: 10px;
    border: none;
    &:hover {
      background: transparent;
      transform: scale(1.1, 1.1);
      color: ${({ theme }) => theme.palette.primary} !important;
    }
    &:focus {
      background: transparent;
      transform: scale(1.1, 1.1);
      color: ${({ theme }) => theme.palette.primary} !important;
    }
  }
  .seen-users {
    margin-top: 30px;
  }

  .empty-data {
    margin-bottom: 15px;
  }
  .title-label {
    font-weight: 700;
    margin-bottom: 15px;
  }
  .content-text {
    white-space: pre-line;
  }
`;

export default InfoStyles;