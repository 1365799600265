import styled from 'styled-components';

const ListStyles = styled.div`
  ${'' /* padding: 0 20px 20px 20px;
  .list-wrapper {
    padding: 0;
  } */}
  .extraAction {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1199px) and (min-width: 993px) {
    .row-filter-container {
      display: flex;
      flex-direction: column;
      .col-filter-content {
        width: 100%;
      }
      .col-actions {
        width: 33.33%;
      }
    }
  }
`;

export default ListStyles;
