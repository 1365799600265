import React from 'react';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
// import DeleteButton from 'components/RestActions/DeleteButton';
import { isArray } from 'lodash';
import { Tag } from 'antd';
import i18next from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PRIORITY } from 'configs/localData';
import UserInfo from 'components/RestField/UserInfo';
import List from '../../../rest/List';
import Filter from '../Filter';
import ListStyles from './styles';

const MessageList = ({ isReceived, keyTab, history, ...props }) => {
  const profileData = useSelector((state) => state.auth.data);

  const handleClickViewBtn = (record) =>
    history.push(`/internalMessages/${keyTab}/${record?.id}/show`);

  return (
    <ListStyles>
      <List
        {...props}
        keyTab={keyTab}
        resource="internalMessages"
        hasSearch={false}
        hasExport={false}
        hasCreate={false}
        widthTable="1000px"
        // createHeader="button.createInternalMessages"
        defaultOptions={{ customApiResource: 'internaltasks' }}
        noCardWrapper
        rowClassName={(record) =>
          isReceived &&
          profileData?.id !== record?.createdUser?.id &&
          !record?.isSeen &&
          !record?.isDeleted
            ? 'active-row-item'
            : ''
        }
        checkIsSeen={(data) =>
          profileData?.id === data?.createdUser?.id || data?.isSeen
        }
        filter={
          <Filter
            keyTab={keyTab}
            isReceived={isReceived}
            format={({ startDate, endDate, ...data }) => ({
              ...data,
              startDate: startDate
                ? moment(startDate).startOf('days').utc().toISOString()
                : startDate,
              endDate: endDate
                ? moment(endDate).endOf('days').utc().toISOString()
                : endDate,
            })}
          />
        }
        redirects={{
          edit: 'screen',
          create: 'modal',
        }}
      >
        {/* <RestFieldItem
          source="priority"
          header="internalMessages.priority"
          format={data =>
            data > 0 ? (
              <Tag color={PRIORITY.find(item => item.value === data)?.color}>
                {i18next.t(PRIORITY.find(item => item.value === data)?.text)}
              </Tag>
            ) : null}
          width={110}
        /> */}
        {/* <RestFieldItem
          source="isDeleted"
          header="internalMessages.isDeleted"
          format={data =>
            data ? <Tag color="purple">{i18next.t('deleted.text')}</Tag> : null}
          width={100}
        /> */}
        <RestFieldItem
          source="title"
          header="internalMessages.title"
          format={(data, record) => (
            <div className="title-task">
              <span className="list-tags">
                {record.isDeleted && (
                  <Tag color="purple">{i18next.t('deleted.text')}</Tag>
                )}
                {record.priority > 0 ? (
                  <Tag
                    color={
                      PRIORITY.find((item) => item.value === record.priority)
                        ?.color
                    }
                  >
                    {i18next.t(
                      PRIORITY.find((item) => item.value === record.priority)
                        ?.text,
                    )}
                  </Tag>
                ) : null}
              </span>
              <Link
                to={`/internalMessages/${keyTab}/${record?.id}/show`}
                className="title-name"
              >
                {data}
              </Link>
            </div>
          )}
        />
        {isReceived && (
          <RestFieldItem
            source="createdUser"
            header="internalMessages.sender"
            format={(data, record) => (
              <UserInfo
                record={record.createdUser}
                nameProp="name"
                isShowPopover
              />
            )}
            width={300}
          />
        )}
        {!isReceived && (
          <RestFieldItem
            source="usersReceived"
            header="internalMessages.receivedUser"
            format={(data, record) => {
              if (!isArray(data) || record?.totalReceiver < 1) return null;
              return (
                <span>
                  {record?.totalReceiver > 1 && data?.length > 0
                    ? `${data[0]?.name} và ${
                        (record?.totalReceiver || 2) - 1
                      } người khác`
                    : data?.[0]?.name}
                </span>
              );
            }}
            width={300}
          />
        )}
        <RestFieldItem
          source="createdAt"
          header="internalMessages.createdAt"
          format={(data) => (
            <span>{moment(data).format('DD-MM-YYYY HH:mm')}</span>
          )}
          width={150}
        />
        <RestFieldItem
          source="totalSeen"
          header="internalMessages.totalSeen"
          // format={data => (data ? <span>{data}</span> : null)}
          format={(data, record) => (
            <span>{`${data}/${record?.totalReceiver}`}</span>
          )}
          width={100}
        />
        <ActionGroup widthAction={80}>
          <EditButton isView source="show" customOnClick={handleClickViewBtn} />
          {/* <DeleteButton /> */}
        </ActionGroup>
      </List>
    </ListStyles>
  );
};

MessageList.propTypes = {
  isReceived: PropTypes.bool,
  keyTab: PropTypes.string,
  history: PropTypes.object,
};

export default MessageList;
