import React from 'react';
import Create from '../../rest/Create';
import Form from '../../Profile/components/Form';
import CreateStyles from './styles';

const UsersCreate = props => {
  return (
    <CreateStyles>
      <Create
        {...props}
        resource="users"
        formatOnSubmit={({ positions, department, ...data }) => ({
          ...data,
          departmentId: department.id,
          positionIds: [positions],
          name: data?.name && data.name.trim(),
        })}
      >
        <Form isCreate />
      </Create>
    </CreateStyles>
  );
};

UsersCreate.propTypes = {};

export default UsersCreate;
