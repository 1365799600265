import { makeCRUDSlice } from '../crudCreator/slice';

export const RESOURCE = 'documentaries';
const documentariesSlice = makeCRUDSlice(RESOURCE, {
  changeDueDate: (state) => {
    state.changeDueDateLoading = true;
  },
  changeDueDateSuccess: (state, { payload }) => {
    state.changeDueDateLoading = false;
    state.data = {
      ...state.data,
      [payload.id]: {
        ...state.data[payload.id],
        dueDateUtc: payload.dueDateUtc,
      },
    };
    state.currentData = {
      ...state.currentData,
      dueDateUtc: payload.dueDateUtc,
    };
  },
  changeDueDateFailure: (state, { payload }) => {
    state.changeDueDateLoading = false;
    state.error = payload;
  },
  seenExternal: () => {},
  seenExternalSuccess: (state, { payload }) => {
    state.data = {
      ...state.data,
      [payload]: {
        ...state.data[payload],
        isSeen: true,
      },
    };
  },
  seenExternalFailure: (state, { payload }) => {
    state.error = payload;
  },

  getTrackingExternal: () => {},
  getTrackingSuccess: (state, { payload }) => {
    // console.log(payload, 'payload')
    state.data = {
      ...state.data,
      [payload.id]: {
        ...state.data[payload.id],
        totalSeen: payload.tracking?.totalSeen || 0,
        totalReceiver: payload.tracking?.totalReceiver || 0,
        seenUsers: payload.tracking?.trackingModels || [],
      },
    };

    state.currentData = {
      ...state.currentData,
      totalSeen: payload.tracking?.totalSeen || 0,
      totalReceiver: payload.tracking?.totalReceiver || 0,
      seenUsers: payload.tracking?.trackingModels || [],
    };
  },
  getTrackingFailure: (state, { payload }) => {
    state.error = payload;
  },
  addActivity: () => {},
  addActivitySuccess: (state, { payload: { data } }) => {
    // state.createActivitySuccess = true;
    state.currentData = {
      ...state.currentData,
      ...data,
    };
  },
  addActivityFailure: (state, { payload }) => {
    // state.createActivitySuccess = false;
    state.error = payload;
  },

  // resetStatusCreatedActivitySuccess: (state) => {
  //   state.createActivitySuccess = false;
  // },

  completeExternal: () => {},
  completeExternalSuccess: (state, { payload: { data } }) => {
    state.currentData = {
      ...state.currentData,
      ...data,
    };
  },
  completeExternalFailure: (state, { payload }) => {
    state.error = payload;
  },

  knowExternal: () => {},
  knowExternalSuccess: (state, { payload: { data } }) => {
    state.currentData = {
      ...state.currentData,
      ...data,
    };
  },
  knowExternalFailure: (state, { payload }) => {
    state.error = payload;
  },
  archiveExternal: () => {},
  archiveExternalSuccess: (state, { payload: { data } }) => {
    state.currentData = {
      ...state.currentData,
      ...data,
    };
  },
  archiveExternalFailure: (state, { payload }) => {
    state.error = payload;
  },
  commentExternal: () => {},
  commentSuccess: (state, { payload: { data } }) => {
    const comments = state.currentData.comments || [];
    comments.unshift(data);
    state.currentData = {
      ...state.currentData,
      comments,
    };
  },
  commentFailure: (state, { payload }) => {
    state.error = payload;
  },

  editCommentExternal: () => {},

  editCommentSuccess: (state, { payload: { data } }) => {
    const comments = state.currentData.comments || [];
    state.currentData = {
      ...state.currentData,
      comments: comments.map((item) => (item.id === data.id ? data : item)),
    };
  },

  editCommentFailure: (state, { payload }) => {
    state.error = payload;
  },

  delCommentExternal: () => {},

  delCommentSuccess: (state, { payload }) => {
    const comments = state.currentData.comments || [];
    state.currentData = {
      ...state.currentData,
      comments: comments.filter((item) => item.id !== payload.idComment),
    };
  },

  delCommentFailure: (state, { payload }) => {
    state.error = payload;
  },

  getCommentsExternal: (state) => {
    state.getComments = true;
  },

  getCommentsSuccess: (state, { payload }) => {
    state.currentData = {
      ...state.currentData,
      comments: payload,
    };
    state.getComments = false;
  },

  getCommentsFailure: (state, { payload }) => {
    state.error = payload;
    state.getComments = false;
  },

  editNoteItemActivity: () => {},

  editNoteItemActivitySuccess: (state, { payload: { data } }) => {
    // state.createActivitySuccess = true;
    state.currentData = {
      ...state.currentData,
      ...data,
    };
  },
  editNoteItemActivityFailure: (state, { payload }) => {
    // state.createActivitySuccess = false;
    state.error = payload;
  },
});

export const { actions, reducer } = documentariesSlice;

export default reducer;
