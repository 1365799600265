import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/documentaries/slice';
import CRUDActions from 'redux/crudActions';

import { RestInputContext } from 'components/RestInput/RestInputContext';
// import ArchiveModalStyles from './styles';
import ModalCustom from 'components/common/ModalCustom';
import FormArchive from '../FormArchive';
import { useModalContext } from '../ModalContext';

const ModalArchive = ({
  form,
  idTask,
  toNumber,
  // activityId,
  // isComplete,
}) => {

  const { visibleArchive, toggleArchive } = useModalContext();

  const isDisabled = useSelector(
    (state) => state.documentaries.isDisabledButtonSubmit,
  );
  const dispatch = useDispatch();

  const options = {
    successAction: CRUDActions.documentaryFiles.getAll(
      { pageSize: 15, pageNumber: 1 },
      { customApiResource: `externaltasks/${idTask}/attachments`, isRefresh: true },
    ),
  };

  // const handleComplete = (values) => {
  //   if (activityId) {
  //     dispatch(
  //       actions.completeExternal({
  //         data: {
  //           ...values,
  //           id: idTask,
  //           activityId,
  //         },
  //         options,
  //       }),
  //     );
  //   }
  // };

  const handleArchive = (values) => {
    dispatch(
      actions.archiveExternal({
        data: {
          ...values,
          id: idTask,
        },
        options,
      }),
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, { toNumber, ...values }) => {
      if (!err) {
        const restValue = { toNumber: toNumber && toNumber.trim(), ...values };
        handleArchive(restValue);
        // if (isComplete) {
        //   handleComplete(restValue);
        // } else {
        //   handleArchive(restValue);
        // }
        handleOnCancel();
      }
    });
  };

  const handleOnCancel = () => {
    toggleArchive();
    form.resetFields();
  };

  return (
    <ModalCustom
      width={800}
      // title={i18next.t(`${isComplete ? 'documentaries.titleCompleteModal' : 'documentaries.titleArchiveModal'}`)}
      title={i18next.t('documentaries.titleArchiveModal')}
      visible={visibleArchive}
      onCancel={handleOnCancel}
      onOk={handleSubmit}
      isDisabled={isDisabled}
    >
      <RestInputContext.Provider value={{ form, record: {toNumber} }}>
        <FormArchive />
      </RestInputContext.Provider>
    </ModalCustom>
  );
};

ModalArchive.propTypes = {
  form: PropTypes.object,
  idTask: PropTypes.string,
  toNumber: PropTypes.string,
  // activityId: PropTypes.string,
  // isComplete: PropTypes.bool,
};
export default Form.create()(ModalArchive);