import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialContextValue = {
  visibleForward: false,
  setVisibleForward: (visible) => visible,
  visibleArchive: false,
  setVisibleArchive: (visible) => visible,
};

const ModalContext = createContext(initialContextValue);

export const ModalProvider = (props) => {
  const [visibleForward, setVisibleForward] = useState(false);
  const [visibleArchive, setVisibleArchive] = useState(false);

  const value = {
    visibleForward,
    setVisibleForward,
    visibleArchive,
    setVisibleArchive,
  };

  return (
    <ModalContext.Provider value={value}>
      {props.children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node,
};

export const useModalContext = () => {
  const {
    visibleForward,
    setVisibleForward,
    visibleArchive,
    setVisibleArchive,
  } = useContext(ModalContext);

  const toggleForward = () => {
    setVisibleForward(!visibleForward);
  };

  const toggleArchive = () => {
    setVisibleArchive(!visibleArchive);
  };

  return {
    visibleForward,
    toggleForward,
    visibleArchive,
    toggleArchive,
  };
};
