import { makeCRUDSlice } from '../crudCreator/slice';

export const RESOURCE = 'internalMessages';
const internalMessagesSlice = makeCRUDSlice(RESOURCE, {
  seenInternal: () => {},
  seenInternalSuccess: (state, { payload }) => {
    state.data = {
      ...state.data,
      [payload]: {
        ...state.data[payload],
        isSeen: true,
      },
    };
    state.currentData = {
      ...state.currentData,
      isSeen: true,
    };
  },
  seenInternalFailure: (state, { payload }) => {
    state.error = payload;
  },

  getTrackingInternal: () => {},
  getTrackingSuccess: (state, { payload }) => {
    state.data = {
      ...state.data,
      [payload.id]: {
        ...state.data[payload.id],
        totalSeen: payload.tracking?.totalSeen || 0,
        totalReceiver: payload.tracking?.totalReceiver || 0,
        seenUsers: payload.tracking?.trackingModels || [],
      },
    };

    state.currentData = {
      ...state.currentData,
      totalSeen: payload.tracking?.totalSeen || 0,
      totalReceiver: payload.tracking?.totalReceiver || 0,
      seenUsers: payload.tracking?.trackingModels || [],
    };
  },
  getTrackingFailure: (state, { payload }) => {
    state.error = payload;
  },

  commentInternal: () => {},
  commentSuccess: (state, { payload: { data } }) => {
    // state.data = {
    //   ...state.data,
    //   [idTask]: {
    //     ...state.data[idTask],
    //     comments: [...state.data[idTask].comments, ...data],
    //   },
    // };
    const comments = state.currentData.comments || [];
    comments.unshift(data);
    state.currentData = {
      ...state.currentData,
      comments,
    };
  },
  commentFailure: (state, { payload }) => {
    state.error = payload;
  },

  editCommentInternal: () => {},

  editCommentSuccess: (state, { payload: { data } }) => {
    const comments = state.currentData.comments || [];
    state.currentData = {
      ...state.currentData,
      comments: comments.map(item => (item.id === data.id ? data : item)),
    };
  },

  editCommentFailure: (state, { payload }) => {
    state.error = payload;
  },

  delCommentInternal: () => {},

  delCommentSuccess: (state, { payload }) => {
    const comments = state.currentData.comments || [];
    state.currentData = {
      ...state.currentData,
      comments: comments.filter(item => (item.id !== payload.idComment)),
    };
  },

  delCommentFailure: (state, { payload }) => {
    state.error = payload;
  },

  getCommentsInternal: state => {
    state.getComments = true;
  },
  getCommentsSuccess: (state, { payload }) => {
    // state.data = {
    //   ...state.data,
    //   [idTask]: {
    //     ...state.data[idTask],
    //     comments,
    //   },
    // };
    state.currentData = {
      ...state.currentData,
      comments: payload,
    };
    state.getComments = false;
  },
  getCommentsFailure: (state, { payload }) => {
    state.error = payload;
    state.getComments = false;
  },
});

export const { actions, reducer } = internalMessagesSlice;

export default reducer;
