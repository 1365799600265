import { call, put, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from 'utils/reduxUtils';
// import { convertResponseData } from 'redux/crudCreator/dataProvider';
import {
  getConfig,
  getSummariesApi,
  getDepartmentsApi,
  getPositionsApi,
  getAllUsersApi,
  getAllTagsApi,
} from 'api/configs';
import {
  getConfigSuccess,
  getConfigFailure,
  getSummaries,
  getSummariesSuccess,
  getSummariesFailure,
  getDepartments,
  getDepartmentsSuccess,
  getDepartmentsFailure,
  getPositions,
  getPositionsSuccess,
  getPositionsFailure,
  getUsers,
  getUsersSuccess,
  getUsersFailure,
  getTags,
  getTagsSuccess,
  getTagsFailure,
} from './slice';

export function* getConfigSaga() {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      getConfig,
    );
    if (response) {
      yield put(getConfigSuccess(response));
    } else {
      yield put(getConfigFailure(response));
    }
  } catch (error) {
    yield put(getConfigFailure(error));
  }
}

export function* getSummariesSaga() {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      getSummariesApi,
    );
    if (response) {
      yield put(getSummariesSuccess(response));
    }
  } catch (error) {
    yield put(getSummariesFailure(error));
  }
}

export function* getDepartmentsSaga() {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      getDepartmentsApi,
    );
    if (response) {
      yield put(getDepartmentsSuccess(response));
    }
  } catch (error) {
    yield put(getDepartmentsFailure(error));
  }
}

export function* getPositionsSaga() {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      getPositionsApi,
    );
    if (response) {
      yield put(getPositionsSuccess(response));
    }
  } catch (error) {
    yield put(getPositionsFailure(error));
  }
}

function* getAllUsersSaga() {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      getAllUsersApi,
    );
    if (response) {
      yield put(getUsersSuccess(response.data));
    }
  } catch (error) {
    yield put(getUsersFailure(error));
  }
}

function* getAllTagsSaga() {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      getAllTagsApi,
    );
    if (response) {
      yield put(getTagsSuccess(response.data));
    }
  } catch (error) {
    yield put(getTagsFailure(error));
  }
}

export default [
  takeLatest([getConfig.type], getConfigSaga),
  takeLatest([getSummaries.type], getSummariesSaga),
  takeLatest([getDepartments.type], getDepartmentsSaga),
  takeLatest([getPositions.type], getPositionsSaga),
  takeLatest([getUsers.type], getAllUsersSaga),
  takeLatest([getTags.type], getAllTagsSaga),
];
