import { uniqBy } from 'lodash';
import theme from '../theme/index';
import commonJobs from './commonJobs';
import nationalities from './nationalities';

export const FORMAT_DATE = 'MMM DD, YYYY';

export const PERMISSION = [
  {
    value: 'PUT',
    text: 'permission.put',
  },
  {
    value: 'CREATE',
    text: 'permission.create',
  },
  {
    value: 'READ',
    text: 'permission.read',
  },
  {
    value: 'DELETE',
    text: 'permission.delete',
  },
  {
    value: 'ADDCLASS',
    text: 'permission.addClass',
  },
  {
    value: 'REMOVECLASS',
    text: 'permission.removeClass',
  },
  {
    value: 'GETLIST',
    text: 'permission.getList',
  },
  {
    value: 'LIST_PERMISSION',
    text: 'permission.listPermission',
  },
];

export const SEMESTERS = [
  {
    value: true,
    text: 'semesters.isDone',
    color: 'gray',
  },
  {
    value: false,
    text: 'semesters.notDone',
    color: 'green',
  },
];

export const CHART_LINES = [
  {
    value: 'tuition',
    dataKey: 'tuition',
    text: 'chart.tuition',
    stroke: '#55ac00',
    fillId: 'tuitionFillColor',
    fillColor: '#dcecff',
  },
  {
    value: 'mealFee',
    dataKey: 'mealFee',
    text: 'chart.mealFee',
    stroke: '#f8961d',
    fillId: 'mealFeeFillColor',
    fillColor: '#ffedd3',
  },
];

export const FINANCES = [
  {
    value: 'tuition',
    text: 'finances.tuition',
  },
  {
    value: 'mealFee',
    text: 'finances.mealFee',
  },
  {
    value: 'subClass',
    text: 'finances.subClass',
  },
];

export const GENDER = [
  {
    value: 'male',
    text: 'gender.male',
  },
  {
    value: 'female',
    text: 'gender.female',
  },
];

export const SUBJECTS = [
  {
    value: 'main',
    text: 'subjects.typeMain',
    color: '#56ac00',
  },
  {
    value: 'sub',
    text: 'subjects.typeSub',
    color: '#f8961d',
  },
];

export const STATUS = [
  {
    value: true,
    text: 'status.active',
    color: 'green',
  },
  {
    value: false,
    text: 'status.inactive',
    color: 'red',
  },
];

export const PAYMENT_STATUS = [
  {
    value: false,
    text: 'Not paid',
    textColor: theme.color.red,
    icon: 'close-circle',
  },
  {
    value: true,
    text: 'Paid',
    textColor: theme.color.green,
    icon: 'check-circle',
  },
];

export const QUOTE_STATUS = [
  {
    value: 'ACCEPTED',
    text: 'Accepted',
    color: theme.color.green,
    textColor: '#fff',
  },
  {
    value: 'REJECTED',
    text: 'Rejected',
    color: theme.color.red,
    textColor: '#fff',
  },
  {
    value: 'CANCELLED',
    text: 'Cancelled',
    color: theme.color.red,
    textColor: '#fff',
  },
];

export const ORDER_STATUS = [
  {
    value: 'WAITING_FOR_QUOTE',
    text: 'Waiting for quote',
    color: theme.color.yellow,
    icon: 'folder-open',
    textColor: '#000',
  },
  {
    value: 'PROCESSING',
    text: 'Processing',
    color: theme.color.green,
    icon: 'interaction',
    textColor: 'white',
  },
  {
    value: 'ONDELIVERY',
    text: 'On Delivery',
    color: theme.color.green,
    icon: 'car',
    textColor: 'white',
  },
  {
    value: 'DELIVERED',
    text: 'Delivered',
    color: theme.color.blue,
    icon: 'home',
    textColor: 'white',
  },
  {
    value: 'COMPLETE',
    text: 'Complete',
    color: theme.color.blue,
    icon: 'check-circle',
    textColor: 'white',
  },
  {
    value: 'CANCELLED',
    text: 'Cancelled',
    color: theme.color.red,
    icon: 'close-circle',
    textColor: 'white',
  },
];

export const PRODUCT_STATUS = [
  {
    value: 'inprogress',
    text: 'status.inprogress',
    color: theme.color.green,
  },
  {
    value: 'pending',
    text: 'status.pending',
    color: theme.color.orange,
  },
  {
    value: 'completed',
    text: 'status.completed',
    color: theme.color.blue,
  },
  {
    value: 'developing',
    text: 'status.developing',
    color: theme.color.green,
  },
];

export const ACTIVITIES_LOG = [
  {
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vulputate justo ornare feugiat.',
  },
  {
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vulputate justo ornare feugiat.',
  },
  {
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vulputate justo ornare feugiat.',
  },
  {
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vulputate justo ornare feugiat.',
  },
];

export const USER_STATUS = [
  {
    id: 1,
    data: 'true',
    text: {
      en: 'Locked',
      vi: 'Khoá',
    },
  },
  {
    id: 1,
    data: 'false',
    text: {
      en: 'Unlock',
      vi: 'Không Khoá',
    },
  },
];

export const MEMBER_STATUS = [
  {
    id: 1,
    data: 'false',
    text: {
      en: 'Locked',
      vi: 'Khoá',
    },
  },
  {
    id: 1,
    data: 'true',
    text: {
      en: 'Unlock',
      vi: 'Không Khoá',
    },
  },
];

export const ACTIVE_TYPES = [
  {
    id: 1,
    value: 'false',
    isActive: false,
    text: 'isActive.inactive',
    color: 'red',
  },
  {
    id: 2,
    value: 'true',
    isActive: true,
    text: 'isActive.active',
    color: 'green',
  },
];

export const DISABLE_TYPES = [
  {
    value: false,
    text: 'isDisabled.notDisabled',
  },
  {
    value: true,
    text: 'isDisabled.disabled',
  },
];

export const MEMBER_ROLES = [
  {
    id: 1,
    text: {
      en: 'Admin',
      vi: 'Admin', 
    },
  },
  {
    id: 2,
    text: {
      en: 'Staff',
      vi: 'Nhân viên',
    },
  },
  {
    id: 3,
    text: {
      en: 'Member',
      vi: 'Khách hàng',
    },
  },
];

export const LANGUAGES = [
  {
    id: 'en',
    text: {
      en: 'English',
      vi: 'Tiếng Anh',
    },
  },
  {
    id: 'vi',
    text: {
      en: 'Vietnamese',
      vi: 'Tiếng Việt',
    },
  },
];

export const GENDERS = [
  {
    value: 'male',
    text: 'gender.male',
    color: '#1890ff',
    icon: 'man',
  },
  {
    value: 'female',
    text: 'gender.female',
    color: '#eb2f96',
    icon: 'woman',
  },
  {
    value: 'other',
    text: 'gender.other',
    color: '#959595',
    icon: 'key',
  },
];

export const BOOKING_STATUS = [
  {
    id: 'INPROGRESS',
    value: 'INPROGRESS',
    text: 'status.inprogress',
    requestSuffixUrl: 'inprogress',
  },
  {
    id: 'COMPLETED',
    value: 'COMPLETED',
    text: 'status.completed',
    requestSuffixUrl: 'complete',
  },
  {
    id: 'CANCELLED',
    value: 'CANCELLED',
    text: 'status.cancelled',
    requestSuffixUrl: 'cancel',
  },
  { id: 'PENDING', value: 'PENDING', text: 'status.pending' },
];

export const HOME_TAB = [
  { id: 'todayBooking', title: 'Today' },
  { id: 'pendingBooking', title: 'Upcoming' },
];

export const MOMENT_CODE = {
  daily: 'd',
  hourly: 'h',
  weekly: 'w',
  monthly: 'M',
};

export const TRANSACTION_TYPE = [
  { value: 'INCOME', text: 'button.income' },
  { value: 'EXPENSE', text: 'button.expense' },
];

export const PACKAGE_TYPES_TIME_UNIT = {
  hourly: 'hour',
  daily: 'day',
  weekly: 'week',
  monthly: 'month',
};
export const BOOKINGS_TYPES = ['todayBooking', 'pendingBooking'];
export const CHECKIN_STATUS = [
  { text: 'checkinStatus.waitingCheckin', value: 'waitingCheckin' },
  { text: 'checkinStatus.waitingCheckout', value: 'waitingCheckout' },
  { text: 'checkinStatus.completed', value: 'completed' },
  { text: 'checkinStatus.all', value: 'all' },
  { text: 'checkinStatus.upcoming', value: 'upcoming' },
];
export const DISCOUNT_UNIT = [
  { text: '%', value: 'percent' },
  { text: 'VND', value: 'number' },
];
export const PAYMENT_METHOD = [
  { text: 'payType.paymentByCash', value: 'cash' },
  { text: 'payType.paymentByBank', value: 'bank' },
];

export const NOTIFICATIONS = [
  // {
  //   value: 'pharmacy',
  //   text: 'notifications.pharmacy',
  //   icon: 'ic-pharmacy',
  // },
  // {
  //   value: 'delivery',
  //   text: 'notifications.delivery',
  //   icon: 'ic-delivery',
  // },
  // {
  //   value: 'prepare',
  //   text: 'notifications.prepare',
  //   icon: 'ic-prepare',
  // },
  {
    value: 'Sending',
    text: 'notifications.send',
    icon: 'ic-send',
  },
];

export const BILLING_UNIT = [
  { text: 'packages.hour', value: 'Hour' },
  { text: 'packages.month', value: 'Month' },
  { text: 'packages.week', value: 'Week' },
  { text: 'packages.day', value: 'Day' },
];

export const ROLE = [
  { text: 'role.superadmin', value: 'superadmin' },
  { text: 'role.admin', value: 'admin' },
  { text: 'role.pharmacist', value: 'user' },
];

export const JOBS = commonJobs;
export const NATIONALITIES = uniqBy(nationalities, 'nationality');

export const QUALIFICATIONS = [
  { text: 'qualifications.university', value: 'university' },
  { text: 'qualifications.colleges', value: 'colleges' },
];

export const OPEN_TIME = [
  {
    value: true,
    text: 'time.open',
    color: '#52c41a',
  },
  {
    value: false,
    text: 'time.closed',
    color: '#e64c38',
  },
];

export const CONTRACT_TYPES = [
  { text: 'contractTypes.official', value: 'official' },
  { text: 'contractTypes.partTime', value: 'partTime' },
];

export const PRIORITY = [
  {text: "priority.urgent", textCode: "priorityCode.urgent", value: 1, color: 'red'},
  {text: "priority.medium", textCode: "priorityCode.medium", value: 0, color: theme.color.yellow},
];

export const FILE_TYPES = [
  {value: "pdf", icon: "file-pdf", color: theme.color.red},
  {value: "ppt", icon: "file-ppt", color: theme.color.pink},
  {value: "pptx", icon: "file-ppt", color: theme.color.pink},
  {value: "doc", icon: "file-word", color: theme.color.blue},
  {value: "docx", icon: "file-word", color: theme.color.blue},
  {value: "xlsx", icon: "file-excel", color: theme.color.green},
  {value: "xls", icon: "file-excel", color: theme.color.green},
  {value: "csv", icon: "file-excel", color: theme.color.green},
  {value: "zip", icon: "file-zip", color: theme.color.violet},
  {value: "zar", icon: "file-zip", color: theme.color.violet},
  {value: "txt", icon: "file-text", color: "currentColor"},
  {value: "mov", icon: "video-camera", color: "currentColor"},
  {value: "mp4", icon: "video-camera", color: "currentColor"},
  {value: "avi", icon: "video-camera", color: "currentColor"},
  {value: "flv", icon: "video-camera", color: "currentColor"},
  {value: "wmv", icon: "video-camera", color: "currentColor"},
];

export const IMAGE_TYPES = ["png", "jpg", "jpeg", "gif", "tif", "tiff"];

export const DELETE_STATUS = [
  {
    id: 1,
    value: 'false',
    isDeleted: false,
    text: 'isDeleted.notDeleted',
    color: theme.color.red,
  },
  {
    id: 2,
    value: 'true',
    isDeleted: true,
    text: 'isDeleted.deleted',
    color: theme.color.green,
  },
];

export const DOCUMENTARIES_STATUS_TABLE = [
  {value: 1, text: "documentaries.statusList.waitingProcessing", color: theme.color.blue},
  {value: 2, text: "documentaries.statusList.processing", color: theme.color.orange},
  {value: 6, text: "documentaries.statusList.waitingForAccept", color: theme.color.yellow},
  {value: 7, text: "documentaries.statusList.submittedToLeader", color: theme.color.forestGreen},
  {value: 8, text: "documentaries.statusList.waitingForSigning", color: theme.color.pink},
  {value: 4, text: "documentaries.statusList.archived", color: theme.color.red},
  {value: 9, text: "documentaries.statusList.needKnow", color: theme.color.coral},
];

export const DOCUMENTARIES_STATUS = [
  {value: 0, text: "documentaries.statusList.pending", color: theme.color.violet},
  {value: 1, text: "documentaries.statusList.waitingProcessing", color: theme.color.blue},
  {value: 2, text: "documentaries.statusList.processing", color: theme.color.orange},
  {value: 3, text: "documentaries.statusList.resolved", color: theme.color.green},
  {value: 4, text: "documentaries.statusList.archived", color: theme.color.red},
  {value: 5, text: "documentaries.statusList.deleted", color: theme.color.gray},
  {value: 6, text: "documentaries.statusList.waitingForAccept", color: theme.color.yellow},
  {value: 7, text: "documentaries.statusList.submittedToLeader", color: theme.color.forestGreen},
  {value: 8, text: "documentaries.statusList.waitingForSigning", color: theme.color.pink},
  {value: 9, text: "documentaries.statusList.needKnow", color: theme.color.coral},
];

export const DOCUMENTARY_STATUS_VALUE = {
  pending: 0,
  waitingProcessing: 1,
  processing: 2,
  process: 3,
  archived: 4,
  deleted: 5,
  waitingForAccept: 6,
  submittedToLeader: 7,
  waitingForSigning: 8,
  needKnow: 9,
}

export const ACTIVITY_ROLE_VALUE = {
  created: 0,
  execute: 1,
  directing: 7,
  report: 2,
  process: 3,
  combine: 4,
  forwardProcessed: 5,
  forwardCombined: 6,
  toKnow: 8,
}

export const ACTIVITY_ROLES = [
  {
    value: 0,
    text: 'activity.created',
    color: theme.color.violet,
    positions: ['Van_Thu'],
  },
  {
    value: 1,
    text: 'activity.execute',
    color: theme.color.blue,
    positions: ['Giam_Doc'],
  },
  {
    value: 7,
    text: 'activity.directing',
    color: theme.color.pink,
    positions: ['Giam_Doc'],
  },
  {
    value: 2,
    text: 'activity.report',
    color: theme.color.yellow,
    positions: ['Giam_Doc'],
  },
  {
    value: 8,
    text: 'activity.toKnow',
    color: theme.color.limeGreen,
    positions: ['Giam_Doc'],
  },
  {
    value: 3,
    text: 'activity.process',
    color: theme.color.orange,
    positions: ['Truong_Phong', 'Pho_Phong', 'Chuyen_Vien', 'Nhan_Vien'],
  },
  {
    value: 4,
    text: 'activity.combine',
    color: theme.color.green,
    positions: ['Truong_Phong', 'Pho_Phong', 'Chuyen_Vien', 'Nhan_Vien'],
  },
  {
    value: 5,
    text: 'activity.forwardProcessed',
    color: theme.color.coral,
    positions: ['Chuyen_Vien', 'Nhan_Vien'],
  },
  {
    value: 6,
    text: 'activity.forwardCombined',
    color: theme.color.forestGreen,
    positions: ['Chuyen_Vien', 'Nhan_Vien'],
  },
];

export const FORWARD_DATA = [
  {role: 'Van_Thu', positionsAccept: ['Giam_Doc', 'Pho_Giam_Doc', 'Chu_Tich']},
  {role: 'Giam_Doc', positionsAccept: ['Giam_Doc', 'Pho_Giam_Doc', 'Chu_Tich', 'Truong_Phong', 'Pho_Phong']},
  {role: 'Truong_Phong', positionsAccept: ['Truong_Phong', 'Pho_Phong', 'Chuyen_Vien', 'Nhan_Vien']},
  {role: 'Pho_Phong', positionsAccept: ['Truong_Phong', 'Pho_Phong', 'Chuyen_Vien', 'Nhan_Vien']},
];

export const ROLE_DATA = [
  {value: 'Van_Thu', name: 'Văn Thư'},
  {value: 'Giam_Doc', name: 'Giám Đốc'},
  {value: 'Pho_Giam_Doc', name: 'Phó Giám Đốc'},
  {value: 'Truong_Phong', name: 'Trưởng Phòng'},
  {value: 'Pho_Phong', name: 'Phó Phòng'},
  {value: 'Nhan_Vien', name: 'Nhân Viên'},
  {value: 'Chuyen_Vien', name: 'Chuyên Viên'},
  {value: 'Khac', name: 'Khác'},
];

export const SEEN_DATA = [
  {value: 'false', isSeen: false, text: 'seenData.notSeen'},
  {value: 'true', isSeen: true, text: 'seenData.seen'},
]
