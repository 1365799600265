import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// import CustomModal from 'components/common/ModalCustom';
import { Spin } from 'antd';
import DrawerStyles from './styles';

const ViewFileModal = ({ visible, urlViewObj, onCancel }) => {
  const [timerId, setTimerId] = useState(null);
  const [loading, setLoading] = useState(true);

  const imageRef = useRef(null);

  useEffect(() => {
    // console.log(urlViewObj, 'urlViewObj')
    // if (!urlViewObj?.isPDF) {
    setLoading(true);
    const interval = setInterval(() => {
      // console.log(interval, 'interval');
      if (!timerId) setTimerId(interval);
      if (document.getElementById('iframeID')) {
        document.getElementById('iframeID').src = document.getElementById(
          'iframeID',
        ).src;
      }
    }, 3000);
    setTimerId(interval);
    return () => clearInterval(interval);
    // $('#iframeID').on('load', function () {
    //   console.log('iframe ready')
    //   clearInterval(timerId);
    // });
    // }
    // setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearLoadIframe = () => {
    // console.log('clearLoadIframe', timerId)
    try {
      setLoading(false);
      // console.log('clearLoadIframe', timerId)
      clearInterval(timerId);
    } catch (err) {
      // console.log('err', err)
      setLoading(false);
    }
    // if (timerId) {
    //   clearInterval(timerId);
    //   setLoading(false);
    // }
  };

  const handleClose = () => {
    onCancel();
    // setLoading(true);
  };

  const handleImageLoaded = () => {
    if (imageRef?.current?.complete) {
      setLoading(false);
    }
  }

  return (
    <DrawerStyles
      visible={visible}
      onClose={handleClose}
      footer={null}
      width="100vw"
      title={urlViewObj?.name}
      destroyOnClose
    >
      <div className="holds-the-iframe">
        {loading && <Spin className="loading-file" />}
        {urlViewObj.isPDF && urlViewObj.isPDF && (
          <iframe
            title="viewFile"
            id="iframeID"
            name="iframeID"
            src={urlViewObj?.url}
            onLoad={clearLoadIframe}
          />
        )}
        {!urlViewObj.isPDF && !urlViewObj.isImage && (
          <iframe
            title="viewFile"
            id="iframeID"
            name="iframeID"
            src={urlViewObj?.url}
            onLoad={clearLoadIframe}
          />
        )}
        {urlViewObj.isImage && (
          <div className="div-image">
            <img
              src={urlViewObj.url}
              alt=""
              ref={imageRef}
              onLoad={handleImageLoaded}
            />
          </div>
        )}
      </div>
    </DrawerStyles>
  );
};

ViewFileModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  urlViewObj: PropTypes.object,
};

export default ViewFileModal;
