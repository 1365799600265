import React from 'react';
import PropTypes from 'prop-types';
import { getRecordData } from 'utils/tools';

import FormTreeSelect from '../../form/FormTreeSelect';
import { RestInputContext } from '../RestInputContext';

const RestTreeSelect = ({isFormatObjectValue, valuePropEdit, ...props}) => {
  const getDefaultValue = record => {
    const value = getRecordData(record, props.source);

    if(props.treeCheckable && isFormatObjectValue) {
      return value?.map((item) => {
        return getRecordData(item, valuePropEdit) || item[props.valueProp];
      });
    }
    if (
      value !== undefined &&
      value !== null
    ) {
      return value;
    }
    return props.defaultValue;
  };
  return (
    <RestInputContext.Consumer>
      {({ record, form }) => (
        <FormTreeSelect
          {...props}
          form={form}
          defaultValue={
            props.formatValue
              ? props.formatValue(getDefaultValue(record))
              : getDefaultValue(record)
          }
        />
      )}
    </RestInputContext.Consumer>
  );
};

RestTreeSelect.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  defaultValue: PropTypes.any,
  mode: PropTypes.string,
  valueProp: PropTypes.string,
  formatValue: PropTypes.func,
  treeCheckable: PropTypes.bool,
  isFormatObjectValue: PropTypes.bool,
  valuePropEdit: PropTypes.string,
};

RestTreeSelect.defaultProps = {
  treeCheckable: true,
}

export default RestTreeSelect;
