import React from 'react';
import i18next from 'i18next';
// import PropTypes from 'prop-types';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestMultiPhotos from 'components/RestInput/RestMultiPhotos';
import FromStyles from './styles';

const FormArchive = () => {

  // const checkToNumberCV = (rule, value, callback) => {
  //   if (value && typeof value === 'string' && value.trim().length === 0) {
  //     callback(i18next.t('error.empty'));
  //   } else if (value && value.trim() === data.fromNumber) {
  //     callback(i18next.t('input.toNumberCV.validateMsg.confirmToNumberCV'));
  //   } else {
  //     callback();
  //   }
  // };

  return (
    <FromStyles>
      <RestInputItem
        // required
        source="toNumber"
        header="documentaries.toNumber"
        placeholder="documentaries.toNumber"
        // isValidatorEmpty={false}
        rules={[
          {
            max: 100,
            message: i18next.t('input.numberCV.validateMsg.maxLength', {
              max: 100,
            }),
          },
          // {validator: checkToNumberCV},
        ]}
      />
      {/* <div className="title-label">{i18next.t("documentaries.completedCVFile")}</div> */}
      <RestMultiPhotos
        resource="documentaries"
        // defaultSourceKey="mediaIds"
        source="mediaIds"
        placeholder="documentaries.medias"
        header="documentaries.completedCVFile"
        typeFile={3}
      />
    </FromStyles>
  );
};

FormArchive.propTypes = {
  // data: PropTypes.object,
  // isComplete: PropTypes.bool,
}

export default FormArchive;
