import styled from 'styled-components';

export const ContentShowStyles = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  & > span.action-buttons-show {
    position: absolute;
    width: auto;
    right: 0;
  }
`;
