import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import RestDateTimePicker from 'components/RestInput/RestDateTimePicker';
import { DOCUMENTARIES_STATUS_TABLE, SEEN_DATA } from 'configs/localData';
// import FilterStyles from './styles';

const Filter = ({ format, ...props }) => {
  const tagsList = useSelector((state) => state.config.tags);

  return (
    // <FilterStyles>
    <Row
      {...props}
      gutter={24}
      style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
    >
      <Col xxl={3} xl={8} lg={8} md={8} xs={24}>
        <RestSelect
          source="isSeen"
          placeholder="documentaries.isSeen"
          resourceData={SEEN_DATA}
          valueProp="value"
          titleProp="text"
          formatText={(data) => i18next.t(data)}
          isShowSearch={false}
        />
      </Col>
      <Col xxl={4} xl={8} lg={8} md={8} xs={24}>
        <RestInputItem source="query" placeholder="documentaries.query" />
      </Col>
      <Col xxl={4} xl={8} lg={8} md={8} xs={24}>
        <RestInputItem source="executedUser" placeholder="documentaries.executedUser" />
      </Col>
      <Col xxl={3} xl={8} lg={8} md={8} xs={24}>
        <RestSelect
          source="status"
          placeholder="documentaries.status"
          resourceData={DOCUMENTARIES_STATUS_TABLE}
          valueProp="value"
          titleProp="text"
          formatText={(data) => i18next.t(data)}
          ruleType="number"
          isShowSearch={false}
        />
      </Col>
      <Col xxl={4} xl={8} lg={8} md={8} xs={24}>
        <RestSelect
          source="tags"
          placeholder="documentaries.tags"
          ruleType="array"
          mode="multiple"
          resourceData={tagsList || []}
          className="tags-select"
        />
      </Col>
      {/* <Col lg={6} lg={12} xs={24}>
        <RestInputItem source="title" placeholder="documentaries.title" />
      </Col> */}
      <Col xxl={3} xl={4} lg={4} md={4} xs={24}>
        <RestDateTimePicker
          source="startDate"
          placeholder="documentaries.startDate"
          isShowTime={false}
        />
      </Col>
      <Col xxl={3} xl={4} lg={4} md={4} xs={24}>
        <RestDateTimePicker
          source="endDate"
          placeholder="documentaries.endDate"
          isShowTime={false}
        />
      </Col>
    </Row>
    // </FilterStyles>
  );
};

Filter.propTypes = {
  format: PropTypes.func,
};

export default Filter;
