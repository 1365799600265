import styled from 'styled-components';

export const UserTagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${'' /* margin-top: 10px; */}
  .ant-avatar {
    margin-right: 10px;
    min-width: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link-class {
    word-break: break-word;
    color: ${({theme}) => theme.text.primary};
    &:hover, &:active {
    color: ${({theme}) => theme.palette.primary} ! important;
  }
  }
  .avatar-item {
    display: inline-flex;
  }
`;

export const UserPopoverWrapper = styled.div`
  display: inline-flex;
`;
