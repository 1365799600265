import React from 'react';
// import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { getDepartments, getPositions } from 'redux/config/selectors';
import { ACTIVE_TYPES } from 'configs/localData';
import i18next from 'i18next';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import RestSelect from '../../../../components/RestInput/RestSelect';

const Filter = props => {
  const departmentsData = useSelector(state => getDepartments(state));
  const positionsData = useSelector(state => getPositions(state));
  return (
    <Row {...props} gutter={24}>
      <Col xl={4} lg={8} md={12} xs={24}>
        <RestInputItem source="name" placeholder="users.name" />
      </Col>
      <Col xl={4} lg={8} md={12} xs={24}>
        <RestInputItem source="userName" placeholder="users.userName" />
      </Col>
      <Col xl={4} lg={8} md={12} xs={24}>
        <RestInputItem source="email" placeholder="users.email" />
      </Col>
      <Col xl={4} lg={8} md={12} xs={24}>
        <RestSelect
          source="departmentSlugs"
          placeholder="users.department"
          resourceData={departmentsData}
          valueProp="slug"
          titleProp="name"
        />
      </Col>
      <Col xl={4} lg={8} md={12} xs={24}>
        <RestSelect
          source="positionSlugs"
          placeholder="users.positions"
          resourceData={positionsData}
          valueProp="slug"
          titleProp="name"
        />
      </Col>
      <Col xl={4} lg={8} md={12} xs={24}>
        <RestSelect
          source="isActive"
          placeholder="users.isActive"
          resourceData={ACTIVE_TYPES}
          valueProp="value"
          titleProp="text"
          formatText={data => i18next.t(data)}
          isShowSearch={false}
        />
      </Col>
    </Row>
  );
};

Filter.propTypes = {};

export default Filter;
