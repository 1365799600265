import styled from 'styled-components';

const AnnouncementInfoStyles = styled.div`
  .header-item-announcement {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .top-item {
      display: flex;
      align-items: center;
      ${'' /* margin-bottom: 15px; */}
      flex-wrap: wrap;
      max-width: 80%;
      .label-priority, .sender-span {
        margin-bottom: 15px;
      }
    }
    .view-button {
      margin-bottom: 15px;
    }
    .ant-tag {
      margin-right: 20px;
    }
    ${
      '' /* .time-span {
      padding-right: 10px;
    }
    .sender-span {
      padding: 0px 10px;
    } */
    }
    ${
      '' /* .label-priority {
      border-right: 1px solid ${({ theme }) => theme.border.default};
      margin-right: 10px;
    } */
    }
    .time-span {
      ${'' /* border-left: 1px solid ${({ theme }) => theme.border.default}; */}
      border-right: 1px solid ${({ theme }) => theme.border.default};
    }
    .sender-span {
      & > i {
        color: ${({ theme }) => theme.palette.primary};
      }
      & > span {
        margin-left: 10px;
      }
    }
  }
  & > div:not(:last-child) {
    margin-bottom: 15px;
  }
  .name-item-announcement {
    font-weight: 600;
    font-size: 15px;
  }
  .view-total-item-announcement {
    display: flex;
    align-items: center;
    .totalView-span {
      margin-left: 5px;
    }
  }
  @media only screen and (max-width: 630px) {
    .header-item-announcement {
      ${
        '' /* display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start; */
      }
      .time-span {
        padding: 10px 0;
        border: none;
      }
      .sender-span {
        padding: 0;
        border: none;
      }
    }
  }
`;

export default AnnouncementInfoStyles;
