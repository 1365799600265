import React from 'react';
// import _ from "lodash";
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Button } from 'antd';
import { updateUser } from 'redux/auth/slice';
import { useDispatch } from 'react-redux';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import CRUDActions from 'redux/crudActions';
import ButtonGroupWrapper from './styles';

const ButtonGroup = props => {
  const dispatch = useDispatch();
  const handleSubmit = (form, record) => {
    form.validateFields(
      (err, { avatarUrl, userName, positions, department, ...values }) => {
        if (!err) {
          dispatch(
            updateUser({
              ...values,
              id: record?.id,
              ...(department && { departmentId: department?.id }),
              ...(positions && { positionIds: [positions] }),
              name: values.name && values.name.trim(),
              ...(!props.isProfile && {
                successAction: response =>
                  CRUDActions.users.editSuccess(response),
              }),
              ...(!props.isProfile && {
                failureAction: response =>
                  CRUDActions.users.editFailure(response),
              }),
            }),
          );
          props.setIsEdit(false);
        }
      },
    );
  };
  return (
    <RestInputContext.Consumer>
      {({ form, record }) => (
        <ButtonGroupWrapper>
          <Button onClick={() => props.setIsEdit(false)} icon="close" className="cancel-button">
            {i18n.t('button.cancel')}
          </Button>
          <Button
            type="primary"
            icon="check"
            onClick={() => handleSubmit(form, record)}
            className="save-button"
          >
            {i18n.t('button.save')}
          </Button>
        </ButtonGroupWrapper>
      )}
    </RestInputContext.Consumer>
  );
};
ButtonGroup.propTypes = {
  setIsEdit: PropTypes.func,
  isProfile: PropTypes.bool,
};

ButtonGroup.defaultProps = {};

export default ButtonGroup;
