import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { Form, Button, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';

import { FilterFormWrapper } from './styles';
import { RestInputContext } from '../../RestInput/RestInputContext';
import Box from '../../common/Box';

class FormComponent extends Component {

  componentDidMount = () => {
    const { form, history, keyTab } = this.props;
    this.unregisterHistoryListener = history.listen((location, action) => {
      // check clear filter when go back on normal page
      const isCheckBack = action === 'POP' && (keyTab ? location.pathname?.includes(keyTab) : true);
      
      // check clear filter khi chuyển tab qua lại 
      // (trang có nhiều tab nhưng dùng chung 1 table - dùng chung state - reducer) - Ex: Internal Messages
      const isCheckPush = action === 'PUSH' && keyTab && location.pathname?.includes(keyTab) && !location.search;

      if (isCheckBack || isCheckPush) {
        // console.log('reset')
        form.resetFields();
      }
    });
  };

  componentWillUnmount() {
    if (this.unregisterHistoryListener) this.unregisterHistoryListener();
  }

  onFilter = () => {
    const { form, format, retrieveList, resourceFilter } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        retrieveList({
          ...resourceFilter,
          pageNumber: 1,
          filter: {
            ...resourceFilter.filter,
            ...format(values),
          },
        });
      }
    });
  };

  onClear = () => {
    this.props.form.resetFields();
    this.props.retrieveList({ filter: {} });
  };

  render() {
    const { form, children, resourceFilter } = this.props;
    // const components = React.Children.map(children, element =>
    //   React.cloneElement(element, {
    //     form,
    //     record: resourceFilter.filter || {},
    //     retrieveList,
    //     required: false,
    //   }),
    // );

    return (
      <FilterFormWrapper>
        <Box className="box-filter">
          <Row gutter={24} className="row-filter-container">
            <Col lg={20} md={24} xs={24} className="col-filter-content">
              <RestInputContext.Provider
                value={{
                  form,
                  record: resourceFilter.filter || {},
                  handleSubmit: this.onFilter,
                  allowPressEnter: true,
                }}
              >
                {React.cloneElement(children, {
                  form,
                })}
              </RestInputContext.Provider>
            </Col>
            <Col lg={4} md={24} xs={24} className="col-actions">
              <div className="filterActions">
                <Button
                  type="primary"
                  onClick={this.onFilter}
                  className="filterButton action-button"
                >
                  {I18n.t('button.filter')}
                </Button>
                <Button
                  onClick={this.onClear}
                  className="clearButton action-button"
                >
                  {I18n.t('button.clearFilter')}
                </Button>
              </div>
              {/* <div className="filterActions">
              <Row gutter={10}>
                <Col span={12}>
                  <Button
                    type="primary"
                    onClick={this.onFilter}
                    className="filterButton"
                  >
                    {I18n.t('button.filter')}
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    onClick={this.onClear}
                    className="filterButton clearButton"
                  >
                    {I18n.t('button.clearFilter')}
                  </Button>
                </Col>
              </Row>
            </div> */}
            </Col>
          </Row>
        </Box>
      </FilterFormWrapper>
    );
  }
}

const FilterForm = Form.create()(withRouter(FormComponent));
const RestFilterForm = props => <FilterForm {...props} />;

FormComponent.propTypes = {
  children: PropTypes.node,
  retrieveList: PropTypes.func,
  form: PropTypes.object,
  format: PropTypes.func,
  resourceFilter: PropTypes.object,
  history: PropTypes.object,
  keyTab: PropTypes.string,
};

FormComponent.defaultProps = {
  format: values => values,
};

export default RestFilterForm;
