import { makeCRUDSlice } from '../crudCreator/slice';

export const RESOURCE = 'users';
const usersSlice = makeCRUDSlice(RESOURCE, {
  disableUser: (state, { payload }) => {
    state.itemLoadings = { ...state.itemLoadings, [payload.id]: false };
  },
  disableUserSuccess: (state, { payload: { data } }) => {
    state.data = {
      ...state.data,
      [data.id]: { ...state.data[data.id], ...data },
    };
    state.currentData = {
      ...state.currentData,
      ...data,
    };
    state.itemLoadings = { ...state.itemLoadings, [data.id]: false };
  },
  disableUserFailure: (state, { payload: { error, id } }) => {
    // state.loading = false;
    state.itemLoadings = { ...state.itemLoadings, [id]: false };
    state.error = error;
  },
  uploadAvatar: (state, { payload }) => {
    state.itemLoadings = { ...state.itemLoadings, [payload.id]: false };
  },
  // uploadAvatarSuccess: (state, { payload: { data } }) => {
  //   state.data = {
  //     ...state.data,
  //     [data.id]: { ...state.data[data.id], ...data },
  //   };
  //   state.currentData = {
  //     ...state.currentData,
  //     ...data,
  //   };
  //   state.itemLoadings = { ...state.itemLoadings, [data.id]: false };
  // },
  uploadAvatarFailure: (state, { payload: { error, id } }) => {
    // state.loading = false;
    state.itemLoadings = { ...state.itemLoadings, [id]: false };
    state.error = error;
  },
});

export const { actions, reducer } = usersSlice;

export default reducer;
