import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import styled from 'styled-components';
import { getValidData } from '../../utils/tools';
import { exportExcel } from '../../api/crud';

const StyledButton = styled(Button)`
  && {
    height: 32px;
    width: 40px;
    padding: 0;
  }
`;

const ExportExcelButton = ({ resource, resourceFilter }) => {
  const query = {
    pageSize: resourceFilter.pageSize,
    pageNumber: resourceFilter.pageNumber,
    filter: JSON.stringify(getValidData(resourceFilter.filter)),
  };
  return (
    <StyledButton onClick={() => exportExcel(resource, query)}>
      <svg
        width="21px"
        height="20px"
        viewBox="0 0 21 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <polygon
            id="path-1"
            points="0.00244399185 0.0102240326 12.1656636 0.0102240326 12.1656636 19.997556 0.00244399185 19.997556"
          />
        </defs>
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="ic/-text-size-copy"
            transform="translate(-2.000000, -2.000000)"
          >
            <g id="excel" transform="translate(2.000000, 2.000000)">
              <g id="Group-3">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Clip-2" />
                <path
                  d="M12.0022811,0.107494908 C11.8992261,0.0237474542 11.7635031,-0.0082688391 11.6338493,0.020610998 L0.337189409,2.62753564 C0.138370672,2.67323829 -0.00191446029,2.85107943 0,3.05507128 L0,17.8276578 C8.14663951e-05,18.0420367 0.156578411,18.2243177 0.368472505,18.2569043 L11.6651324,19.9948676 C11.9023218,20.0313646 12.1241548,19.8686354 12.160611,19.6314868 C12.1639919,19.6096945 12.1656619,19.5876578 12.1656619,19.5656212 L12.1656619,0.44814664 C12.1660285,0.315641548 12.1058656,0.190224033 12.0022811,0.107494908"
                  id="Fill-1"
                  fill="#00A152"
                  mask="url(#mask-2)"
                />
              </g>
              <path
                d="M20.4209369,18.2621589 L11.7311609,18.2621589 C11.4912016,18.2621589 11.2967006,18.0676171 11.2967006,17.8276578 C11.2967006,17.5876986 11.4912016,17.3931568 11.7311609,17.3931568 L19.9864358,17.3931568 L19.9864358,2.620611 L11.7311609,2.620611 C11.4912016,2.620611 11.2967006,2.42606925 11.2967006,2.18610998 C11.2967006,1.94615071 11.4912016,1.75160896 11.7311609,1.75160896 L20.4209369,1.75160896 C20.6608961,1.75160896 20.8553971,1.94615071 20.8553971,2.18610998 L20.8553971,17.8276578 C20.8553971,18.0676171 20.6608961,18.2621589 20.4209369,18.2621589"
                id="Fill-4"
                fill="#00A152"
              />
              <path
                d="M8.25527495,13.9172709 C8.10545825,13.9172709 7.96623218,13.8400815 7.88684318,13.7130346 L3.54195519,6.76126273 C3.40839104,6.56187373 3.46175153,6.29201629 3.6610998,6.15845214 C3.8604888,6.02492872 4.13034623,6.07824847 4.26391039,6.27763747 C4.26916497,6.28545825 4.27413442,6.29344196 4.27885947,6.30154786 L8.62370672,13.2533605 C8.7504277,13.4571487 8.68794297,13.7250509 8.48415479,13.8517719 C8.41543788,13.8944603 8.33617108,13.9171487 8.25527495,13.9172709"
                id="Fill-5"
                fill="#F8F6F4"
              />
              <path
                d="M3.91038697,13.9172709 C3.6704277,13.9167821 3.47629328,13.7218737 3.47678208,13.4819145 C3.47694501,13.4007739 3.49983707,13.3213035 3.54285132,13.2525051 L7.88769857,6.30069246 C8.00802444,6.09307536 8.27389002,6.02232179 8.48150713,6.14264766 C8.6890835,6.26297352 8.7598778,6.5288391 8.63955193,6.73641548 C8.63482688,6.74456212 8.6298167,6.75258656 8.62460285,6.7603666 L4.27971487,13.7121792 C4.2003666,13.8398371 4.06069246,13.9173931 3.91038697,13.9172709"
                id="Fill-6"
                fill="#F8F6F4"
              />
              <path
                d="M15.2070876,18.2621589 C14.9671283,18.2621589 14.7725866,18.0676171 14.7725866,17.8276578 L14.7725866,2.18610998 C14.7725866,1.94615071 14.9671283,1.75160896 15.2070876,1.75160896 C15.4470468,1.75160896 15.6415479,1.94615071 15.6415479,2.18610998 L15.6415479,17.8276578 C15.6415479,18.0676171 15.4470468,18.2621589 15.2070876,18.2621589"
                id="Fill-7"
                fill="#00A152"
              />
              <path
                d="M20.4209369,15.6552342 L11.7311609,15.6552342 C11.4912016,15.6552342 11.2967006,15.4606925 11.2967006,15.2207332 C11.2967006,14.9807739 11.4912016,14.7862322 11.7311609,14.7862322 L20.4209369,14.7862322 C20.6608961,14.7862322 20.8553971,14.9807739 20.8553971,15.2207332 C20.8553971,15.4606925 20.6608961,15.6552342 20.4209369,15.6552342"
                id="Fill-8"
                fill="#00A152"
              />
              <path
                d="M20.4209369,13.0483096 L11.7311609,13.0483096 C11.4912016,13.0483096 11.2967006,12.8537678 11.2967006,12.6138086 C11.2967006,12.3738493 11.4912016,12.1793075 11.7311609,12.1793075 L20.4209369,12.1793075 C20.6608961,12.1793075 20.8553971,12.3738493 20.8553971,12.6138086 C20.8553971,12.8537678 20.6608961,13.0483096 20.4209369,13.0483096"
                id="Fill-9"
                fill="#00A152"
              />
              <path
                d="M20.4209369,10.4413849 L11.7311609,10.4413849 C11.4912016,10.4413849 11.2967006,10.2468432 11.2967006,10.0068839 C11.2967006,9.76692464 11.4912016,9.57238289 11.7311609,9.57238289 L20.4209369,9.57238289 C20.6608961,9.57238289 20.8553971,9.76692464 20.8553971,10.0068839 C20.8553971,10.2468432 20.6608961,10.4413849 20.4209369,10.4413849"
                id="Fill-10"
                fill="#00A152"
              />
              <path
                d="M20.4209369,7.83446029 L11.7311609,7.83446029 C11.4912016,7.83446029 11.2967006,7.63991853 11.2967006,7.39995927 C11.2967006,7.16 11.4912016,6.96545825 11.7311609,6.96545825 L20.4209369,6.96545825 C20.6608961,6.96545825 20.8553971,7.16 20.8553971,7.39995927 C20.8553971,7.63991853 20.6608961,7.83446029 20.4209369,7.83446029"
                id="Fill-11"
                fill="#00A152"
              />
              <path
                d="M20.4209369,5.22753564 L11.7311609,5.22753564 C11.4912016,5.22753564 11.2967006,5.03299389 11.2967006,4.79303462 C11.2967006,4.55307536 11.4912016,4.3585336 11.7311609,4.3585336 L20.4209369,4.3585336 C20.6608961,4.3585336 20.8553971,4.55307536 20.8553971,4.79303462 C20.8553971,5.03299389 20.6608961,5.22753564 20.4209369,5.22753564"
                id="Fill-12"
                fill="#00A152"
              />
            </g>
          </g>
        </g>
      </svg>
    </StyledButton>
  );
};
ExportExcelButton.propTypes = {
  resource: PropTypes.string,
  resourceFilter: PropTypes.object,
};

export default ExportExcelButton;
