import React from 'react';
import PropTypes from 'prop-types';
// import { actions } from 'redux/documentBoxes/slice';
import { getIdByLocation } from 'utils/tools';

import Create from '../../rest/Create';
import FileForm from '../components/FileForm';

const BoxFilesCreate = props => {

  const folderId = getIdByLocation(props.location, 'documentBoxes');

  // const handleSubmitAction = ({ media, folderId, name,...data }) => {
  //   return actions.createFile({
  //     folderId,
  //     data: {
  //       ...data,
  //       name: name && name.trim(),
  //       mediaId: (media && media.id) || media,
  //     },
  //     options: { isBack: true },
  //   });
  // };

  const handleSubmit = ({ media, name,...data }) => {
    return({
      ...data,
      ...folderId && {parentId: folderId},
      title: name && name.trim(),
      mediaId: (media && media.id) || media,
    })
  }
  
  return (
    <Create
      {...props}
      resource="documentBoxes"
      header="boxFiles.createPage"
      // customOnSubmit={handleSubmitAction}
      defaultOptions={{ customApiResource: 'files', isGetAll: false }}
      formatOnSubmit={handleSubmit}
    >
      <FileForm />
    </Create>
  );};

BoxFilesCreate.propTypes = {
  location: PropTypes.object,
};

export default BoxFilesCreate;
