import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {Popconfirm, Button} from 'antd';
import { logout } from 'redux/auth/slice';
import i18n from 'i18next';
import LogoutStyles from './styles';

const LogoutComponent = ({currentUser}) => {
  const dispatch = useDispatch();
  return (
    <LogoutStyles>
      <Popconfirm
        placement="top"
        title={i18n.t('profile.confirmLogout')}
        onConfirm={() => {currentUser?.id && dispatch(logout(currentUser.id))}}
        okText={i18n.t('button.ok')}
        cancelText={i18n.t('button.cancel')}
      >
        <Button type="primary" icon="logout" className="lock-user-button">
          {i18n.t('header.logout')}
        </Button>
      </Popconfirm>
    </LogoutStyles>
  )
}

LogoutComponent.propTypes = {
  currentUser: PropTypes.object,
}

export default LogoutComponent;