import React, { useEffect } from 'react';
// import moment from 'moment';
// import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/documentBoxes/slice';
// import { Row, Col, Button, Icon, notification } from 'antd';
import i18next from 'i18next';
import {checkRole} from 'utils/tools';

import CreateButton from 'components/RestActions/CreateButton';
import PageTitle from 'components/common/PageTitle';
import CustomBreadcrumb from 'components/common/Breadcrumb';
// import UserInfo from 'components/RestField/UserInfo';

// import List from '../../rest/List';
// import RestFieldItem from '../../../components/RestField/RestFieldItem';
// import ActionGroup from '../../../components/RestActions/ActionGroup';
// import EditButton from '../../../components/RestActions/EditButton';
// import CustomButton from '../../../components/RestActions/CustomButton';
// import DeleteButton from '../../../components/RestActions/DeleteButton';
// import ModalViewFile from '../../../components/RestField/ModalViewFile'; 
// import Filter from '../components/Filter';
// import FolderTree from '../components/FolderTree';
// import ModalTreeFolder from '../components/ModalTreeFolder';
// import { getPrivateUrlApi } from '../../../api/uploadMedia';
import ListFolderFile from '../components/ListFolderFile';
import ListStyles from './styles';

const DocumentBoxesList = props => {

  const { match, location } = props;
  const isResultSearchScreen = (!!location.search?.includes('query='));
  const folderId = match.params.id;

  const selectedFolder = useSelector(state => state.documentBoxes.selectedFolder);
  // const currentUser = useSelector(state => state.auth.data);
  // const roleUser = useSelector(state => state.auth.role);
  const permissionData = useSelector((state) => state.auth.permissionData);
  const roles = useSelector(state => state.auth.role);
  const dispatch = useDispatch();

  useEffect(() => {
    folderId && dispatch(actions.getByIdFileOrFolder({ id: folderId }));
    // eslint-disable-next-line
  }, [folderId]);

  const customBreadcrumb = () => {

    if (isResultSearchScreen) {
      return [{ title: i18next.t('documentBoxes.searchResult'), path: '#' }];
    }
    if (folderId) {
      return [
        { title: i18next.t('documentBoxes.header'), path: '/documentBoxes' },
        { title: i18next.t(`${selectedFolder?.name || i18next.t('error.waitingUpdate')}`), path: `/documentBoxes/${folderId}` },
      ];
    }
    return [
      { title: i18next.t('documentBoxes.header'), path: '/documentBoxes' },
    ];
  };

  const checkCreateFolder = () => {
    if(checkRole(['Quan_Tri_Vien'], roles)) return true;
    return checkRole(['AllowAll', 'FolderAddNew'], permissionData) && folderId;
  }

  const checkCreateFile = () => {
    if(!selectedFolder?.parentId) return false;
    return checkRole(['AllowAll', 'FileInFolderAddNew'], permissionData) && match.params?.id;
  }

  return (
    <ListStyles>
      <PageTitle
        extraAction={(
          <div className="extraAction-div">
            {checkCreateFolder() && (
              <CreateButton
                header="button.createFolder"
                resource="documentboxs"
                gotoCreatePage={() =>
                  props.history.replace(`${location.pathname}${location.search}#documentBoxes/create`)}
              />
            )}
            {checkCreateFile() && (
              <CreateButton
                header="button.createFile"
                resource="documentboxs"
                gotoCreatePage={() =>
                  props.history.replace(`${location.pathname}${location.search}#boxFiles/create`)}
              />
            )}
          </div>
        )}
      >
        <CustomBreadcrumb data={customBreadcrumb()} />
      </PageTitle>
      <ListFolderFile
        {...props}
        folderId={folderId}
        isResultSearchScreen={isResultSearchScreen}
      />
      {/* <Row className="row-document">
        <Col xl={6} lg={6} md={6} sm={24} xs={24} className="col-folder">
          <div className="col-folder-web">
            <FolderTree />
          </div>
          <div className="col-folder-mobile">
            <Button
              onClick={toggleVisible}
              // icon="folder-open"
              className="folder-selected"
            >
              <span className="span-button-folder-selected">
                <Icon type="folder-open" />
                {selectedFolder?.name}
              </span>
              <Icon type="down" />
            </Button>
          </div>
        </Col>
        <Col
          xl={18}
          lg={18}
          md={18}
          sm={24}
          xs={24}
          className="col-list-document"
        >
          <List
            {...props}
            resource="documentBoxes"
            defaultOptions={{
              customApiResource: `documentboxs/${selectedFolder?.id}/files`,
            }}
            hasSearch={false}
            hasExport={false}
            hasCreate={false}
            noCardWrapper
            isDynamicApiResource
            widthTable="1000px"
            filter={(
              <Filter
                format={({ startDate, endDate, ...data }) => ({
                  ...data,
                  startDate: startDate
                    ? moment(startDate).startOf('days').utc().toISOString()
                    : startDate,
                  endDate: endDate
                    ? moment(endDate).endOf('days').utc().toISOString()
                    : endDate,
                })}
              />
            )}
          >
            <RestFieldItem
              source="documentName"
              header="boxFiles.documentName"
            />
            <RestFieldItem
              source="createdUser"
              header="boxFiles.createdUser"
              format={(data, record) => (
                <UserInfo record={record.createdUser} nameProp="name" isShowPopover />
              )}
              width={250}
            />
            <RestFieldItem
              source="createdDateUtc"
              header="boxFiles.createdAt"
              format={(data) => (
                <span>{data && moment(data).format('DD-MM-YYYY HH:mm')}</span>
              )}
              width={150}
            />
            <ActionGroup widthAction={160}>
              <CustomButton type="view" handleViewFile={handleViewFile} />
              <CustomButton type="download" />
              <EditButton
                roles={['FileFolderEdit']}
                checkShowAction={checkShowAction}
                gotoEditPage={(idFile) =>
                  props.history.replace(
                    `#boxFiles/${selectedFolder?.id}/${idFile}/edit`,
                  )}
              />
              <DeleteButton
                roles={['FileFolderRemove']}
                checkShowAction={checkShowAction}
                customResource="boxFiles"
                customMessageItemName="boxFiles.customMessageItemName"
                deleteItem={handleDeletedFile}
              />
            </ActionGroup>
          </List>
        </Col>
      </Row> */}
      {/* <ModalTreeFolder visible={visibleModalTree} onCancel={toggleVisible} /> */}
      {/* {visibleModalViewFile && (
        <ModalViewFile 
          visible
          onCancel={onCancel}
          urlViewObj={urlViewObj}
        />
      )} */}
    </ListStyles>
  );};

DocumentBoxesList.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
};

export default DocumentBoxesList;
