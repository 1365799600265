import React from 'react';
import PropTypes from 'prop-types';
// import { actions } from 'redux/documentBoxes/slice';
import { getIdByLocation } from 'utils/tools';

import Create from '../../rest/Create';
import Form from '../components/Form';

const DocumentBoxesCreate = props => {
  
  const folderId = getIdByLocation(props.location, 'documentBoxes');

  // const handleSubmitAction = ({name, ...data}) => {
  //   return actions.createFolder({
  //     data: {
  //       ...data,
  //       folderName: name && name.trim(),
  //       ...folderId && {parentId: folderId},
  //     },
  //     options: { isBack: true },
  //   });
  // };

  return (
    <Create
      {...props}
      resource="documentBoxes"
      defaultOptions={{ customApiResource: 'files', isGetAll: false }}
      formatOnSubmit={({name, ...data}) => ({
        ...data,
        title: name && name.trim(),
        ...folderId && {parentId: folderId},
      })}
      // customOnSubmit={handleSubmitAction}
    >
      <Form />
    </Create>
  );};

DocumentBoxesCreate.propTypes = {
  location: PropTypes.object,
};

export default DocumentBoxesCreate;
