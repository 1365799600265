const theme = {
  palette: {
    primary: '#FFC107',
    lightPrimary: '#4cb1e8',
    secondary: '#82D4D9',
    loadingBackgroundColor: '#2c3e51cc',
    color: ['#788195', '#E4E6E9', '#f8b51d'],
    dark: '#000444',
  },
  fonts: {
    primary: 'Roboto',
  },
  fontWeight: {
    thin: 100, // Thin
    utraLight: 200, // Ultra Light
    light: 300, // Light
    regular: 400, // Regular
    medium: 500, // Medium
    semibold: 600, // Semibold
    bold: 700, // Bold
    heavy: 800, // Heavy
    black: 900, // Black
  },
  background: {
    primary: '#FFC107',
    warning: '#f8b51d',
    error: '#ed1558',
    content: '#fff',
    // container: '#f5f6fc',
    container: '#eaeaea',
    input: '#efeff0',
    disabled: '#969696',
    headerTable: '#ffff',
    gray: '#f0f0f0',
    contentItem: '#f8fcff',
    notSeenNoti: '#B2DFDB',
    notSeenTask: '#B2DFDB',
    clearButton: '#ffc10726',
    fileItem: '#0569ff26',
    cancelButton: '#F0F4FC',
    notSeenTaskExecute: '#FFCCBC',
    selectedFolder: '#FFF3CF',
  },
  text: {
    // primary: '#2F3F60', // color-design
    primary: '#1f2933', // color-change
    text: '#1f2933',
    lightPrimary: '#3e4c59',
    // secondary: '#a4a4a4',
    secondary: '#8691AD',
    tabTitle: '#7f817c',
    empty: '#969696',
    highlight: '#5d4ec2',
    disabled: '#969696',
    formLabel: '#2F3F60',
    headerTable: '#0f100d',
    note: '#878787',
    formIcon: '#41433f',
    fileItem: '#0569FF',
    title: '#000000',
  },
  border: {
    default: '#D7E0F3',
    light: '#e4e7eb',
  },
  scrollbar: {
    thumb: '#b7b6c2',
    track: '#f0f3fa',
  },
  color: {
    gray: '#a3a3a3',
    green: '#4fcea2',
    brightGreen: '#52c41a',
    red: '#e64c38',
    blue: '#0992d0',
    orange: '#f5a623',
    pink: '#F75D81',
    limeGreen: '#4fcea2',
    lightGreen: '#3ebac2',
    blueShade: '#2d7fd3',
    yellow: '#FFCA28',
    violet: '#665ca7',
    purple: '#ac009f',
    forestGreen: '#6ba96b',
    coral: '#ff7f50',
  },
  alert: {
    error: 'red',
    success: '#4fcea2',
  },
  card: {
    header: '#d4d2f450',
  },
  sidebar: {
    activeText: 'white',
    inActiveText: '#b6b6b6',
    activeBG: '#373B53',
  },
  drawer: {
    headerTitle: '#000000',
    closeIcon: '#111111',
  },
  table: {
    // headerBackground: '#eff2f9',
    // headerBackground: '#F3F6FF',
    headerBackground: '#dde2f1',
    headerColor: '#50649c',
    color: '#7286a2',
  },
  folderTree: {
    colorIcon: '#0569FF',
    colorText: '#2F3F60',
  },
};
module.exports = theme;
