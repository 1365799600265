import styled from 'styled-components';

const FormStyles = styled.div`
.ant-select-selection--single {
  display: inline-table;
  width: 100%;
  .avatar-item {
    padding-bottom: 5px;
  }
}
`;

export default FormStyles;