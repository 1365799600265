import React from 'react';
import { Spin } from 'antd';
import LaunchScreenStyles from './styles';

const LaunchScreen = () => {
  return (
    <LaunchScreenStyles>
      <Spin size="large" />
    </LaunchScreenStyles>
  );
};
export default LaunchScreen;
