import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider, Empty } from 'antd';
import viVN from 'antd/es/locale-provider/vi_VN';
import 'moment/locale/vi';
import store, { history } from './redux/store';
import theme from './configs/theme';
import Routes from './routes';
import './configs/language';
import * as serviceWorker from './serviceWorker';
import AppWrapper, { GlobalStyle } from './appStyle';
// import { initFirebase } from './api/firebase';

// initFirebase();
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ConfigProvider locale={viVN}>
        <AppWrapper>
          <GlobalStyle />
          <ConnectedRouter history={history}>
            <ConfigProvider renderEmpty={() => <Empty />}>
              <Routes />
            </ConfigProvider>
          </ConnectedRouter>
        </AppWrapper>
      </ConfigProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
