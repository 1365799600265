import styled from 'styled-components';

const TreeStyles = styled.div`

.rst__tree > div, .ReactVirtualized__Grid {
  height: 100%!important;
}


.rstcustom__rowWrapper{
  padding: 0;
}
.rstcustom__collapseButton {
  display: none;
}
.rst__node:first-child .rst__lineBlock {
  display: none;
}
.rst__nodeContent {
  left: 0;
}
.rstcustom__rowLabel {
  white-space: pre-wrap;
  padding-right: 0;
}
.rstcustom__rowContents {
  border: none;
  box-shadow: none;
  padding-left: 2px;
}
.rstcustom__lineChildren {
&::after {
  height: 60px;
  z-index: 1;
}
}
.ant-avatar {
  z-index: 2;
}
.rst__lineHalfHorizontalRight::before {
  background: repeating-linear-gradient(90deg, #ffffff, #ffffff 5px, #80B3FF 5px, #80B3FF 10px)
}
.rstcustom__lineChildren::after,
.rst__lineFullVertical::after, 
.rst__lineHalfVerticalTop::after, 
.rst__lineHalfVerticalBottom::after {
  background: repeating-linear-gradient( 180deg, #ffffff, #ffffff 5px, #80B3FF 5px, #80B3FF 10px)
}
`;

export default TreeStyles;
