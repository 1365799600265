import { createSlice, createAction } from '@reduxjs/toolkit';
// import {uniq, flatten} from 'lodash';
import { decryptingToken } from 'utils/tools';

export const initialState = {
  isAuthenticated: !!localStorage.getItem('sessionToken'),
  // isAuthenticated: true,
  data: {
    name: localStorage.getItem('name') || '',
    id: localStorage.getItem('id'),
  },
  role: null,
  loginError: false,
  loginSuccess: false,
  permissionData: null,
  isActive: true,
};

const { actions, reducer } = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    login: state => {
      state.loading = true;
    },
    loginSuccess: (state, { payload }) => {
      const res = decryptingToken(localStorage.getItem('sessionToken'));
      state.loading = false;
      state.data = payload;
      // state.role = payload.role;
      state.role = res.role;
      // state.roleSlug = ['Van_Thu'];
      // state.permissionData = uniq(flatten(payload.roles?.map(item => item.permissions)));
      // state.permissionData = ['AllowAll'];
      state.permissionData = res.permissions;
      state.isAuthenticated = payload.isActive;
      state.loginError = false;
      state.loginSuccess = true;
      state.isFirstLogin = payload.isFirstLogin;
    },
    logout: state => {
      state.isAuthenticated = false;
    },
    loginFail: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.loginError = payload;
      state.loginSuccess = false;
    },
    getCurentUserSuccess: (state, { payload }) => {
      const res = decryptingToken(localStorage.getItem('sessionToken'));
      state.data = payload;
      state.role = res.role;
      // state.roleSlug = ['Van_Thu'];
      // state.permissionData = uniq(flatten(payload.roles?.map(item => item.permissions)));
      // state.role = 'admin';
      state.permissionData = res.permissions;
      state.isFirstLogin = payload.isFirstLogin;
      state.isAuthenticated = payload.isActive;
    },
    getCurentUserFailure: (state, { payload }) => {
      state.error = payload;
    },
    updateUser: state => {
      state.loading = false;
    },
    updateUserSuccess: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isFirstLogin = false;
    },
    updateUserFailure: state => {
      state.loading = false;
      state.isFirstLogin = false;
    },
    forgotPassword: state => {
      state.loading = true;
    },
    forgotPasswordSuccess: state => {
      state.loading = false;
    },
    forgotPasswordFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    resetPasswordSuccess: state => {
      state.loading = false;
    },
    resetPasswordFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    registerSuccess: state => {
      state.isAuthenticated = true;
      state.loginError = false;
      state.loginSuccess = true;
    },
    registerFail: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    getPermissionsSuccess: (state, { payload }) => {
      state.loading = false;
      state.permissionData = payload;
    },
    getPermissionsFail: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const registerWithToken = createAction('Auth/registerWithToken');
export const register = createAction('Auth/register');
export const resetPassword = createAction('Auth/resetPassword');
// export const login = createAction('Auth/login');
export const getCurentUser = createAction('Auth/getCurentUser');
export const getPermissions = createAction('Auth/getPermissions');

export const {
  login,
  loginSuccess,
  logout,
  loginFail,
  updateUser,
  updateUserFailure,
  updateUserSuccess,
  getCurentUserFailure,
  getCurentUserSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  forgotPassword,
  registerFail,
  registerSuccess,
  getPermissionsSuccess,
  getPermissionsFail,
} = actions;

export default reducer;
