import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserInfo from 'components/RestField/UserInfo';
import moment from 'moment';
import i18next from 'i18next';
import { Button } from 'antd';
import SeenUsersStyles, { ModalStyles } from './styles';

const SeenUsers = ({ totalSeen, totalReceiver, seenUsers }) => {
  const [visible, setVisible] = useState(false);
  return (
    <SeenUsersStyles>
      {totalSeen ? (
        <Button className="seen-users" onClick={() => setVisible(true)}>
          {totalReceiver ? (
            <strong>{`${totalSeen}/${totalReceiver}`}</strong>
          ) : (
            <strong>{totalSeen}</strong>
          )}

          <span>{i18next.t('internalMessages.seenUsers')}</span>
        </Button>
      ) : (
        <div className="empty-data">{i18next.t('seen.emptyData')}</div>
      )}
      <ModalStyles
        visible={visible}
        title={i18next.t('seen.seenUsersTitle')}
        closable
        footer={null}
        onCancel={() => setVisible(false)}
      >
        {seenUsers?.map((user, index) => (
          <div className="item-user-seen" key={String(index)}>
            <UserInfo
              record={user?.user}
              note={
                user.seenDate &&
                moment(user.seenDate).format('DD-MM-YYYY HH:mm')
              }
              isShowPopover
            />
          </div>
        ))}
      </ModalStyles>
    </SeenUsersStyles>
  );
};

SeenUsers.propTypes = {
  totalSeen: PropTypes.number,
  totalReceiver: PropTypes.number,
  seenUsers: PropTypes.array,
};

export default SeenUsers;
