import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { goBack as goBackAction, replace } from 'connected-react-router';
import { Icon } from 'antd';
import { closeModal as closeModalAction } from 'redux/modal/slice';
import CRUDActions from '../../../redux/crudActions';
import RestCreateComponent from '../../../components/RestLayout/Create';
import Text from '../../../components/common/Text';
import crudSelectors from '../../../redux/crudSelectors';

const RestCreate = props => {
  // const onBack = () => {
  //   const { route, closeModal, goBack } = props;
  //   if (!route) {
  //     goBack();
  //   } else {
  //     closeModal();
  //   }
  // };
  const onBack = () => {
    const { visibleModal, goBack, closeModal } = props;
    if (!visibleModal) {
      goBack();
    } else {
      closeModal();
    }
  };

  const { showModal, header, resource } = props;
  return !showModal ? (
    <RestCreateComponent {...props} onBack={onBack} />
  ) : (
    <>
      {header !== null && (
        <Text type="h4" className="modalTitleContent">
          <div className="modalTitle">
            {!header || typeof header === 'string'
              ? I18n.t(header || `${resource}.createPage`)
              : header}
          </div>
          <Icon onClick={onBack} className="modalBtnBack" type="ic-close" />
        </Text>
      )}
      <RestCreateComponent {...props} onBack={onBack} />
    </>
  );
};

const mapStateToProps = (state, props) => ({
  route: state.modal.current,
  record: crudSelectors[props.resource].getDefaultCreateData(state, props),
  loading: crudSelectors[props.resource].getCreateLoading(state, props),
  error: crudSelectors[props.resource].getError(state, props),
  location: state.router.location,
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: data => {
      if (props.customOnSubmit) {
        dispatch(
          props.customOnSubmit(data, { isBack: true, isGetAll: true, ...props.defaultOptions }),
        );
      } else
        dispatch(
          CRUDActions[props.resource].create(data, {
            isBack: true,
            isGetAll: true,
            ...props.defaultOptions,
          }),
        );
    },
    // gotoShowPage: id =>
    //   props.history.push(
    //     `${props.match.path.replace('create', '')}/${id}/edit`,
    //   ),
    gotoShowPage: id =>
      dispatch(replace(`${props.location.pathname}/${id}/show)`)),
    closeModal: () => dispatch(closeModalAction()),
    goBack: () => dispatch(goBackAction()),
  };
};

RestCreate.propTypes = {
  closeModal: PropTypes.func,
  resource: PropTypes.string,
  header: PropTypes.any,
  route: PropTypes.string,
  showModal: PropTypes.bool,
  goBack: PropTypes.func,
  visibleModal: PropTypes.bool,
};
const ConnectedRestCreate = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestCreate);

ConnectedRestCreate.propTypes = {
  goShowPageWhenSuccess: PropTypes.bool,
};
ConnectedRestCreate.defaultProps = {
  goShowPageWhenSuccess: true,
  defaultOptions: {
    isBack: true,
  },
};
export default ConnectedRestCreate;
