import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Row, Col } from 'antd';
import { isArray } from 'lodash';
import { useSelector } from 'react-redux';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { ACTIVITY_ROLES } from 'configs/localData';
import { checkRole } from 'utils/tools';
import UsersReceivedStyles from './styles';

const UsersReceived = ({ usersData }) => {
  const innerWidthWindow = window.innerWidth;

  const dataActivityRoles = ACTIVITY_ROLES?.filter(
    item => item.value > 1 && item.value < 5,
  );
  const roleUserTask = useSelector(
    state => state.documentaries.currentData?.role,
  );

  const getActivityRoles = user => {
    // console.log(user, 'user');
    const rolesUser = user?.roles?.map(item => item.slug);
    const roleGiamDoc = checkRole(['Giam_Doc'], rolesUser);
    const roleTruongPhoPhong = checkRole(
      ['Truong_Phong', 'Pho_Phong'],
      rolesUser,
    );

    let rest = dataActivityRoles;

    switch (roleUserTask) {
      case 1:
        if (roleGiamDoc) {
          rest = ACTIVITY_ROLES?.filter(
            item => item.value === 2 || item.value === 7 || item.value === 8,
          );
          return { data: rest, defaultValue: rest?.[0]?.value };
        }
        rest = dataActivityRoles?.filter(item => item.value > 2);
        return { data: rest, defaultValue: rest?.[1]?.value }; // giam doc thi default la phoi hop

      case 3:
        if (roleTruongPhoPhong) {
          rest = ACTIVITY_ROLES?.filter(
            item => item.value === 3 || item.value === 4,
          );
          return { data: rest, defaultValue: rest?.[0]?.value };
        }
        rest = ACTIVITY_ROLES?.filter(item => item.value === 5);
        return { data: rest, defaultValue: rest?.[0]?.value };

      case 4:
        if (roleTruongPhoPhong) {
          rest = ACTIVITY_ROLES?.filter(
            item => item.value === 3 || item.value === 4,
          );
          return { data: rest, defaultValue: rest?.[0]?.value };
        }
        rest = ACTIVITY_ROLES?.filter(item => item.value === 6);
        return { data: rest, defaultValue: rest?.[0]?.value };

      default:
        return { data: rest, defaultValue: rest?.[0]?.value };
    }
  };

  return (
    <RestInputContext.Consumer>
      {({ form }) => (
        <UsersReceivedStyles>
          <div className="list-users-received">
            <div className="label-title">
              {i18next.t('activity.receivedUserList')}
            </div>
            {innerWidthWindow > 630 ? (
              <div className="content-list">
                <Row className="header-list" gutter={24}>
                  <Col span={6}>{i18next.t('activity.receivedUser')}</Col>
                  <Col span={6}>{i18next.t('activity.role')}</Col>
                  <Col span={12}>{i18next.t('activity.note')}</Col>
                </Row>
                {isArray(form.getFieldValue('items')) &&
                  form.getFieldValue('items')?.map((item, index) => {
                    const current =
                      isArray(usersData) &&
                      usersData?.find(user => user?.id === item);
                    const dataResourceObj = getActivityRoles(current);
                    return (
                      <Row
                        gutter={24}
                        key={String(index)}
                        className="row-item-user"
                      >
                        <Col span={5}>{current?.name}</Col>
                        <Col span={7}>
                          <RestSelect
                            required
                            source={`users[${index}].role`}
                            placeholder="activity.role"
                            resourceData={dataResourceObj?.data}
                            valueProp="value"
                            titleProp="text"
                            formatText={data => i18next.t(data)}
                            defaultValue={dataResourceObj.defaultValue}
                            isShowSearch={false}
                            allowClear={false}
                            ruleType="number"
                          />
                        </Col>
                        <Col span={12}>
                          <RestInputItem
                            // required
                            source={`users[${index}].notes`}
                            placeholder="activity.note"
                            rules={[
                              {
                                max: 500,
                                message: i18next.t(
                                  'input.content.validateMsg.maxLength',
                                  {
                                    max: 500,
                                  },
                                ),
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            ) : (
              <div className="users-list-mobile">
                {isArray(form.getFieldValue('items')) &&
                  form.getFieldValue('items')?.map((item, index) => {
                    const current =
                      isArray(usersData) &&
                      usersData?.find(user => user?.id === item);
                    const dataResourceObj = getActivityRoles(current);

                    return (
                      <div className="item-mobile" key={String(index)}>
                        <Row gutter={24} className="name-user-mobile item-user">
                          <Col span={8} className="label-mobile">
                            {`${i18next.t('activity.receivedUser')}: `}
                          </Col>
                          <Col span={16} className="value-name">
                            {isArray(usersData) &&
                              usersData.find(user => user?.id === item)?.name}
                          </Col>
                        </Row>

                        <Row gutter={24} className="role-user-mobile item-user">
                          <Col span={8} className="label-mobile">
                            {`${i18next.t('activity.role')}: `}
                          </Col>
                          <Col span={16} className="value-name">
                            <RestSelect
                              required
                              source={`users[${index}].role`}
                              placeholder="activity.role"
                              resourceData={dataResourceObj?.data}
                              valueProp="value"
                              titleProp="text"
                              formatText={data => i18next.t(data)}
                              defaultValue={dataResourceObj?.defaultValue}
                              isShowSearch={false}
                              allowClear={false}
                              ruleType="number"
                            />
                          </Col>
                        </Row>

                        <Row gutter={24} className="note-user-mobile item-user">
                          <Col span={8} className="label-mobile">
                            {`${i18next.t('activity.note')}: `}
                          </Col>
                          <Col span={16} className="value-name">
                            <RestInputItem
                              // required
                              source={`users[${index}].notes`}
                              placeholder="activity.note"
                              rules={[
                                {
                                  max: 500,
                                  message: i18next.t(
                                    'input.content.validateMsg.maxLength',
                                    {
                                      max: 500,
                                    },
                                  ),
                                },
                              ]}
                            />
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </UsersReceivedStyles>
      )}
    </RestInputContext.Consumer>
  );
};

UsersReceived.propTypes = {
  usersData: PropTypes.array,
};
export default UsersReceived;
