import { makeCRUDSlice } from '../crudCreator/slice';

export const RESOURCE = 'notifications';

const notificationsSlice = makeCRUDSlice(RESOURCE, {
  getNotificationCount: () => {},
  getNotificationCountSuccess: (state, { payload }) => {
    state.totalNewAnnouncement = payload.totalNewAnnouncement;
    // state.totalNewDocumentary = payload.totalNewDocummentary;
    state.totalNewExternalTask = payload.totalNewExternalTask;
    state.totalNewInternalTask = payload.totalNewInternalTask;
    state.totalNewNotification = payload.totalNewNotification;
  },
  getNotificationCountFailure: (state, { payload }) => {
    state.error = payload;
  },

  recountNotification: (state, { payload: { type = 'notify' } }) => {
    switch (type) {
      case 'notify':
        state.totalNewAnnouncement =
          state.totalNewAnnouncement > 0 && state.totalNewAnnouncement - 1;
        break;
      case 'internal':
        state.totalNewInternalTask =
          state.totalNewInternalTask > 0 && state.totalNewInternalTask - 1;
        break;
      case 'external':
        state.totalNewExternalTask =
          state.totalNewExternalTask > 0 && state.totalNewExternalTask - 1;
        break;
      default:
        break;
    }
  },

  getAllNotifications: state => {
    state.loading = true;
  },
  getAllNotificationsSuccess: (state, { payload: { data } }) => {
    state.ids = [...data.ids];
    state.data = { ...data.data };
    state.loading = false;
  },
  getAllNotificationsFailure: (state, { payload }) => {
    state.error = payload;
    state.loading = false;
  },
  watchFirebaseNotifications: () => {},
  watchFirebaseNotificationsFailure: (state, { payload }) => {
    state.error = payload;
  },
  updateInstallations: () => {},
  updateInstallationsFailure: (state, { payload }) => {
    state.error = payload;
  },
  updateNotifications: (state, { payload }) => {
    state.notSeen += 1;
    state.ids = [payload.id, ...state.ids];
    state.data = {
      ...state.data,
      [payload.id]: payload,
    };
  },
  trackingResetAnnouncements: () => {},
  trackingResetNotifications: () => {},
  trackingResetNotificationsSuccess: (state, { payload: { type = 'notify' }}) => {
    switch (type) {
      case 'notify':
        state.totalNewNotification = 0;
        break;
      case 'announcements':
        state.totalNewAnnouncement = 0;
        break;
      default:
        break;
    }
  },
  trackingResetNotificationsFailure: (state, { payload: { type = 'notify', error }}) => {
    state.error = error;
    switch (type) {
      case 'notify':
        state.totalNewNotification = 0;
        break;
      case 'announcements':
        state.totalNewAnnouncement = 0;
        break;
      default:
        break;
    }
  },
  seenNotification: () => {},
  seenNotificationSuccess: (state, { payload }) => {
    state.data = {
      ...state.data,
      [payload]: {
        ...state.data[payload],
        isSeen: true,
      },
    };
  },
  seenNotificationFailure: (state, { payload }) => {
    state.error = payload;
  },
});

export const { actions, reducer } = notificationsSlice;

export default reducer;
