import styled from 'styled-components';
import { Form } from 'antd';

export const FilterFormWrapper = styled(Form)`
  margin-bottom: 10px;
  .box-filter {
    padding: 0;
    background: none;
    border: none;
    margin-bottom: 10px;
  }
  .row-filter-container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    .col-actions {
      display: flex;
      align-items: center;
    }
  }
  .filterActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
    & > .filterButton {
      margin-right: 24px;
    }
    & > .action-button {
      width: 50%;
    }
  }
  .border {
  }
  .filterButton {
    width: 100%;
    font-size: 14px;
  }
  .clearButton {
    background: ${({ theme }) => theme.background.clearButton};
    color: ${({ theme }) => theme.text.primary};
    border: none;
    &:hover {
      color: ${({theme}) => theme.palette.primary};
    }
  }
  .ant-form-item {
    margin-bottom: 0px;
    margin-right: -1px;
  }
  .ant-form-item-label label {
    color: ${({ theme }) => theme.text.itemLabel};
  }
  .date-picker-wrapper {
    height: 50px;
    .ant-calendar-picker {
      height: 40px;
      line-height: 40px;
      margin-bottom: 10px;
      input {
        padding-right: 25px;
      }
    }
  }
  .ant-select-selection, .ant-input {
    border: none;
  }
`;
