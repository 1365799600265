import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Upload,
  Avatar,
  // Progress,
  Form,
  Input,
  Icon,
  notification,
  Spin,
} from 'antd';
import I18n from 'i18next';
import { loginFail } from 'redux/auth/slice';
import { connect } from 'react-redux';
import AvatarCropperModal from './AvatarCropperModal';
import UploadImageWrapper from './style';
import user from '../../../assets/images/user.png';
import { getUrl, uploadMedia } from '../../../api/uploadMedia';

const uploadUrl = `${process.env.REACT_APP_SERVER_URL}/api/medias`;
const FormItem = Form.Item;

// const confirm = Modal.confirm;

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      // eslint-disable-next-line
      prevRecordImgSource:
        get(this.props.record, this.props.source) || undefined,
      imgDisplay: get(this.props.record, this.props.source) || undefined,
      // idFile: null,
      loading: false,
      // loadingProgress: 0,
      isShowCropperModal: false,
      hasErr: false,
    };
  }
  
  static getDerivedStateFromProps(nextProps, prevState){
    if (
      get(nextProps.record, nextProps.source) !== prevState.prevRecordImgSource
    ) {
      return {
        prevRecordImgSource: get(nextProps.record, nextProps.source),
        imgDisplay:
          get(nextProps.record, nextProps.source) || nextProps.defaultValue,
      };
    }
    return {};
  };

  // componentDidMount() {
  //   window.addEventListener('beforeunload', this.onUnload);
  //   // this.onLoad();
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('beforeunload', this.onUnload);
  // }

  // onLoad = () => {
  //   const getUrl = localStorage.getItem('url');
  //   if (getUrl) {
  //     this.onRemove(getUrl);
  //   }
  // };

  onUnload = e => {
    if (this.state.imgDisplay && this.props.form) {
      localStorage.setItem('url', this.state.imgDisplay);
      // eslint-disable-next-line
      e.returnValue = '';
    }
  };

  onHideCropperModal = () => {
    this.setState({
      isShowCropperModal: false,
    });
  };

  onChangePreview = async ({ croppedFile }) => {
    try {
      const { onUploadSuccess, source, form, record, typeFile } = this.props;
      if (this.state.imgDisplay) {
        this.onRemove(this.state.imgDisplay);
      }
      this.setState({
        isShowCropperModal: false,
        loading: true,
      });

      // const responseS3 = await getUrl(croppedFile.name, croppedFile.type); // upload file NAME len server, nhận về presignUrl với url
      const responseS3 = await getUrl({
        fileName: croppedFile.name,
        typeFile,
        contentType: croppedFile.type,
      });
      if (responseS3?.data) {
        try {
          const rest = await uploadMedia(responseS3?.data?.presignUrl, croppedFile); // truyên presign url vào để put lên s3
          if (rest?.status === 200) {
            // console.log(rest, 'rest');
            this.setState({
              imgDisplay: responseS3.data.urlFile,
              loading: false,
              hasErr: false,
              // idFile: responseS3.data.id,
              // loadingProgress: 100,
            });
            onUploadSuccess && onUploadSuccess(record, responseS3.data.urlFile);
            form &&
              form.setFieldsValue({
                [source]: responseS3.data.urlFile,
              });
          }
          else {
            this.setState({
              loading: false,
              hasErr: true,
            });
            notification.error({
              message: I18n.t('error.title'),
              description: I18n.t('error.errorUploadFile'),
              position: 'tr',
            });
          }
          return responseS3.data;
        } catch (error) {
          return error;
        }

      }
      return responseS3;
    } catch (error) {
      if (error.code === 401) {
        // yield put(logout());
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('name');
        localStorage.removeItem('id');
        this.props.loginFail(error);
        notification.error({
          message: I18n.t('error.title'),
          description: I18n.t('error.error401'),
          position: 'tr',
        });
      } else
        notification.error({
          message: I18n.t('error.title'),
          description: I18n.t('error.description'),
          position: 'tr',
          autoDismiss: 15,
        });
      this.setState({
        file: null,
        imgDisplay: null,
        loading: false,
        hasErr: true,
        // loadingProgress: 0,
      });
      return error;
    }
  };

  onRemove = () => {
    // del('/deleteFile', { url });
    // fetch(`${process.env.REACT_APP_SERVER_URL}/api/medias`, {
    //   method: 'DELETE',
    //   body: JSON.stringify({ key: url }),
    // });
    this.setState({
      file: null,
      imgDisplay: null,
      loading: false,
      // loadingProgress: 0,
    });
    localStorage.removeItem('url');
  };

  renderImage() {
    const { style, defaultText, defaultIcon, defaultValue } = this.props;
    const { loading, imgDisplay, hasErr } = this.state;
    if (loading) {
      return (
        <Avatar style={style}>
          {/* <Progress percent={this.state.loadingProgress} showInfo={false} /> */}
          <Spin />
          <div className="ant-upload-text">{I18n.t('upload.uploading')}</div>
        </Avatar>
      );
    }
    if (!imgDisplay) {
      return (
        <Avatar
          icon={defaultIcon}
          src={(!defaultText && user) || defaultValue}
          style={style}
        >
          <span className="default-image">{defaultText}</span>
        </Avatar>
      );
    }

    if (!hasErr) {
      return <Avatar src={imgDisplay} style={style} />;
    }
    return (
      <Avatar src={imgDisplay} style={style}>
        {/* <Progress
          percent={this.state.loadingProgress}
          showInfo={false}
          status="exception"
        /> */}
        <div className="ant-upload-text">{I18n.t('upload.uploadFailed')}</div>
      </Avatar>
    );
  }

  render() {
    const {
      hasCrop,
      form,
      source,
      style,
      className,
      label,
      defaultValue,
      cropDimension,
      header,
    } = this.props;
    const { imgDisplay } = this.state;
    const props = {
      showUploadList: false,
      action: uploadUrl,
      beforeUpload: file => {
        this.setState(() => ({
          file,
          isShowCropperModal: hasCrop,
        }));
        if (!hasCrop) {
          this.onChangePreview({ croppedFile: file });
        }
        return false;
      },
    };

    return (
      <UploadImageWrapper className={className}>
        <FormItem label={label}>
          {form &&
            form.getFieldDecorator(source, {
              initialValue: imgDisplay || defaultValue,
            })(<Input style={{ display: 'none' }} />)}
          <Upload {...props} accept="image/*">
            <div className="image-uploader">
              {this.renderImage()}
              <div className="image-hover" style={style}>
                <Icon type="camera" className="image-hover-icon" />
              </div>
            </div>
          </Upload>
          <AvatarCropperModal
            cropDimension={cropDimension}
            isShowModal={this.state.isShowCropperModal}
            onHideModal={this.onHideCropperModal}
            onChangePreview={this.onChangePreview}
            image={this.state.file}
          />
        </FormItem>
        {header && <div className="header">{I18n.t(header)}</div>}
      </UploadImageWrapper>
    );
  }
}

UploadImage.propTypes = {
  showErrorMsg: PropTypes.func,
  form: PropTypes.object,
  source: PropTypes.string,
  record: PropTypes.object,
  style: PropTypes.object,
  defaultText: PropTypes.string,
  defaultIcon: PropTypes.string,
  onUploadImage: PropTypes.func,
  className: PropTypes.string,
  cropDimension: PropTypes.object,
  hasCrop: PropTypes.bool,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  onUploadSuccess: PropTypes.func,
  header: PropTypes.string,
  typeFile: PropTypes.number,
  loginFail: PropTypes.func,
};

UploadImage.defaultProps = {
  hasCrop: true,
  typeFile: 0,
};

export default connect(
  null,
  dispatch => ({
    loginFail: error => {
      dispatch(loginFail(error));
    },
  }),
)(UploadImage);
