import { keyBy, isArray } from 'lodash';
import { PRIMARY_KEY } from './slice';
import { getValidData } from '../../utils/tools';

export const convertRequestParams = (type, { filter, ...params }) =>
  // resource
  // options = { primaryKey: PRIMARY_KEY }
  {
    const formatedParams = {
      ...params,
      count: undefined,
    };
    const validFilter = getValidData(filter, true);
    switch (type) {
      case 'GET_ALL':
        return {
          ...formatedParams,
          ...validFilter,
        };
      case 'GET_BY_ID':
        return {
          ...params,
          [PRIMARY_KEY]: Number(params[PRIMARY_KEY]),
        };
      case 'EDIT':
        delete formatedParams.id;
        return getValidData(formatedParams);
      case 'CREATE':
        return getValidData(formatedParams);
      case 'DELETE':
      default:
        return {};
    }
  };

export const convertResponseData = (
  type,
  response,
  options = { primaryKey: PRIMARY_KEY },
) => {
  switch (type) {
    case 'GET_ALL':
      return {
        data: keyBy(
          response?.data?.map(data => ({
            ...data,
            [options.primaryKey]: data[options.primaryKey || PRIMARY_KEY],
            backupId: data[PRIMARY_KEY],
          })),
          options.primaryKey || PRIMARY_KEY,
        ),
        ids: response?.data?.map(
          data => data[options.primaryKey || PRIMARY_KEY],
        ),
        total: response?.context?.paging?.totalItems || 0,
      };
    case 'GET_BY_ID':
    case 'CREATE':
      if (!response?.data) return null;

      if (isArray(response.data)) return response.data;
      
      return response.data.id
        ? {
            ...response.data,
            [PRIMARY_KEY]: response.data[options.primaryKey || PRIMARY_KEY],
          }
        : null;
    case 'EDIT':
      return response && response ? { ...response } : null;
    case 'DELETE':
    default:
      return response;
  }
};
