import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const DocumentaryFilesShow = props => (
  <RestShow {...props} hasEdit resource="documentaryFiles">
    <RestFieldItem source="name" header="documentaryFiles.name" />
    <RestFieldItem source="createdAt" header="documentaryFiles.createdAt" />
    <RestFieldItem source="sourceNumber" header="documentaryFiles.sourceNumber" />
  </RestShow>
);

export default DocumentaryFilesShow;
