import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CreateButton from 'components/RestActions/CreateButton';
// import { push } from 'connected-react-router';
import { convertObjToSearchStr, checkRole } from 'utils/tools';
import moment from 'moment';
import RestShow from '../../rest/Show';
// import RestFieldItem from '../../../components/RestField/RestFieldItem';
import MessageInfo from '../components/MessageInfo';

const InternalMessagesShow = props => {
  // const messageCurrent = useSelector(
  //   state => state.internalMessage.currentData,
  // );
  const { match } = props;
  
  const customBreadcrumb = [
    {
      key: 'page-title',
      title: i18next.t('internalMessages.header'),
      path: `/internalMessages/${match.params?.model || 'received'}`,
    },
    {
      key: 'page-detail',
      title: i18next.t('internalMessages.detailInternalMessage'),
      path: props.location.pathname || '/',
    },
  ];

  const profileData = useSelector(state => state.auth.data);
  const currentData = useSelector(state => state.internalMessages.currentData);
  const permissionData = useSelector(state => state.auth.permissionData);

  const checkShowAction = () => {
    return (
      !currentData?.isDeleted && profileData.id === currentData?.createdUser?.id
      // currentData.totalSeen < 1 &&
      // (moment().diff(moment(currentData?.createdAt), 'hours') <= 12)
      // checkRole(permission, permissionData)
    );
  };

  const checkDisabledAction = () => {
    // console.log(process.env.REACT_APP_EXPIRED_TIME_INTERNAL, 'expired_internal')
    return (
      // currentData?.totalSeen > 0 ||
      moment().diff(moment(currentData?.createdAt), 'seconds') >
        (process.env.REACT_APP_EXPIRED_TIME_INTERNAL || 86400)
    );
  };

  return (
    <RestShow
      {...props}
      hasEdit={checkShowAction()}
      hasDel={checkShowAction()}
      disabledEdit={checkDisabledAction()}
      disabledDel={checkDisabledAction()}
      resource="internalMessages"
      {...match.params?.model && {customResource: `internalMessages/${match.params.model}`}}
      customBreadcrumb={customBreadcrumb}
      defaultOptions={{ customApiResource: 'internaltasks' }}
      isDeleted={false}
      extraAction={(
        <div>
          {checkRole(['DocumentTaskCreate', 'AllowAll'], permissionData) ? (
            <CreateButton
              header="button.createDocumentary"
              resource="documentaries"
              gotoCreatePage={() =>
                props.history.replace(
                  `#documentaries/create?${convertObjToSearchStr({
                    title: currentData.title,
                    content: currentData.content,
                    priority: currentData.priority,
                    // activities: currentData.activities,
                    medias: currentData.medias,
                  })}`,
                )}
            />
          ) : null}
          <CreateButton
            header="button.forward"
            style={{marginLeft: 15}}
            resource="internalMessages"
            gotoCreatePage={() =>
              props.history.replace(
                `#internalMessages/create?${convertObjToSearchStr({
                  title: `${i18next.t('button.forward')}: ${currentData.title}`,
                  content: currentData.content,
                  priority: currentData.priority,
                  // activities: currentData.activities,
                  medias: currentData.medias,
                })}`,
              )}
          />
        </div>
      )}
      customMessageItemName="internalMessages.customMessageItemName"
    >
      <MessageInfo idTask={props?.match?.params?.id} />
    </RestShow>
  );
};

InternalMessagesShow.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default InternalMessagesShow;
