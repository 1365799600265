import {
  takeLatest,
  call,
  put,
  // take,
  select,
} from 'redux-saga/effects';

import {
  getAllFoldersApi,
  createFolderApi,
  editFolderApi,
  delFolderApi,
  createFileApi,
  editFileApi,
  delFileApi,
  getByIdFileApi,
  getByIdFileOrFolderApi,
} from 'api/documentBoxes';

import { apiWrapper } from 'utils/reduxUtils';
import { goBack } from 'connected-react-router';
import crudActions from 'redux/crudActions';
// import { convertResponseData } from 'redux/crudCreator/dataProvider';
import { makeCRUDSaga } from '../crudCreator/saga';
import { RESOURCE, actions } from './slice';
import { closeModal } from '../modal/slice';

function* getAllFoldersSaga({ payload }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: false, isShowSuccessNoti: false },
      getAllFoldersApi,
      payload,
    );

    // const pageSize = payload.pageSize || 1;

    // const result = convertResponseData('GET_ALL', response);
    if (response) {
      yield put(
        actions.getAllFoldersSuccess({
          ...response,
        }),
      );
    }
  } catch (error) {
    yield put(actions.getAllFoldersFailure(error));
  }
}

function* createFolderSaga({payload: { options, data }}) {
  try {
    const currentModal = yield select(state => state.router.location.hash);
    const response = yield call(
      apiWrapper,
      {
        isShowProgress: true,
        isShowSuccessNoti: true,
        ...options,
      },
      createFolderApi,
      data,
    );
    
    if (response) {
      yield put(actions.createFolderSuccess(response));
      if (options.isBack) {
        yield put(currentModal ? closeModal() : goBack());
      }
    } else {
      // showInAppNoti({ title: null, content: response.message, type: 'error' });
      yield put(actions.createFolderFailure({ data: response }));
    }
  } catch (error) {
    yield put(actions.createFolderFailure({ data: error }));
  }
}

function* editFolderSaga({payload: { options, id, folderName }}) {
  try {
    const currentModal = yield select(state => state.router.location.hash);
    const response = yield call(
      apiWrapper,
      {
        isShowProgress: true,
        isShowSuccessNoti: true,
        ...options,
      },
      editFolderApi,
      id,
      {folderName},
    );
    
    if (response) {
      yield put(actions.editFolderSuccess(response));
      if (options.isBack) {
        yield put(currentModal ? closeModal() : goBack());
      }
    } else {
      // showInAppNoti({ title: null, content: response.message, type: 'error' });
      yield put(actions.editFolderFailure({ data: response }));
    }
  } catch (error) {
    yield put(actions.editFolderFailure({ id, error }));
  }
}

function* delFolderSaga({payload: { options, id }}) {
  try {
    // const currentModal = yield select(state => state.router.location.hash);
    const response = yield call(
      apiWrapper,
      {
        isShowProgress: true,
        isShowSuccessNoti: true,
        ...options,
      },
      delFolderApi,
      id,
    );
    
    if (response) {
      yield put(actions.delFolderSuccess({id, isDeleted: true}));
      // if (options.isBack) {
      //   yield put(currentModal ? closeModal() : goBack());
      // }
    } else {
      yield put(actions.delFolderFailure({ data: response }));
    }
  } catch (error) {
    yield put(actions.editFolderFailure({ error, id}));
  }
}

function* createFileSaga({payload: { options, data, folderId }}) {
  try {
    const { pageSize, sortBy, sortDesc, filter, selectedFolder } = yield select(
      state => state.documentBoxes,
    );
    const currentModal = yield select(state => state.router.location.hash);
    const response = yield call(
      apiWrapper,
      {
        isShowProgress: true,
        isShowSuccessNoti: true,
        ...options,
      },
      createFileApi,
      folderId,
      data,
    );
    
    if (response) {
      yield put(actions.createFileSuccess({data: response, folderId}));
      yield put(crudActions.documentBoxes.getAll(
        {pageSize, pageNumber: 1, sortBy, sortDesc, filter}, 
        {isRefresh: true, customApiResource: `documentboxs/${selectedFolder?.id}/files`}));
      if (options.isBack) {
        yield put(currentModal ? closeModal() : goBack());
      }
    } else {
      // showInAppNoti({ title: null, content: response.message, type: 'error' });
      yield put(actions.createFileFailure({ data: response }));
    }
  } catch (error) {
    yield put(actions.createFileFailure({ data: error }));
  }
}

function* getByIdFileSaga({payload: {folderId, id, options={isShowProgress: false}} }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: options.isShowProgress },
      getByIdFileApi,
      folderId,
      id,
    );
    yield put(actions.getByIdFileSuccess({ data: response.data }));
  } catch (error) {
    yield put(actions.getByIdFileFailure({ data: error }));
  }
}

function* editFileSaga({payload: { options, id, folderId, documentName }}) {
  try {
    const currentModal = yield select(state => state.router.location.hash);
    const response = yield call(
      apiWrapper,
      {
        isShowProgress: true,
        isShowSuccessNoti: true,
        ...options,
      },
      editFileApi,
      folderId,
      id,
      {documentName},
    );
    
    if (response) {
      yield put(actions.editFileSuccess(response));
      if (options.isBack) {
        yield put(currentModal ? closeModal() : goBack());
      }
    } else {
      // showInAppNoti({ title: null, content: response.message, type: 'error' });
      yield put(actions.editFileFailure({ data: response }));
    }
  } catch (error) {
    yield put(actions.editFileFailure({ data: error }));
  }
}

function* delFileSaga({payload: { options, id, folderId }}) {
  try {
    // const currentModal = yield select(state => state.router.location.hash);
    const response = yield call(
      apiWrapper,
      {
        isShowProgress: true,
        isShowSuccessNoti: true,
        ...options,
      },
      delFileApi,
      folderId,
      id,
    );
    
    if (response) {
      yield put(actions.delFileSuccess({data: {id}, folderId, isDeleted: true}));
      // if (options.isBack) {
      //   yield put(currentModal ? closeModal() : goBack());
      // }
    } else {
      yield put(actions.delFileFailure({ data: response }));
    }
  } catch (error) {
    yield put(actions.editFileFailure({ error, id}));
  }
}

function* getByIdFileOrFolderSaga({payload: {id, options={isShowProgress: false}} }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: options.isShowProgress },
      getByIdFileOrFolderApi,
      id,
    );
    yield put(actions.getByIdFileOrFolderSuccess({ data: response }));
  } catch (error) {
    yield put(actions.getByIdFileOrFolderFailure({ data: error }));
  }
}

export default [...makeCRUDSaga(RESOURCE, [], actions), 
  takeLatest([actions.getAllFolders.type], getAllFoldersSaga),
  takeLatest([actions.createFolder.type], createFolderSaga),
  takeLatest([actions.editFolder.type], editFolderSaga),
  takeLatest([actions.delFolder.type], delFolderSaga),
  takeLatest([actions.createFile.type], createFileSaga),
  takeLatest([actions.getByIdFile.type], getByIdFileSaga),
  takeLatest([actions.editFile.type], editFileSaga),
  takeLatest([actions.delFile.type], delFileSaga),
  takeLatest([actions.getByIdFileOrFolder.type], getByIdFileOrFolderSaga)];
