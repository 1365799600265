import styled, { createGlobalStyle } from 'styled-components';
import { CLASS_UTILITY } from 'utils/css';

const AppWrapper = styled.div`
  .gradientBackground {
    background-image: ${({ theme }) =>
      `linear-gradient(90deg, ${theme.palette.lightPrimary}, ${theme.palette.primary})`};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${CLASS_UTILITY}
  .note {
    font-size: 12px;
    color: ${({ theme }) => theme.text.note};
  }
  .black {
    color: ${({ theme }) => theme.text.primary};
  }
  & > * {
    font-family: 'Open Sans', sans-serif;
  }
  .anticon:before {
    display: block;
    font-family: 'anticon', 'dana-dashboard' !important;
  }
  .anticon:after {
    display: block;
    font-family: 'anticon', 'dana-dashboard' !important;
  }
  .text-primary {
    color: ${({ theme }) => theme.palette.primary};
  }
  .text-headerTable {
    color: ${({ theme }) => theme.palette.headerTable};
  }
  .bg-primary {
    background: ${({ theme }) => theme.background.primary};
  }
  .bg-warning {
    background: ${({ theme }) => theme.background.warning};
  }
  .bg-error {
    background: ${({ theme }) => theme.background.error};
  }
  .t-14px-1\\.57 {
    font: normal normal 14px/1.57 ${({ theme }) => theme.fonts.primary};
  }
  .t-500-14px-1\\.57 {
    font: normal 500 14px/1.57 ${({ theme }) => theme.fonts.primary};
  }
  .t-500-16px-1\\.5 {
    font: normal 500 16px/1.5 ${({ theme }) => theme.fonts.header};
  }
  .t-500-24px-1\\.17 {
    font: normal 500 16px/1.17 ${({ theme }) => theme.fonts.header};
  }
  .ml-8px {
    margin-left: 8px;
  }
  .ant-card-body {
    padding-top: 20px
  }

  /* ------------------ Override antd---------------- */
  .ant-pagination .ant-pagination-item-active a {
    ${'' /* color: #ffffff; */}
    color: ${({ theme }) => theme.text.primary};
  }
  .ant-pagination-item-disabled {
    pointer-events: none; /* fix call api when click page number disabled */
  }

${
  '' /* .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
 border: none;
} */
}

  .ant-popover-inner-content 
  {
    .ant-popover-message {
      display: flex;
      align-items: center;
      i {
        top: auto;
      }
    }
    .ant-popover-buttons .ant-btn {
      padding-left: 10px;
      padding-right: 10px;
      height: 30px;
    }
  }
  .ant-form-item .ant-form-explain {
      margin-top: 2px;
    }

  .ant-select-dropdown {
    .avatar-item {
      width: 100%;
      & > div {
        width: 100%;
        a {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .loading {
      text-align: center;
      margin: auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  /* style tree select */
  .ant-select-tree-treenode-switcher-close {
    display: flex;
    align-items: center;
  }
  .ant-form-item-required::before {
    content: '';
  }

  .ant-form-item-required::after {
    display: inline-block !important;
    ${'' /* display: table-cell; */}
    margin-left: 4px !important;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*' !important;
  }
  /* fix tràn text trên tootip khi nhập chữ không ngăn cách nhau */
  .ant-popover-inner-content {
    word-break: break-word;
  }
  ${
    '' /* @media not all and (min-resolution:.001dpcm) { @media {
  .safari_only { 
      .ant-row::before {
        display: table-row;
      }
  }
}} */
  }
  .ant-row::before {
    display: flex;
  }

  @media only screen and (max-width: 767px) {
    select,
    textarea,
    input {
      font-size: 14px;
    }
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 430px) {
    .ant-notification {
      width: 80vw;
    }
    .ant-select-dropdown {
      min-width: 300px;
      max-width: calc(100vw - 60px);
    }
  }
`;

export default AppWrapper;
