import React, { useState } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
// import { fetch } from 'whatwg-fetch';
import { FILE_TYPES } from 'configs/localData';
import { Icon, Button, Row, Col } from 'antd';
import { isImageFile, getExtensionFile, downloadFileByURL, handleViewFile } from 'utils/tools';
import ViewFileModal from '../ModalViewFile';
import FilesListStyles from './styles';
// import { getPrivateUrlApi } from '../../../api/uploadMedia';

const FilesList = ({ medias, header }) => {
  const [visibleModalViewFile, setVisibleModalViewFile] = useState(false);
  const [urlViewObj, setUrlViewObj] = useState({ name: '', url: '/' });
  const getPropertyFile = (property, media) => {
    return getExtensionFile(media?.fileName)
      ? FILE_TYPES.find(
        (file) =>
          file.value.toLocaleLowerCase() ===
          getExtensionFile(media?.fileName).toLocaleLowerCase(),
      )?.[property]
      : null;
  };

  const handleClick = async (e, item) => {
    e.preventDefault();
    handleViewFile(item, (data = {}) => {
      data.data && setUrlViewObj(data.data);
      data.visible && setVisibleModalViewFile(data.visible);
    });
    // fetch(item?.urlFile, {
    //   headers: {
    //     // 'Access-Control-Allow-Origin':'*',
    //     // Origin: '*',
    //   },
    // })
    //   .then((res) => {
    //     if(res.status === 200) {
    //       if (
    //         item?.urlFile &&
    //         /\.(xlsx|xls|xlsm|pdf|doc|docx|ppt|pptx)$/i.test(item.fileName)
    //       ) {
    //         setUrlViewObj({ name: item.fileName, url: `//docs.google.com/gview?embedded=true&url=${encodeURIComponent(item.urlFile)}` });
    //         setVisibleModalViewFile(true);
    //       } else {
    //         window.open(item?.urlFile, '_blank');
    //       }
    //     }
    //     else {
    //       notification.error({
    //         message: i18next.t('error.title'),
    //         description: i18next.t('error.errorFile403'),
    //         duration: 2,
    //         position: 'tr',
    //       });
    //     }
    //   })
  };

  const onCancel = () => {
    setVisibleModalViewFile(false);
  };

  return (
    <FilesListStyles className="list-file">
      <div className="title-label">
        {header
          ? `${i18next.t(header)}: `
          : `${i18next.t('medias.attachedFile')}: `}
      </div>
      {!medias?.length && (
        <div className="empty-media">{i18next.t('medias.empty')}</div>
      )}
      <Row className="list-file-item" gutter={15}>
        {medias?.map((item) => {
          return (
            <Col key={item.id} className="col-file-item">
              <div className="file-item">
                <Button
                  className="download-file"
                  onClick={() => {
                    downloadFileByURL(item);
                  }}
                >
                  <Icon type="download" />
                </Button>
                <span
                  // href="javascript:void(0)"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  role="presentation"
                  className="a-file-item"
                  onClick={(e) => handleClick(e, item)}
                >
                  <span className="icon-file-name">
                    {isImageFile(item?.fileName) ? (
                      <div className="wrapper-file-item">
                        <img
                          src={item?.urlFile}
                          alt=""
                          className="image-file"
                        />
                      </div>
                    ) : (
                      <div className="wrapper-file-item">
                        <Icon
                          type={getPropertyFile('icon', item) || 'file'}
                          style={{
                              color: getPropertyFile('color', item),
                            }}
                          className="icon-file"
                        />
                      </div>
                      )}
                    <span className="name-file">{item?.fileName}</span>
                  </span>
                </span>
              </div>
            </Col>
          );
        })}
      </Row>
      {visibleModalViewFile && (
        <ViewFileModal visible onCancel={onCancel} urlViewObj={urlViewObj} />
      )}
    </FilesListStyles>
  );
};
FilesList.propTypes = {
  medias: PropTypes.array,
  header: PropTypes.string,
};
export default FilesList;
