import React from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import i18next from 'i18next';
import {getFileName} from 'utils/tools';
// import { getTreeFoldersSelect } from 'redux/documentBoxes/selectors';
// import RestTreeSelect from 'components/RestInput/RestTreeSelect';
import RestMultiPhotos from 'components/RestInput/RestMultiPhotos';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const BoxFilesForm = ({isEdit, form, ...props}) => {
  // const listFolders = useSelector(state => getTreeFoldersSelect(state));
  // const selectedFolderId = useSelector(state => state.documentBoxes.selectedFolder?.id);
  // const checkSelectedFolder = () => {
  //   if(listFolders?.find(item => item.id === selectedFolderId || item?.children?.find(childItem => childItem.id === selectedFolderId))) return selectedFolderId;
  //   return null;
  // }

  const handleSetFileName = (fileName) => {
    const name = form.getFieldValue('name');
    if (!name || (name && name.trim().length === 0)) {
      form.setFieldsValue({ name: getFileName(fileName) })
    }
  }
  return (
    <div {...props}>
      {/* {!isEdit && (
        <RestTreeSelect
          required
          source="folderId"
          header="boxFiles.folder"
          placeholder="boxFiles.folder"
          treeData={listFolders}
          defaultValue={checkSelectedFolder()}
          treeCheckable={false}
        />
      )} */}
      <RestInputItem
        required
        source="name"
        header="boxFiles.documentName"
        placeholder="boxFiles.documentName"
        rules={[
          {
            max: 500,
            message: i18next.t('input.fileName.validateMsg.maxLength', {
              max: 500,
            }),
          },
        ]}
      />
      {!isEdit && (
        <RestMultiPhotos
          required
          resource="documentBoxes"
          multiple={false}
          // defaultSourceKey="media"
          source="media"
          header="boxFiles.attachedFile"
          placeholder="documentaries.medias"
          requiredMessage="input.medias.validateMsg.required"
          typeFile={4}
          setFileName={handleSetFileName}
        />
      )}
    </div>
  );};

BoxFilesForm.propTypes = {
  isEdit: PropTypes.bool,
  form: PropTypes.object,
};

BoxFilesForm.defaultProps = {
  isEdit: false,
}

export default BoxFilesForm;
