import React from 'react';
// import i18next from 'i18next';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { checkRole } from 'utils/tools';
// import HomeWrapper from './style';
// import PageTitle from '../../components/common/PageTitle';
// import SummaryRow from './components/SummaryRow';

const Home = (props) => {
  // const summaries = useSelector(state => state.config.summaries);

  const rolesUser = useSelector(state => state.auth.role);
    return (
      rolesUser ? (
        <Redirect
          to={{
          pathname: checkRole(['Quan_Tri_Vien'], rolesUser) ? '/users' : '/documentaries',
          // eslint-disable-next-line
          state: { from: props.location },
        }}
        />
) : null
    );
  }
  // return (
  //   <HomeWrapper>
  //     {/* <PageTitle>{i18next.t('home.title.overview')}</PageTitle> */}
  //     {/* <SummaryRow summaries={summaries} /> */}
  //   </HomeWrapper>
  // );
// };

Home.propTypes = {};

export default Home;
